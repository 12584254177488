.table,
.im-card-table,
.im-card-no-border-table{
    @extend .mb-0;
    font-family: "Source Sans Pro";
}

.mat-table{
    .mat-header-row{
        height: 35px;
    }
}

.im-card-table-noborder-spacing{
    tr .mat-cell{
        border-right: 1px solid $border-light-gray;
        span{
            border:0!important;
        }
    }
}
.bordered-checkbox.mat-checkbox{
    border:1px solid #4D4F5C;
    height:22px;
    .mat-checkbox-inner-container.mat-checkbox-inner-container-no-side-margin{
        border:0 !important;
        
        display: inline-block;
        padding-right: 9px;
        word-break: break-word;
        margin-bottom: 14px;
    }
    .mat-checkbox-frame{border:0;}
   &.mat-checkbox-checked .mat-checkbox-background,
   &.mat-checkbox-indeterminate .mat-checkbox-background{
    background: transparent;
   } 
   .mat-checkbox-checkmark-path{stroke: #4D4F5C !important;}
}

.table-header_uppercase{
    thead th, .mat-sort-header-button {
        text-transform: uppercase;
    }
}

.im-card-table , .im-card-no-border-table {
    thead th {
        color: #a4a7b5;
        background: #d5def1;
        border-bottom:none;
        border-top: none;
        vertical-align: middle!important;
        @include im-font(14px,18px,bold);
        color: $label-dark;
        padding: 4px 14px 0 14px!important;
        border-right: 1px solid $border-light-gray;
        &:last-child{
            border-right: none;
        }
    }

    tbody{
        tr{
        .mat-cell{
            @include im-font(14px,20px);
            color: #4D4F5C;
            padding: 14px 0px 0 14px!important;
            word-break: break-word;
            &.inner-table-with-nopadding {
                padding: 0 !important;
            }
            span {
                border-right: 1px solid $border-light-gray;
                width: 100%;
                display: inline-block;
                padding-right: 9px;
                word-break: break-word;
                margin-bottom: 14px;

            }
            &:last-child{
                span {
                        border:none;

                }
            }
            &.mat-column-fileLocation{
                text-align: center;
            }
        }

        }
    }
}


.mat-list-base {
    .mat-list-item {
        .mat-line
        {
            color: #4D4F5C;
            @include im-font(14px,20px);
        }
    }
}

.no-brick-rt-brdr{
    tbody {
      tr {
          .mat-cell span {
            border-right: unset !important;
         }
      }
    }
}

.table-fixed {
  table-layout: fixed;
}

.radio-btn .mat-radio-label{
    .mat-radio-container{
      border: 1px solid grey!important;
      border-radius: 18px;
      padding: 10px 2px;
      margin-bottom: 0px;
    }
  }
