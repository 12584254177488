.green-slide-toggle-bar {
    .p-inputswitch .p-inputswitch-slider:after{
    content: "No";
    font-size: 50%;
    font-weight: 700;
    color: #fff;
    position: absolute;
    left: 28px;
    top: 7px;
    }
}
.green-slide-toggle-bar {
    .p-inputswitch .p-inputswitch-slider:after{
    content: "Yes";
    font-size: 50%;
    font-weight: 700;
    color: #fff;
    position: absolute;
    left: 6px;
    top: 8px;
    }
}