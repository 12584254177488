.filter-all-select {
  .p-checkbox-label {
    line-height: 20px;
    margin: 0;
  }
}
.nofify-bene-checkbox {
  .p-field-checkbox > label {
    margin-left: 0.5rem;
    line-height: 1;
    /* top: 7px; */
    margin-top: 5px;
  }
}
.filter-all-select {
  .p-checkbox-label {
    line-height: 20px;
    margin: 0;
  }
}
.processing-checkbox {
  .p-checkbox-label {
    margin-bottom: 0px;
    font-size: 14px;
    color: #4d4f5c;
  }
}

.decision-check {
  .p-checkbox-label {
    margin-bottom: 0px;
  }
}

.gc-re-other-recruitment-accordian-checkbox {
  .p-checkbox-label {
    margin: 0 0 0 10px;
    font-size: 20px;
    font-weight: 600;
    color: #7b7b7b;
  }
}

.checkbox-label-allign {
  .p-checkbox-label {
    line-height: 1;
    margin-top: 0.5rem;
  }
}

.client-project-documents-checkbox-holder {
  width: max-content !important;
  margin-right: 30px;
  margin-top: 12px;
  .p-checkbox-label {
    line-height: 1;
    margin-top: 0.5rem;
    width: max-content;
    display: block;
    white-space: nowrap;
  }
}

.custom-checkbox {
  .p-checkbox-label {
    padding-top: 5px;
    line-height: 20px;
  }
}

.tree-select-checkbox-label {
  margin-bottom: 0px;
  line-height: 23px;
  cursor: pointer !important;
  &.p-disabled {
    cursor: not-allowed !important;
  }
}

.manage-case-status {
  .checkbox-inline {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: end;
    input {
      width: 16px;
      height: 16px;
    }
    label {
      margin-top: 8px;
    }
  }
}
