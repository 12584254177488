.ben-update-employment {
  padding: 15px 30px;
  background-color: #ffffff;
  .p-dialog-header {
    border-bottom: 2px solid #d1d7de !important;
    padding: 1.5rem 15px !important;
    .p-dialog-title {
      color: #1baade;
    }
    .p-dialog-header-icons {
      .p-dialog-header-icon {
        border: 2px solid #222b45;
        width: 2rem;
        height: 2rem;
        &:hover {
          border-color: #222b45 !important;
          background: #ffffff !important;
        }
        .p-dialog-header-close-icon {
          font-weight: 700;
          font-size: 12px;
          color: #222b45;
        }
      }
    }
  }
  .p-dialog-content {
    padding: 0 15px 0.5rem 15px !important;
  }
}

.timeline-step {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gc-show-notification {
  .p-dialog-header {
    .p-dialog-title {
      color: #3d3d63;
      font-weight: bold !important;
      font-size: 22px !important;
      line-height: 32px;
    }
    .p-dialog-header-icons {
      display: none;
    }
  }
}
.fdns-paystub-upload {
  background-color: #ffffff;
  .p-dialog-header {
    border-bottom: 1px solid #d1d7de !important;
    padding: 10px !important;
    .p-dialog-title {
      color: #1baade;
    }
  }
  .p-dialog-content {
    padding: 10px !important;
    height: 100%;
  }
}
.timeline-bene-ack-dialog {
  background-color: #ffffff;
  .p-dialog-header {
    border-bottom: 1px solid #d1d7de !important;
    padding: 10px !important;
  }
  .p-dialog-content {
    padding: 10px !important;
    height: 100%;
  }
}

.attorney-invite-emp-bene {
  &.p-dialog {
    .p-dialog-header {
      border-bottom: 1px solid;
    }
    .p-dialog-content {
      padding: 2rem 1.5rem 2rem 1.5rem;
    }
  }
}

.build-new-visa-popup {
  max-height: 90% !important;
  &.p-dialog {
    .p-dialog-header {
      border-bottom: 1px dashed;
      .p-dialog-title {
        font-weight: 700;
        font-size: 1.65rem;
      }
    }
    .p-dialog-content {
      padding: 1.5rem;
      overflow-y: auto !important;
      p-radiobutton {
        margin-bottom: 5px;
        .p-radiobutton-label {
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}

.move-to-step-popup {
  max-height: 90% !important;
  &.p-dialog {
    .p-dialog-header {
      border-bottom: 1px dashed;
      padding: 20px 30px !important;
      .p-dialog-title {
        font-weight: 700;
        font-size: 1.65rem;
      }
    }
    .p-dialog-content {
      padding: 30px !important;
      overflow-y: auto !important;
      p-radiobutton {
        margin-bottom: 5px;
        .p-radiobutton-label {
          cursor: pointer;
        }
      }
    }
  }
}

.pdf-preview-step-popup {
  max-height: 90% !important;
  &.p-dialog {
    .p-dialog-header {
      border-bottom: 1px solid #d9d6d6;
      padding: 10px 10px !important;
      .p-dialog-title {
        color: #3d3d63;
        font: normal normal bold 22px Source Sans Pro;
        // font-size: 1.65rem;
      }
    }
    .p-dialog-content {
      padding: 30px !important;
      overflow-y: auto !important;
      p-radiobutton {
        margin-bottom: 5px;
        .p-radiobutton-label {
          cursor: pointer;
        }
      }
    }
  }
}

.dynamic-menu-popup {
  &.p-dialog {
    width: 70%;
    .p-dialog-header {
      .p-dialog-title {
        font-weight: 700;
        color: #186aa5;
      }
    }
  }
}

.i9-identity-docs {
  .p-dialog-header{
    padding: 10px 10px 10px 10px;
  }
  .checkBoxList1 {
    margin-left: 0;
  }
  .i9-docs-subcategory {
    padding-left: 1.6rem !important;
  }
  .doclist-heading,
  .subHeading {
    font-weight: 600 !important;
  }
}


.fb-dialog-style {
  .p-dialog-header {
    .p-dialog-header-icons {
      position: absolute !important;
      top:10px !important;
      right:10px !important;
      }
  }
}

.i9-upload-docs {
  .p-dialog-header{
    padding: 10px 20px 0px 20px!important;
  }
  .checkBoxList1 {
    margin-left: 0;
  }
  .i9-docs-subcategory {
    padding-left: 1.6rem !important;
    font-size: 13px;
  }
  .doclist-heading,
  .subHeading {
    font-weight: 600 !important;
  }
}

.confirm-upload-documents{
  width: 30vw;
  .p-confirm-dialog-message{
    padding-top: 1rem !important;
  }
}
.rfe-response-modal{
  .p-dialog-header{
    justify-content: flex-end !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}