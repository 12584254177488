.review-petition-tab-view.p-tabview {
    border: 0;

    .p-tabview-panels {
        padding: 0px !important;
    }

    .p-tabview-nav {
        background-color: #F5F7F7 !important;

        li {
            width: auto;

            &:not(.p-highlight):not(.p-disabled):hover {
                .p-tabview-nav-link {
                    border-color: #6c757d !important;
                }
            }

            .p-tabview-nav-link {
                background-color: rgba(0, 0, 0, 0) !important;
                border-color: #dee2e6 !important;
                color: #6c757d !important;
                border-width: 0 0 2px 0 !important;
                padding: 0.2rem 0 !important;
                font-size: 16px;

                .p-tabview-title {
                    padding-left: 1rem;
                    padding-right: 1rem;
                    margin: 0;
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                    border-right: 1px solid #CECECE;
                }
            }

            &.p-highlight .p-tabview-nav-link {
                background-color: rgba(0, 0, 0, 0) !important;
                border-color: #2196F3 !important;
            }
        }
    }
}