.pointer {
  cursor: pointer;
}

body {
  font: normal normal normal 14px/20px Source Sans Pro;
}

.icn {
  border: 0;
}

.invalid-message {
  display: block;
}

.disabled {
  opacity: 0.65 !important;
  cursor: default !important;
}

.circle-border {
  border-radius: 50% !important;
}

.videoPlayer {
  height: 310px;
  width: 550px !important;
  margin-left: auto;
  margin-right: auto;
}

.fr-floating-btn {
  display: none !important;
}

.mat-tooltip {
  background-color: white !important;
  color: #333246 !important;
  font-size: 11px !important;
  box-shadow: 0px 5px 10px #ededed;
  border: 1px solid #687c93 !important;
}

.family-information-container {
  .im-input:disabled {
    background-color: #ffffff;
    opacity: 0.6;
  }
}

.fb-filter-container {
  .form-control {
    height: 40px;

    .p-dropdown {
      margin: -6px -12px;
      border: 0px !important;
      height: 38px;
      width: calc(100% + 24px);
    }
  }
}

.font-weight-600 {
  font-weight: 600 !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

ngx-extended-pdf-viewer {
  .annotationLayer {

    .buttonWidgetAnnotation.checkBox input,
    .textWidgetAnnotation input,
    .textWidgetAnnotation textarea,
    .choiceWidgetAnnotation select {
      background-image: none !important;
      background-color: #ffffff !important;
      color: #000000 !important;
    }

    .buttonWidgetAnnotation.checkBox input,
    .buttonWidgetAnnotation.radioButton input,
    .choiceWidgetAnnotation select,
    .textWidgetAnnotation input,
    .textWidgetAnnotation textarea {
      pointer-events: none;
      user-select: none;
    }
  }

  .toolbarField.pageNumber {
    border-radius: 2px !important;
    border: 1px solid rgba(0, 0, 0, .4) !important;
    color: #5a5a5a !important;
    font-size: 12px !important;
    line-height: 14px !important;
  }
}

ngx-extended-pdf-viewer .toolbarField.pageNumber .ngx-extended-pdf-viewer input,
.ngx-extended-pdf-viewer select {
  color: #000000 !important;
}