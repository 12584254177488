input,
.im-input,
.im-input-error,
.im-input-error-outline,
.im-input-success,
.im-textbox,
.select-comp,
.txt-comp,
.mat-select,
.select2-container--default .select2-selection--multiple {
  background-color: $white;
  border-radius: $border-radius-base;
  border: 1px solid $input-border-gray;
  color: $input-color-base;
  width: 100%;
  padding: 8px 16px 10px 16px;
  height: 40px;
  @include im-font(15px, 20px);
  //max-width: 324px;
}

.mat-input-element.mat-form-field-autofill-control {
  background-color: $white;
  color: $input-color-base;
  @include im-font(15px, 20px);
  height: 31px;
}

.select-comp {
  display: block;
  padding-left: 14px !important;
}

.im-textarea {
  width: 100%;
}

.mat-form-field-appearance-outline {
  .mat-form-field-outline {
    color: $input-border-gray;
  }
}

.date-field .mat-form-field-outline {
  height: 40px;
}

.date-field input {
  cursor: pointer;
}

.date-field {
  .mat-form-field-infix {
    border-top: none !important;
    padding: 15px 0px !important;
    margin-top: 8px;
    top: 0;
  }
  //IM-4229
  &.step-date-wrong-invalid-shown.mat-form-field-invalid .mat-form-field-outline-thick {
    color: #7d8fa3;
  }
}

.mat-datepicker-toggle-default-icon {
  width: 24px !important;
  height: 24px !important;
  margin-right: 10px !important;
}

.mat-radio-container {
  height: 14px;
  width: 14px;
}

.mat-radio-label-content {
  height: 20px;
  color: $label-dark;
  @include im-font(16px, 20px, 500);
}

.coverletter-radio-text {
  .mat-radio-label-content {
    font-size: 14px;
  }
}

.mat-radio-button .mat-ripple-element {
  background-color: transparent !important;
}

.mat-radio-group {
  .mat-radio-button {
    margin-left: 20px;
    .mat-radio-label {
      align-items: initial;
    }
    &:first-child {
      margin-left: 0px;
    }
  }
}

.mat-form-field {
  color: $label-dark;
  @include im-font(15px, 22px);
  .mat-form-field-wrapper {
    margin: 0px !important;
    padding: 0px !important;
  }
  .mat-icon-button {
    width: 19px;
    height: 17px;
  }
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  padding-left: 14px !important;
  min-height: 45px;
  padding-right: 0px !important;
  .mat-form-field-infix {
    padding: 0px !important;
  }
}

.mat-select {
  border: 1px solid $input-border-gray;
  padding: 8px 10px 10px 16px !important;
  .mat-select-trigger {
    @include im-font(15px, 21px);
    color: $label-dark;
  }
  .mat-select-value {
    min-width: 20px;
    color: $label-dark;
  }
}
.mat-paginator-outer-container {
  padding-bottom: 10px;
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none;
  }
  .mat-select {
    width: auto;
    margin-top: -10px;
  }
  .mat-paginator-page-size {
    align-items: inherit;
  }
}
.mat-form-field-wrapper {
  .mat-form-field-underline {
    display: none;
  }
}
.example-chip-list {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-infix {
        padding: 0;
        .mat-form-field-label-wrapper {
          top: 0px;
        }
      }
    }
    .mat-form-field-underline {
      display: block;
    }
  }
}
.chips-contianer {
  margin-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.mat-form-field-label-wrapper {
  left: 14px;
  @include im-font(15px, 21px);
  color: $label-dark;
}

.mat-form-field-appearance-legacy {
  .mat-form-field-infix {
    padding: 0;
  }
  &.no-border-top .mat-form-field-infix {
    border-top: 0;
  }
  .mat-form-field-label {
    color: $input-color-base;
    left: 14px;
  }
  // .mat-form-field-can-float{
  //     .mat-input-server{
  //         &:focus{
  //             +.mat-form-field-label-wrapper{
  //                 .mat-form-field-label{
  //                     transform: none;
  //                 }
  //             }
  //         }
  //     }
  //     &.mat-form-field-should-float{
  //         .mat-form-field-label{
  //             transform: none;
  //         }
  //     }
  //}
}

.mat-form-field-type-mat-select {
  &.ng-dirty {
    .mat-form-field-label-wrapper {
      display: none;
    }
  }
}

.mat-form-field:not(.example-chip-list) {
  &.mat-focused {
    &.mat-primary .mat-select-arrow {
      color: $input-color-base;
    }
    .mat-form-field-label {
      transform: none !important;
      color: $input-color-base;
    }
  }
}

// .cdk-overlay-pane{
//     margin-top:43px;
// }

.mat-option {
  background: none !important;
  color: $input-color-base;
  padding: 0 18px !important;
  @include im-font(15px, 20px);
}

.mat-select-panel {
  max-width: 234px !important;
  border: 1px solid $input-border-gray;
}

.mat-select-panel-wrap {
  margin-top: 43px;
}

div.search-box {
  padding: 3px 14px;
  border: 1px solid #ced4da;
  height: 43px;
  border-radius: 18px;
  width: 300px;

  .search-icon {
    height: 24px;
    width: 24px;
    margin: 6px 0;
  }

  .search-input {
    border: 0;
    outline: 0;
    height: 37px;
    width: calc(100% - 27px);
    margin-right: 3px;
    float: left;
  }
}

.search-input-group {
  width: 250px !important;

  .form-control {
    padding: 5px 30px 5px 8px !important;
    flex: 1 !important;
    height: 38px;
  }

  .input-group-append {
    height: 38px;
    width: 36px;
    margin-left: -36px;
    padding: 0;

    .btn {
      padding: 7px 6px !important;
      margin: 0 !important;
      width: 36px;
      height: 38px;
      min-width: 36px !important;

      .fa {
        margin: 0;
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
    }
  }
}

.p-calendar {
  .p-inputtext {
    @extend .im-input;
    //border-right: 0 !important;
  }
  .p-datepicker-trigger {
    background: white;
    color: $input-border-gray;
    padding: 0 !important;
    border: 1px solid $input-border-gray;
    border-left: 0 !important;
  }
}

.p-datepicker table td {
  padding: 0 !important;
  span.p-highlight {
    color: #040404 !important;
    background: #60fffe;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    transition: box-shadow 0.2s;
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  visibility: hidden !important;
}

.p-radiobutton-label {
  margin-bottom: 0;
}

.radio_green {
  .p-radiobutton {
    .p-radiobutton-box.p-highlight {
      background-color: #33ac00;
      border: #33ac00;
      color: white;
      visibility: visible !important;
      font: normal normal normal 14px/1 FontAwesome;
      .p-radiobutton-icon {
        background-color: #33ac00;
        border: #33ac00;
        color: white;
        visibility: visible !important;
        font: normal normal normal 13px/1 FontAwesome;
        &::before {
          content: "\f00c";
        }
      }
    }
  }
}

.w-100 .p-dropdown {
  width: 100%;
}

.titleSheet {
  .p-inplace {
    .p-inplace-display {
      &:focus {
        box-shadow: none;
      }
      &:not(.p-disabled):hover {
        background: transparent;
      }
      label {
        cursor: pointer;
      }
    }
    .p-inplace-content {
      input {
        width: 30%;
      }
      .p-button-icon-only {
        background: transparent;
        border: transparent;
        color: #2196f3;
        margin-left: 0.5rem;
        margin-bottom: 4px;
      }
    }
  }
}

.mat-slide-toggle-bar {
  border-radius: 12px !important;
  min-width: 50px;
  height: 22px !important;
}

.mat-slide-toggle-thumb-container {
  top: 2px !important;
  left: 1px !important;
}

.mat-slide-toggle-thumb {
  height: 18px !important;
  width: 18px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar::after,
.mat-slide-toggle:not(.mat-checked) .mat-slide-toggle-bar::after {
  font-style: normal;
  font-variant: normal;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px;
  font-family: "Source Sans Pro";
  top: 0px !important;
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: white !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(30px, 0, 0) !important;
  left: 7px !important;
}

.p-autocomplete {
  .p-autocomplete-input {
    border-right: 0;
  }
  .p-autocomplete-input:hover {
    border-color: #ced4da !important;
  }
  .p-autocomplete-input:focus,
  .p-autocomplete-input:active {
    box-shadow: none;
  }
  .p-button {
    background: white;
    border-color: #ced4da;
    color: #495057;
    &:enabled:hover {
      background: white;
      border-color: #ced4da;
      color: #495057;
    }
    &:active {
      border-color: #0b7ad1;
    }
  }
}

.input-disabled {
  input[readonly] {
    background-color: transparent;
    opacity: 0.6;
    cursor: default;
  }
}

.p-inputnumber.create-task-input {
  padding: 0;
  border: 0;
  width: 100%;
  height: 40px;
  .p-inputnumber-input {
    background-color: #ffffff;
    border-radius: 3px;
    border: 1px solid #7d8fa3;
    color: #3f3356;
    padding: 8px 16px 10px 16px;
    height: 40px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    font-family: "Source Sans Pro";
  }
}

textarea.create-task-textarea {
  width: 100%;
  padding: 8px 16px 10px 16px;
}

.lca-soc-code{
  padding-top: 0.3rem !important;
  .mat-form-field-infix{
    border: none!important;
  }
  .mat-select{
    border: 1px solid #c3d0de !important
  }
}

.uscis-number-outer {
  .mat-radio-label-content{
    width:100% !important;
  }
}
.line-height-inherit{
  .mat-radio-label-content{
    line-height: inherit !important;
  }
}