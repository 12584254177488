.p-component-overlay {
  display: none;
}

.p-overflow-hidden {
  overflow: auto !important;
}

body.p-overflow-hidden {
  overflow: hidden !important;
}

.date-filter-calendar {
  .p-calendar {
    width: 100%;
    height: 28px;
  }

  .p-datepicker-touch-ui {
    top: 20vh !important;
    min-width: 40vw !important;
    transform: translate(-50%, 0) !important;
  }

  .p-calendar-w-btn .p-inputtext {
    border-right: 0;
    border-color: #c3c3c3;
    height: 100%;

    &:hover,
    &:focus {
      border-color: #c3c3c3;
      box-shadow: none;
    }
  }

  .p-datepicker-trigger.p-button-icon-only {
    background-color: #ffffff;
    color: #000000;
    border-color: #c3c3c3;
    border-width: 1px 1px 1px 0;

    &:hover,
    &:focus,
    &:active {
      background-color: #ffffff;
      color: #000000;
      border-color: #c3c3c3;
      border-width: 1px 1px 1px 0;
      box-shadow: none;
    }
  }
}

.filter-datepicker {
  &.filter-datepicker-h40 {
    .p-calendar {
      height: 40px;
    }
  }

  .p-calendar {
    width: 100%;
    height: 34px;
  }

  .p-calendar-w-btn .p-inputtext {
    border-right: 0;
    border-color: #c3c3c3;
    height: 100%;
    padding: 8px 12px 10px 12px;

    &:hover,
    &:focus {
      border-color: #c3c3c3;
      box-shadow: none;
    }
  }

  .p-datepicker-trigger.p-button-icon-only {
    background-color: #ffffff;
    color: #000000;
    border-color: #c3c3c3;
    border-width: 1px 1px 1px 0;

    &:hover,
    &:focus,
    &:active {
      background-color: #ffffff;
      color: #000000;
      border-color: #c3c3c3;
      border-width: 1px 1px 1px 0;
      box-shadow: none;
    }
  }
}

.viewby-reports-datepicker {
  .p-calendar {
    width: 250px;
    height: 42px;
  }

  .p-calendar-w-btn .p-inputtext {
    border-right: 0;
    border-color: #c3c3c3;
    height: 100%;

    &:hover,
    &:focus {
      border-color: #c3c3c3;
      box-shadow: none;
    }
  }

  .p-datepicker-trigger.p-button-icon-only {
    background-color: #ffffff;
    color: #000000;
    border-color: #c3c3c3;
    border-width: 1px 1px 1px 0;

    &:hover,
    &:focus,
    &:active {
      background-color: #ffffff;
      color: #000000;
      border-color: #c3c3c3;
      border-width: 1px 1px 1px 0;
      box-shadow: none;
    }
  }
}

.onboard-employment {
  &.p-calendar .p-datepicker {
    top: -150px !important;
  }
}

.calendar-input {
  .p-calendar {
    width: 100%;
  }
}

.fdnsQa-upload-calender {
  &.p-calendar .p-datepicker {
    top: -120px !important;
  }
}

.im-input-calendar {
  .p-calendar {
    width: 100%;
  }
}

.datePicker-emp {
  .p-datepicker-trigger {
    height: auto !important;
    top: 0 !important;
  }
}