.p-readonly.p-inputswitch {
  .p-inputswitch-slider {
    cursor: default;
    &::before {
      background-color: #f1f1f1 !important;
    }
  }
}

.notify-switches{
  .notifyDisabled{
    .p-disabled {
      &.p-inputswitch-checked {
        .p-inputswitch-slider {
          // background-color: #ced4da!important;
          background-color: #F8F9FA!important;
          border: 1px solid#0000001A;
        }    
      }
      &:not(.p-inputswitch-checked) {
        .p-inputswitch-slider{
          // background-color: #ced4da!important;
          background-color: #F8F9FA!important;
          border: 1px solid#0000001A;
        }
      }
      .p-inputswitch-slider::after {
        color: #D0C9D6!important;
      }
      .p-inputswitch-slider::before {
        background: #D0C9D6;
      }
    }
  }

  .p-inputswitch {
    .p-inputswitch-slider{
      // background-color: #606060;
      width:60px;
  }
    &.p-inputswitch-checked {
      .p-inputswitch-slider{
        background-color: #158fc4!important;
      
    }

      // ::ng-deep.mat-slide-toggle-thumb-container{
      //     left: 1px !important;
      // }
      .p-inputswitch-slider::before{
        left: 0.9rem;
      }
      .p-inputswitch-slider::after {
        content: "ON";
        //font-size: 50%;
        //font-weight: 700;
        color: white;
        position: absolute;
        left: 8px;
        //top: -5px;
      }
    }
    &:not(.p-inputswitch-checked) {
      .p-inputswitch-slider{
        background-color: #888E90!important;
      
    }
      .p-inputswitch-slider::after {
        content: "OFF";
        //font-size: 50%;
        //font-weight: 700;
        color: white;
        position: absolute;
        left: 25px;
        //top: -5px;
      }
    }
  }
}
