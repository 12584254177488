$black: #000000;
$gray-dark: #4A4A4A;
$gray-darker: #333246;
$gray: #687C93;
$gray-lighter:#EDEDED;
$white: #ffffff; 

$input-color-base:#3F3356;

$border-color:#CCD4DE;
$border-light-gray: #E1E1E1;
$border-blue:#19A0DA;
$border-blue-active:#158FC4;
$input-border: #47607D;
$light-blue: #DAE9FD;
$tab-inactive: #F8FBFF;
$tab-active:#EDFAFF;

$tab-gray-default: #F5F7F7;
$active-tab-border: #01A0DA;

$border-gray:#707070;

$input-border-gray: #7D8FA3;

$button-color-blue:#10A0DA;
$button-blue-dark:#0077a3;

$button-light-gray: #728499;

$label-dark:#4D4F5C;
$panel-header: #3A3A3A;

$default-button-bg: #DEDEDE;