.grey-border-pmenu {
  width: 100%;
  margin-bottom: 15px;
  margin-top: 5px;
  .p-megamenu {
    border: 0;
    border-top: 2px solid #cccccc;
    border-bottom: 2px solid #cccccc;
    border-radius: 0;
    padding: 0 15px;
    .p-megamenu-root-list .p-menuitem {
      .p-menuitem-link {
        border-radius: 0;
        padding: 0.75rem 1.25rem;
        box-shadow: none !important;
        .p-menuitem-text {
          color: #6b6b6b;
          font-weight: 600;
        }
        &.p-menuitem-link-active {
          background-color: #0071c9;
          .p-menuitem-text {
            color: #ffffff;
          }
        }
      }
    }
  }
}
.individual-list{
  .p-menu{
    width: 13.5rem;
  }
}
