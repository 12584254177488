$font-family-base: 'Source Sans Pro';
$imgpath:"/images";

$font-size-base:14px;
$font-size-medium:18px;
$font-size-large:20px;

$border-radius-base: 3px;

$media-lg:1200px;
