.control-lbl {
  font-size: $font-size-base;
  font-family: $font-family-base;
  color: $gray-dark;
}

h5.labelText {
  @extend .pb-0;
  @include im-font(20px, 13px, 600);
  color: $label-dark;
  padding-top: 8px;
  height: 28px;
}

.labelSubText {
  @extend .pb-0;
  @include im-font(16px, 13px, 600);
  color: $label-dark;
  padding-top: 8px;
}

.label-base {
  @include im-font(14px, 20px);
}

.label-cls {
  @extend .label-base;
  color: $gray-dark;
  @extend .mb-0;
}

.label-light {
  @extend .label-base;
  color: #a3a6b4;
}

.label-dark {
  @include im-font(16px, 27px);
  color: $input-color-base;
}

.bold-label {
  color: $label-dark;
  @include im-font(16px, 20px);
}

.label_medium {
  @include im-font(14px, 20px, 600);
  color: $label-dark;
}

.valueTxt {
  color: $input-color-base;
  @include im-font(16px, 27px);
}

label.row {
  color: #a3a6b4;
  @include im-font(14px, 20px);
}
.filter-label {
  color: #000000;
}
