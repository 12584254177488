.p-form-chips {
  display: block;
  width: 100%;
  .p-chips {
    width: 100%;
    .p-chips-multiple-container {
      width: 100%;
      padding: 0 0.5rem 0.25rem;
      .p-chips-token {
        height: 28px;
        margin-top: 0.25rem;
      }
      .p-chips-input-token {
        margin-top: 0.25rem;
        padding: 0;
        input {
          height: 28px;
        }
      }
    }
  }
}
.greenCard-i140-chip {
  .p-chips {
    width: 100%;
    .p-chips-multiple-container {
      width: 100%;
    }
  }
}

.create-masterlist-chip {
  .p-chips {
    height: 40px;
    width: 100%;
    .p-chips-multiple-container {
      width: 100%;
      .p-chips-input-token input {
        border: 0 !important;
        height: calc(40px - 1rem);
      }
    }
  }
}
.vd-chip {
  .p-chips {
  
    .p-chips-multiple-container {
      width: 100%;
      display:block;
      .p-chips-input-token input {
        border: 0 !important;
        height: calc(40px - 1rem);
      }
    }
  }
}


