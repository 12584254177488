.fb-placeholder-tree {
  .p-tree {
    .p-tree-empty-message {
      font-size: 12px;
      white-space: pre-wrap;
      color: #6c757d;
      font-style: italic;
      background-color: #f6f9fe !important;
    }
    &.p-treenode-dragover {
      .p-tree-empty-message,
      .p-treenode-label,
      .p-treenode-label > label.bg-primary {
        background: #e1e1e1 !important;
      }
    }
    .p-tree-wrapper {
      // height: 700px;
      width: calc(100% - 30px);
      margin: 10px 15px;
    }
    .p-tree-filter-container {
      width: calc(100% - 30px);
      margin: 10px 15px;
      .p-tree-filter {
        border-radius: 20px !important;
        padding: 5px 30px 5px 5px;
        height: 30px;
      }
    }
    .p-tree-container {
      .p-treenode {
        border: 0;
        .p-treenode-content {
          .p-tree-toggler {
            top: 0rem;
            color: #bcc5d3;
            margin-right: 0;
            width: 20px;
            &:enabled:hover {
              color: #bcc5d3;
              background: rgba(0, 0, 0, 0);
            }
            .pi {
              font-size: 10px;
              line-height: 2rem;
            }
          }
        }
      }
    }
    .p-treenode-children {
      padding: 0 0 15px 2rem !important;
      color: #728499;
      font-weight: 600;
      .p-treenode.child {
        .p-treenode-content {
          .p-treenode-label {
            cursor: move;
            label {
              cursor: move;
            }
          }
        }
      }
    }
  }
}

.fb-columnTree {
  .p-tree {
    height: 58px;
    .p-tree-empty-message {
      font-size: 12px;
      white-space: pre-wrap;
      color: #6c757d;
      font-style: italic;
      background-color: #f6f9fe !important;
      height: 58px;
    }
    &.p-treenode-dragover {
      .p-tree-empty-message,
      .p-treenode-label,
      .p-treenode-label > label.bg-primary {
        background: #e1e1e1 !important;
      }
    }
    .p-tree-container {
      .p-treenode {
        height: 58px;
      }
    }
  }
  .p-tree-toggler {
    display: none;
  }
  .p-treenode-label {
    font-size: 15px;
    background-color: #f5f9fd;
  }
}
