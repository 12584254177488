//Imports
@import "color";

.header-container {
    width: 100% !important;
    height: 47px !important;
    background-color: $neutral-white !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    border-top: 2px solid $primary-cerulean;
    border-bottom: 3px solid $primary-cerulean;
}

.header--menu-item {
    font-family: Helvetica Neue !important;
    font-size: 15px !important;
    color: $primar-blueBayoux !important;
    text-transform: uppercase;
}

.header--menu-item:link {
    text-decoration: none;
}

.header--menuItem-active {
    font-weight: bold;
    color: $primary-cerulean !important;
}
