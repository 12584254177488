$taskListBackround: #f5f7f7;
.duty-card {
  &.job-duties-jds {
    .p-card-body {
      padding: 0px 15px 0px 0px !important;
    }
  }

  .p-card-body {
    padding-left: 0px !important;
  }

  .duty-list {
    background-color: #b5d8ff;
    border-radius: 15px;
    max-width: 20px;
    padding: 5px 10px;
  }
}

.courses-card {
  .p-card-body {
    padding: 0px !important;

    .p-card-content {
      padding: 0px !important;
    }
  }

  .document-header {
    background-color: #f8f9fa;
    color: #333333;
    padding: 10px 0px;

    h6 {
      font-size: 15px;
    }
  }

  .courses {
    padding: 8px 0px !important;
  }

  .document {
    padding: 8px 0px !important;
    border: 1px solid #e9ecef;
  }

  .duty-list {
    background-color: #b5d8ff;
    border-radius: 15px;
    max-width: 20px;
    padding: 5px 10px;
  }
}

p-card .tasklist-card {
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
  border: unset !important;

  // height: 100%;
  .p-card-body {
    height: 100%;

    .p-card-content {
      padding-bottom: 0px !important;
      height: 100%;

      .content-holder {
        height: calc(100% - 92px);
      }
    }
  }
}

.beneficiaryFilter {
  .p-overlaypanel {
    top: 50px !important;
  }
}

.fd-master-table-card {
  .p-card-body {

    // padding: 0px !important;
    .p-card-content {
      padding: 0.5rem 0 !important;
    }
  }
}

.attorney-dashborad {
  .tasklist-card {
    min-height: 600px;
  }
}

.petitioner-dashboard {
  .tasklist-card {
    min-height: 580px;
  }
}

.quick-card {
  .p-card-body {
    padding: 10px 15px !important;

    .p-card-content {
      h6 {
        font-size: 18px !important;
        font-weight: bold !important;
        color: #60626e !important;
      }
    }
  }
}

.quick-card-pro {
  .p-card {
    background: #EDF4FB !important;
    color: #495057;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    border-radius: 3px;
  }
  .p-card .p-card-body{
    padding : 0px!important;
  }
}
.new-dashboard{
  .tasklist-card {
    background: $taskListBackround;
    .tab-container{
      background:  $taskListBackround;
    }
    .task-tab{
      background: #fff;
      cursor: pointer;
      font-weight: 600;
      font-size: 1rem;
      &.activeTasksTab{
        background:  $taskListBackround;
      }
    }
    .p-card-body{
      background: #fff;
    }
    .p-card-content{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .content-holder{
      background:  $taskListBackround;
    }
    .taskFilter-btn{
      background-color: #fff;
    }
  }
}
