@charset "UTF-8";
.pl-0, .mat-stepper-horizontal .mat-horizontal-content-container, .btn-stepper span {
  padding-left: 0px !important;
}

.pr-0, .mat-stepper-horizontal .mat-horizontal-content-container {
  padding-right: 0px !important;
}

.pb-0, .labelSubText, h5.labelText {
  padding-bottom: 0px !important;
}

.mt-0, .my-0, .mat-tab-list {
  margin-top: 0px !important;
}

.mb-0, .table,
.im-card-table,
.im-card-no-border-table, .label-cls, .my-0, .mat-tab-list {
  margin-bottom: 0px !important;
}

.mr-0, .task-page-tab-state a.mat-tab-link {
  margin-right: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-13 {
  margin-right: 13px;
}

.ml-n4r {
  margin-left: -4rem !important;
}

.border-bottom-0, .mat-tab-nav-bar, .task-page-tab-state .mat-tab-header {
  border-bottom: 0px !important;
}

.border-0, .builder-tab, .task-page-tab-state a.mat-tab-link {
  border: 0px !important;
}

.border-radius-0, .task-page-tab-state a.mat-tab-link {
  border-radius: 0px !important;
}

.w-max-content {
  width: max-content !important;
}

button.disabled,
button:disabled {
  cursor: not-allowed !important;
}

.p-button:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn, .button_bg-gray,
.back-button, .btn-success,
.im-btn-primary, .p-button-outlined,
.im-btn {
  border-radius: 10px !important;
  opacity: 1;
  font: normal normal bold 14px/20px "Source Sans Pro" !important;
  min-width: 43px !important;
  text-transform: uppercase;
  border: 1px solid transparent;
  height: 43px;
  padding: 0px 29px !important;
  vertical-align: top;
}
.btn.btn-primary, .btn-primary.button_bg-gray,
.btn-primary.back-button, .btn-primary.btn-success,
.btn-primary.im-btn-primary, .btn-primary.p-button-outlined,
.btn-primary.im-btn {
  background-color: #19A0DA;
  border: #19A0DA;
}
.btn.btn-primary:focus, .btn-primary.button_bg-gray:focus,
.btn-primary.back-button:focus, .btn-primary.btn-success:focus,
.btn-primary.im-btn-primary:focus, .btn-primary.p-button-outlined:focus,
.btn-primary.im-btn:focus, .btn.btn-primary:hover, .btn-primary.button_bg-gray:hover,
.btn-primary.back-button:hover, .btn-primary.btn-success:hover,
.btn-primary.im-btn-primary:hover, .btn-primary.p-button-outlined:hover,
.btn-primary.im-btn:hover, .btn.btn-primary:active, .btn-primary.button_bg-gray:active,
.btn-primary.back-button:active, .btn-primary.btn-success:active,
.btn-primary.im-btn-primary:active, .btn-primary.p-button-outlined:active,
.btn-primary.im-btn:active {
  background-color: #158FC4;
  box-shadow: 0px 0px 10px #00000029 !important;
}

.im-btn.btn-primary:focus, .im-btn.btn-primary:hover, .im-btn.btn-primary:active {
  background-color: #158FC4 !important;
}

.p-button-outlined:focus, .p-button-outlined:hover, .p-button-outlined:active,
.im-btn:focus,
.im-btn:hover,
.im-btn:active {
  background: transparent !important;
}
.p-button-outlined.im-btn-primary.im-btn-md:focus, .p-button-outlined.im-btn-primary.im-btn-md:hover, .p-button-outlined.im-btn-primary.im-btn-md:active,
.im-btn.im-btn-primary.im-btn-md:focus,
.im-btn.im-btn-primary.im-btn-md:hover,
.im-btn.im-btn-primary.im-btn-md:active {
  background-color: #158FC4 !important;
}

.tab_button {
  text-align: center;
  background: no-repeat padding-box #dedede;
  color: #5f6c74 !important;
  height: 41px !important;
  font: normal normal normal 22px/24px "Source Sans Pro" !important;
  border-radius: 0 !important;
  text-transform: none;
}

.active-button_blue {
  color: #ffffff !important;
  background-color: #10A0DA !important;
}

.btn-success,
.im-btn-primary {
  color: #ffffff;
  background-color: #19A0DA !important;
  border: 1px solid transparent !important;
}
.btn-success:focus, .btn-success:active, .btn-success:hover,
.im-btn-primary:focus,
.im-btn-primary:active,
.im-btn-primary:hover {
  box-shadow: 0px 0px 10px #00000029 !important;
}
.btn-success.btn-success_disabled, .btn-success:disabled,
.im-btn-primary.btn-success_disabled,
.im-btn-primary:disabled {
  background-color: #DEDEDE !important;
  color: #707070 !important;
  cursor: not-allowed !important;
}

.btn-success {
  border: 1px solid transparent;
}
.btn-success:focus, .btn-success:active, .btn-success:hover {
  background-color: #19A0DA !important;
}

.im-drop-icon {
  background-color: #10A0DA;
  border-radius: 1rem;
}

.im-btn-outline-primary:hover {
  background-color: #10A0DA;
  color: #ffffff;
}

.im-btn.btn-outline-primary:hover {
  color: #10A0DA !important;
}

.navbar-rgt-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  background: #043085;
  padding: 6px 15px;
  color: #ffffff;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px !important;
  font-family: "Source Sans Pro";
  text-transform: uppercase;
}
.navbar-rgt-btn img {
  width: 25px;
  margin-right: 10px;
}
.navbar-rgt-btn:hover {
  color: #ffffff;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .navbar-rgt-btn {
    position: absolute;
    right: 10px;
    top: 5px;
  }
}
.btn-stepper {
  background-color: #728499 !important;
  height: 32px !important;
  width: 32px !important;
}
.btn-stepper span {
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 12px !important;
  font-family: "Source Sans Pro";
  color: #ffffff;
  vertical-align: initial !important;
  line-height: 0px !important;
}

.default-button {
  background: #DEDEDE !important;
  height: 43px;
  font: normal normal bold 14px/20px "Source Sans Pro" !important;
  color: #47607D !important;
}

.save-button_whitebg {
  border: 1px solid #19A0DA;
  background: #ffffff;
  height: 43px;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px !important;
  font-family: "Source Sans Pro";
  color: #19A0DA;
}
.save-button_whitebg .im-drop-icon {
  color: #ffffff;
  margin: 0px;
  height: 25px;
  width: 25px;
  vertical-align: text-bottom;
}
.save-button_whitebg:hover .im-drop-icon {
  background: #ffffff;
  color: #19A0DA;
}

.button_whitebg, .button_whitebgImp {
  border: 1px solid #19A0DA !important;
  background: #ffffff;
  height: 43px;
  color: #19A0DA;
  text-transform: uppercase;
  padding: 0 17px !important;
}
.button_whitebg .im-drop-icon, .button_whitebgImp .im-drop-icon {
  color: #ffffff;
  margin: 0px;
  height: 22px;
  width: 23px;
  font-size: 12px;
  padding-top: 7px;
}
.button_whitebg:hover, .button_whitebgImp:hover, .button_whitebg:focus, .button_whitebgImp:focus, .button_whitebg:active, .button_whitebgImp:active {
  background: #ffffff;
  color: #158FC4 !important;
  box-shadow: 0px 0px 10px #00000029;
  border: 1px solid #158FC4 !important;
}
.button_whitebg:hover .im-drop-icon, .button_whitebgImp:hover .im-drop-icon, .button_whitebg:focus .im-drop-icon, .button_whitebgImp:focus .im-drop-icon, .button_whitebg:active .im-drop-icon, .button_whitebgImp:active .im-drop-icon {
  background: #158FC4;
  color: white;
}
.button_whitebg:disabled, .button_whitebgImp:disabled {
  cursor: not-allowed !important;
  box-shadow: none;
  border: 1px solid #707070 !important;
  color: #707070 !important;
}

.p-button-icon-only .p-button-label {
  display: none;
}

.button_whitebgImp {
  color: #19A0DA !important;
}

.button-width-icon-left {
  padding-left: 12px !important;
  padding-right: 17px !important;
}
.button-width-icon-left .fa {
  font-size: 24px;
  vertical-align: middle;
  height: 22px;
  width: 22px;
  margin-top: -6px;
}
.button-width-icon-left:hover .fa, .button-width-icon-left:focus .fa {
  background-color: transparent;
}

.button_bg-gray,
.back-button {
  background: #E1E1E1;
  color: #465f7c;
}
.button_bg-gray:hover, .button_bg-gray:active, .button_bg-gray:focus,
.back-button:hover,
.back-button:active,
.back-button:focus {
  border: 1px solid #a3a6b4;
  color: #47607d;
  box-shadow: 0px 0px 10px #00000029;
}

.viewType {
  text-align: right;
}
.viewType button {
  width: 155px;
  line-height: 40px;
}
.viewType button.activebtn {
  background-color: #19a0da !important;
}
.viewType button .mat-icon {
  position: relative;
  top: 6px;
  margin-right: 3px;
}

.taskFilter-btn .pi-filter:before {
  color: #56addd;
  padding-left: 10px;
  font-size: 20px;
}

.hideLabel .p-button-label {
  display: none;
}

.ui-button:enabled:focus {
  box-shadow: none;
}

.buttonWithDropDownMenu.settingButton .p-button {
  background: white;
  width: 60px !important;
  border-radius: 3px;
  padding: 3px;
  height: 30px;
  border: 1px solid #c1b8b8;
}
.buttonWithDropDownMenu.settingButton .p-button:hover {
  background-color: transparent;
}
.buttonWithDropDownMenu.settingButton .p-button .fa {
  color: #475f7b !important;
  height: 24px;
  padding: 0;
  margin: 0;
  font-size: 24px;
  top: 10%;
  left: 10%;
  width: 50%;
}
.buttonWithDropDownMenu.settingButton .p-button-label {
  color: #475f7b;
  font-size: 24px;
  font: normal normal normal 14px/1 FontAwesome;
  margin-left: 5px;
  margin-bottom: 0;
}
.buttonWithDropDownMenu.settingButton .p-button-label::after {
  content: "";
}

.buttonWithDropDownMenu.settingButton .p-button {
  background: white;
  width: 52px !important;
  border-radius: 3px;
  padding: 3px;
  height: 30px;
  border: 1px solid #c1b8b8;
}
.buttonWithDropDownMenu.settingButton .p-button:hover {
  background-color: transparent;
}

.p-picklist .p-picklist-header {
  width: 400px;
}
.p-picklist .p-picklist-buttons {
  width: 150px;
  padding: 0 !important;
  margin-right: 0.5rem;
}
.p-picklist .p-picklist-buttons .p-button {
  width: 100%;
  font: normal normal bold 14px/20px "Source Sans Pro" !important;
}
.p-picklist .p-picklist-buttons .p-button:last-child, .p-picklist .p-picklist-buttons .p-button:nth-last-child(2) {
  background-color: #EDEDED;
  color: #000000;
  border-color: #EDEDED;
}
.p-picklist .p-picklist-buttons .p-button .pi-angle-right:before {
  content: "Add ";
}
.p-picklist .p-picklist-buttons .p-button .pi-angle-right:after {
  content: "";
  font-size: 13px;
}
.p-picklist .p-picklist-buttons .p-button .pi-angle-double-right:before {
  content: "Add All ";
}
.p-picklist .p-picklist-buttons .p-button .pi-angle-double-right:after {
  content: "";
  font-size: 13px;
}
.p-picklist .p-picklist-buttons .p-button .pi-angle-left:after {
  content: " Remove";
}
.p-picklist .p-picklist-buttons .p-button .pi-angle-left:before {
  content: "";
  font-size: 13px;
}
.p-picklist .p-picklist-buttons .p-button .pi-angle-double-left:after {
  content: " Remove All";
}
.p-picklist .p-picklist-buttons .p-button .pi-angle-double-left:before {
  content: "";
  font-size: 13px;
}

.vd-picklist.p-picklist .p-picklist-header {
  width: 545px !important;
}
.vd-picklist.p-picklist .p-picklist-buttons {
  width: 150px;
  padding: 0 !important;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.vd-picklist.p-picklist .p-picklist-buttons .p-button {
  width: 100%;
  font: normal normal bold 14px/20px "Source Sans Pro" !important;
}
.vd-picklist.p-picklist .p-picklist-buttons .p-button:last-child, .vd-picklist.p-picklist .p-picklist-buttons .p-button:nth-last-child(2) {
  background-color: #EDEDED;
  color: #000000;
  border-color: #EDEDED;
}
.vd-picklist.p-picklist .p-picklist-buttons .p-button .pi-angle-right:before {
  content: "ADD ";
  font-size: 11px;
}
.vd-picklist.p-picklist .p-picklist-buttons .p-button .pi-angle-right:after {
  content: "";
  font-size: 13px;
}
.vd-picklist.p-picklist .p-picklist-buttons .p-button .pi-angle-double-right:before {
  content: "ADD ALL ";
  font-size: 11px;
}
.vd-picklist.p-picklist .p-picklist-buttons .p-button .pi-angle-double-right:after {
  content: "";
  font-size: 13px;
}
.vd-picklist.p-picklist .p-picklist-buttons .p-button .pi-angle-left:after {
  content: " REMOVE";
  font-size: 11px;
}
.vd-picklist.p-picklist .p-picklist-buttons .p-button .pi-angle-left:before {
  content: "";
  font-size: 13px;
}
.vd-picklist.p-picklist .p-picklist-buttons .p-button .pi-angle-double-left:after {
  content: " REMOVE ALL";
  font-size: 11px;
}
.vd-picklist.p-picklist .p-picklist-buttons .p-button .pi-angle-double-left:before {
  content: "";
  font-size: 13px;
}

.p-button.linkButton {
  background: transparent;
  color: #10A0DA;
  border: 0;
  padding: 0;
}
.p-button.linkButton .p-button-icon {
  background: #10A0DA;
  color: white;
  padding: 8px 10px;
  border-radius: 50%;
}
.p-button.linkButton:hover {
  background: transparent !important;
  color: #10A0DA !important;
}
.p-button.linkButton:hover .p-button-icon {
  background: #10A0DA;
  color: white;
}

.icon-button {
  border: 0;
  background: transparent;
}
.icon-button:hover {
  color: #19a0da;
}

.link-button {
  border: 0;
  background: transparent;
  color: #19a0da !important;
  text-decoration: underline;
  text-transform: none;
}
.link-button:hover {
  border: 0;
  background: transparent;
  text-decoration: underline !important;
}
.link-button:focus {
  border: 0;
  background: transparent;
  box-shadow: none !important;
}

.copyButton.p-button {
  padding: 0px !important;
}
.copyButton.p-button.p-button-outlined {
  height: 27px !important;
  border-radius: 3px !important;
  padding: 10px !important;
}
.copyButton.p-button.p-button-success {
  padding: 5px !important;
  height: 27px !important;
  background-color: #4cba20 !important;
}
.copyButton.p-button.copiedButton {
  padding: 5px !important;
  height: 27px !important;
  background-color: #4cba20 !important;
  color: white !important;
}
.copyButton.p-button .p-button-label {
  font-weight: normal;
  font-size: 14px;
}

.lcaBrowseButton.p-button {
  background-color: #ffffff !important;
  color: #158fc4 !important;
  padding: 5px !important;
}

.copyButton.p-button {
  padding: 0px !important;
}
.copyButton.p-button.p-button-outlined {
  height: 27px !important;
  border-radius: 3px !important;
  padding: 10px !important;
}
.copyButton.p-button.p-button-success {
  padding: 5px !important;
  height: 27px !important;
  background-color: #4cba20 !important;
}
.copyButton.p-button .p-button-label {
  font-weight: normal;
  font-size: 14px;
}

.btn.disabled, .disabled.p-button-outlined,
.disabled.im-btn, .disabled.btn-success,
.disabled.im-btn-primary, .disabled.button_bg-gray,
.disabled.back-button,
.btn:disabled,
.p-button-outlined:disabled,
.im-btn:disabled,
.btn-success:disabled,
.im-btn-primary:disabled,
.button_bg-gray:disabled,
.back-button:disabled {
  opacity: 0.65;
  cursor: not-allowed !important;
}

.btn.vdCreateBtn, .vdCreateBtn.p-button-outlined,
.vdCreateBtn.im-btn, .vdCreateBtn.btn-success,
.vdCreateBtn.im-btn-primary, .vdCreateBtn.button_bg-gray,
.vdCreateBtn.back-button {
  color: white;
  background-color: #1aa0da;
  border-radius: 3px !important;
  font-weight: 100 !important;
}

.btn.vdCancelBtn, .vdCancelBtn.p-button-outlined,
.vdCancelBtn.im-btn, .vdCancelBtn.btn-success,
.vdCancelBtn.im-btn-primary, .vdCancelBtn.button_bg-gray,
.vdCancelBtn.back-button {
  color: #596f88;
  background-color: #e1e1e1;
  border-radius: 3px !important;
  font-weight: 100 !important;
}

.btn.vdResetBtn, .vdResetBtn.p-button-outlined,
.vdResetBtn.im-btn, .vdResetBtn.btn-success,
.vdResetBtn.im-btn-primary, .vdResetBtn.button_bg-gray,
.vdResetBtn.back-button {
  color: #1aa0da;
  background-color: white;
  border-radius: 3px !important;
  border: 1px solid #1aa0da !important;
  font-weight: 100 !important;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.p-button.menu-button {
  background: transparent;
  border: 1px solid #7D8FA3;
  padding: 0 !important;
  color: #7D8FA3;
  height: 25px;
  width: 3rem !important;
  padding-right: 2rem !important;
}
.p-button.menu-button .p-button-icon {
  padding-left: 1.8rem;
  margin-right: 1rem;
  margin-bottom: 0;
  padding-right: 0;
  position: absolute;
  font-size: 1rem;
}
.p-button.menu-button .downArrowIcon {
  position: absolute;
  right: 5px;
}
.p-button.menu-button:focus, .p-button.menu-button:hover, .p-button.menu-button:active {
  background: transparent !important;
  border: 1px solid #7D8FA3 !important;
  color: #7D8FA3 !important;
}

.create-task-btn.btn-add {
  line-height: 30px;
}
.create-task-btn.btn-add i.fa-plus-circle {
  border: none;
  padding: 0;
  font-size: 18px !important;
  top: 2px;
  position: relative;
}

.sync-btn span {
  font-weight: 700;
  font-size: 17px;
  line-height: 18px;
}

.external-links-container .btn-create .p-button-label {
  color: #3686b1;
  font-size: 13px;
  font-weight: 600;
}

.efile-btn .p-button-rounded {
  display: contents;
}
.efile-btn .pi {
  padding: 10px 15px;
}

.inline-edit-mode .edit-controls button .p-button-icon {
  font-size: 12px;
}
.inline-edit-mode .edit-controls button.save-btn .p-button-icon {
  color: #28a745;
}
.inline-edit-mode .edit-controls button.clear-btn .p-button-icon {
  color: #dc3545;
}
.inline-edit-mode .edit-controls button.cancel-btn .p-button-icon {
  color: #7f7f7f;
}

.p-button.fd-taskFilter-btn, .p-button.hover {
  color: #8c9195;
  background: #f5f7f7;
  border: 1px solid #dddddd;
  padding: 0.3rem 0.5rem;
}

.fb-btn-icon .pi {
  font-size: 25px !important;
}

.syncBtn .pi {
  padding-left: 10px !important;
}

.content {
  padding: 35px;
}

.content-container {
  padding: 30px;
  background: #ffffff;
}

.divCenter {
  margin-left: auto;
  margin-right: auto;
}

input,
.im-input,
.p-calendar .p-inputtext,
.im-input-error,
.im-input-error-outline,
.im-input-success,
.im-textbox,
.select-comp,
.txt-comp,
.mat-select,
.select2-container--default .select2-selection--multiple {
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #7D8FA3;
  color: #3F3356;
  width: 100%;
  padding: 8px 16px 10px 16px;
  height: 40px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px !important;
  font-family: "Source Sans Pro";
}

.mat-input-element.mat-form-field-autofill-control {
  background-color: #ffffff;
  color: #3F3356;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px !important;
  font-family: "Source Sans Pro";
  height: 31px;
}

.select-comp {
  display: block;
  padding-left: 14px !important;
}

.im-textarea {
  width: 100%;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #7D8FA3;
}

.date-field .mat-form-field-outline {
  height: 40px;
}

.date-field input {
  cursor: pointer;
}

.date-field .mat-form-field-infix {
  border-top: none !important;
  padding: 15px 0px !important;
  margin-top: 8px;
  top: 0;
}
.date-field.step-date-wrong-invalid-shown.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #7d8fa3;
}

.mat-datepicker-toggle-default-icon {
  width: 24px !important;
  height: 24px !important;
  margin-right: 10px !important;
}

.mat-radio-container {
  height: 14px;
  width: 14px;
}

.mat-radio-label-content {
  height: 20px;
  color: #4D4F5C;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px !important;
  font-family: "Source Sans Pro";
}

.coverletter-radio-text .mat-radio-label-content {
  font-size: 14px;
}

.mat-radio-button .mat-ripple-element {
  background-color: transparent !important;
}

.mat-radio-group .mat-radio-button {
  margin-left: 20px;
}
.mat-radio-group .mat-radio-button .mat-radio-label {
  align-items: initial;
}
.mat-radio-group .mat-radio-button:first-child {
  margin-left: 0px;
}

.mat-form-field {
  color: #4D4F5C;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px !important;
  font-family: "Source Sans Pro";
}
.mat-form-field .mat-form-field-wrapper {
  margin: 0px !important;
  padding: 0px !important;
}
.mat-form-field .mat-icon-button {
  width: 19px;
  height: 17px;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  padding-left: 14px !important;
  min-height: 45px;
  padding-right: 0px !important;
}
.mat-form-field-appearance-outline .mat-form-field-flex .mat-form-field-infix {
  padding: 0px !important;
}

.mat-select {
  border: 1px solid #7D8FA3;
  padding: 8px 10px 10px 16px !important;
}
.mat-select .mat-select-trigger {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px !important;
  font-family: "Source Sans Pro";
  color: #4D4F5C;
}
.mat-select .mat-select-value {
  min-width: 20px;
  color: #4D4F5C;
}

.mat-paginator-outer-container {
  padding-bottom: 10px;
}
.mat-paginator-outer-container .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
.mat-paginator-outer-container .mat-select {
  width: auto;
  margin-top: -10px;
}
.mat-paginator-outer-container .mat-paginator-page-size {
  align-items: inherit;
}

.mat-form-field-wrapper .mat-form-field-underline {
  display: none;
}

.example-chip-list .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  padding: 0;
}
.example-chip-list .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
  top: 0px;
}
.example-chip-list .mat-form-field-wrapper .mat-form-field-underline {
  display: block;
}

.chips-contianer {
  margin-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mat-form-field-label-wrapper {
  left: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px !important;
  font-family: "Source Sans Pro";
  color: #4D4F5C;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
}
.mat-form-field-appearance-legacy.no-border-top .mat-form-field-infix {
  border-top: 0;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #3F3356;
  left: 14px;
}

.mat-form-field-type-mat-select.ng-dirty .mat-form-field-label-wrapper {
  display: none;
}

.mat-form-field:not(.example-chip-list).mat-focused.mat-primary .mat-select-arrow {
  color: #3F3356;
}
.mat-form-field:not(.example-chip-list).mat-focused .mat-form-field-label {
  transform: none !important;
  color: #3F3356;
}

.mat-option {
  background: none !important;
  color: #3F3356;
  padding: 0 18px !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px !important;
  font-family: "Source Sans Pro";
}

.mat-select-panel {
  max-width: 234px !important;
  border: 1px solid #7D8FA3;
}

.mat-select-panel-wrap {
  margin-top: 43px;
}

div.search-box {
  padding: 3px 14px;
  border: 1px solid #ced4da;
  height: 43px;
  border-radius: 18px;
  width: 300px;
}
div.search-box .search-icon {
  height: 24px;
  width: 24px;
  margin: 6px 0;
}
div.search-box .search-input {
  border: 0;
  outline: 0;
  height: 37px;
  width: calc(100% - 27px);
  margin-right: 3px;
  float: left;
}

.search-input-group {
  width: 250px !important;
}
.search-input-group .form-control {
  padding: 5px 30px 5px 8px !important;
  flex: 1 !important;
  height: 38px;
}
.search-input-group .input-group-append {
  height: 38px;
  width: 36px;
  margin-left: -36px;
  padding: 0;
}
.search-input-group .input-group-append .btn, .search-input-group .input-group-append .p-button-outlined,
.search-input-group .input-group-append .im-btn, .search-input-group .input-group-append .btn-success,
.search-input-group .input-group-append .im-btn-primary, .search-input-group .input-group-append .button_bg-gray,
.search-input-group .input-group-append .back-button {
  padding: 7px 6px !important;
  margin: 0 !important;
  width: 36px;
  height: 38px;
  min-width: 36px !important;
}
.search-input-group .input-group-append .btn .fa, .search-input-group .input-group-append .p-button-outlined .fa,
.search-input-group .input-group-append .im-btn .fa, .search-input-group .input-group-append .btn-success .fa,
.search-input-group .input-group-append .im-btn-primary .fa, .search-input-group .input-group-append .button_bg-gray .fa,
.search-input-group .input-group-append .back-button .fa {
  margin: 0;
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.p-calendar .p-datepicker-trigger {
  background: white;
  color: #7D8FA3;
  padding: 0 !important;
  border: 1px solid #7D8FA3;
  border-left: 0 !important;
}

.p-datepicker table td {
  padding: 0 !important;
}
.p-datepicker table td span.p-highlight {
  color: #040404 !important;
  background: #60fffe;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: box-shadow 0.2s;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  visibility: hidden !important;
}

.p-radiobutton-label {
  margin-bottom: 0;
}

.radio_green .p-radiobutton .p-radiobutton-box.p-highlight {
  background-color: #33ac00;
  border: #33ac00;
  color: white;
  visibility: visible !important;
  font: normal normal normal 14px/1 FontAwesome;
}
.radio_green .p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon {
  background-color: #33ac00;
  border: #33ac00;
  color: white;
  visibility: visible !important;
  font: normal normal normal 13px/1 FontAwesome;
}
.radio_green .p-radiobutton .p-radiobutton-box.p-highlight .p-radiobutton-icon::before {
  content: "";
}

.w-100 .p-dropdown {
  width: 100%;
}

.titleSheet .p-inplace .p-inplace-display:focus {
  box-shadow: none;
}
.titleSheet .p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: transparent;
}
.titleSheet .p-inplace .p-inplace-display label {
  cursor: pointer;
}
.titleSheet .p-inplace .p-inplace-content input {
  width: 30%;
}
.titleSheet .p-inplace .p-inplace-content .p-button-icon-only {
  background: transparent;
  border: transparent;
  color: #2196f3;
  margin-left: 0.5rem;
  margin-bottom: 4px;
}

.mat-slide-toggle-bar {
  border-radius: 12px !important;
  min-width: 50px;
  height: 22px !important;
}

.mat-slide-toggle-thumb-container {
  top: 2px !important;
  left: 1px !important;
}

.mat-slide-toggle-thumb {
  height: 18px !important;
  width: 18px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar::after,
.mat-slide-toggle:not(.mat-checked) .mat-slide-toggle-bar::after {
  font-style: normal;
  font-variant: normal;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 20px;
  font-family: "Source Sans Pro";
  top: 0px !important;
}

.mat-slide-toggle.mat-primary.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: white !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
  transform: translate3d(30px, 0, 0) !important;
  left: 7px !important;
}

.p-autocomplete .p-autocomplete-input {
  border-right: 0;
}
.p-autocomplete .p-autocomplete-input:hover {
  border-color: #ced4da !important;
}
.p-autocomplete .p-autocomplete-input:focus,
.p-autocomplete .p-autocomplete-input:active {
  box-shadow: none;
}
.p-autocomplete .p-button {
  background: white;
  border-color: #ced4da;
  color: #495057;
}
.p-autocomplete .p-button:enabled:hover {
  background: white;
  border-color: #ced4da;
  color: #495057;
}
.p-autocomplete .p-button:active {
  border-color: #0b7ad1;
}

.input-disabled input[readonly] {
  background-color: transparent;
  opacity: 0.6;
  cursor: default;
}

.p-inputnumber.create-task-input {
  padding: 0;
  border: 0;
  width: 100%;
  height: 40px;
}
.p-inputnumber.create-task-input .p-inputnumber-input {
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid #7d8fa3;
  color: #3f3356;
  padding: 8px 16px 10px 16px;
  height: 40px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  font-family: "Source Sans Pro";
}

textarea.create-task-textarea {
  width: 100%;
  padding: 8px 16px 10px 16px;
}

.lca-soc-code {
  padding-top: 0.3rem !important;
}
.lca-soc-code .mat-form-field-infix {
  border: none !important;
}
.lca-soc-code .mat-select {
  border: 1px solid #c3d0de !important;
}

.uscis-number-outer .mat-radio-label-content {
  width: 100% !important;
}

.line-height-inherit .mat-radio-label-content {
  line-height: inherit !important;
}

.control-lbl {
  font-size: 14px;
  font-family: "Source Sans Pro";
  color: #4A4A4A;
}

h5.labelText {
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 13px !important;
  font-family: "Source Sans Pro";
  color: #4D4F5C;
  padding-top: 8px;
  height: 28px;
}

.labelSubText {
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 13px !important;
  font-family: "Source Sans Pro";
  color: #4D4F5C;
  padding-top: 8px;
}

.label-base, .label-light, .label-cls {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px !important;
  font-family: "Source Sans Pro";
}

.label-cls {
  color: #4A4A4A;
}

.label-light {
  color: #a3a6b4;
}

.label-dark {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px !important;
  font-family: "Source Sans Pro";
  color: #3F3356;
}

.bold-label {
  color: #4D4F5C;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px !important;
  font-family: "Source Sans Pro";
}

.label_medium {
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px !important;
  font-family: "Source Sans Pro";
  color: #4D4F5C;
}

.valueTxt {
  color: #3F3356;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px !important;
  font-family: "Source Sans Pro";
}

label.row {
  color: #a3a6b4;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px !important;
  font-family: "Source Sans Pro";
}

.filter-label {
  color: #000000;
}

.leftSidebar {
  border: 0.5px solid #707070;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
}
.leftSidebar a.im-list-group-item {
  padding: 20px 0 0 0;
  border: 0;
  width: 250px;
}
.leftSidebar a.im-list-group-item.active {
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px !important;
  font-family: "Source Sans Pro";
  letter-spacing: 0px;
  color: #01A0DA !important;
  border-right: 5px solid #01A0DA;
}
.leftSidebar a.im-list-group-item span {
  border-bottom: 0.5px solid #707070;
  margin-left: 14px;
  width: 88%;
  padding-bottom: 20px;
  padding-left: 14px;
  display: inline-block;
}
.leftSidebar a.im-list-group-item:last-child span {
  border-bottom: none;
}
.leftSidebar a.im-list-group-item:first-child span {
  border-top: none;
}

.im-list-group-item {
  width: 250px;
  white-space: nowrap;
  padding: 20px;
  color: #4A4A4A;
  font-weight: bold;
  background-color: #ffffff !important;
  border-bottom: 0.5px solid #707070;
  border-top: 0.5px solid #707070;
}
.im-list-group-item:last-child {
  border-bottom: none;
}
.im-list-group-item:first-child {
  border-top: none;
}

.mat-chip-list-wrapper .mat-standard-chip, .mat-chip-list-wrapper input.mat-input-element {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px !important;
  font-family: "Source Sans Pro";
  color: #4D4F5C;
  margin-right: 15px;
  margin-top: 9px !important;
  margin-bottom: 18px !important;
}

.mat-chip.mat-standard-chip {
  background-color: #DAE9FD;
  color: #465F7C;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px !important;
  font-family: "Source Sans Pro";
}

.pointer {
  cursor: pointer;
}

body {
  font: normal normal normal 14px/20px Source Sans Pro;
}

.icn {
  border: 0;
}

.invalid-message {
  display: block;
}

.disabled {
  opacity: 0.65 !important;
  cursor: default !important;
}

.circle-border {
  border-radius: 50% !important;
}

.videoPlayer {
  height: 310px;
  width: 550px !important;
  margin-left: auto;
  margin-right: auto;
}

.fr-floating-btn {
  display: none !important;
}

.mat-tooltip {
  background-color: white !important;
  color: #333246 !important;
  font-size: 11px !important;
  box-shadow: 0px 5px 10px #ededed;
  border: 1px solid #687c93 !important;
}

.family-information-container .im-input:disabled, .family-information-container .p-calendar .p-inputtext:disabled, .p-calendar .family-information-container .p-inputtext:disabled {
  background-color: #ffffff;
  opacity: 0.6;
}

.fb-filter-container .form-control {
  height: 40px;
}
.fb-filter-container .form-control .p-dropdown {
  margin: -6px -12px;
  border: 0px !important;
  height: 38px;
  width: calc(100% + 24px);
}

.font-weight-600 {
  font-weight: 600 !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

ngx-extended-pdf-viewer .annotationLayer .buttonWidgetAnnotation.checkBox input,
ngx-extended-pdf-viewer .annotationLayer .textWidgetAnnotation input,
ngx-extended-pdf-viewer .annotationLayer .textWidgetAnnotation textarea,
ngx-extended-pdf-viewer .annotationLayer .choiceWidgetAnnotation select {
  background-image: none !important;
  background-color: #ffffff !important;
  color: #000000 !important;
}
ngx-extended-pdf-viewer .annotationLayer .buttonWidgetAnnotation.checkBox input,
ngx-extended-pdf-viewer .annotationLayer .buttonWidgetAnnotation.radioButton input,
ngx-extended-pdf-viewer .annotationLayer .choiceWidgetAnnotation select,
ngx-extended-pdf-viewer .annotationLayer .textWidgetAnnotation input,
ngx-extended-pdf-viewer .annotationLayer .textWidgetAnnotation textarea {
  pointer-events: none;
  user-select: none;
}
ngx-extended-pdf-viewer .toolbarField.pageNumber {
  border-radius: 2px !important;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  color: #5a5a5a !important;
  font-size: 12px !important;
  line-height: 14px !important;
}

ngx-extended-pdf-viewer .toolbarField.pageNumber .ngx-extended-pdf-viewer input,
.ngx-extended-pdf-viewer select {
  color: #000000 !important;
}

.im-navbar {
  background: #0882bc;
  background: linear-gradient(90deg, #0882bc 0%, #0257a5 100%);
  box-shadow: 0px 0px 13px #0000000F;
  height: 50px;
}
.im-navbar .im-nav-item {
  height: 50px;
}
.im-navbar .im-nav-item .im-nav-link {
  display: block;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px !important;
  font-family: "Source Sans Pro";
  letter-spacing: -1px;
  color: #FFFFFF;
  text-shadow: 0px 1px 0px #17040429;
  text-transform: uppercase;
  border-right: 1px solid #1EA7DB;
  border-left: 1px solid #0B93C6;
  padding: 7px 10px;
  height: 35px;
  margin-top: 7px;
}
.im-navbar .im-nav-item .im-nav-link.dropdown-toggle:after {
  content: "";
  font-family: "FontAwesome";
  font-weight: 900;
  display: inline-block;
  margin-left: 8px;
  vertical-align: 0;
  border: 0;
  font-size: 12px;
}
.im-navbar .im-nav-item:hover, .im-navbar .im-nav-item.active {
  background: #005f93;
  color: #ffffff;
}
.im-navbar .im-nav-item:hover .im-nav-link, .im-navbar .im-nav-item.active .im-nav-link {
  border-left: 1px solid #2f8dc9;
  border-right: 1px solid #2f8dc9;
}
.im-navbar .im-nav-item:first-child {
  border-left: 0;
}
.im-navbar .im-nav-item:last-child {
  border-right: 0;
}

.navbar-light .dropdown-menu, .navbar-light .im-dropdown-menu {
  min-width: 100%;
  margin-top: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  padding: 0px;
}
.navbar-light .dropdown-menu a, .navbar-light .im-dropdown-menu a {
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px !important;
  font-family: "Source Sans Pro";
  letter-spacing: -0.09px;
  color: #4D4F5C;
  text-transform: uppercase;
  padding: 12px 19px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.navbar-light .dropdown-menu a:last-child, .navbar-light .im-dropdown-menu a:last-child {
  border: 0;
}
.navbar-light .dropdown-menu a:hover, .navbar-light .im-dropdown-menu a:hover {
  background: #F5F6F9;
}

.mat-expansion-panel {
  border: 1px solid #E1E1E1;
  box-shadow: none !important;
}
.mat-expansion-panel .mat-expansion-panel-header {
  height: 60px !important;
  padding: 0 20px;
  font-family: "Source Sans Pro";
  font-size: 18px;
  background: #F5F7F7 !important;
}
.mat-expansion-panel .mat-expansion-panel-header:focus, .mat-expansion-panel .mat-expansion-panel-header:hover {
  background: #F5F7F7 !important;
}
.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-panel-header-title {
  color: #3A3A3A;
}

.mat-expansion-panel-content .mat-expansion-panel-body {
  padding: 0px;
}
.mat-expansion-panel-content .mat-expansion-panel-body .timeline-row {
  text-align: center;
  background: no-repeat padding-box #dedede;
  color: #5f6c74;
  height: 41px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 24px !important;
  font-family: "Source Sans Pro";
}

.col-styled {
  padding-top: 7px;
}

.petition-timeline {
  padding-top: 44px;
}
.petition-timeline .timeline-content {
  padding: 5px;
  border-radius: 0;
  display: block;
  position: relative;
  min-height: 32px;
  bottom: 0;
  max-height: 60px;
  margin: 5px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #ededed;
  color: #657071;
  border: 0 !important;
  border-left: 3px solid #ededed;
  white-space: break-spaces;
  line-height: inherit;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px !important;
  font-family: "Source Sans Pro";
}
.petition-timeline .timeline-content:hover {
  text-decoration: none;
}
.petition-timeline .timeline-content:before {
  content: "";
  transform: translateY(-50%);
  border: 2px solid #e1e1e1;
  position: absolute;
  width: 75px;
  left: -80px;
  top: 50%;
  height: 1px;
}
.petition-timeline .timeline .inprogress .inprogress {
  background-color: #c4e5ef;
  border: 0 !important;
  border-left: 3px solid #01a0da !important;
}

.mat-stepper-horizontal.pb-mat-stepper-custom-horizontal .mat-horizontal-stepper-header-container {
  width: 100%;
  margin-left: 0;
}
.mat-stepper-horizontal.pb-mat-stepper-custom-horizontal .mat-horizontal-stepper-header-container .mat-horizontal-stepper-header .mat-step-label {
  padding: 5px 10px;
  top: 5px;
}

.background_white {
  background: white;
}

.blue_panel {
  background-color: #DAE9FD;
}
.blue_panel .mat-form-field-appearance-outline .mat-form-field-flex .mat-form-field-infix {
  margin-top: -3px;
}

.white_panel {
  background-color: #ffffff;
  height: 60px;
}

.customPanel .p-overlaypanel {
  border: 0;
  bottom: 40px !important;
  left: 0% !important;
  top: auto !important;
}
.customPanel .p-overlaypanel::before, .customPanel .p-overlaypanel:after {
  border: 0 !important;
  border-color: transparent !important;
}
.customPanel .p-overlaypanel .p-overlaypanel-content {
  padding: 0 !important;
  padding-bottom: 20px !important;
  border: 1px solid #CCD4DE;
}
.customPanel .p-overlaypanel .p-overlaypanel-content .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper {
  max-height: 100px !important;
}
.customPanel .p-overlaypanel .p-overlaypanel-content p-button {
  display: block;
}
.customPanel .p-overlaypanel .p-overlaypanel-content > .p-button {
  background: transparent;
  border: 0;
  width: 100%;
  font-size: 14px;
  color: #3F3356;
  text-align: left;
  border-bottom: 1px solid #CCD4DE;
  height: 30px;
  border-radius: 0;
}
.customPanel .p-overlaypanel .p-overlaypanel-content > .p-button:hover {
  background-color: #DEDEDE;
  border-color: #CCD4DE;
}
.customPanel .p-overlaypanel .p-overlaypanel-content > .p-button .p-button-label {
  margin-bottom: 0;
  color: #333246 !important;
}

.rfeTimelineLeft {
  margin-left: -100%;
}
.rfeTimelineLeft .p-timeline.p-timeline-vertical .p-timeline-event-content .separator {
  width: 5% !important;
}

.rfeTimeline {
  margin-top: 30px;
}
.rfeTimeline .p-timeline-event-connector {
  width: 5px;
}
.rfeTimeline .p-button {
  padding: 5px;
  border-radius: 0;
  min-height: 32px;
  max-height: 60px;
  width: fit-content;
  background-color: #EDEDED;
  color: #657071;
  border: 0 !important;
  border-left: 3px solid #EDEDED;
  white-space: break-spaces;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px !important;
  font-family: "Source Sans Pro";
  margin-top: -4px;
  border-left: 4px solid #4d4f5c !important;
}
.rfeTimeline .p-button:hover, .rfeTimeline .p-button:focus {
  background-color: #e4e4e4 !important;
  color: #333246 !important;
}
.rfeTimeline .inprogress .p-button {
  background-color: #c4e5ef;
  border-left: 4px solid #01a0da !important;
}
.rfeTimeline .inprogress .p-button:hover, .rfeTimeline .inprogress .p-button:focus {
  background-color: #bcdce6 !important;
}
.rfeTimeline .complete .p-button {
  background-color: #dff4dd;
  border-left: 4px solid #4cba20 !important;
}
.rfeTimeline .complete .p-button:hover, .rfeTimeline .complete .p-button:focus {
  background-color: #d6ead4 !important;
}
.rfeTimeline .submitted .p-button {
  background-color: #84e0ce;
  border-left: 4px solid #54a796 !important;
}
.rfeTimeline .submitted .p-button:hover, .rfeTimeline .submitted .p-button:focus {
  background-color: #84e0ce !important;
}
.rfeTimeline .custom-marker {
  height: 28px;
  width: 28px;
}
.rfeTimeline .custom-marker .new {
  background-image: url(http://64.227.6.98/assets/images/StepNew.svg);
  height: 28px;
  width: 28px;
  display: inline-block;
}
.rfeTimeline .custom-marker .inprogress {
  background-image: url(http://64.227.6.98/assets/images/StepInprogress.svg);
  height: 28px;
  width: 28px;
  display: inline-block;
}
.rfeTimeline .custom-marker .complete {
  background-image: url(http://64.227.6.98/assets/images/StepComplete.svg);
  height: 28px;
  width: 28px;
  display: inline-block;
}
.rfeTimeline .custom-marker .fa {
  left: -3px;
  top: 1px;
  position: absolute;
}
.rfeTimeline .custom-marker .submitted {
  background-image: url(http://64.227.6.98/assets/images/StepSubmitted.svg);
  height: 28px;
  width: 28px;
  display: inline-block;
}
.rfeTimeline .p-timeline-event-separator {
  z-index: 1;
}
.rfeTimeline .p-timeline.p-timeline-vertical .p-timeline-event-opposite {
  padding: 0 5rem !important;
  z-index: 0;
}
.rfeTimeline .p-timeline.p-timeline-vertical .p-timeline-event-opposite .separator {
  margin: 0;
  top: 15px;
  width: 18%;
  position: absolute;
  right: 50%;
  z-index: -1;
  border: 2px solid #e1e1e1;
  background: #e1e1e1;
}
.rfeTimeline .p-timeline.p-timeline-vertical .p-timeline-event-opposite .p-button {
  top: 2px;
}
.rfeTimeline .p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 5rem !important;
}
.rfeTimeline .p-timeline.p-timeline-vertical .p-timeline-event-content .separator {
  position: absolute;
  margin: 0;
  transform: translate(0, 12px);
  width: 14%;
  left: 50%;
  border: 2px solid #e1e1e1;
  background: #e1e1e1;
}

.p-card {
  box-shadow: none;
  border: 1px solid #e1e1e1;
}
.p-card .p-card-title {
  color: #007bff;
  font-weight: 600;
  margin: 0;
  font-size: 19px;
}

.bg-gray .p-card {
  background: #f8f8f8;
  box-shadow: none;
}
.bg-gray .p-card .radio-panel {
  display: inline-block;
  border-radius: 3px;
  background: #f2ffed;
  border: 1px solid #e1e1e1;
  padding: 1rem;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  background: white !important;
  border: 0 !important;
  padding: 10px !important;
}
.p-accordion .p-accordion-content {
  padding: 0 !important;
  border: 1px solid #e1e1e1 !important;
  margin: 0 15px 15px 15px;
}
.p-accordion .p-accordion-content .p-datatable .p-datatable-tbody > tr > td {
  padding: 10px !important;
  vertical-align: top;
  border-bottom: 1px solid #e9ecef;
}
.p-accordion .p-accordion-content .p-datatable .p-datatable-tbody > tr > td:last-child {
  width: 30%;
  padding: 10px 0px !important;
}
.p-accordion .p-accordion-content .p-datatable .p-datatable-tbody > tr > td > .content {
  padding: 10px !important;
  vertical-align: top;
}
.p-accordion .p-accordion-content .p-datatable .p-datatable-tbody > tr > td > .content:last-child {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.p-accordion .p-accordion-content .p-datatable .p-datatable-thead > tr > th:last-child {
  width: 30%;
}
.p-accordion .p-accordion-content .p-button.p-button-icon-only {
  padding: 0px;
  height: 23px;
  font-size: 20px;
  top: -3px;
}

.rfeDialog .p-dialog-content {
  overflow-y: scroll !important;
  max-height: 90%;
}
.rfeDialog .p-dialog-mask.p-component-overlay {
  z-index: 1000 !important;
}

.p-overlaypanel {
  z-index: 1;
}

.p-tabview {
  border: 1px solid #e1e1e1;
  margin-top: 10px;
}
.p-tabview .p-tabview-panels {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.p-tabview .p-tabview-panel .p-button-outlined {
  padding: 5px 7px !important;
  font-size: 14px !important;
  min-height: auto !important;
  height: auto;
  font-weight: normal !important;
  line-height: 12px !important;
  border-radius: 7px !important;
}
.p-tabview .p-tabview-nav li {
  width: 50%;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 10px !important;
  color: #01a0da !important;
  background-color: #f5f5f5 !important;
  font-size: 14px;
  border-right: 1px solid #e1e1e1 !important;
  border-radius: 0 !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link .p-tabview-title {
  margin-left: auto;
  margin-right: auto;
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: #e1e1e1 !important;
  color: #6c757d !important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus,
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

.p-tree {
  border: 0 !important;
  padding: 0 !important;
}
.p-tree .p-treenode-children {
  padding: 0 1rem 1rem !important;
}
.p-tree .p-treenode-children .p-treenode-droppoint {
  height: 0.5rem !important;
}
.p-tree .p-treenode-children .p-treenode .p-treenode-content .p-tree-toggler {
  display: none;
}
.p-tree .p-treenode-droppoint {
  height: 1rem !important;
}
.p-tree .p-tree-container .p-treenode {
  padding: 0 !important;
  border: 1px solid #e1e1e1;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 0 !important;
  padding: 0 !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content[aria-expanded=false] .p-datatable-header {
  display: none;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content[aria-expanded=false] .p-tree-toggler {
  top: 0px;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  box-shadow: none !important;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label {
  width: 100%;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-label .p-button-text {
  padding: 0px;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  top: -2rem;
}

.summary-accordion .p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 0px !important;
  background-color: #ffffff !important;
  color: #495057 !important;
}
.summary-accordion .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-header-text {
  width: auto;
}
.summary-accordion .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link {
  padding: 0px !important;
  background-color: #ffffff !important;
  color: #495057 !important;
}
.summary-accordion .p-accordion-content {
  border: 0 !important;
}

#fdnsqaContainer .fdnsqa.p-tabview {
  border: none !important;
  margin-top: 10px;
}
#fdnsqaContainer .fdnsqa.p-tabview .p-tabview-panels {
  padding: 0px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
#fdnsqaContainer .fdnsqa.p-tabview .p-tabview-panel {
  padding: 0px !important;
}
#fdnsqaContainer .fdnsqa.p-tabview .p-tabview-panel .p-button-outlined {
  padding: 5px 7px !important;
  font-size: 14px !important;
  min-height: auto !important;
  height: auto;
  font-weight: normal !important;
  line-height: 12px !important;
  border-radius: 7px !important;
}
#fdnsqaContainer .fdnsqa.p-tabview .p-tabview-nav {
  padding: 0 20px !important;
  background-color: #f5f7f7 !important;
  border: 1px solid #dee2e6 !important;
  border-width: 1px !important;
}
#fdnsqaContainer .fdnsqa.p-tabview .p-tabview-nav li {
  width: 25% !important;
  padding: 20px 0;
}
#fdnsqaContainer .fdnsqa.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 10px !important;
  color: #6c757d !important;
  background-color: inherit !important;
  font-size: 14px;
  border-right: 1px solid #dee2e6 !important;
  border-radius: 0 !important;
  border-width: 0 0 0 0px;
}
#fdnsqaContainer .fdnsqa.p-tabview .p-tabview-nav li .p-tabview-nav-link .p-tabview-title {
  margin-left: auto;
  margin-right: auto;
}
#fdnsqaContainer .fdnsqa.p-tabview .p-tabview-nav li.p-highlight {
  border-bottom: 3px solid #08a3db !important;
}
#fdnsqaContainer .fdnsqa.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #08a3db !important;
  background-color: #a6d5fa !important;
}

.overlayContainer {
  height: 500px;
  width: 500px;
}
.overlayContainer .footer {
  position: absolute;
  bottom: 0;
  width: 94%;
  text-align: center;
}

.overlayContainer .mat-tab-label {
  font-size: 16px;
  font-family: "Source Sans Pro";
}
.overlayContainer .mat-tab-label-container {
  padding-bottom: 2px;
}
.overlayContainer .mat-tab-label-active {
  border-bottom: 1px solid #01a0da !important;
  color: #01a0da;
}
.overlayContainer .mat-tab-body-wrapper {
  min-height: auto !important;
}
.overlayContainer .mat-ink-bar {
  display: none;
}
.overlayContainer .mat-tab-header {
  border-bottom: 0;
}

.p-dialog {
  box-shadow: none;
  max-height: 100% !important;
}

.p-dialog-content {
  overflow-y: scroll !important;
  max-height: 100%;
}

.p-fileupload .p-message.p-message-error {
  margin: 0px 0px 10px;
}
.p-fileupload .p-message .p-message-wrapper {
  padding: 5px;
  font: normal normal normal 14px/20px Source Sans Pro;
}

.joyride-step__container {
  width: 700px;
  padding: 1rem !important;
  border-radius: 5px;
}
.joyride-step__container .joyride-step__header {
  display: none;
}
.joyride-step__container .joyride-step__title {
  font-weight: 500;
  font: normal normal bold 14px/20px Source Sans Pro;
  font-size: 1.5rem;
  color: #257cb0 !important;
}
.joyride-step__container .joyride-step__footer {
  display: none;
}
.joyride-step__container .joyride-step__button {
  display: inline;
}
.joyride-step__container .joyride-step__button .joyride-button {
  margin: 0;
  background-color: white !important;
  color: #ccd4de !important;
  font-size: 34px;
  padding: 0;
  line-height: 18px;
  font-weight: normal;
  vertical-align: middle;
}
.joyride-step__container .joyride-step__button .joyride-button:hover {
  border: 2px solid transparent !important;
  color: #687c93 !important;
}
.joyride-step__container .joyride-step__close {
  display: none;
}
.joyride-step__container .btn-primary {
  text-transform: none;
  font-weight: normal !important;
  font-size: 18px !important;
}
.joyride-step__container .navigationButtons .btn, .joyride-step__container .navigationButtons .p-button-outlined,
.joyride-step__container .navigationButtons .im-btn, .joyride-step__container .navigationButtons .btn-success,
.joyride-step__container .navigationButtons .im-btn-primary, .joyride-step__container .navigationButtons .button_bg-gray,
.joyride-step__container .navigationButtons .back-button {
  padding: 0 !important;
  min-width: 0 !important;
}
.joyride-step__container .navigationButtons .btn:focus, .joyride-step__container .navigationButtons .p-button-outlined:focus,
.joyride-step__container .navigationButtons .im-btn:focus, .joyride-step__container .navigationButtons .btn-success:focus,
.joyride-step__container .navigationButtons .im-btn-primary:focus, .joyride-step__container .navigationButtons .button_bg-gray:focus,
.joyride-step__container .navigationButtons .back-button:focus {
  box-shadow: none;
}
.joyride-step__container .navigationButtons .btn i, .joyride-step__container .navigationButtons .p-button-outlined i,
.joyride-step__container .navigationButtons .im-btn i, .joyride-step__container .navigationButtons .btn-success i,
.joyride-step__container .navigationButtons .im-btn-primary i, .joyride-step__container .navigationButtons .button_bg-gray i,
.joyride-step__container .navigationButtons .back-button i {
  font-size: 40px;
  margin-top: -6px;
  width: 30px;
  color: #687c93;
}

.lca-acknowledgement-container .lcaAcknowledge.p-tabview {
  border: none !important;
}
.lca-acknowledgement-container .lcaAcknowledge.p-tabview .p-tabview-panels {
  padding: 0px !important;
}
.lca-acknowledgement-container .lcaAcknowledge.p-tabview .p-tabview-panel {
  padding: 0px !important;
}
.lca-acknowledgement-container .lcaAcknowledge.p-tabview .p-tabview-panel .p-button-outlined {
  padding: 7px 7px !important;
  font-size: 14px !important;
  min-height: auto !important;
  height: auto;
  font-weight: normal !important;
  line-height: 14px !important;
  border-radius: 0px !important;
}
.lca-acknowledgement-container .lcaAcknowledge.p-tabview .p-tabview-panel .p-button-secondary,
.lca-acknowledgement-container .lcaAcknowledge.p-tabview .p-tabview-panel .p-button-primary {
  padding: 7px 7px !important;
  line-height: 14px !important;
  border-radius: 0px !important;
  text-transform: uppercase;
  font-size: 14px;
}
.lca-acknowledgement-container .lcaAcknowledge.p-tabview .p-tabview-nav {
  border: none !important;
}
.lca-acknowledgement-container .lcaAcknowledge.p-tabview .p-tabview-nav li {
  width: auto !important;
  margin-left: 1% !important;
  margin-right: 1% !important;
}
.lca-acknowledgement-container .lcaAcknowledge.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: #6c757d !important;
  background-color: inherit !important;
  font-size: 16px;
  border-right: 0px !important;
  border-radius: 0 !important;
  border-width: 0 0 0 0px;
}
.lca-acknowledgement-container .lcaAcknowledge.p-tabview .p-tabview-nav li .p-tabview-nav-link .p-tabview-title {
  margin-left: auto;
  margin-right: auto;
}
.lca-acknowledgement-container .lcaAcknowledge.p-tabview .p-tabview-nav li.p-highlight {
  border-bottom: 3px solid #08a3db !important;
}
.lca-acknowledgement-container .lcaAcknowledge.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #08a3db !important;
  background-color: #a6d5fa !important;
}

.overlayPanel {
  top: 38px !important;
  left: 15px !important;
}

.fd_hr_tab_style.p-tabview {
  border-top: none !important;
  border-right: none !important;
}
.fd_hr_tab_style.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-bottom: 4px solid #6eb4ff !important;
  border-right: 1px solid #e1e1e1 !important;
  background-color: #ffffff !important;
}
.fd_hr_tab_style.p-tabview .p-tabview-nav li.custom-add-tab {
  flex-grow: 1;
  border-bottom: 1px solid #dee2e6 !important;
  max-width: 100%;
}
.fd_hr_tab_style.p-tabview .p-tabview-nav li.custom-add-tab .p-tabview-nav-link {
  background-color: transparent !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: none !important;
  pointer-events: none;
}
.fd_hr_tab_style.p-tabview .p-tabview-nav li.custom-add-tab .p-tabview-nav-link * {
  pointer-events: auto;
}
.fd_hr_tab_style.p-tabview .p-tabview-nav li {
  flex: 1;
  max-width: calc(100% / var(--tab-count, 1)) !important;
}
.fd_hr_tab_style.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  height: 50px;
  width: 100%;
  min-width: 150px;
  line-height: 40px;
  border-bottom: 1px solid #dee2e6 !important;
  border-top: 1px solid #dee2e6 !important;
}
.fd_hr_tab_style.p-tabview .p-tabview-nav {
  border-left: none !important;
  border-right: none !important;
}
.fd_hr_tab_style.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link .p-link-active {
  border-bottom: none !important;
}
.fd_hr_tab_style.p-tabview .p-tabview-panels {
  border-right: 1px solid #dee2e6 !important;
}
.fd_hr_tab_style.p-tabview .p-tabview-panel {
  padding: 0px !important;
}
.fd_hr_tab_style.p-tabview .p-tabview-nav {
  border-bottom: none !important;
}
.fd_hr_tab_style.p-tabview .p-tabview-nav li {
  width: auto !important;
}
.fd_hr_tab_style.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 10px !important;
  color: #6c757d !important;
  background-color: inherit !important;
  font-size: 14px;
  border-right: 1px solid #dee2e6 !important;
  border-radius: 0 !important;
  border-width: 0 0 0 0px;
}
.fd_hr_tab_style.p-tabview .p-tabview-nav li .p-tabview-nav-link .p-tabview-title {
  margin-left: auto;
  margin-right: auto;
}

.fd_vr_tab_style.p-tabview {
  border: 1px solid #dee2e6 !important;
  display: flex;
  border-width: 2px !important;
}
.fd_vr_tab_style.p-tabview .p-tabview-nav:not(.fd_hr_tab_style > .p-tabview-nav) {
  display: flex;
  flex-direction: column;
  padding-top: 12px !important;
  padding-bottom: 24px !important;
  background-color: #fff !important;
  border-width: 1px !important;
  border-bottom: none !important;
}
.fd_vr_tab_style.p-tabview .p-tabview-nav:not(.fd_hr_tab_style > .p-tabview-nav) li.custom-add-tab {
  flex-grow: 1;
  margin-top: -10px;
  border-right: 1px solid #dee2e6 !important;
}
.fd_vr_tab_style.p-tabview .p-tabview-nav:not(.fd_hr_tab_style > .p-tabview-nav) li.custom-add-tab .p-tabview-nav-link {
  background-color: transparent !important;
  border: none !important;
  pointer-events: none;
}
.fd_vr_tab_style.p-tabview .p-tabview-nav:not(.fd_hr_tab_style > .p-tabview-nav) li {
  width: auto !important;
}
.fd_vr_tab_style.p-tabview .p-tabview-nav:not(.fd_hr_tab_style > .p-tabview-nav) li .p-tabview-nav-link {
  padding: 10px !important;
  color: #6c757d !important;
  font-size: 14px;
  border-right: 1px solid #dee2e6 !important;
  border-left: 1px solid #dee2e6 !important;
  margin-left: 28px;
  margin-top: 12px;
  border-radius: 0 !important;
  border-width: 0 0 0 0px;
  line-height: 16px;
}
.fd_vr_tab_style.p-tabview .p-tabview-nav:not(.fd_hr_tab_style > .p-tabview-nav) li .p-tabview-nav-link .p-tabview-title {
  margin-left: auto;
  margin-right: auto;
}
.fd_vr_tab_style.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-right: none !important;
  background-color: #ffffff !important;
}
.fd_vr_tab_style.p-tabview .p-tabview-nav li.custom-add-tab .p-tabview-nav-link * {
  pointer-events: auto;
  position: relative;
  left: 12px;
}
.fd_vr_tab_style.p-tabview .p-tabview-nav li.custom-add-tab .p-tabview-nav-link i {
  pointer-events: auto;
  color: #3b922b !important;
  font-size: 20px;
}
.fd_vr_tab_style.p-tabview .p-tabview-nav li.custom-add-tab .p-tabview-nav-link span {
  position: relative;
  bottom: 2px;
  left: 8px;
}
.fd_vr_tab_style.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  height: 50px;
  width: 180px;
  line-height: 40px;
  border-bottom: 1px solid #dee2e6 !important;
  border-top: 1px solid #dee2e6 !important;
}
.fd_vr_tab_style.p-tabview .p-tabview-nav {
  border-left: none !important;
  border-right: none !important;
}
.fd_vr_tab_style.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link .p-link-active {
  border-bottom: none !important;
}
.fd_vr_tab_style.p-tabview .p-tabview-panels {
  flex-grow: 1;
}
.fd_vr_tab_style.p-tabview .p-tabview-panel {
  padding: 0px !important;
}

.task-page-tab-state .mat-tab-header {
  padding: 10px 10px 16px 10px;
  background: #ffffff;
}
.task-page-tab-state a.mat-tab-link {
  background: #F8FBFF 0% 0% no-repeat padding-box;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px !important;
  font-family: "Source Sans Pro";
  letter-spacing: 0px;
  color: rgba(51, 50, 70, 0.5);
  border-bottom: 1px solid #E1E1E1;
  height: 64px;
  margin: 0 5px 0 5px;
  justify-content: left;
}
.task-page-tab-state a.mat-tab-link:hover {
  text-decoration: none;
}
.task-page-tab-state a.mat-tab-link.mat-tab-label-active {
  background: #EDFAFF 0% 0% no-repeat padding-box;
  border-bottom: 5px solid #01A0DA !important;
  color: #333246;
  box-shadow: 1px 0px 5px #01A0DA;
}

.mat-tab-link-container {
  display: inline-block !important;
  flex-grow: revert !important;
}

.builder-tab .mat-tab-link-container {
  padding: 0 30px;
  background: #ffffff;
}
.builder-tab .mat-tab-link-container .mat-tab-links {
  height: 80px;
  border: 1px solid #E1E1E1;
  background: #F5F7F7;
}
.builder-tab .mat-tab-link-container .mat-tab-link {
  opacity: 1;
  background: #F5F7F7;
  border-right: 1px solid #E1E1E1;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px !important;
  font-family: "Source Sans Pro";
  letter-spacing: 0px;
  color: #4A4A4A;
  min-width: 130px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.builder-tab .mat-tab-link-container .mat-tab-link.active-tab {
  border-bottom: 5px solid #01A0DA;
}
.builder-tab.gray-tab .mat-tab-link-container {
  padding: 0;
}
.builder-tab.gray-tab .mat-tab-link-container .mat-tab-links {
  height: auto;
  padding: 0 10px;
  border: 0;
}
.builder-tab.gray-tab .mat-tab-link-container .mat-tab-link {
  border-right: 0;
}
.builder-tab.gray-tab .mat-tab-link-container .mat-tab-link.active-tab {
  font-weight: 700;
  text-decoration: none;
}

.summaryTask-tab .p-tabview {
  text-align: center;
  border: 0;
}
.summaryTask-tab .p-tabview hr {
  border-width: 4px !important;
}
.summaryTask-tab .p-tabview .p-tabview-nav {
  display: inline-flex;
  border: 0;
}
.summaryTask-tab .p-tabview .p-tabview-nav li {
  width: auto;
}
.summaryTask-tab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link label {
  color: #2196f3;
  border-bottom: 3px solid #2196f3;
  padding: 10px;
}
.summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link {
  background: none !important;
  width: 170px;
  display: block;
  border: 0 !important;
  margin: 0;
  padding: 20px 0 !important;
  padding-right: 0;
}
.summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link:focus {
  box-shadow: none !important;
}
.summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link .header-icon {
  margin-left: auto;
  margin-right: auto;
  height: 90px;
  border-width: 4px !important;
  width: 90px;
  background: none !important;
}
.summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link .header-icon img {
  max-height: 50px;
  height: 100%;
  vertical-align: middle;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;
  display: block;
}
.summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link label {
  padding: 10px;
  border-width: 3px;
  margin: 0;
  color: #687c93;
}
.summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link label span.pi {
  pointer-events: auto !important;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.summaryTask-tab .p-tabview .p-tabview-panels {
  border-top: 1px solid rgba(112, 112, 112, 0.46) !important;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 1px;
}
.summaryTask-tab .p-tabview .p-tabview-panels .p-tabview-panel {
  text-align: left;
}
.summaryTask-tab .p-tabview .p-tabview-panels .p-tabview-panel .p-button-outlined {
  padding: 0 29px !important;
  font-size: 16px !important;
  min-height: auto !important;
  height: 43px;
  font-weight: normal !important;
  line-height: 20px !important;
  text-transform: none;
}

.summaryTimeline .p-timeline {
  margin-left: 38px;
  margin-top: -3px;
}
.summaryTimeline .p-timeline-left .p-timeline-event-opposite {
  display: none;
}
.summaryTimeline .p-timeline-event {
  min-height: 0;
}
.summaryTimeline .p-timeline-event-marker {
  border: 1px solid #e1e1e1 !important;
  border-radius: 0 !important;
  width: 1px !important;
  height: 1rem !important;
}

.vd-summaryTask-tab .p-tabview {
  text-align: center;
  border: 0;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav {
  display: flex;
  flex-wrap: nowrap;
  border: 0;
  margin: 0 30px;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav li {
  flex-grow: 1;
  flex-basis: 0;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: #2196f3;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link label {
  color: #2196f3;
  border: 0;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link {
  background: none !important;
  width: 100%;
  display: block;
  border: 0 !important;
  margin: 0;
  padding: 10px 0 !important;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link:focus {
  box-shadow: none !important;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link .icon-holder {
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link .icon-holder .zero-index-line {
  width: 50%;
  position: absolute;
  top: 50%;
  right: 0;
  margin: 0;
  z-index: 0;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link .icon-holder .non-zero-index-line {
  width: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  margin: 0;
  z-index: 0;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link .icon-holder .final-index-line {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  margin: 0;
  z-index: 0;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link .icon-holder .header-icon {
  margin-left: auto;
  margin-right: auto;
  border-width: 2px !important;
  padding: 0 !important;
  height: 43px;
  width: 43px;
  display: block;
  line-height: 43px;
  background-color: #fff;
  z-index: 2;
  position: relative;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link .icon-holder .header-icon img {
  margin: 6px auto 7px;
  height: 25px;
  position: relative;
  display: block;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link .icon-holder label {
  padding: 5px;
  border-width: 3px;
  margin: 0;
  color: #687c93;
}
.vd-summaryTask-tab .p-tabview .p-tabview-nav .p-tabview-nav-link .icon-holder label span.pi {
  pointer-events: auto !important;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.vd-summaryTask-tab .p-tabview .p-tabview-panels {
  border-top: 1px solid rgba(112, 112, 112, 0.46) !important;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 1px;
}
.vd-summaryTask-tab .p-tabview .p-tabview-panels .p-tabview-panel {
  text-align: left;
}
.vd-summaryTask-tab .p-tabview .p-tabview-panels .p-tabview-panel .p-button-outlined {
  padding: 0 29px !important;
  font-size: 16px !important;
  min-height: auto !important;
  height: 43px;
  font-weight: normal !important;
  line-height: 20px !important;
  text-transform: none;
}

#profileContainer .mainTabStyle {
  border: 0px;
}
#profileContainer .mainTabStyle.p-tabview .p-tabview-nav {
  padding: 0px !important;
  border: 0px;
}
#profileContainer .mainTabStyle.p-tabview .p-tabview-nav li {
  width: calc(20% - 10px) !important;
  margin: 0 5px;
}
#profileContainer .mainTabStyle.p-tabview .p-tabview-nav li:first-child {
  margin-left: 0px;
}
#profileContainer .mainTabStyle.p-tabview .p-tabview-nav li:last-child {
  margin-right: 0px;
}
#profileContainer .mainTabStyle.p-tabview .p-tabview-nav li a.p-tabview-nav-link {
  border: 0px !important;
}
#profileContainer .mainTabStyle.p-tabview .p-tabview-nav li.p-highlight a.p-tabview-nav-link {
  border-bottom: 3px solid #19a0da !important;
}

#profileContainer .mainTabStyle.p-tabview > .p-tabview-nav li.p-highlight:after,
#profileContainer .subTabStyle.p-tabview > .p-tabview-nav li.p-highlight:after {
  position: absolute;
  margin-left: 9%;
  border-top: 7px solid #19a0da;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  color: transparent;
}

#profileContainer .family .mainTabStyle.p-tabview > .p-tabview-nav li.p-highlight:after {
  top: 61px;
}

#profileContainer .subTabStyle.p-tabview > .p-tabview-nav li.p-highlight:after {
  margin-left: 50%;
  left: -7px;
  top: 100%;
}

#profileContainer .subTabStyle.p-tabview .p-tabview-nav {
  border: 0px solid gray !important;
  display: block;
  background-color: white !important;
}
#profileContainer .subTabStyle.p-tabview .p-tabview-nav li {
  float: left;
  width: auto !important;
}

#profileContainer .subTabStyle.p-tabview > .p-tabview-nav li {
  margin-left: 0% !important;
  margin-right: 0% !important;
}
#profileContainer .subTabStyle.p-tabview > .p-tabview-nav li.p-highlight a.p-tabview-nav-link {
  border-bottom: 0 !important;
  background-color: #19a0da !important;
  color: #ffffff !important;
}

#profileContainer .subTabStyle.p-tabview {
  margin-top: 0px !important;
  margin-bottom: 2rem !important;
  border: 0;
}

#profileContainer .subTabStyle.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: #6c757d !important;
  font-size: 14px;
  border: 1px solid #e3dfdf !important;
  padding: 18px 25px !important;
  border-right: none !important;
}

#profileContainer .subTabStyle.p-tabview > ul > li:first-child > a {
  border-radius: 25px 0 0 25px !important;
}

#profileContainer .subTabStyle.p-tabview .p-tabview-nav li.p-highlight {
  border-bottom: 0px !important;
  width: auto !important;
  position: relative;
}

.task-access-tab .mat-tab-group {
  flex-direction: row;
}
.task-access-tab .mat-tab-header {
  border-bottom: none;
}
.task-access-tab .mat-tab-header-pagination {
  display: none !important;
}
.task-access-tab .mat-tab-labels {
  flex-direction: column;
}
.task-access-tab .mat-tab-labels .mat-tab-label {
  border: 1px solid #e1e1e1;
  background-color: #f5f7f7 !important;
  color: #000000 !important;
  font-weight: 600 !important;
}
.task-access-tab .mat-tab-labels .mat-tab-label-content {
  padding: 0px 40px;
}
.task-access-tab .mat-tab-labels .mat-tab-label-active {
  color: #2196f3 !important;
  cursor: pointer !important;
  font-weight: 600 !important;
}
.task-access-tab .mat-ink-bar {
  background-color: unset !important;
}
.task-access-tab .mat-ink-bar {
  height: 100%;
  left: 98% !important;
}
.task-access-tab .mat-tab-body-wrapper {
  flex: 1 1 auto;
}

.emailNotification-tab .p-tabview {
  text-align: left;
  border: 0;
}
.emailNotification-tab .p-tabview hr {
  border-width: 4px !important;
}
.emailNotification-tab .p-tabview .p-tabview-nav {
  background: #f5f7f7 !important;
  border: 1px solid #f5f7f7 !important;
  border: 0;
}
.emailNotification-tab .p-tabview .p-tabview-nav li {
  width: auto;
  cursor: pointer;
}
.emailNotification-tab .p-tabview .p-tabview-nav li:hover {
  background-color: #f6f9fd;
}
.emailNotification-tab .p-tabview .p-tabview-nav li.p-highlight {
  background-color: #f6f9fd;
}
.emailNotification-tab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link label {
  color: #2196f3;
  border-bottom: 3px solid #2196f3;
  padding: 10px;
}
.emailNotification-tab .p-tabview .p-tabview-nav .p-tabview-nav-link {
  background: none !important;
  display: block;
  border: 0 !important;
  margin: 0;
  padding: 0 !important;
  text-align: center;
  border-right: 1px solid #bebebe !important;
}
.emailNotification-tab .p-tabview .p-tabview-nav .p-tabview-nav-link:focus {
  box-shadow: none !important;
}
.emailNotification-tab .p-tabview .p-tabview-nav .p-tabview-nav-link label {
  padding: 18px 40px !important;
  border-width: 3px;
  margin: 0;
  color: #687c93;
}
.emailNotification-tab .p-tabview .p-tabview-nav .p-tabview-nav-link label span.pi {
  pointer-events: auto !important;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.emailNotification-tab .p-tabview .p-tabview-panels {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 30px !important;
  margin-top: 1px;
}
.emailNotification-tab .p-tabview .p-tabview-panels .p-tabview-panel {
  text-align: left;
}
.emailNotification-tab .p-tabview .p-tabview-panels .p-tabview-panel .p-button-outlined {
  padding: 0 29px !important;
  font-size: 16px !important;
  min-height: auto !important;
  height: 43px;
  font-weight: normal !important;
  line-height: 20px !important;
  text-transform: none;
}

.taskAllocation-tab .p-tabview {
  text-align: center;
  border: 0;
}
.taskAllocation-tab .p-tabview hr {
  border-width: 4px !important;
}
.taskAllocation-tab .p-tabview .p-tabview-nav {
  display: inline-flex;
  border: 0;
}
.taskAllocation-tab .p-tabview .p-tabview-nav li {
  width: auto;
}
.taskAllocation-tab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link label {
  color: #2196f3;
  border-bottom: 3px solid #2196f3;
  padding: 10px;
}
.taskAllocation-tab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link .row span {
  background-color: #2196f3;
  color: #ffffff;
}
.taskAllocation-tab .p-tabview .p-tabview-nav .p-tabview-nav-link {
  background: none !important;
  width: 170px;
  display: block;
  border: 0 !important;
  margin: 0;
  padding: 20px 0 !important;
  padding-right: 0;
}
.taskAllocation-tab .p-tabview .p-tabview-nav .p-tabview-nav-link:focus {
  box-shadow: none !important;
}
.taskAllocation-tab .p-tabview .p-tabview-nav .p-tabview-nav-link .header-icon {
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  border-width: 4px !important;
  width: 60px;
}
.taskAllocation-tab .p-tabview .p-tabview-nav .p-tabview-nav-link .header-icon img {
  max-height: 50px;
  height: 100%;
  vertical-align: middle;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;
  display: block;
}
.taskAllocation-tab .p-tabview .p-tabview-nav .p-tabview-nav-link label {
  padding: 10px;
  border-width: 3px;
  margin: 0;
  color: #687c93;
}
.taskAllocation-tab .p-tabview .p-tabview-nav .p-tabview-nav-link label span.pi {
  pointer-events: auto !important;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.taskAllocation-tab .p-tabview .p-tabview-panels {
  border-top: 1px solid rgba(112, 112, 112, 0.46) !important;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 1px;
}
.taskAllocation-tab .p-tabview .p-tabview-panels .p-tabview-panel {
  text-align: left;
}
.taskAllocation-tab .p-tabview .p-tabview-panels .p-tabview-panel .p-button-outlined {
  padding: 0 29px !important;
  font-size: 16px !important;
  min-height: auto !important;
  height: 43px;
  font-weight: normal !important;
  line-height: 20px !important;
  text-transform: none;
}

.ques-tabs .p-tabview .p-tabview-nav li {
  width: 335px !important;
}

.attorneyInterfaceTab .p-tabview {
  text-align: center;
  border: 0;
}
.attorneyInterfaceTab .p-tabview hr {
  border-width: 4px !important;
}
.attorneyInterfaceTab .p-tabview .p-tabview-nav {
  display: inline-flex;
  border: 0;
}
.attorneyInterfaceTab .p-tabview .p-tabview-nav li {
  width: auto;
}
.attorneyInterfaceTab .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link label {
  color: #2196f3;
  border-bottom: 3px solid #2196f3;
  padding: 5px;
}
.attorneyInterfaceTab .p-tabview .p-tabview-nav .p-tabview-nav-link {
  background: none !important;
  width: 170px;
  display: block;
  border: 0 !important;
  margin: 0;
  padding: 20px 0 !important;
  padding-right: 0;
}
.attorneyInterfaceTab .p-tabview .p-tabview-nav .p-tabview-nav-link:focus {
  box-shadow: none !important;
}
.attorneyInterfaceTab .p-tabview .p-tabview-nav .p-tabview-nav-link .header-icon {
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  border-width: 4px !important;
  width: 70px;
  background: none !important;
}
.attorneyInterfaceTab .p-tabview .p-tabview-nav .p-tabview-nav-link .header-icon img {
  max-height: 50px;
  height: 100%;
  vertical-align: middle;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;
  display: block;
}
.attorneyInterfaceTab .p-tabview .p-tabview-nav .p-tabview-nav-link label {
  padding: 10px;
  border-width: 3px;
  margin: 0;
  color: #687c93;
}
.attorneyInterfaceTab .p-tabview .p-tabview-nav .p-tabview-nav-link label span.pi {
  pointer-events: auto !important;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.attorneyInterfaceTab .p-tabview .p-tabview-panels {
  border-top: 1px solid rgba(112, 112, 112, 0.46) !important;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 1px;
}
.attorneyInterfaceTab .p-tabview .p-tabview-panels .p-tabview-panel {
  text-align: left;
}
.attorneyInterfaceTab .p-tabview .p-tabview-panels .p-tabview-panel .p-button-outlined {
  padding: 0 29px !important;
  font-size: 16px !important;
  min-height: auto !important;
  height: 43px;
  font-weight: normal !important;
  line-height: 20px !important;
  text-transform: none;
}

.attorneyInterfaceTab .p-tabview .p-tabview-nav li.p-highlight .header-icon.active {
  border-color: #01A0DA !important;
}
.attorneyInterfaceTab .p-tabview .p-tabview-panels {
  border-top: 1px dashed rgba(112, 112, 112, 0.46) !important;
}

.visit-task-widget .mat-tab-label {
  background-color: #d2d2d2;
  min-width: 49% !important;
  margin-right: 8px;
}
.visit-task-widget .mat-tab-label:last-child {
  margin-right: 0;
}
.visit-task-widget .mat-ink-bar {
  height: 4px !important;
}
.visit-task-widget .mat-table .mat-header-row {
  height: 45px;
}

.table,
.im-card-table,
.im-card-no-border-table {
  font-family: "Source Sans Pro";
}

.mat-table .mat-header-row {
  height: 35px;
}

.im-card-table-noborder-spacing tr .mat-cell {
  border-right: 1px solid #E1E1E1;
}
.im-card-table-noborder-spacing tr .mat-cell span {
  border: 0 !important;
}

.bordered-checkbox.mat-checkbox {
  border: 1px solid #4D4F5C;
  height: 22px;
}
.bordered-checkbox.mat-checkbox .mat-checkbox-inner-container.mat-checkbox-inner-container-no-side-margin {
  border: 0 !important;
  display: inline-block;
  padding-right: 9px;
  word-break: break-word;
  margin-bottom: 14px;
}
.bordered-checkbox.mat-checkbox .mat-checkbox-frame {
  border: 0;
}
.bordered-checkbox.mat-checkbox.mat-checkbox-checked .mat-checkbox-background, .bordered-checkbox.mat-checkbox.mat-checkbox-indeterminate .mat-checkbox-background {
  background: transparent;
}
.bordered-checkbox.mat-checkbox .mat-checkbox-checkmark-path {
  stroke: #4D4F5C !important;
}

.table-header_uppercase thead th, .table-header_uppercase .mat-sort-header-button {
  text-transform: uppercase;
}

.im-card-table thead th, .im-card-no-border-table thead th {
  color: #a4a7b5;
  background: #d5def1;
  border-bottom: none;
  border-top: none;
  vertical-align: middle !important;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px !important;
  font-family: "Source Sans Pro";
  color: #4D4F5C;
  padding: 4px 14px 0 14px !important;
  border-right: 1px solid #E1E1E1;
}
.im-card-table thead th:last-child, .im-card-no-border-table thead th:last-child {
  border-right: none;
}
.im-card-table tbody tr .mat-cell, .im-card-no-border-table tbody tr .mat-cell {
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px !important;
  font-family: "Source Sans Pro";
  color: #4D4F5C;
  padding: 14px 0px 0 14px !important;
  word-break: break-word;
}
.im-card-table tbody tr .mat-cell.inner-table-with-nopadding, .im-card-no-border-table tbody tr .mat-cell.inner-table-with-nopadding {
  padding: 0 !important;
}
.im-card-table tbody tr .mat-cell span, .im-card-no-border-table tbody tr .mat-cell span {
  border-right: 1px solid #E1E1E1;
  width: 100%;
  display: inline-block;
  padding-right: 9px;
  word-break: break-word;
  margin-bottom: 14px;
}
.im-card-table tbody tr .mat-cell:last-child span, .im-card-no-border-table tbody tr .mat-cell:last-child span {
  border: none;
}
.im-card-table tbody tr .mat-cell.mat-column-fileLocation, .im-card-no-border-table tbody tr .mat-cell.mat-column-fileLocation {
  text-align: center;
}

.mat-list-base .mat-list-item .mat-line {
  color: #4D4F5C;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px !important;
  font-family: "Source Sans Pro";
}

.no-brick-rt-brdr tbody tr .mat-cell span {
  border-right: unset !important;
}

.table-fixed {
  table-layout: fixed;
}

.radio-btn .mat-radio-label .mat-radio-container {
  border: 1px solid grey !important;
  border-radius: 18px;
  padding: 10px 2px;
  margin-bottom: 0px;
}

.mat-stepper-horizontal {
  font-family: "Source Sans Pro";
  width: 100%;
}
.mat-stepper-horizontal .mat-horizontal-stepper-header-container {
  width: 60%;
  margin-left: 20%;
}
.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header {
  padding: 10px 0;
  text-transform: uppercase;
}
.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-label-active {
  color: #01a0da !important;
}
.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-icon-selected {
  background-color: #01a0da !important;
}
.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-label, .mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-text-label {
  font-weight: bold !important;
}

.back-continue-icon {
  font-size: 43px;
  color: #061058;
}

.grey-border-pmenu {
  width: 100%;
  margin-bottom: 15px;
  margin-top: 5px;
}
.grey-border-pmenu .p-megamenu {
  border: 0;
  border-top: 2px solid #cccccc;
  border-bottom: 2px solid #cccccc;
  border-radius: 0;
  padding: 0 15px;
}
.grey-border-pmenu .p-megamenu .p-megamenu-root-list .p-menuitem .p-menuitem-link {
  border-radius: 0;
  padding: 0.75rem 1.25rem;
  box-shadow: none !important;
}
.grey-border-pmenu .p-megamenu .p-megamenu-root-list .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #6b6b6b;
  font-weight: 600;
}
.grey-border-pmenu .p-megamenu .p-megamenu-root-list .p-menuitem .p-menuitem-link.p-menuitem-link-active {
  background-color: #0071c9;
}
.grey-border-pmenu .p-megamenu .p-megamenu-root-list .p-menuitem .p-menuitem-link.p-menuitem-link-active .p-menuitem-text {
  color: #ffffff;
}

.individual-list .p-menu {
  width: 13.5rem;
}

.date-filter .p-dropdown {
  width: 100%;
  height: 42px;
}
.date-filter .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0;
}
.date-filter .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: #ffffff !important;
}
.date-filter .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight:hover {
  background: #e9ecef !important;
}
.date-filter .p-dropdown-label-empty {
  visibility: visible;
}

.filetype-dropdown .p-dropdown {
  width: 100%;
  height: 42px;
}

.filtertype-dropdown .p-dropdown {
  height: 34px;
}
.filtertype-dropdown .p-dropdown-label {
  align-items: center;
  padding: 5px 10px;
}

.filter-wage-select {
  width: calc(100% / 3);
}
.filter-wage-select .p-dropdown {
  height: 34px;
  width: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}
.filter-wage-select .p-dropdown.p-focus, .filter-wage-select .p-dropdown:hover {
  box-shadow: none !important;
  border-color: #ced4da !important;
}
.filter-wage-select .p-dropdown .p-dropdown-label.p-placeholder {
  font-size: 12px;
}
.filter-wage-select .p-dropdown .p-dropdown-label {
  font-size: 15px;
  text-transform: lowercase;
  line-height: 16px;
}
.filter-wage-select .p-dropdown .p-dropdown-label::first-line {
  text-transform: capitalize;
}
.filter-wage-select .p-dropdown .p-dropdown-item {
  text-transform: lowercase;
}
.filter-wage-select .p-dropdown .p-dropdown-item::first-line {
  text-transform: capitalize;
}

.filter-dropdown .p-dropdown-label {
  font-size: 14px !important;
}
.filter-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 14px;
}
.filter-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #495057;
  background: white;
  font-size: 14px;
}
.filter-dropdown .duties-list .mat-form-field-flex .mat-form-field-infix {
  border-top: 0.4375em solid transparent !important;
}

.processing-dropdown label {
  color: #4d4848;
  font-size: 14px;
}
.processing-dropdown .p-dropdown {
  width: 100%;
}
.processing-dropdown .p-dropdown .p-dropdown-items-wrapper .p-dropdown-items {
  max-height: 100px;
}
.processing-dropdown .p-dropdown .p-dropdown-label {
  font-size: 14px;
}
.processing-dropdown .p-dropdown .p-dropdown-label .p-placeholder {
  color: #d0c9d6;
  font-size: 14px;
}

.bene-we-dropdown.p-dropdown {
  border: 1px solid #7d8fa3;
  height: 40px;
  width: 100%;
}

.pdropdown-readonly.p-dropdown {
  background-color: rgba(0, 0, 0, 0.12);
}
.pdropdown-readonly.p-dropdown * {
  cursor: not-allowed;
}

.h1b-err-dropdown.p-dropdown {
  height: 40px;
}

.registrations-dropdown.p-dropdown .p-dropdown-panel {
  width: 250px;
}

.create-beneficiary .p-fluid .p-dropdown .p-dropdown-label {
  font-family: "Source Sans Pro" !important;
  font-size: 17px;
  color: #000;
}

.address-component-country-dropdown.readonly-country .p-dropdown .p-dropdown-label,
.address-component-country-dropdown.readonly-country .p-dropdown .p-dropdown-trigger {
  cursor: not-allowed;
}

.firm-info-dropdown .p-dropdown.p-disabled {
  background-color: #dedede;
  opacity: 1;
}

.move-to-step-dropdown.disabled {
  cursor: not-allowed !important;
}
.move-to-step-dropdown.disabled .p-dropdown.p-disabled {
  cursor: not-allowed !important;
}

.json-viewer-popup .json-viewer-content .json-viewer-content-dropdown.p-dropdown {
  width: 100%;
}

.edit-beneficiary-profile .p-dropdown.p-component {
  width: 100% !important;
}

.filter-manage-case-status .p-component.p-multiselect, .filter-manage-case-status .p-component.p-dropdown {
  width: 100% !important;
}

.bene-we-dropdown.currency-dropdown {
  width: 85px !important;
}
.bene-we-dropdown.country-code-dropdown {
  width: 120px !important;
}

.has-passport-outer .p-checkbox-label {
  margin-bottom: 0.2rem !important;
}

.dynamic-menu-dropdown.p-dropdown {
  width: 100%;
  height: 40px;
}

.fb-menu-dropdown.p-dropdown {
  width: 100%;
  height: 40px;
}

.manage-user-country-dropdown .p-dropdown {
  width: 120px !important;
}

.p-dropdown-w-100.p-dropdown {
  width: 100% !important;
}

.info-graph-widget .mat-card-header-text {
  margin: 0 0 5px 0 !important;
}

.info-graph-widget .filter-dropdown .p-dropdown-label,
.visit-task-widget .p-dropdown-label {
  font-size: 10px !important;
  padding: 3px 3px 3px 10px;
  width: auto !important;
}

.info-graph-widget .pi-chevron-down,
.visit-task-widget .pi-chevron-down {
  font-size: 10px;
}

.info-graph-widget .mat-select-value-text,
.visit-task-widget .mat-select-value-text {
  font-size: 10px !important;
}

.info-graph-widget .mat-select-value-text,
.visit-task-widget .mat-select-value-text {
  font-size: 10px !important;
}

.petitioner-add-user .phone-field .p-dropdown {
  width: 100% !important;
}

.fb-dashboard-card-filter-dropdown.p-dropdown {
  margin: 15px 0 5px;
}
.fb-dashboard-card-filter-dropdown.p-dropdown.reference-library-filter {
  width: 130px;
}
.fb-dashboard-card-filter-dropdown.p-dropdown.month-filter {
  width: 125px;
}
.fb-dashboard-card-filter-dropdown.p-dropdown.year-filter {
  width: 86px;
}
.fb-dashboard-card-filter-dropdown.p-dropdown.outcome-filter {
  width: 80px;
}
.fb-dashboard-card-filter-dropdown.p-multiselect {
  margin: 15px 0 5px;
}
.fb-dashboard-card-filter-dropdown.p-multiselect.reference-library-filter {
  width: 130px;
}
.fb-dashboard-card-filter-dropdown.p-multiselect .p-multiselect-label-empty {
  visibility: visible;
}

.p-component-overlay {
  display: none;
}

.p-overflow-hidden {
  overflow: auto !important;
}

body.p-overflow-hidden {
  overflow: hidden !important;
}

.date-filter-calendar .p-calendar {
  width: 100%;
  height: 28px;
}
.date-filter-calendar .p-datepicker-touch-ui {
  top: 20vh !important;
  min-width: 40vw !important;
  transform: translate(-50%, 0) !important;
}
.date-filter-calendar .p-calendar-w-btn .p-inputtext {
  border-right: 0;
  border-color: #c3c3c3;
  height: 100%;
}
.date-filter-calendar .p-calendar-w-btn .p-inputtext:hover, .date-filter-calendar .p-calendar-w-btn .p-inputtext:focus {
  border-color: #c3c3c3;
  box-shadow: none;
}
.date-filter-calendar .p-datepicker-trigger.p-button-icon-only {
  background-color: #ffffff;
  color: #000000;
  border-color: #c3c3c3;
  border-width: 1px 1px 1px 0;
}
.date-filter-calendar .p-datepicker-trigger.p-button-icon-only:hover, .date-filter-calendar .p-datepicker-trigger.p-button-icon-only:focus, .date-filter-calendar .p-datepicker-trigger.p-button-icon-only:active {
  background-color: #ffffff;
  color: #000000;
  border-color: #c3c3c3;
  border-width: 1px 1px 1px 0;
  box-shadow: none;
}

.filter-datepicker.filter-datepicker-h40 .p-calendar {
  height: 40px;
}
.filter-datepicker .p-calendar {
  width: 100%;
  height: 34px;
}
.filter-datepicker .p-calendar-w-btn .p-inputtext {
  border-right: 0;
  border-color: #c3c3c3;
  height: 100%;
  padding: 8px 12px 10px 12px;
}
.filter-datepicker .p-calendar-w-btn .p-inputtext:hover, .filter-datepicker .p-calendar-w-btn .p-inputtext:focus {
  border-color: #c3c3c3;
  box-shadow: none;
}
.filter-datepicker .p-datepicker-trigger.p-button-icon-only {
  background-color: #ffffff;
  color: #000000;
  border-color: #c3c3c3;
  border-width: 1px 1px 1px 0;
}
.filter-datepicker .p-datepicker-trigger.p-button-icon-only:hover, .filter-datepicker .p-datepicker-trigger.p-button-icon-only:focus, .filter-datepicker .p-datepicker-trigger.p-button-icon-only:active {
  background-color: #ffffff;
  color: #000000;
  border-color: #c3c3c3;
  border-width: 1px 1px 1px 0;
  box-shadow: none;
}

.viewby-reports-datepicker .p-calendar {
  width: 250px;
  height: 42px;
}
.viewby-reports-datepicker .p-calendar-w-btn .p-inputtext {
  border-right: 0;
  border-color: #c3c3c3;
  height: 100%;
}
.viewby-reports-datepicker .p-calendar-w-btn .p-inputtext:hover, .viewby-reports-datepicker .p-calendar-w-btn .p-inputtext:focus {
  border-color: #c3c3c3;
  box-shadow: none;
}
.viewby-reports-datepicker .p-datepicker-trigger.p-button-icon-only {
  background-color: #ffffff;
  color: #000000;
  border-color: #c3c3c3;
  border-width: 1px 1px 1px 0;
}
.viewby-reports-datepicker .p-datepicker-trigger.p-button-icon-only:hover, .viewby-reports-datepicker .p-datepicker-trigger.p-button-icon-only:focus, .viewby-reports-datepicker .p-datepicker-trigger.p-button-icon-only:active {
  background-color: #ffffff;
  color: #000000;
  border-color: #c3c3c3;
  border-width: 1px 1px 1px 0;
  box-shadow: none;
}

.onboard-employment.p-calendar .p-datepicker {
  top: -150px !important;
}

.calendar-input .p-calendar {
  width: 100%;
}

.fdnsQa-upload-calender.p-calendar .p-datepicker {
  top: -120px !important;
}

.im-input-calendar .p-calendar {
  width: 100%;
}

.datePicker-emp .p-datepicker-trigger {
  height: auto !important;
  top: 0 !important;
}

.filter-p-multiselect .p-multiselect.p-component {
  font-size: 0.7rem;
  width: 100%;
  height: 34px;
}

.filter-check .p-multiselect {
  width: 100%;
  float: right;
}
.filter-check .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  visibility: hidden;
}

.multiselect-custom .p-multiselect {
  width: 100%;
  float: right;
}
.multiselect-custom .p-multiselect .p-multiselect-label {
  font-size: 14px;
}
.multiselect-custom .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  visibility: hidden;
}

.add-additional-details-dialog .p-multiselect {
  border-color: #c3d0de !important;
}
.add-additional-details-dialog .p-multiselect-open {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.role-multiselect {
  width: 100%;
}
.role-multiselect .p-multiselect-panel {
  width: 100%;
}

.associate-company-multiselect .p-multiselect .p-multiselect-label.p-placeholder {
  font-size: 0.9rem !important;
}

.filter-manage-case-status .p-multiselect-filter-container {
  padding-left: 30px !important;
}

table.reports-table {
  border-collapse: separate !important;
  border-spacing: 0;
  font-size: 14px;
}
table.reports-table .p-datatable-thead {
  background-color: #f8fbff;
}

.profile-table .p-datatable-wrapper {
  border-bottom: 1px solid #e9ecef;
}
.profile-table th {
  font-size: 12px;
  text-transform: initial;
  color: #a2a2a2;
}

.pa-table .p-datatable-wrapper {
  border-bottom: 1px solid #dee2e6;
}

.i9-table .p-datatable .p-datatable-tbody > tr > td {
  padding: 0px 10px !important;
}
.i9-table .i9-table .p-button.p-button-icon-only.p-button-rounded {
  border-radius: 48%;
  height: 1.357rem;
  padding: 4px !important;
}
.i9-table .p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}
.i9-table .p-button.p-button-icon-only {
  width: 1.357rem;
  padding: 0.5rem 0;
}
.i9-table .p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}

.greencard-pwd-table > .p-datatable-thead > tr > th {
  background-color: #f2f2f2 !important;
  border: 0 !important;
  color: #4a4a4a !important;
  font-weight: 300 !important;
  font-size: 14px;
  line-height: 20px;
  height: 46px;
  padding: 13px 1rem !important;
}
.greencard-pwd-table > .p-datatable-tbody > tr > td {
  padding: 13px 1rem !important;
  border-bottom: 1px solid #afafaf !important;
  font-size: 16px;
  color: #4a4a4a;
  line-height: 22px;
}
.greencard-pwd-table > .p-datatable-tbody > tr:last-child > td {
  border-bottom: 0 !important;
}

.visa-table > .p-datatable-thead > tr > th {
  background-color: #f2f2f2 !important;
  border: 0 !important;
  color: #4a4a4a !important;
  font-weight: 300 !important;
  font-size: 14px;
  line-height: 20px;
  height: 46px;
  padding: 13px 1rem !important;
  white-space: nowrap;
}
.visa-table > .p-datatable-tbody > tr > td {
  padding: 13px 1rem !important;
  border-bottom: 1px solid #afafaf !important;
  font-size: 16px;
  color: #4a4a4a;
  line-height: 22px;
}
.visa-table > .p-datatable-tbody > tr:last-child > td {
  border-bottom: 0 !important;
}

.visa-row-nodata {
  text-align: center;
  padding: 13px 1rem;
  font-size: 16px;
  color: #ff9417;
  line-height: 22px;
}

.beneficiary-details-perm tr.main-header > th {
  background-color: #f5f5f5 !important;
  border: 1px solid #707070 !important;
  border-width: 1px 0 1px 0 !important;
  color: #4a4a4a !important;
  font-weight: 300 !important;
  font-size: 14px;
  line-height: 18px;
  padding: 13px 1rem !important;
}
.beneficiary-details-perm tr.main-header > th:first-child {
  border-width: 1px 0 1px 1px !important;
}
.beneficiary-details-perm tr.main-header > th:last-child {
  border-width: 1px 1px 1px 0 !important;
}
.beneficiary-details-perm tr.main-header > th.row-toggle-width {
  width: 70px;
}
.beneficiary-details-perm tr.main-header > th.col-actions-width {
  width: 100px;
}
.beneficiary-details-perm tr > td {
  padding: 6px 1rem !important;
  border: 1px solid #707070 !important;
  font-size: 16px;
  color: #4a4a4a;
  line-height: 20px;
}
.beneficiary-details-perm tr > td:first-child {
  border-width: 0 0 0 1px !important;
}
.beneficiary-details-perm tr > td:last-child {
  border-width: 0 1px 0 0 !important;
}
.beneficiary-details-perm tr > td .expanded-row-title {
  display: block;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  color: #9a9a9a;
}
.beneficiary-details-perm tr > td .expanded-row-value {
  display: block;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  color: #4a4a4a;
}
.beneficiary-details-perm tr.main-row > td {
  border-width: 1px 0 0 0 !important;
}
.beneficiary-details-perm tr.main-row > td:first-child {
  border-width: 1px 0 0 1px !important;
}
.beneficiary-details-perm tr.main-row > td:last-child {
  border-width: 1px 1px 0 0 !important;
}
.beneficiary-details-perm tr.expanded-row > td {
  padding: 0 0 !important;
}
.beneficiary-details-perm tr:last-child.expanded-row > td {
  border-width: 0 0 1px 0 !important;
}
.beneficiary-details-perm tr:last-child.expanded-row > td:first-child {
  border-width: 0 0 1px 1px !important;
}
.beneficiary-details-perm tr:last-child.expanded-row > td:last-child {
  border-width: 0 1px 1px 0 !important;
}
.beneficiary-details-perm tr:last-child.main-row > td {
  border-width: 1px 0 1px 0 !important;
}
.beneficiary-details-perm tr:last-child.main-row > td:first-child {
  border-width: 1px 0 1px 1px !important;
}
.beneficiary-details-perm tr:last-child.main-row > td:last-child {
  border-width: 1px 1px 1px 0 !important;
}

.main-row-nodata {
  text-align: center;
  padding: 6px 1rem;
  border: 1px solid #707070;
  border-width: 0 1px 1px 1px;
  font-size: 16px;
  color: #ff9417;
  line-height: 20px;
}

.fdnsBeneListingTable {
  border: 1px solid #e9ecef !important;
}
.fdnsBeneListingTable .p-datatable-tbody > tr > td {
  text-align: left !important;
  border: 1px solid #e9ecef;
  border-width: 1px !important;
  padding: 10px !important;
  padding-left: 20px !important;
}
.fdnsBeneListingTable .p-datatable-thead > tr > th {
  text-align: left !important;
  padding: 10px !important;
  padding-left: 20px !important;
  border: 1px solid #e9ecef;
  border-width: 1px !important;
  font-weight: 600;
  color: #495057;
  background: #f8fbff;
  transition: box-shadow 0.2s;
}

.fdnsQAListingTable {
  border: 1px solid #e9ecef !important;
}
.fdnsQAListingTable .p-datatable-tbody > tr > td {
  text-align: left !important;
  border: 1px solid #e9ecef;
  border-width: 1px !important;
  padding: 10px !important;
}
.fdnsQAListingTable .p-datatable-thead > tr > th {
  text-align: left !important;
  padding: 10px !important;
  border: 1px solid #e9ecef;
  border-width: 1px !important;
  font-weight: 600;
  color: #495057;
  background: #d0dce8 !important;
  transition: box-shadow 0.2s;
}

.fdnsVisitListingTable {
  border-bottom: 1px solid #e9ecef !important;
}
.fdnsVisitListingTable .p-datatable-thead > tr > th {
  padding: 10px !important;
}

.fileUploadedDocumentsTable {
  border: 1px solid #e9ecef !important;
}
.fileUploadedDocumentsTable .p-datatable-thead > tr > th {
  padding: 10px !important;
}

.myFormListingTable {
  border-bottom: 1px solid #e9ecef !important;
  padding: 10px !important;
}
.myFormListingTable .p-datatable-thead > tr > th {
  background: #edf4fb !important;
  padding: 10px !important;
}
.myFormListingTable .p-datatable-tbody > tr > td {
  padding: 10px !important;
}

.fileUploadedDocumentsTable {
  border: 1px solid #e9ecef !important;
}
.fileUploadedDocumentsTable .p-datatable-thead > tr > th {
  padding: 10px !important;
}

.vdFeeTable {
  border: 1px solid #e9ecef !important;
}
.vdFeeTable .p-datatable-thead > tr > th {
  padding: 10px !important;
  background-color: #edf4fb !important;
}
.vdFeeTable .p-datatable-thead > tr > th > span {
  margin-left: 20px !important;
}
.vdFeeTable .p-datatable-tbody > tr > td {
  padding: 10px !important;
}
.vdFeeTable .attorney-search-beneficiary {
  font-size: 13px;
}
.vdFeeTable .attorney-search-beneficiary tr > th {
  font-weight: bolder;
}
.vdFeeTable .attorney-search-beneficiary tr > td {
  padding: 0.5rem 1rem !important;
}
.vdFeeTable .i130-address-table {
  border-collapse: separate !important;
  border-spacing: 0;
}
.vdFeeTable .i130-address-table .p-datatable-thead > tr > th {
  border-top: 1px solid #e1e1e1 !important;
  font-size: 16px;
  line-height: 22px;
  color: #333333 !important;
  padding: 6px 1rem !important;
  font-weight: normal !important;
}
.vdFeeTable .i130-address-table .p-datatable-thead > tr > th:first-child {
  border-top-left-radius: 5px;
  border-left: 1px solid #e1e1e1 !important;
}
.vdFeeTable .i130-address-table .p-datatable-thead > tr > th:last-child {
  border-top-right-radius: 5px;
  border-right: 1px solid #e1e1e1 !important;
}
.vdFeeTable .i130-address-table .p-datatable-tbody > tr > td {
  border-color: #b5b5b5 !important;
  font-size: 16px;
  line-height: 22px;
  color: #626262 !important;
  padding: 7px 1rem !important;
}
.vdFeeTable .i130-address-table .p-datatable-tbody > tr > td:first-child {
  border-width: 0 0 1px 1px;
}
.vdFeeTable .i130-address-table .p-datatable-tbody > tr > td:last-child {
  border-width: 0 1px 1px 0;
}
.vdFeeTable .p-datepicker .p-datepicker-buttonbar {
  padding: 0 !important;
}
.vdFeeTable .task-table .table {
  border: 0 !important;
  margin-top: 0px !important;
}
.vdFeeTable .task-table .table .p-datatable.p-component {
  border: 1px solid #eef4fa !important;
}

.vdFileUploadTable {
  border: 1px solid #e9ecef !important;
}
.vdFileUploadTable .p-datatable-thead > tr > th {
  padding: 10px !important;
  background-color: #edf4fb !important;
}
.vdFileUploadTable .p-datatable-thead > tr > th > span {
  margin-left: 20px !important;
}
.vdFileUploadTable .p-datatable-tbody > tr > td {
  padding: 10px !important;
}

.container-json-viewer .p-datatable-scrollable-body {
  border-bottom: 1px solid #eef4fa;
}

.p-datatable .pdf-list-json-viewer .p-datatable-thead > tr > th {
  background-color: #e0f0f7 !important;
  padding: 10px 1rem;
  cursor: default;
}
.p-datatable .pdf-list-json-viewer .p-datatable-thead > tr > th .col-title {
  font-size: 16px !important;
}
.p-datatable .pdf-list-json-viewer .p-datatable-thead > tr > th .col-json-viewer-title {
  font-size: 20px;
  font-weight: 600;
  color: #4d4f5c;
  line-height: 42px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-thead > tr > th.action-width {
  width: 200px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-thead > tr > th.checkbox-col-width {
  width: 68px;
  padding: 1rem 1rem 1rem 2rem;
}
.p-datatable .pdf-list-json-viewer .p-datatable-thead > tr > th.checkbox-col-width.in-search-popup {
  padding: 1rem;
  width: 53px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr.group-row {
  background-color: #dfefe1;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td {
  position: relative;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td.checkbox-col-width {
  width: 68px;
  padding: 1rem 1rem 1rem 2rem;
  overflow: hidden;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td.checkbox-col-width .checkbox-holder {
  position: absolute;
  top: 1rem;
  left: 2rem;
  z-index: 1;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td.checkbox-col-width.in-search-popup {
  padding: 1rem;
  width: 53px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td.checkbox-col-width.in-search-popup .checkbox-holder {
  left: 1rem;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td.checkbox-col-width .group {
  position: absolute;
  height: 100%;
  left: 1rem;
  width: 15px;
  z-index: 1;
  border-style: solid;
  border-color: #2680eb;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td.checkbox-col-width .group.group-first {
  border-width: 0 0 0 2px;
  top: calc(1rem + 10px);
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td.checkbox-col-width .group.group-middle {
  border-width: 0 0 0 2px;
  top: 0;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td.checkbox-col-width .group.group-last {
  border-width: 0 0 0 2px;
  bottom: calc(100% - (1rem + 10px));
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td.checkbox-col-width .minimise-group {
  position: absolute;
  top: 1rem;
  left: 0.5rem;
  border: 2px solid #2680eb;
  color: #2680eb;
  font-size: 10px;
  background-color: #ffffff;
  display: block;
  z-index: 2;
  width: 20px;
  height: 20px;
  padding: 3px;
  font-weight: 700;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .content-json-viewer {
  font-size: 14px;
  font-weight: 600;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .group-title {
  margin-left: 1rem;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .pi {
  font-size: 20px;
  color: #465f7c;
  cursor: pointer;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .pi.pi-arrows-alt {
  float: left;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .pi.p-checkbox-icon.pi-check {
  font-size: 14px;
  color: #ffffff;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .pi.maximize-group {
  position: absolute;
  top: 1rem;
  left: 0.5rem;
  border: 2px solid #87bc8e;
  color: #87bc8e;
  font-size: 10px;
  background-color: #dfefe1;
  display: block;
  z-index: 2;
  width: 20px;
  height: 20px;
  padding: 3px;
  font-weight: 700;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-edit-json-object {
  float: left;
  width: calc(100% - 241px);
  min-height: 1px;
  margin-right: 15px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-edit-json-object .edit-element-search-popup-textarea {
  width: calc(100% - 80px);
  padding: 5px;
  line-height: 20px !important;
  font-size: 16px !important;
  min-height: 34px;
  margin-right: 15px;
  float: left;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-edit-json-object .pi {
  margin-top: 7px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-json-object {
  float: left;
  width: calc(100% - 241px);
  min-height: 1px;
  margin-right: 15px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-json-object .part-title {
  font-size: 20px;
  font-weight: 700;
  display: block;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-json-object .form-group .highlight-search-text-json-viewer {
  padding: 0.2em;
  background-color: #f8ec8f !important;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-json-object .form-group .json-viewer-input {
  width: 350px;
  max-width: 100%;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-json-object .form-group .pdf-checkbox {
  width: max-content;
  margin-right: 15px;
  float: left;
  max-width: 100%;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-json-object .form-group .pdf-dropdown.p-dropdown {
  width: 350px;
  max-width: 100%;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-json-object .form-group .pdf-radiobutton {
  width: max-content;
  margin-right: 15px;
  float: left;
  max-width: 100%;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-json-object .sub-head-title {
  font-size: 19px;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-json-object .element-head-title {
  font-size: 18px;
  margin-bottom: 5px;
  display: block;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-json-object .questionnaire-lbl {
  float: left;
  margin-right: 15px;
  margin-top: 3px;
  margin-bottom: 0;
  max-width: calc(100% - 70px);
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-json-object .p-inputswitch.questionnaire-switch {
  background-color: rgba(0, 0, 0, 0);
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-json-object .json-viewer-textarea {
  width: 100%;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-edit-object {
  float: left;
  width: calc(100% - 107px);
  min-height: 1px;
  margin-right: 15px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-edit-object .edit-element-search-popup-textarea {
  width: 100%;
  padding: 5px;
  line-height: 20px !important;
  font-size: 16px !important;
  min-height: 34px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-search-object {
  float: left;
  width: calc(100% - 73px);
  min-height: 1px;
  margin-right: 15px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-search-object.container-contry-state-object {
  width: calc(100% - 190px);
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-search-object .highlight-search-text-json-viewer {
  padding: 0.2em;
  background-color: #f8ec8f !important;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-search-object .part-title {
  font-size: 20px;
  font-weight: 700;
  display: block;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-search-object .form-group .json-viewer-input {
  width: 350px;
  max-width: 100%;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-search-object .form-group .pdf-checkbox {
  width: max-content;
  margin-right: 15px;
  float: left;
  max-width: 100%;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-search-object .form-group .pdf-dropdown.p-dropdown {
  width: 350px;
  max-width: 100%;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-search-object .form-group .pdf-radiobutton {
  width: max-content;
  margin-right: 15px;
  float: left;
  max-width: 100%;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-search-object .form-group .sub-head-title {
  font-size: 19px;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-search-object .form-group .element-head-title {
  font-size: 18px;
  margin-bottom: 5px;
  display: block;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-search-object .form-group .questionnaire-lbl {
  float: left;
  margin-right: 15px;
  margin-top: 3px;
  margin-bottom: 0;
  max-width: calc(100% - 70px);
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-search-object .form-group .p-inputswitch.questionnaire-switch {
  background-color: rgba(0, 0, 0, 0);
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .container-search-object .form-group .json-viewer-textarea {
  width: 100%;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .element-type-div {
  float: left;
  margin-right: 15px;
  margin-top: -5px;
  width: 150px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .element-type-div .p-dropdown.p-component {
  height: 30px;
  width: 100%;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .element-type-div .p-dropdown.p-component .p-dropdown-label {
  padding: 0;
  line-height: 28px;
  margin-left: 5px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .element-type-div .p-dropdown.p-component .p-dropdown-trigger .p-dropdown-trigger-icon {
  font-size: 14px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .speed-dial {
  float: left;
  position: relative;
  height: 20px;
  width: 26px;
  margin-right: 15px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .speed-dial .fa {
  font-size: 23px;
  color: #465f7c;
  cursor: pointer;
  width: 20px;
  height: 23px;
  margin-top: -2px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .speed-dial .fa.speed-dial-active {
  padding: 8px 10px 10px;
  border-radius: 50%;
  background-color: #01a0da;
  width: 40px;
  height: 40px;
  margin-left: -8px;
  margin-top: -12px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .speed-dial .speed-dial-content {
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  z-index: 1;
  width: 56px;
  margin-left: -28px;
  height: 0;
  overflow: hidden;
  transition: height 0.2s, padding 0.2s;
  padding: 0;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .speed-dial .speed-dial-content.speed-dial-content-active {
  height: 171px;
  padding: 3px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .speed-dial .speed-dial-content > .pi {
  display: block;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
  margin-bottom: 5px;
  background-color: #fff;
  width: 50px;
  height: 50px;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr > td .element-id-txt {
  color: #999999;
  font-size: 14px;
  font-weight: 600;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f5f7f7;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr:nth-child(even).group-row {
  background-color: #dfefe1;
}
.p-datatable .pdf-list-json-viewer .p-datatable-tbody > tr:nth-child(even) > td.checkbox-col-width .minimise-group {
  background-color: #f5f7f7;
}

.fd-table-widget .p-datatable-tbody > tr {
  background-color: #ffffff;
}
.fd-table-widget .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f8f9fa;
}

.action-col-width {
  width: 90px;
}

.p-datatable-fb-forms.p-datatable.alternate-colour .p-datatable-tbody > tr:nth-child(even) {
  background-color: #f8f9fa;
}
.p-datatable-fb-forms.p-datatable .p-datatable-tbody > tr > td {
  word-break: break-all;
}
.p-datatable-fb-forms.p-datatable .p-datatable-tbody > tr > td > span:not(.not-text) {
  word-break: break-all;
}

.p-datatable .fb-past-holiday {
  border: 0 !important;
}
.p-datatable .fb-past-holiday .p-datatable-tbody > tr > td {
  padding: 2px 0 2px 5px;
  color: #475f7b;
  font-weight: 600 !important;
  font-size: 14px;
  opacity: 0.6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.p-datatable .fb-past-holiday .p-datatable-tbody > tr > td:first-child {
  width: 35px;
  text-overflow: clip;
}
.p-datatable .fb-past-holiday .p-datatable-tbody > tr > td:last-child {
  width: 170px;
  font-weight: normal;
}

.p-datatable .fb-upcoming-holiday {
  border: 0 !important;
}
.p-datatable .fb-upcoming-holiday .p-datatable-tbody > tr.next-holiday {
  background-color: #edf4fb;
}
.p-datatable .fb-upcoming-holiday .p-datatable-tbody > tr > td {
  padding: 2px 0 2px 5px;
  color: #475f7b;
  font-weight: 600 !important;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.p-datatable .fb-upcoming-holiday .p-datatable-tbody > tr > td:first-child {
  width: 35px;
  text-overflow: clip;
}
.p-datatable .fb-upcoming-holiday .p-datatable-tbody > tr > td:last-child {
  width: 170px;
  font-weight: normal;
}

.p-datatable.p-fbtable-striped .fb-table .p-datatable-tbody > tr:nth-child(even) {
  background: #f8f9fa;
}
.p-datatable.p-fbtable-gridlines .fb-table .p-datatable-tbody > tr,
.p-datatable.p-fbtable-gridlines .fb-table .p-datatable-thead > tr {
  border: 1px solid #e9ecef !important;
}
.p-datatable.p-fbtable-text-wrap .fb-table .p-datatable-tbody > tr > td {
  white-space: unset;
}
.p-datatable .fb-table {
  border: 0 !important;
}
.p-datatable .fb-table .p-datatable-thead > tr > th {
  padding: 10px 5px;
  background-color: #edf4fb;
  font-weight: 600;
  font-size: 16px;
  color: #475f7b;
  white-space: nowrap;
  line-height: 16px;
}
.p-datatable .fb-table .p-datatable-thead > tr > th.w-180px {
  width: 180px;
}
.p-datatable .fb-table .p-datatable-thead > tr > th.w-110px {
  width: 110px;
}
.p-datatable .fb-table .p-datatable-thead > tr > th.w-100px {
  width: 100px;
}
.p-datatable .fb-table .p-datatable-thead > tr > th.w-80px {
  width: 80px;
}
.p-datatable .fb-table .p-datatable-thead > tr > th.w-75px {
  width: 75px;
}
.p-datatable .fb-table .p-datatable-thead > tr > th.w-65px {
  width: 65px;
}
.p-datatable .fb-table .p-datatable-thead > tr > th.w-60px {
  width: 60px;
}
.p-datatable .fb-table .p-datatable-thead > tr > th.w-40px {
  width: 40px;
}
.p-datatable .fb-table .p-datatable-thead > tr > th.header-column-wrap {
  white-space: normal;
  line-height: 16px;
}
.p-datatable .fb-table .p-datatable-tbody > tr.next-holiday {
  background-color: #edf4fb;
}
.p-datatable .fb-table .p-datatable-tbody > tr > td {
  padding: 10px 5px;
  color: #475f7b;
  font-weight: normal !important;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
}
.p-datatable .fb-table .p-datatable-tbody > tr > td.text-wrap {
  white-space: unset;
}
.p-datatable .fb-table .p-datatable-tbody > tr > td.w-100px {
  width: 100px;
}
.p-datatable .fb-table .p-datatable-tbody > tr > td.w-110px {
  width: 110px;
}
.p-datatable .fb-table .p-datatable-tbody > tr > td.w-180px {
  width: 180px;
}
.p-datatable .fb-table .p-datatable-tbody > tr > td.w-60px {
  width: 60px;
}
.p-datatable .fb-table .p-datatable-tbody > tr > td.w-80px {
  width: 80px;
}
.p-datatable .fb-table .p-datatable-tbody > tr > td.td-data-blue {
  color: #23a7dc;
}
.p-datatable .fb-table .p-datatable-tbody > tr > td.td-data-green {
  color: #1e9502;
}
.p-datatable .fb-table .p-datatable-tbody > tr > td .col-wrapper {
  display: flex;
  align-items: center;
}
.p-datatable .fb-table .p-datatable-tbody > tr > td .col-wrapper .col-content {
  flex: 1;
  white-space: normal;
  word-wrap: break-word;
}
.p-datatable .fb-table .p-datatable-tbody > tr > td .col-wrapper .col-info-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  width: 20px;
}
.p-datatable .fb-table .p-datatable-tbody > tr > td .col-wrapper .col-info-text .pi.pi-ellipsis-v {
  font-size: 7px;
  font-weight: 900;
  display: block;
  border: 1px solid #475f7b;
  border-radius: 50%;
  padding: 2px 3px 2px 2px;
  cursor: pointer;
}

.timesheet-summary .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #f5f5f5 !important;
}

.filter-wage-text .p-inputnumber-input {
  height: 34px;
  width: 100%;
  float: left;
}
.filter-wage-text .p-inputnumber-input:focus, .filter-wage-text .p-inputnumber-input:hover {
  box-shadow: none !important;
  border-color: #ced4da !important;
}
.filter-wage-text.wage-from .p-inputnumber-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.filter-wage-text.wage-to .p-inputnumber-input {
  border-radius: 0;
  border-right: 0;
  border-left: 0;
}

.pa-accordian {
  text-align: center;
}
.pa-accordian .p-accordion-header-link:focus {
  box-shadow: none !important;
}
.pa-accordian .p-accordion-header-link {
  border: none !important;
  background-color: transparent !important;
  text-align: center;
  margin-bottom: 20px;
  display: block !important;
}
.pa-accordian .p-accordion-header-link .pi-chevron-down:before {
  border: 1px solid;
  border-radius: 19px;
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
}
.pa-accordian .p-accordion-header-link .pi-chevron-right:before {
  border: 1px solid;
  border-radius: 19px;
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
}
.pa-accordian .p-accordion-header-link .p-accordion-header-text {
  text-align: left !important;
  font-size: 16px;
  font-weight: 400;
}
.pa-accordian .p-accordion-content {
  padding: 0px !important;
  text-align: left;
  border: none !important;
  margin-bottom: 20px;
}
.pa-accordian .p-accordion-content .p-tree {
  padding: 1px !important;
  background: #f5f7f7;
  border: none !important;
}
.pa-accordian .p-accordion-content .p-tree .pi-chevron-right:before {
  content: "";
}
.pa-accordian .p-accordion-content .p-tree .pi-chevron-down:before {
  content: "";
}
.pa-accordian .p-accordion-content .p-tree .p-tree-container .p-treenode .p-treenode-content {
  margin: 0px 5px;
  border-bottom: 1px solid lightgray;
}
.pa-accordian .p-accordion-content .p-tree .p-tree-container .p-treenode .p-treenode-children .p-treenode-label {
  font-size: 12px;
}
.pa-accordian .p-accordion-content .p-tree .p-tree-container .p-treenode .p-treenode-children .p-treenode-content {
  border-bottom: none;
  padding: 0px;
}
.pa-accordian .p-accordion-content .p-tree .p-tree-container .p-treenode .p-treenode-children .p-treenode-content .pi-chevron-right:before {
  content: "";
}

.faqTopicAccordion .p-accordion .p-accordion-header .p-accordion-header-link {
  background-color: #f8f8f8 !important;
  font-size: 24px;
  color: #707070 !important;
}
.faqTopicAccordion .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  display: none;
}
.faqTopicAccordion .p-accordion .p-accordion-content {
  background-color: #f8f8f8 !important;
}

.subProfileContent .p-accordion .subTabProfile p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  background: #F2F2F2 !important;
}
.subProfileContent .subTabProfile .p-accordion-header {
  background: #F2F2F2 !important;
}
.subProfileContent .p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  padding: 0.8em !important;
}
.subProfileContent .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  display: block !important;
}
.subProfileContent .subTabProfile .p-accordion-header .p-accordion-header-link .p-accordion-header-text {
  padding-left: 1rem !important;
}
.subProfileContent .p-accordion-header-text {
  text-align: left !important;
  width: 80%;
}

.mainProfileContent .personal-details-header .p-accordion-header {
  background: #F2F2F2 !important;
}
.mainProfileContent .p-accordion .personal-details-heade:not(.p-disabled) .p-accordion-header-link {
  background: #FFFFFF !important;
}
.mainProfileContent .p-accordion .personal-details-header .p-accordion-header:not(.p-disabled) .p-accordion-header-link {
  border-bottom: 1px solid #FFFFFF !important;
}

.questionAnswerAccordion .p-accordion .p-accordion-header {
  padding-bottom: 10px;
}
.questionAnswerAccordion .p-accordion .p-accordion-header .p-accordion-header-link {
  background-color: #fff !important;
  font-size: 20px;
  color: #707070 !important;
}
.questionAnswerAccordion .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  display: none;
}

.fdnsDocAccordion .p-accordion {
  border: 1px solid #dee2e6 !important;
}
.fdnsDocAccordion .p-accordion .p-accordion-tab {
  margin-bottom: 5px !important;
}
.fdnsDocAccordion .p-accordion .p-accordion-header .p-accordion-header-link {
  background: #edf4fb !important;
}
.fdnsDocAccordion .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  display: none;
}
.fdnsDocAccordion .p-accordion .p-accordion-content {
  border: none !important;
}

.fdnsDocAccordion .p-accordion {
  border: 1px solid #dee2e6 !important;
}
.fdnsDocAccordion .p-accordion .p-accordion-tab {
  margin-bottom: 5px !important;
}
.fdnsDocAccordion .p-accordion .p-accordion-header .p-accordion-header-link {
  background: #edf4fb !important;
}
.fdnsDocAccordion .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  display: none;
}
.fdnsDocAccordion .p-accordion .p-accordion-content {
  border: none !important;
}

.review-accordions .p-accordion .p-accordion-header .p-accordion-header-link {
  background: none !important;
  color: #495057 !important;
  padding: 10px !important;
}
.review-accordions .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  padding-bottom: 15px !important;
  background: none !important;
  color: #495057 !important;
  padding: 10px !important;
}
.review-accordions .p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border: 1px solid #aaaaaa !important;
  background: none !important;
  color: #495057 !important;
  padding: 10px !important;
}
.review-accordions .p-accordion p-accordiontab:last-child .p-accordion-content h6 {
  padding: 10px 10px 0px;
  font-size: 14px;
  font-weight: 400;
}
.review-accordions .p-accordion p-accordiontab:last-child .p-accordion-content button {
  margin: 15px 0px 6px;
}

.notification-acc .p-accordion-tab {
  border: 0;
  padding: 10px 10px 0px;
  margin-bottom: 20px !important;
}
.notification-acc .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  align-items: baseline;
}
.notification-acc .p-accordion-tab .p-accordion-header .p-disabled .p-toggleable-content {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.notification-acc .p-accordion-tab .p-toggleable-content {
  margin-top: 10px;
}
.notification-acc .p-accordion-tab .p-toggleable-content .p-accordion-content {
  border: none !important;
  border-top: 1px solid #dee2e6 !important;
  padding-top: 20px !important;
}
.notification-acc .p-accordion-tab .p-accordion-toggle-icon {
  color: #76889d;
  font-size: 13px;
}
.notification-acc .p-accordion-tab .p-accordion-header-text {
  color: #6f6f6f;
}

.notification-acc {
  padding: 10px 15px;
}
.notification-acc .p-accordion .p-accordion-content .p-datatable .p-datatable-tbody > tr > td:last-child {
  padding-left: 10px !important;
}

.role-accordion .p-accordion-tab {
  border: 0;
  margin-bottom: 20px !important;
}
.role-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  align-items: baseline;
}
.role-accordion .p-accordion-tab .p-accordion-header .p-disabled .p-toggleable-content {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.role-accordion .p-accordion-tab .p-toggleable-content .p-accordion-content {
  border: none !important;
  border-top: 1px solid #dee2e6 !important;
  padding-top: 20px !important;
}
.role-accordion .p-accordion-tab .p-accordion-toggle-icon {
  color: #76889d;
  font-size: 13px;
}
.role-accordion .p-accordion-tab .p-accordion-header-text {
  color: #6f6f6f;
}

.role-accordion {
  padding: 10px 15px;
}
.role-accordion .p-accordion .p-accordion-content .p-datatable .p-datatable-tbody > tr > td:last-child {
  padding-left: 10px !important;
}

.role-accordion .p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0px !important;
}
.role-accordion .p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  background-color: #f7faff !important;
  border: 1px solid #dfe6ef !important;
}

.leave_summary .p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  background-color: #edf4fb !important;
  border: none !important;
  border-top: 1px solid #ccc;
}
.leave_summary .role-accordion .p-accordion-tab .p-toggleable-content .p-accordion-content {
  border-top: 1px solid #ccc !important;
  background-color: #edf4fb !important;
  margin: 0;
  padding: 20px 35px !important;
}
.leave_summary .p-accordion-header-text {
  font-size: 16px !important;
}
.leave_summary .role-accordion {
  padding-bottom: 0 !important;
}

.cloud-storage-main .set-accordion .p-accordion-header {
  background-color: transparent !important;
}
.cloud-storage-main .set-accordion .p-accordion .p-accordion-header-link {
  white-space: nowrap !important;
  flex-direction: row-reverse;
}
.cloud-storage-main .set-accordion .btn-link {
  text-transform: capitalize;
  color: #01a0da;
}
.cloud-storage-main .set-accordion .btn-link:disabled {
  color: #6c757d !important;
}

.multiFeeAccordion .p-accordion .p-accordion-content {
  border: none !important;
  margin: 0 !important;
}

.individual-details h6.heading {
  font-size: 1rem !important;
}
.individual-details .add-title-sheet .linkButton .p-button-label {
  font-size: 1rem !important;
}
.individual-details .action-block .p-button-outlined {
  padding: 5px !important;
  font: normal normal bold 12px/18px "Source Sans Pro" !important;
}
.individual-details .action-block .p-button-outlined.p-button-icon-only {
  height: 43px !important;
}

.filter-all-select .p-checkbox-label {
  line-height: 20px;
  margin: 0;
}

.nofify-bene-checkbox .p-field-checkbox > label {
  margin-left: 0.5rem;
  line-height: 1;
  /* top: 7px; */
  margin-top: 5px;
}

.filter-all-select .p-checkbox-label {
  line-height: 20px;
  margin: 0;
}

.processing-checkbox .p-checkbox-label {
  margin-bottom: 0px;
  font-size: 14px;
  color: #4d4f5c;
}

.decision-check .p-checkbox-label {
  margin-bottom: 0px;
}

.gc-re-other-recruitment-accordian-checkbox .p-checkbox-label {
  margin: 0 0 0 10px;
  font-size: 20px;
  font-weight: 600;
  color: #7b7b7b;
}

.checkbox-label-allign .p-checkbox-label {
  line-height: 1;
  margin-top: 0.5rem;
}

.client-project-documents-checkbox-holder {
  width: max-content !important;
  margin-right: 30px;
  margin-top: 12px;
}
.client-project-documents-checkbox-holder .p-checkbox-label {
  line-height: 1;
  margin-top: 0.5rem;
  width: max-content;
  display: block;
  white-space: nowrap;
}

.custom-checkbox .p-checkbox-label {
  padding-top: 5px;
  line-height: 20px;
}

.tree-select-checkbox-label {
  margin-bottom: 0px;
  line-height: 23px;
  cursor: pointer !important;
}
.tree-select-checkbox-label.p-disabled {
  cursor: not-allowed !important;
}

.manage-case-status .checkbox-inline {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: end;
}
.manage-case-status .checkbox-inline input {
  width: 16px;
  height: 16px;
}
.manage-case-status .checkbox-inline label {
  margin-top: 8px;
}

.p-form-chips {
  display: block;
  width: 100%;
}
.p-form-chips .p-chips {
  width: 100%;
}
.p-form-chips .p-chips .p-chips-multiple-container {
  width: 100%;
  padding: 0 0.5rem 0.25rem;
}
.p-form-chips .p-chips .p-chips-multiple-container .p-chips-token {
  height: 28px;
  margin-top: 0.25rem;
}
.p-form-chips .p-chips .p-chips-multiple-container .p-chips-input-token {
  margin-top: 0.25rem;
  padding: 0;
}
.p-form-chips .p-chips .p-chips-multiple-container .p-chips-input-token input {
  height: 28px;
}

.greenCard-i140-chip .p-chips {
  width: 100%;
}
.greenCard-i140-chip .p-chips .p-chips-multiple-container {
  width: 100%;
}

.create-masterlist-chip .p-chips {
  height: 40px;
  width: 100%;
}
.create-masterlist-chip .p-chips .p-chips-multiple-container {
  width: 100%;
}
.create-masterlist-chip .p-chips .p-chips-multiple-container .p-chips-input-token input {
  border: 0 !important;
  height: calc(40px - 1rem);
}

.vd-chip .p-chips .p-chips-multiple-container {
  width: 100%;
  display: block;
}
.vd-chip .p-chips .p-chips-multiple-container .p-chips-input-token input {
  border: 0 !important;
  height: calc(40px - 1rem);
}

.timeline .p-timeline-event-content {
  padding-left: 0px !important;
}
.timeline .p-timeline-event-opposite {
  padding-right: 0px !important;
}

.green-card-pwd-timeline .p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.green-card-pwd-timeline .p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0;
}

.green-slide-toggle-bar .p-inputswitch .p-inputswitch-slider:after {
  content: "No";
  font-size: 50%;
  font-weight: 700;
  color: #fff;
  position: absolute;
  left: 28px;
  top: 7px;
}

.green-slide-toggle-bar .p-inputswitch .p-inputswitch-slider:after {
  content: "Yes";
  font-size: 50%;
  font-weight: 700;
  color: #fff;
  position: absolute;
  left: 6px;
  top: 8px;
}

.duty-card.job-duties-jds .p-card-body {
  padding: 0px 15px 0px 0px !important;
}
.duty-card .p-card-body {
  padding-left: 0px !important;
}
.duty-card .duty-list {
  background-color: #b5d8ff;
  border-radius: 15px;
  max-width: 20px;
  padding: 5px 10px;
}

.courses-card .p-card-body {
  padding: 0px !important;
}
.courses-card .p-card-body .p-card-content {
  padding: 0px !important;
}
.courses-card .document-header {
  background-color: #f8f9fa;
  color: #333333;
  padding: 10px 0px;
}
.courses-card .document-header h6 {
  font-size: 15px;
}
.courses-card .courses {
  padding: 8px 0px !important;
}
.courses-card .document {
  padding: 8px 0px !important;
  border: 1px solid #e9ecef;
}
.courses-card .duty-list {
  background-color: #b5d8ff;
  border-radius: 15px;
  max-width: 20px;
  padding: 5px 10px;
}

p-card .tasklist-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  border: unset !important;
}
p-card .tasklist-card .p-card-body {
  height: 100%;
}
p-card .tasklist-card .p-card-body .p-card-content {
  padding-bottom: 0px !important;
  height: 100%;
}
p-card .tasklist-card .p-card-body .p-card-content .content-holder {
  height: calc(100% - 92px);
}

.beneficiaryFilter .p-overlaypanel {
  top: 50px !important;
}

.fd-master-table-card .p-card-body .p-card-content {
  padding: 0.5rem 0 !important;
}

.attorney-dashborad .tasklist-card {
  min-height: 600px;
}

.petitioner-dashboard .tasklist-card {
  min-height: 580px;
}

.quick-card .p-card-body {
  padding: 10px 15px !important;
}
.quick-card .p-card-body .p-card-content h6 {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #60626e !important;
}

.quick-card-pro .p-card {
  background: #EDF4FB !important;
  color: #495057;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
}
.quick-card-pro .p-card .p-card-body {
  padding: 0px !important;
}

.new-dashboard .tasklist-card {
  background: #f5f7f7;
}
.new-dashboard .tasklist-card .tab-container {
  background: #f5f7f7;
}
.new-dashboard .tasklist-card .task-tab {
  background: #fff;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
}
.new-dashboard .tasklist-card .task-tab.activeTasksTab {
  background: #f5f7f7;
}
.new-dashboard .tasklist-card .p-card-body {
  background: #fff;
}
.new-dashboard .tasklist-card .p-card-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.new-dashboard .tasklist-card .content-holder {
  background: #f5f7f7;
}
.new-dashboard .tasklist-card .taskFilter-btn {
  background-color: #fff;
}

.updt-emp-rb .p-radiobutton-label {
  margin-bottom: 0;
  font-weight: 600;
  cursor: pointer;
}

.letter-radio {
  font-size: 15px;
  padding-right: 20px;
  font-weight: 500;
  color: #4d4f5c;
}
.letter-radio .p-radiobutton .p-radiobutton-box {
  border-color: #158fc4;
}

.re-isnewspaper-radio-holder {
  margin-top: 3px;
  margin-right: 5px;
  float: right;
}
.re-isnewspaper-radio-holder .p-radiobutton {
  width: 14px;
  height: 14px;
}
.re-isnewspaper-radio-holder .p-radiobutton .p-radiobutton-box {
  width: 14px;
  height: 14px;
}
.re-isnewspaper-radio-holder .p-radiobutton-label {
  line-height: 14px;
  font-size: 14px;
  margin-left: 2px;
}

.create-task-radio {
  align-items: start;
}
.create-task-radio .p-radiobutton {
  margin-top: 1px;
}
.create-task-radio .p-radiobutton-label {
  font-size: 14px;
  line-height: 18px;
}

.profileRadio .p-radiobutton-label {
  font-size: 13px !important;
  color: grey !important;
}

.disabled .p-radiobutton {
  cursor: not-allowed !important;
}

.p-radiobutton.p-radiobutton-disabled {
  cursor: not-allowed !important;
}

.p-radiobutton-label.p-disabled {
  cursor: not-allowed !important;
}

.form-value-radiobutton + .p-radiobutton-label {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #4a4a4a;
}

.i130-radio-button {
  align-items: start;
}

.fd-radio-custom-style .p-radiobutton-box {
  border: 2px solid #10A0DA !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -4px;
}

.fd-vert-radio-custom-style .p-radiobutton-box {
  border: 2px solid #10A0DA !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.img-radio-button-label-container {
  float: left !important;
}
.img-radio-button-label-container p-radiobutton,
.img-radio-button-label-container p-checkbox {
  float: left !important;
}
.img-radio-button-label-container .common-bene-class-for-translation.img-radio-button-label {
  margin-left: 2px !important;
  margin-right: 5px !important;
  line-height: 20px !important;
  float: left !important;
}

.ben-update-employment {
  padding: 15px 30px;
  background-color: #ffffff;
}
.ben-update-employment .p-dialog-header {
  border-bottom: 2px solid #d1d7de !important;
  padding: 1.5rem 15px !important;
}
.ben-update-employment .p-dialog-header .p-dialog-title {
  color: #1baade;
}
.ben-update-employment .p-dialog-header .p-dialog-header-icons .p-dialog-header-icon {
  border: 2px solid #222b45;
  width: 2rem;
  height: 2rem;
}
.ben-update-employment .p-dialog-header .p-dialog-header-icons .p-dialog-header-icon:hover {
  border-color: #222b45 !important;
  background: #ffffff !important;
}
.ben-update-employment .p-dialog-header .p-dialog-header-icons .p-dialog-header-icon .p-dialog-header-close-icon {
  font-weight: 700;
  font-size: 12px;
  color: #222b45;
}
.ben-update-employment .p-dialog-content {
  padding: 0 15px 0.5rem 15px !important;
}

.timeline-step {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.gc-show-notification .p-dialog-header .p-dialog-title {
  color: #3d3d63;
  font-weight: bold !important;
  font-size: 22px !important;
  line-height: 32px;
}
.gc-show-notification .p-dialog-header .p-dialog-header-icons {
  display: none;
}

.fdns-paystub-upload {
  background-color: #ffffff;
}
.fdns-paystub-upload .p-dialog-header {
  border-bottom: 1px solid #d1d7de !important;
  padding: 10px !important;
}
.fdns-paystub-upload .p-dialog-header .p-dialog-title {
  color: #1baade;
}
.fdns-paystub-upload .p-dialog-content {
  padding: 10px !important;
  height: 100%;
}

.timeline-bene-ack-dialog {
  background-color: #ffffff;
}
.timeline-bene-ack-dialog .p-dialog-header {
  border-bottom: 1px solid #d1d7de !important;
  padding: 10px !important;
}
.timeline-bene-ack-dialog .p-dialog-content {
  padding: 10px !important;
  height: 100%;
}

.attorney-invite-emp-bene.p-dialog .p-dialog-header {
  border-bottom: 1px solid;
}
.attorney-invite-emp-bene.p-dialog .p-dialog-content {
  padding: 2rem 1.5rem 2rem 1.5rem;
}

.build-new-visa-popup {
  max-height: 90% !important;
}
.build-new-visa-popup.p-dialog .p-dialog-header {
  border-bottom: 1px dashed;
}
.build-new-visa-popup.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  font-size: 1.65rem;
}
.build-new-visa-popup.p-dialog .p-dialog-content {
  padding: 1.5rem;
  overflow-y: auto !important;
}
.build-new-visa-popup.p-dialog .p-dialog-content p-radiobutton {
  margin-bottom: 5px;
}
.build-new-visa-popup.p-dialog .p-dialog-content p-radiobutton .p-radiobutton-label {
  font-weight: 600;
  cursor: pointer;
}

.move-to-step-popup {
  max-height: 90% !important;
}
.move-to-step-popup.p-dialog .p-dialog-header {
  border-bottom: 1px dashed;
  padding: 20px 30px !important;
}
.move-to-step-popup.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  font-size: 1.65rem;
}
.move-to-step-popup.p-dialog .p-dialog-content {
  padding: 30px !important;
  overflow-y: auto !important;
}
.move-to-step-popup.p-dialog .p-dialog-content p-radiobutton {
  margin-bottom: 5px;
}
.move-to-step-popup.p-dialog .p-dialog-content p-radiobutton .p-radiobutton-label {
  cursor: pointer;
}

.pdf-preview-step-popup {
  max-height: 90% !important;
}
.pdf-preview-step-popup.p-dialog .p-dialog-header {
  border-bottom: 1px solid #d9d6d6;
  padding: 10px 10px !important;
}
.pdf-preview-step-popup.p-dialog .p-dialog-header .p-dialog-title {
  color: #3d3d63;
  font: normal normal bold 22px Source Sans Pro;
}
.pdf-preview-step-popup.p-dialog .p-dialog-content {
  padding: 30px !important;
  overflow-y: auto !important;
}
.pdf-preview-step-popup.p-dialog .p-dialog-content p-radiobutton {
  margin-bottom: 5px;
}
.pdf-preview-step-popup.p-dialog .p-dialog-content p-radiobutton .p-radiobutton-label {
  cursor: pointer;
}

.dynamic-menu-popup.p-dialog {
  width: 70%;
}
.dynamic-menu-popup.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  color: #186aa5;
}

.i9-identity-docs .p-dialog-header {
  padding: 10px 10px 10px 10px;
}
.i9-identity-docs .checkBoxList1 {
  margin-left: 0;
}
.i9-identity-docs .i9-docs-subcategory {
  padding-left: 1.6rem !important;
}
.i9-identity-docs .doclist-heading,
.i9-identity-docs .subHeading {
  font-weight: 600 !important;
}

.fb-dialog-style .p-dialog-header .p-dialog-header-icons {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
}

.i9-upload-docs .p-dialog-header {
  padding: 10px 20px 0px 20px !important;
}
.i9-upload-docs .checkBoxList1 {
  margin-left: 0;
}
.i9-upload-docs .i9-docs-subcategory {
  padding-left: 1.6rem !important;
  font-size: 13px;
}
.i9-upload-docs .doclist-heading,
.i9-upload-docs .subHeading {
  font-weight: 600 !important;
}

.confirm-upload-documents {
  width: 30vw;
}
.confirm-upload-documents .p-confirm-dialog-message {
  padding-top: 1rem !important;
}

.rfe-response-modal .p-dialog-header {
  justify-content: flex-end !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.timeline .p-timeline-event-content {
  padding-left: 0px !important;
}
.timeline .p-timeline-event-opposite {
  padding-right: 0px !important;
}

.green-card-pwd-timeline .p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.green-card-pwd-timeline .p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0;
}

.green-card-pwd-subtask-accordian.p-panel > .p-panel-header {
  border: 0;
  border-bottom: 1px solid #dee2e6;
  padding: 1rem;
  background: #ffffff;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.green-card-pwd-subtask-accordian.p-panel > .p-toggleable-content > .p-panel-content {
  border: 0;
}

.subtask-form-panel.p-panel .p-panel-header {
  border-color: #c3d9e2;
  background-color: #ebfaff;
}
.subtask-form-panel.p-panel .p-panel-content {
  border-color: #c3d9e2;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
}

.gc-pwd-duty-panel.p-panel .p-panel-header {
  border-color: #c3d9e2;
  background-color: #ebfaff;
  padding-top: 5px;
  padding-bottom: 5px;
}
.gc-pwd-duty-panel.p-panel .p-panel-content {
  border-color: #c3d9e2;
}
.gc-pwd-duty-panel .p-panel-header-icon {
  height: 1rem !important;
}

.lcaPanel .p-panel .p-panel-header {
  background-color: #e4efff;
  padding: 5px;
}
.lcaPanel .p-panel .p-panel-header .p-panel-title {
  font-size: 15px;
}
.lcaPanel .p-panel .p-panel-content {
  font-size: 14px;
  text-align: justify;
  padding: 10px;
}
.lcaPanel .p-panel .p-panel-content h6 {
  font-size: 14px;
}
.lcaPanel .p-panel .p-panel-content p {
  font-size: 13px;
}

.p-form .p-label,
.common-form-styles .p-label {
  font-size: 14px;
  line-height: 20px;
  font-family: "Source Sans Pro";
  color: #4a4a4a;
}
.p-form .p-label.p-label-small,
.common-form-styles .p-label.p-label-small {
  font-size: 12px;
  line-height: 14px;
}
.p-form .p-label .p-sub-label,
.common-form-styles .p-label .p-sub-label {
  font-size: 12px;
  line-height: 14px;
}
.p-form .p-label.p-label-blue,
.common-form-styles .p-label.p-label-blue {
  color: #1798db;
}
.p-form .p-label.p-label-bold,
.common-form-styles .p-label.p-label-bold {
  font-weight: 600;
}
.p-form .p-inputtextarea,
.common-form-styles .p-inputtextarea {
  min-height: 88px !important;
}
.p-form .p-form-control,
.common-form-styles .p-form-control {
  display: block;
  width: 100%;
  height: 38px;
  margin: 0;
}
.p-form .p-form-control.p-input:disabled,
.common-form-styles .p-form-control.p-input:disabled {
  background-color: #cccccc;
  color: #666666;
}
.p-form .p-form-control > .p-component,
.common-form-styles .p-form-control > .p-component {
  width: 100%;
  height: 38px;
}
.p-form .p-form-control.p-dropdown-readonly > .p-component,
.common-form-styles .p-form-control.p-dropdown-readonly > .p-component {
  background-color: #f1f1f1 !important;
  border: 1px solid #c3d0de !important;
  box-shadow: none !important;
}
.p-form .p-form-control[readonly],
.common-form-styles .p-form-control[readonly] {
  background-color: #f1f1f1 !important;
  border: 1px solid #c3d0de !important;
  box-shadow: none !important;
}
.p-form .p-form-control.p-dropdown-contact,
.common-form-styles .p-form-control.p-dropdown-contact {
  width: 70px;
}
.p-form .p-form-control.p-dropdown-contact > .p-component,
.common-form-styles .p-form-control.p-dropdown-contact > .p-component {
  border-radius: 3px 0 0 3px;
  border-right: 0;
}
.p-form .p-form-control.p-dropdown-contact > .p-component .p-inputtext,
.common-form-styles .p-form-control.p-dropdown-contact > .p-component .p-inputtext {
  padding: 0.5rem 0 0.5rem 0.5rem;
}
.p-form .p-form-control.p-input-contact,
.common-form-styles .p-form-control.p-input-contact {
  width: calc(100% - 70px);
}
.p-form .p-form-control.p-input-contact .p-inputtext,
.common-form-styles .p-form-control.p-input-contact .p-inputtext {
  height: 38px;
  padding: 0.5rem 0.5rem 0.5rem 0;
  border-radius: 0 3px 3px 0;
  border-left: 0;
}
.p-form .p-btn.btn, .p-form .p-btn.p-button-outlined,
.p-form .p-btn.im-btn, .p-form .p-btn.btn-success,
.p-form .p-btn.im-btn-primary, .p-form .p-btn.button_bg-gray,
.p-form .p-btn.back-button,
.common-form-styles .p-btn.btn,
.common-form-styles .p-btn.p-button-outlined,
.common-form-styles .p-btn.im-btn,
.common-form-styles .p-btn.btn-success,
.common-form-styles .p-btn.im-btn-primary,
.common-form-styles .p-btn.button_bg-gray,
.common-form-styles .p-btn.back-button {
  border-radius: 4px !important;
  height: 43px !important;
  font-size: 14px !important;
  font-weight: bold !important;
  cursor: pointer;
}
.p-form .p-btn.btn.btn-primary, .p-form .p-btn.btn-primary.p-button-outlined,
.p-form .p-btn.btn-primary.im-btn, .p-form .p-btn.btn-primary.btn-success,
.p-form .p-btn.btn-primary.im-btn-primary, .p-form .p-btn.btn-primary.button_bg-gray,
.p-form .p-btn.btn-primary.back-button,
.common-form-styles .p-btn.btn.btn-primary,
.common-form-styles .p-btn.btn-primary.p-button-outlined,
.common-form-styles .p-btn.btn-primary.im-btn,
.common-form-styles .p-btn.btn-primary.btn-success,
.common-form-styles .p-btn.btn-primary.im-btn-primary,
.common-form-styles .p-btn.btn-primary.button_bg-gray,
.common-form-styles .p-btn.btn-primary.back-button {
  background-color: #19a0da;
  color: #ffffff;
}
.p-form .p-btn.btn.btn-light, .p-form .p-btn.btn-light.p-button-outlined,
.p-form .p-btn.btn-light.im-btn, .p-form .p-btn.btn-light.btn-success,
.p-form .p-btn.btn-light.im-btn-primary, .p-form .p-btn.btn-light.button_bg-gray,
.p-form .p-btn.btn-light.back-button,
.common-form-styles .p-btn.btn.btn-light,
.common-form-styles .p-btn.btn-light.p-button-outlined,
.common-form-styles .p-btn.btn-light.im-btn,
.common-form-styles .p-btn.btn-light.btn-success,
.common-form-styles .p-btn.btn-light.im-btn-primary,
.common-form-styles .p-btn.btn-light.button_bg-gray,
.common-form-styles .p-btn.btn-light.back-button {
  background-color: #dedede !important;
  color: #47607d !important;
}
.p-form .p-btn.btn.btn-mark-complete, .p-form .p-btn.btn-mark-complete.p-button-outlined,
.p-form .p-btn.btn-mark-complete.im-btn, .p-form .p-btn.btn-mark-complete.btn-success,
.p-form .p-btn.btn-mark-complete.im-btn-primary, .p-form .p-btn.btn-mark-complete.button_bg-gray,
.p-form .p-btn.btn-mark-complete.back-button,
.common-form-styles .p-btn.btn.btn-mark-complete,
.common-form-styles .p-btn.btn-mark-complete.p-button-outlined,
.common-form-styles .p-btn.btn-mark-complete.im-btn,
.common-form-styles .p-btn.btn-mark-complete.btn-success,
.common-form-styles .p-btn.btn-mark-complete.im-btn-primary,
.common-form-styles .p-btn.btn-mark-complete.button_bg-gray,
.common-form-styles .p-btn.btn-mark-complete.back-button {
  background-color: #ffffff;
  border: 1px solid #19a3dd;
}
.p-form .p-btn.btn.btn-mark-complete:disabled, .p-form .p-btn.btn-mark-complete.p-button-outlined:disabled,
.p-form .p-btn.btn-mark-complete.im-btn:disabled, .p-form .p-btn.btn-mark-complete.btn-success:disabled,
.p-form .p-btn.btn-mark-complete.im-btn-primary:disabled, .p-form .p-btn.btn-mark-complete.button_bg-gray:disabled,
.p-form .p-btn.btn-mark-complete.back-button:disabled,
.common-form-styles .p-btn.btn.btn-mark-complete:disabled,
.common-form-styles .p-btn.btn-mark-complete.p-button-outlined:disabled,
.common-form-styles .p-btn.btn-mark-complete.im-btn:disabled,
.common-form-styles .p-btn.btn-mark-complete.btn-success:disabled,
.common-form-styles .p-btn.btn-mark-complete.im-btn-primary:disabled,
.common-form-styles .p-btn.btn-mark-complete.button_bg-gray:disabled,
.common-form-styles .p-btn.btn-mark-complete.back-button:disabled {
  cursor: default;
  border: 1px solid #a2a2a2;
}
.p-form .p-btn.btn.btn-mark-complete:disabled .btn-text, .p-form .p-btn.btn-mark-complete.p-button-outlined:disabled .btn-text,
.p-form .p-btn.btn-mark-complete.im-btn:disabled .btn-text, .p-form .p-btn.btn-mark-complete.btn-success:disabled .btn-text,
.p-form .p-btn.btn-mark-complete.im-btn-primary:disabled .btn-text, .p-form .p-btn.btn-mark-complete.button_bg-gray:disabled .btn-text,
.p-form .p-btn.btn-mark-complete.back-button:disabled .btn-text,
.common-form-styles .p-btn.btn.btn-mark-complete:disabled .btn-text,
.common-form-styles .p-btn.btn-mark-complete.p-button-outlined:disabled .btn-text,
.common-form-styles .p-btn.btn-mark-complete.im-btn:disabled .btn-text,
.common-form-styles .p-btn.btn-mark-complete.btn-success:disabled .btn-text,
.common-form-styles .p-btn.btn-mark-complete.im-btn-primary:disabled .btn-text,
.common-form-styles .p-btn.btn-mark-complete.button_bg-gray:disabled .btn-text,
.common-form-styles .p-btn.btn-mark-complete.back-button:disabled .btn-text {
  color: #a2a2a2;
  border-right: 1px solid #707070;
}
.p-form .p-btn.btn.btn-mark-complete:disabled .btn-info, .p-form .p-btn.btn-mark-complete.p-button-outlined:disabled .btn-info,
.p-form .p-btn.btn-mark-complete.im-btn:disabled .btn-info, .p-form .p-btn.btn-mark-complete.btn-success:disabled .btn-info,
.p-form .p-btn.btn-mark-complete.im-btn-primary:disabled .btn-info, .p-form .p-btn.btn-mark-complete.button_bg-gray:disabled .btn-info,
.p-form .p-btn.btn-mark-complete.back-button:disabled .btn-info,
.common-form-styles .p-btn.btn.btn-mark-complete:disabled .btn-info,
.common-form-styles .p-btn.btn-mark-complete.p-button-outlined:disabled .btn-info,
.common-form-styles .p-btn.btn-mark-complete.im-btn:disabled .btn-info,
.common-form-styles .p-btn.btn-mark-complete.btn-success:disabled .btn-info,
.common-form-styles .p-btn.btn-mark-complete.im-btn-primary:disabled .btn-info,
.common-form-styles .p-btn.btn-mark-complete.button_bg-gray:disabled .btn-info,
.common-form-styles .p-btn.btn-mark-complete.back-button:disabled .btn-info {
  background-color: #a2a2a2;
}
.p-form .p-btn.btn.btn-mark-complete .btn-text, .p-form .p-btn.btn-mark-complete.p-button-outlined .btn-text,
.p-form .p-btn.btn-mark-complete.im-btn .btn-text, .p-form .p-btn.btn-mark-complete.btn-success .btn-text,
.p-form .p-btn.btn-mark-complete.im-btn-primary .btn-text, .p-form .p-btn.btn-mark-complete.button_bg-gray .btn-text,
.p-form .p-btn.btn-mark-complete.back-button .btn-text,
.common-form-styles .p-btn.btn.btn-mark-complete .btn-text,
.common-form-styles .p-btn.btn-mark-complete.p-button-outlined .btn-text,
.common-form-styles .p-btn.btn-mark-complete.im-btn .btn-text,
.common-form-styles .p-btn.btn-mark-complete.btn-success .btn-text,
.common-form-styles .p-btn.btn-mark-complete.im-btn-primary .btn-text,
.common-form-styles .p-btn.btn-mark-complete.button_bg-gray .btn-text,
.common-form-styles .p-btn.btn-mark-complete.back-button .btn-text {
  color: #19a3dd;
  border-right: 1px solid #19a3dd;
  line-height: 20px;
  padding: 0 10px;
  margin-right: 10px;
  float: left;
}
.p-form .p-btn.btn.btn-mark-complete .btn-info, .p-form .p-btn.btn-mark-complete.p-button-outlined .btn-info,
.p-form .p-btn.btn-mark-complete.im-btn .btn-info, .p-form .p-btn.btn-mark-complete.btn-success .btn-info,
.p-form .p-btn.btn-mark-complete.im-btn-primary .btn-info, .p-form .p-btn.btn-mark-complete.button_bg-gray .btn-info,
.p-form .p-btn.btn-mark-complete.back-button .btn-info,
.common-form-styles .p-btn.btn.btn-mark-complete .btn-info,
.common-form-styles .p-btn.btn-mark-complete.p-button-outlined .btn-info,
.common-form-styles .p-btn.btn-mark-complete.im-btn .btn-info,
.common-form-styles .p-btn.btn-mark-complete.btn-success .btn-info,
.common-form-styles .p-btn.btn-mark-complete.im-btn-primary .btn-info,
.common-form-styles .p-btn.btn-mark-complete.button_bg-gray .btn-info,
.common-form-styles .p-btn.btn-mark-complete.back-button .btn-info {
  background-color: #19a3dd;
  color: #ffffff;
  float: left;
  width: 14px;
  height: 14px;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  border-radius: 50%;
  margin: 3px 0;
}
.p-form .p-btn:disabled,
.common-form-styles .p-btn:disabled {
  cursor: not-allowed !important;
}
.p-form .p-checkbox,
.common-form-styles .p-checkbox {
  width: max-content;
}
.p-form .p-checkbox .p-checkbox-checked + .p-checkbox-label,
.common-form-styles .p-checkbox .p-checkbox-checked + .p-checkbox-label {
  font-weight: bold;
}
.p-form .p-checkbox .p-checkbox-label,
.common-form-styles .p-checkbox .p-checkbox-label {
  font-size: 14px;
  line-height: 20px;
  font-family: "Source Sans Pro";
  color: #4a4a4a;
  margin-bottom: 0;
}
.p-form .p-form-datepicker.p-calendar,
.common-form-styles .p-form-datepicker.p-calendar {
  width: 100%;
}
.p-form .p-form-datepicker.p-calendar .p-inputtext,
.common-form-styles .p-form-datepicker.p-calendar .p-inputtext {
  height: 38px;
}
.p-form .p-form-datepicker.p-calendar .p-datepicker,
.common-form-styles .p-form-datepicker.p-calendar .p-datepicker {
  left: auto !important;
  right: 0px !important;
}
.p-form .p-form-datepicker.p-calendar .p-datepicker .p-datepicker-header .p-datepicker-title select,
.common-form-styles .p-form-datepicker.p-calendar .p-datepicker .p-datepicker-header .p-datepicker-title select {
  border-radius: 3px;
  padding: 2px;
}

.p-btn-outside-form {
  border-radius: 4px !important;
  height: 43px;
  font-size: 14px !important;
  font-weight: bold !important;
  cursor: pointer;
}

.p-button-edit .p-button-icon-left {
  margin-right: 0px !important;
  font-size: 22px;
}

.gc-re-other-recruitment-icon-btn .p-button-icon.pi {
  height: 20px;
  width: 20px;
  font-size: 20px;
}

.cancel-btn {
  color: #656565;
  background: #efefef;
  border: 1px solid #efefef;
}

.fb-filter-btn.p-button .p-button-icon-right {
  color: #56addd !important;
}

.blue-nav-btn-above-lists.p-button {
  background-color: #1aa0da;
  text-transform: uppercase;
  color: #ffffff;
}
.blue-nav-btn-above-lists.p-button:enabled:hover {
  background-color: #1aa0da;
  color: #ffffff;
}

.selected-file-display button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}
.selected-file-display button:hover {
  background-color: #dc3545 !important;
}
.selected-file-display button .p-button-label {
  display: none;
}

.notification-banner .btn-close-black {
  height: 1rem !important;
  width: 1.1rem !important;
  background: #000000 !important;
  border: 1px solid #000000 !important;
}
.notification-banner .btn-close-black:hover {
  background: #000000 !important;
  border: 1px solid #000000 !important;
}
.notification-banner .btn-close-black .pi-times {
  font-size: 0.5rem !important;
}

.confirm-resend-request-data button.btn-cancel-resend {
  background-color: #dddddd !important;
  border-color: #dddddd !important;
  color: #495057 !important;
}
.confirm-resend-request-data button.btn-cancel-resend:hover {
  background-color: #dddddd !important;
  border-color: #dddddd !important;
  color: #495057 !important;
}

.p-button.view-btn-form-review, .p-button.edit-btn-form-review {
  color: #134563 !important;
  float: left;
  margin-right: 10px;
  padding: 0 !important;
  border: 0;
  width: 18px !important;
  background-color: #ffffff !important;
}
.p-button.view-btn-form-review .pi, .p-button.edit-btn-form-review .pi {
  font-size: 18px;
}

.document-upload-section .p-button-link {
  padding: 0 !important;
  font-size: small !important;
}

.parsing-section .p-button-success,
.parsing-section .p-button-danger {
  height: 25px !important;
  cursor: default !important;
}

.step-navigation button.blue-btn {
  background-color: #1ca0da;
  border: none;
  color: #fff;
  padding: 8px 22px;
  border-radius: 6px;
}
.step-navigation button.blue-btn:hover {
  background-color: #1ca0da;
}

.gc-file-upload {
  width: 121px;
  display: block;
}
.gc-file-upload.disabled .p-fileupload {
  cursor: not-allowed;
}
.gc-file-upload .p-fileupload {
  width: 121px;
}
.gc-file-upload .p-fileupload .p-button {
  border: 1px solid #027cff !important;
  border-radius: 6px !important;
  background-color: #ffffff !important;
  color: #027cff !important;
  font-size: 18px !important;
  line-height: 22px;
  height: 40px;
  width: 121px;
  margin-top: 10px;
}
.gc-file-upload .p-fileupload .p-button.p-disabled {
  cursor: not-allowed !important;
}
.gc-file-upload .p-fileupload .p-message.p-message-error {
  background: rgba(0, 0, 0, 0);
  border: 0;
  color: #f44336;
  white-space: nowrap;
  margin: 0 0;
  height: 0;
  font-size: 12px;
  position: relative;
  top: 55px;
}
.gc-file-upload .p-fileupload .p-message.p-message-error .p-message-wrapper {
  padding: 0;
}
.gc-file-upload .p-fileupload .p-message.p-message-error .p-message-wrapper .p-message-icon {
  display: none;
}

.upload-container {
  border: 1px dashed #dee2e6;
  border-radius: 4px;
  margin: 30px 100px 50px;
  position: relative;
}
.upload-container.files-exit .p-component.p-fileupload.p-fileupload-advanced .p-fileupload-buttonbar .p-button {
  display: none;
}
.upload-container.files-exit .p-component.p-fileupload.p-fileupload-advanced .p-fileupload-buttonbar .p-button.p-fileupload-choose {
  display: none;
}
.upload-container.files-exit .p-component.p-fileupload.p-fileupload-advanced .p-fileupload-buttonbar p-button {
  display: none;
}
.upload-container .p-component.p-fileupload.p-fileupload-advanced {
  background: rgba(0, 0, 0, 0);
}
.upload-container .p-component.p-fileupload.p-fileupload-advanced .p-fileupload-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
}
.upload-container .p-component.p-fileupload.p-fileupload-advanced .p-fileupload-content * {
  display: none !important;
}
.upload-container .p-component.p-fileupload.p-fileupload-advanced .p-fileupload-buttonbar {
  position: absolute;
  width: 186px;
  height: 36px;
  border: 0;
  padding: 0;
  top: 270px;
  left: calc(50% - 93px);
  z-index: 2;
  background-color: rgba(0, 0, 0, 0);
}
.upload-container .p-component.p-fileupload.p-fileupload-advanced .p-fileupload-buttonbar .p-button {
  display: none;
}
.upload-container .p-component.p-fileupload.p-fileupload-advanced .p-fileupload-buttonbar .p-button.p-fileupload-choose {
  width: 186px;
  padding: 0;
  height: 36px;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  display: block;
  margin: 0;
  color: rgba(0, 0, 0, 0);
}
.upload-container .p-component.p-fileupload.p-fileupload-advanced .p-fileupload-buttonbar p-button {
  display: none;
}
.upload-container .upload-pdf-img {
  width: 70px;
  height: 87px;
  position: absolute;
  top: 50px;
  left: calc(50% - 35px);
}
.upload-container .upload-bold-txt {
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  display: block;
  margin-top: 160px;
  margin-bottom: 10px;
}
.upload-container .upload-normal-txt {
  display: block;
  font-size: 19px;
  text-align: center;
  margin-bottom: 5px;
}
.upload-container .upload-choose-btn {
  background-color: #1aa0da;
  color: #fff;
  width: 186px;
  height: 36px;
  padding: 8px 40px;
  font-size: 13px;
  border-radius: 4px;
  margin: 5px auto 50px;
}
.upload-container .selected-file-holder {
  margin: 30px;
  width: calc(100% - 60px);
  background-color: #edf4fb;
  padding-top: 8px;
  padding-bottom: 8px;
}
.upload-container .selected-file-holder .uploaded-file-txt {
  outline-width: 0px;
}
.upload-container .selected-file-holder .selected-file-delete .p-button {
  height: 40px;
  color: #465f7c;
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0;
  margin-right: 15px;
}
.upload-container .selected-file-holder .selected-file-upload .p-button {
  height: 40px;
}
.upload-container .upload-success {
  border: 1px solid #707070;
  padding: 10px 15px;
  background-color: #eaf8e7;
  height: 55px;
  margin: 0 auto 30px;
  width: max-content;
  position: relative;
  z-index: 1;
}
.upload-container .upload-success i.pi.pi-check {
  color: #eaf8e7;
  background-color: #41ad49;
  border-radius: 50%;
  padding: 7px;
  font-size: 21px;
  font-weight: 700;
  margin-right: 10px;
  float: left;
}
.upload-container .upload-success span {
  color: #000000;
  line-height: 35px;
  float: left;
  font-size: 18px;
  margin-right: 50px;
}
.upload-container .upload-success a {
  color: #0f92e5;
  font-size: 18px;
  float: left;
  line-height: 35px;
  font-weight: 600;
  text-decoration: none;
}
.upload-container .uploaded-file-list {
  position: relative;
  z-index: 1;
  padding: 15px;
}
.upload-container .uploaded-file-list .p-datatable table {
  border: 0 !important;
}
.upload-container .uploaded-file-list .p-datatable table .p-datatable-thead tr {
  height: 0px;
}
.upload-container .uploaded-file-list .p-datatable table .p-datatable-thead tr th {
  padding: 0;
}
.upload-container .uploaded-file-list .p-datatable table .p-datatable-tbody tr td {
  padding: 0 0 15px 0 !important;
  position: relative;
}
.upload-container .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-datatable-reorderablerow-handle {
  position: absolute;
  z-index: 2;
  top: 15px;
  right: 15px;
}
.upload-container .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .uploaded-file-delete {
  position: absolute;
  z-index: 2;
  top: 6px;
  right: 46px;
}
.upload-container .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .uploaded-file-delete .p-button {
  color: #465f7c;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}
.upload-container .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component {
  background-color: #edf4fb;
  padding-bottom: 15px;
}
.upload-container .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  background-color: #edf4fb !important;
  padding: 15px 15px 0 !important;
  transition: padding 1s;
}
.upload-container .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab.p-accordion-tab-active .p-accordion-header .p-accordion-header-link {
  padding: 15px 15px 15px !important;
}
.upload-container .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab:last-child .p-accordion-content {
  margin-bottom: 0;
}

.build-steps-content-div {
  border: 1px solid #bcc5d3;
  border-width: 0 1px 1px 1px;
  background-color: #f7faff;
  padding: 15px 0;
}
.build-steps-content-div .build-steps-scrollable {
  max-height: calc(100vh - 140px);
  overflow: auto;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table {
  border: 0 !important;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-thead tr {
  height: 0px;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-thead tr th {
  padding: 0;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr:last-child td {
  padding: 0 0 0 0 !important;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td {
  padding: 0 0 15px 0 !important;
  position: relative;
  background-color: #f7faff;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-datatable-reorderablerow-handle {
  position: absolute;
  z-index: 2;
  top: 15px;
  right: 15px;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .uploaded-file-delete {
  position: absolute;
  z-index: 2;
  top: 6px;
  right: 46px;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .uploaded-file-delete .p-button {
  color: #465f7c;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component {
  background-color: #edf4fb;
  padding-bottom: 15px;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  background-color: #edf4fb !important;
  padding: 15px 15px 0 !important;
  transition: padding 1s;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab.p-accordion-tab-active .p-accordion-header .p-accordion-header-link {
  padding: 15px 15px 15px !important;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content {
  margin-bottom: 0;
  background-color: #edf4fb;
  border-width: 1px 0 0 0 !important;
  border-style: dashed !important;
  border-color: #aaaaaa !important;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .disabled-element {
  position: absolute;
  width: 100%;
  height: calc(100% - 1rem);
  top: -0.5rem;
  z-index: 1;
  left: 0;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: not-allowed;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .part-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  display: block;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .sub-head-title {
  font-size: 19px;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .element-head-title {
  font-size: 18px;
  margin-bottom: 5px;
  display: block;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .questionnaire-lbl {
  float: left;
  margin-right: 15px;
  margin-top: 3px;
  margin-bottom: 0;
  max-width: calc(100% - 70px);
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .p-inputswitch.questionnaire-switch {
  background-color: rgba(0, 0, 0, 0);
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .json-viewer-textarea {
  width: 100%;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .form-group .json-viewer-input {
  width: 350px;
  max-width: 100%;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .form-group .pdf-checkbox {
  width: max-content;
  margin-right: 15px;
  float: left;
  max-width: 100%;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .form-group .pdf-radiobutton {
  width: max-content;
  margin-right: 15px;
  float: left;
  max-width: 100%;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .form-group .pdf-dropdown.p-dropdown {
  width: 350px;
  max-width: 100%;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .form-group .vd-pdf-datepicker.p-calendar {
  width: 350px;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .form-group .vd-pdf-datepicker.p-calendar .p-inputtext {
  height: 38px;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .form-group .vd-pdf-datepicker.p-calendar .p-datepicker {
  left: auto !important;
  right: 0px !important;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .form-group .vd-pdf-datepicker.p-calendar .p-datepicker .p-datepicker-header .p-datepicker-title select {
  border-radius: 3px;
  padding: 2px;
}
.build-steps-content-div .build-steps-scrollable .uploaded-file-list .p-datatable table .p-datatable-tbody tr td .p-accordion.p-component p-accordiontab .p-accordion-tab .p-accordion-content .form-group .vd-pdf-datepicker.p-calendar .p-datepicker-trigger.p-component.p-button.p-button-icon-only {
  height: 38px;
  top: 0;
  color: #ffffff;
  background-color: #2196f3;
  border-color: #2196f3 !important;
}

.pdf-preview-step-popup .part-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
  display: block;
}
.pdf-preview-step-popup .sub-head-title {
  font-size: 19px;
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}
.pdf-preview-step-popup .element-head-title {
  font-size: 18px;
  margin-bottom: 5px;
  display: block;
}
.pdf-preview-step-popup .questionnaire-lbl {
  float: left;
  margin-right: 15px;
  margin-top: 3px;
  margin-bottom: 0;
  max-width: calc(100% - 70px);
}
.pdf-preview-step-popup .p-inputswitch.questionnaire-switch {
  background-color: rgba(0, 0, 0, 0);
}
.pdf-preview-step-popup .json-viewer-textarea {
  width: 100%;
}
.pdf-preview-step-popup .form-group .json-viewer-input {
  width: 350px;
  max-width: 100%;
}
.pdf-preview-step-popup .form-group .pdf-checkbox {
  width: max-content;
  margin-right: 15px;
  float: left;
  max-width: 100%;
}
.pdf-preview-step-popup .form-group .pdf-radiobutton {
  width: max-content;
  margin-right: 15px;
  float: left;
  max-width: 100%;
}
.pdf-preview-step-popup .form-group .pdf-dropdown.p-dropdown {
  width: 100%;
  max-width: 100%;
}
.pdf-preview-step-popup .form-group .vd-pdf-datepicker.p-calendar {
  width: 100%;
}
.pdf-preview-step-popup .form-group .vd-pdf-datepicker.p-calendar .p-inputtext {
  height: 38px;
}
.pdf-preview-step-popup .form-group .vd-pdf-datepicker.p-calendar .p-datepicker {
  left: auto !important;
  right: 0px !important;
}
.pdf-preview-step-popup .form-group .vd-pdf-datepicker.p-calendar .p-datepicker .p-datepicker-header .p-datepicker-title select {
  border-radius: 3px;
  padding: 2px;
}
.pdf-preview-step-popup .form-group .vd-pdf-datepicker.p-calendar .p-datepicker-trigger.p-component.p-button.p-button-icon-only {
  height: 38px;
  top: 0;
  color: #ffffff;
  background-color: #2196f3;
  border-color: #2196f3 !important;
}

.dynamic-menu-img-upload-container {
  background-color: #f8f8f8;
  height: 75px;
  border-radius: 3px;
  padding: 17px 10px 17px 10px;
}
.dynamic-menu-img-upload-container .dynamic-menu-image-upload {
  width: 111px;
  float: left;
  margin-right: 10px;
}
.dynamic-menu-img-upload-container .dynamic-menu-image-upload.disabled .p-fileupload {
  cursor: not-allowed;
}
.dynamic-menu-img-upload-container .dynamic-menu-image-upload .p-fileupload {
  width: 111px;
}
.dynamic-menu-img-upload-container .dynamic-menu-image-upload .p-fileupload .p-button {
  border: 0px;
  border-radius: 3px !important;
  background-color: #efefef !important;
  color: #a3a6b4 !important;
  font-size: 18px !important;
  line-height: 22px;
  height: 40px;
  width: 111px;
}
.dynamic-menu-img-upload-container .dynamic-menu-image-upload .p-fileupload .p-button.p-disabled {
  cursor: not-allowed !important;
}
.dynamic-menu-img-upload-container .dynamic-menu-image-upload .p-fileupload .p-message.p-message-error {
  background: rgba(0, 0, 0, 0);
  border: 0;
  color: #f44336;
  white-space: nowrap;
  margin: 0 0;
  height: 0;
  font-size: 12px;
  position: relative;
  top: 55px;
}
.dynamic-menu-img-upload-container .dynamic-menu-image-upload .p-fileupload .p-message.p-message-error .p-message-wrapper {
  padding: 0;
}
.dynamic-menu-img-upload-container .dynamic-menu-image-upload .p-fileupload .p-message.p-message-error .p-message-wrapper .p-message-icon {
  display: none;
}
.dynamic-menu-img-upload-container .dynamic-menu-img-upload-name-container {
  float: left;
  width: calc(100% - 130px);
}
.dynamic-menu-img-upload-container .dynamic-menu-img-upload-name-container .fileuploaded-name-anchor {
  cursor: pointer;
  float: left;
  margin-right: 10px;
  max-width: calc(100% - 40px);
}
.dynamic-menu-img-upload-container .dynamic-menu-img-upload-name-container .fileuploaded-name-anchor .fileuploaded-name {
  line-height: 40px;
  color: #027cff;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
}
.dynamic-menu-img-upload-container .dynamic-menu-img-upload-name-container .fileuploaded-name-anchor .fileuploaded-name .pi.pi-image {
  color: #e04443;
  font-weight: 600;
  margin-right: 5px;
  float: left;
  line-height: 40px;
}
.dynamic-menu-img-upload-container .dynamic-menu-img-upload-name-container .fileuploaded-name-anchor:hover .fileuploaded-name {
  text-decoration: underline;
}
.dynamic-menu-img-upload-container .dynamic-menu-img-upload-name-container .fileuploaded-name-anchor:hover .fileuploaded-name .pi.pi-image {
  text-decoration: none;
}

.create-application-hidden .p-fileupload {
  display: none;
}

.letter-pdf .zoom {
  min-height: 700px !important;
}

.letter-textarea .fr-toolbar .fr-btn-grp {
  margin: 0px;
}
.letter-textarea .fr-toolbar .fr-command.fr-btn, .letter-textarea .fr-popup .fr-command.fr-btn, .letter-textarea .fr-modal .fr-command.fr-btn {
  margin: 0px;
}

.p-form-chips {
  display: block;
  width: 100%;
}
.p-form-chips .p-chips {
  width: 100%;
}
.p-form-chips .p-chips .p-chips-multiple-container {
  width: 100%;
  padding: 0 0.5rem 0.25rem;
}
.p-form-chips .p-chips .p-chips-multiple-container .p-chips-token {
  height: 28px;
  margin-top: 0.25rem;
}
.p-form-chips .p-chips .p-chips-multiple-container .p-chips-input-token {
  margin-top: 0.25rem;
  padding: 0;
}
.p-form-chips .p-chips .p-chips-multiple-container .p-chips-input-token input {
  height: 28px;
}

.greenCard-i140-chip .p-chips {
  width: 100%;
}
.greenCard-i140-chip .p-chips .p-chips-multiple-container {
  width: 100%;
}

.create-masterlist-chip .p-chips {
  height: 40px;
  width: 100%;
}
.create-masterlist-chip .p-chips .p-chips-multiple-container {
  width: 100%;
}
.create-masterlist-chip .p-chips .p-chips-multiple-container .p-chips-input-token input {
  border: 0 !important;
  height: calc(40px - 1rem);
}

.vd-chip .p-chips .p-chips-multiple-container {
  width: 100%;
  display: block;
}
.vd-chip .p-chips .p-chips-multiple-container .p-chips-input-token input {
  border: 0 !important;
  height: calc(40px - 1rem);
}

.p-readonly.p-inputswitch .p-inputswitch-slider {
  cursor: default;
}
.p-readonly.p-inputswitch .p-inputswitch-slider::before {
  background-color: #f1f1f1 !important;
}

.notify-switches .notifyDisabled .p-disabled.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #F8F9FA !important;
  border: 1px solid #0000001A;
}
.notify-switches .notifyDisabled .p-disabled:not(.p-inputswitch-checked) .p-inputswitch-slider {
  background-color: #F8F9FA !important;
  border: 1px solid #0000001A;
}
.notify-switches .notifyDisabled .p-disabled .p-inputswitch-slider::after {
  color: #D0C9D6 !important;
}
.notify-switches .notifyDisabled .p-disabled .p-inputswitch-slider::before {
  background: #D0C9D6;
}
.notify-switches .p-inputswitch .p-inputswitch-slider {
  width: 60px;
}
.notify-switches .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: #158fc4 !important;
}
.notify-switches .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::before {
  left: 0.9rem;
}
.notify-switches .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider::after {
  content: "ON";
  color: white;
  position: absolute;
  left: 8px;
}
.notify-switches .p-inputswitch:not(.p-inputswitch-checked) .p-inputswitch-slider {
  background-color: #888E90 !important;
}
.notify-switches .p-inputswitch:not(.p-inputswitch-checked) .p-inputswitch-slider::after {
  content: "OFF";
  color: white;
  position: absolute;
  left: 25px;
}

.beneficiary-experience-froala-editor .fr-box.fr-basic.fr-top .fr-toolbar.fr-top {
  border-radius: 0;
}
.beneficiary-experience-froala-editor .fr-box.fr-basic.fr-top .fr-toolbar.fr-top .fr-btn-grp {
  margin: 0 5px 0 5px;
}
.beneficiary-experience-froala-editor .fr-box.fr-basic.fr-top .fr-wrapper {
  max-height: 115px !important;
  height: 115px !important;
  display: flex;
  flex-flow: column;
}
.beneficiary-experience-froala-editor .fr-box.fr-basic.fr-top .fr-wrapper .fr-element {
  flex-grow: 1;
}
.beneficiary-experience-froala-editor .fr-box.fr-basic.fr-top .second-toolbar {
  border-radius: 0;
}
.beneficiary-experience-froala-editor .fr-box.fr-basic.fr-top .fr-sticky-on {
  position: absolute;
}

.p-divider.p-divider-horizontal:before {
  border-top: 1px #c3c3c3;
}

.header-divider .p-divider-horizontal.p-divider-left {
  margin-bottom: 1px;
}

.tab-header-divider .p-divider.p-divider-horizontal {
  margin: 0.3rem 0 1rem;
}

.vd-container .vd-build-steps .p-divider.p-divider-horizontal.p-component {
  margin: 0;
}
.vd-container .vd-build-steps .p-divider.p-divider-horizontal.p-component:before {
  border-width: 2px;
}

.vertical-dashed-blue .p-divider-vertical:before {
  border-left: 2px dashed #94d3ef !important;
  margin-top: 0.2rem;
}

.divider-m-5.p-divider.p-divider-horizontal {
  margin: 5px 0;
}

.lcaProgress .p-progressbar {
  height: 1.5rem;
  top: 5px;
}
.lcaProgress .p-progressbar .p-progressbar-determinate .p-progressbar-label {
  font-weight: 600;
}
.lcaProgress .p-progressbar .p-progressbar-value {
  background: lightskyblue !important;
}
.lcaProgress .p-progressbar-label {
  line-height: 1.5rem !important;
  font-weight: 500 !important;
}

.duties-lis-box .p-listbox-header, .duties-lis-box .p-listbox-item {
  display: block;
}

.termsButton {
  color: #19a0da !important;
  border: 0 !important;
  background: transparent;
  outline: 0;
  font: normal normal 600 12px/18px Source Sans Pro;
  text-align: left;
  width: fit-content;
}
.termsButton:hover .mat-button-focus-overlay, .termsButton:focus .mat-button-focus-overlay {
  opacity: 0;
}

.update-subtask-details-form mat-slide-toggle.mat-checked .mat-slide-toggle-bar::after {
  content: none !important;
}
.update-subtask-details-form .mat-slide-toggle:not(.mat-checked) .mat-slide-toggle-bar::after {
  content: none !important;
}

.fb-placeholder-tree .p-tree .p-tree-empty-message {
  font-size: 12px;
  white-space: pre-wrap;
  color: #6c757d;
  font-style: italic;
  background-color: #f6f9fe !important;
}
.fb-placeholder-tree .p-tree.p-treenode-dragover .p-tree-empty-message,
.fb-placeholder-tree .p-tree.p-treenode-dragover .p-treenode-label,
.fb-placeholder-tree .p-tree.p-treenode-dragover .p-treenode-label > label.bg-primary {
  background: #e1e1e1 !important;
}
.fb-placeholder-tree .p-tree .p-tree-wrapper {
  width: calc(100% - 30px);
  margin: 10px 15px;
}
.fb-placeholder-tree .p-tree .p-tree-filter-container {
  width: calc(100% - 30px);
  margin: 10px 15px;
}
.fb-placeholder-tree .p-tree .p-tree-filter-container .p-tree-filter {
  border-radius: 20px !important;
  padding: 5px 30px 5px 5px;
  height: 30px;
}
.fb-placeholder-tree .p-tree .p-tree-container .p-treenode {
  border: 0;
}
.fb-placeholder-tree .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  top: 0rem;
  color: #bcc5d3;
  margin-right: 0;
  width: 20px;
}
.fb-placeholder-tree .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #bcc5d3;
  background: rgba(0, 0, 0, 0);
}
.fb-placeholder-tree .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler .pi {
  font-size: 10px;
  line-height: 2rem;
}
.fb-placeholder-tree .p-tree .p-treenode-children {
  padding: 0 0 15px 2rem !important;
  color: #728499;
  font-weight: 600;
}
.fb-placeholder-tree .p-tree .p-treenode-children .p-treenode.child .p-treenode-content .p-treenode-label {
  cursor: move;
}
.fb-placeholder-tree .p-tree .p-treenode-children .p-treenode.child .p-treenode-content .p-treenode-label label {
  cursor: move;
}

.fb-columnTree .p-tree {
  height: 58px;
}
.fb-columnTree .p-tree .p-tree-empty-message {
  font-size: 12px;
  white-space: pre-wrap;
  color: #6c757d;
  font-style: italic;
  background-color: #f6f9fe !important;
  height: 58px;
}
.fb-columnTree .p-tree.p-treenode-dragover .p-tree-empty-message,
.fb-columnTree .p-tree.p-treenode-dragover .p-treenode-label,
.fb-columnTree .p-tree.p-treenode-dragover .p-treenode-label > label.bg-primary {
  background: #e1e1e1 !important;
}
.fb-columnTree .p-tree .p-tree-container .p-treenode {
  height: 58px;
}
.fb-columnTree .p-tree-toggler {
  display: none;
}
.fb-columnTree .p-treenode-label {
  font-size: 15px;
  background-color: #f5f9fd;
}

.fb-default-column-table .col .p-inplace {
  text-align: center;
  height: 55px;
}
.fb-default-column-table .col .p-inplace .p-inplace-display {
  font-size: 12px;
  display: block;
  padding: 0rem 0.3rem;
  word-break: break-all;
}
.fb-default-column-table .col .p-inplace .p-inputtext {
  margin: 0px 0 0px 0px;
  padding: 1px;
  height: auto;
  font-size: 11px;
  vertical-align: top;
}
.fb-default-column-table .col .p-inplace .p-button {
  width: 20px;
  height: 20px;
  color: #4cba20;
  background: transparent;
  padding: 0;
  border: 0;
  margin-bottom: 5px;
}
.fb-default-column-table .col .p-inplace .p-button.p-button-danger {
  color: black !important;
  float: right;
  margin-right: 10px;
}
.fb-default-column-table .col .p-inplace .p-button .p-button-icon {
  font-size: 11px;
}

.autocomplete-heading-text {
  font-size: 0.8rem;
  font-weight: 600;
}

.autocomplete-value-text {
  font-size: 0.8rem;
  max-width: 95%;
  display: inline-block;
  overflow-x: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.p-autocomplete-item {
  padding: 0.5rem 0 0.5rem 0 !important;
}

.p-autocomplete-dd {
  width: 100% !important;
}

.petition-type-autocomplete .p-autocomplete-panel {
  min-width: 800px !important;
}

.p-autocomplete-dd .p-autocomplete-input, .p-autocomplete-dd .p-autocomplete-multiple-container {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.attorney-autocomplete .p-autocomplete {
  width: 100% !important;
}
.attorney-autocomplete .p-autocomplete .p-autocomplete-item {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.attorney-autocomplete.platform-admin-ac .p-autocomplete-loader {
  display: none;
}

.switch-toggle a,
.switch-light span span {
  display: none;
}

/* We can't test for a specific feature,
 * so we only target browsers with support for media queries.
 */
@media only screen {
  /* Checkbox
  */
  .switch-light {
    position: relative;
    display: block;
    /* simulate default browser focus outlines on the switch,
    * when the inputs are focused.
    */
  }

  .switch-light::after {
    clear: both;
    content: "";
    display: table;
  }

  .switch-light *,
.switch-light *:before,
.switch-light *:after {
    box-sizing: border-box;
  }

  .switch-light a {
    display: block;
    transition: all 0.2s ease-out;
  }

  .switch-light label,
.switch-light > span {
    /* breathing room for bootstrap/foundation classes.
    */
    line-height: 2em;
  }

  .switch-light input:focus ~ span a,
.switch-light input:focus + label {
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;
    /* Chrome/Opera gets its native focus styles.
    */
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 0) {
  .switch-light input:focus ~ span a,
.switch-light input:focus + label {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
}
@media only screen {
  /* don't hide the input from screen-readers and keyboard access
  */
  .switch-light input {
    position: absolute;
    opacity: 0;
    z-index: 3;
  }

  .switch-light input:checked ~ span a {
    right: 0%;
  }

  /* inherit from label
  */
  .switch-light strong {
    font-weight: inherit;
  }

  .switch-light > span {
    position: relative;
    overflow: hidden;
    display: block;
    min-height: 2em;
    /* overwrite 3rd party classes padding
    * eg. bootstrap .alert
    */
    padding: 0;
    text-align: left;
  }

  .switch-light span span {
    position: relative;
    z-index: 2;
    display: block;
    float: left;
    width: 50%;
    text-align: center;
    user-select: none;
  }

  .switch-light a {
    position: absolute;
    right: 50%;
    top: 0;
    z-index: 1;
    display: block;
    width: 50%;
    height: 100%;
    padding: 0;
  }

  /* bootstrap 4 tweaks
  */
  .switch-light.row {
    display: flex;
  }

  .switch-light .alert-light {
    color: #333;
  }

  /* Radio Switch
  */
  .switch-toggle {
    position: relative;
    display: block;
    width: 435px;
    /* simulate default browser focus outlines on the switch,
    * when the inputs are focused.
    */
    /* For callout panels in foundation
    */
    padding: 0 !important;
    /* 2 items
    */
    /* 3 items
    */
    /* 4 items
    */
    /* 5 items
    */
    /* 6 items
    */
  }

  .switch-toggle::after {
    clear: both;
    content: "";
    display: table;
  }

  .switch-toggle *,
.switch-toggle *:before,
.switch-toggle *:after {
    box-sizing: border-box;
  }

  .switch-toggle a {
    display: block;
    transition: all 0.2s ease-out;
  }

  .switch-toggle label,
.switch-toggle > span {
    /* breathing room for bootstrap/foundation classes.
    */
    line-height: 2em;
  }

  .switch-toggle input:focus ~ span a,
.switch-toggle input:focus + label {
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;
    /* Chrome/Opera gets its native focus styles.
    */
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 0) {
  .switch-toggle input:focus ~ span a,
.switch-toggle input:focus + label {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
}
@media only screen {
  .switch-toggle input {
    position: absolute;
    left: 0;
    opacity: 0;
  }

  .switch-toggle input + label {
    position: relative;
    z-index: 2;
    display: block;
    float: left;
    padding: 0 0.5em;
    margin: 0;
    text-align: center;
  }

  .switch-toggle a {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(4),
.switch-toggle label:nth-child(2):nth-last-child(4) ~ label,
.switch-toggle label:nth-child(2):nth-last-child(4) ~ a {
    width: 50%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(4) ~ input:checked:nth-child(3) + label ~ a {
    left: 50%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(6),
.switch-toggle label:nth-child(2):nth-last-child(6) ~ label,
.switch-toggle label:nth-child(2):nth-last-child(6) ~ a {
    width: 33.33%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(6) ~ input:checked:nth-child(3) + label ~ a {
    left: 33.33%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(6) ~ input:checked:nth-child(5) + label ~ a {
    left: 66.66%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(8),
.switch-toggle label:nth-child(2):nth-last-child(8) ~ label,
.switch-toggle label:nth-child(2):nth-last-child(8) ~ a {
    width: 25%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(3) + label ~ a {
    left: 25%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(5) + label ~ a {
    left: 50%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(7) + label ~ a {
    left: 75%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(10),
.switch-toggle label:nth-child(2):nth-last-child(10) ~ label,
.switch-toggle label:nth-child(2):nth-last-child(10) ~ a {
    width: 20%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(3) + label ~ a {
    left: 20%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(5) + label ~ a {
    left: 40%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(7) + label ~ a {
    left: 60%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(9) + label ~ a {
    left: 80%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(12),
.switch-toggle label:nth-child(2):nth-last-child(12) ~ label,
.switch-toggle label:nth-child(2):nth-last-child(12) ~ a {
    width: 16.6%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(3) + label ~ a {
    left: 16.6%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(5) + label ~ a {
    left: 33.2%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(7) + label ~ a {
    left: 49.8%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(9) + label ~ a {
    left: 66.4%;
  }

  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(11) + label ~ a {
    left: 83%;
  }

  /* Candy Theme
  * Based on the "Sort Switches / Toggles (PSD)" by Ormal Clarck
  * http://www.premiumpixels.com/freebies/sort-switches-toggles-psd/
  */
  .switch-toggle.switch-candy,
.switch-light.switch-candy > span {
    background-color: #CED4DA;
    border-radius: 3px;
    box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.2);
  }

  .switch-light.switch-candy span span,
.switch-light.switch-candy input:checked ~ span span:first-child,
.switch-toggle.switch-candy label {
    color: #fff;
    font-weight: bold;
    text-align: center;
    text-shadow: 1px 1px 1px #191b1e;
  }

  .switch-light.switch-candy input ~ span span:first-child,
.switch-light.switch-candy input:checked ~ span span:nth-child(2),
.switch-candy input:checked + label {
    color: #333;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  }

  .switch-candy a {
    border: 1px solid #333;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), inset 0 1px 1px rgba(255, 255, 255, 0.45);
    background-color: #38a3d4;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), transparent);
  }

  .switch-candy-blue a {
    background-color: #38a3d4;
  }

  .switch-candy-yellow a {
    background-color: #f5e560;
  }

  /* iOS Theme
  */
  .switch-ios.switch-light span span {
    color: #888b92;
  }

  .switch-ios.switch-light a {
    left: 0;
    top: 0;
    width: 2em;
    height: 2em;
    background-color: #fff;
    border-radius: 100%;
    border: 0.25em solid #D8D9DB;
    transition: all 0.2s ease-out;
  }

  .switch-ios.switch-light > span {
    display: block;
    width: 100%;
    height: 2em;
    background-color: #D8D9DB;
    border-radius: 1.75em;
    transition: all 0.4s ease-out;
  }

  .switch-ios.switch-light > span span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    line-height: 1.875em;
    vertical-align: middle;
    transition: all 0.2s ease-out;
  }

  .switch-ios.switch-light > span span:first-of-type {
    opacity: 1;
    padding-left: 1.875em;
  }

  .switch-ios.switch-light > span span:last-of-type {
    padding-right: 1.875em;
  }

  .switch-ios.switch-light input:checked ~ span a {
    left: 100%;
    border-color: #38a3d4;
    margin-left: -2em;
  }

  .switch-ios.switch-light input:checked ~ span {
    border-color: #38a3d4;
    box-shadow: inset 0 0 0 30px #38a3d4;
  }

  .switch-ios.switch-light input:checked ~ span span:first-of-type {
    opacity: 0;
  }

  .switch-ios.switch-light input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
  }

  .switch-ios.switch-toggle {
    background-color: #D8D9DB;
    border-radius: 30px;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 0;
  }

  .switch-ios.switch-toggle a {
    background-color: #38a3d4;
    border: 0.125em solid #D8D9DB;
    border-radius: 1.75em;
    transition: all 0.12s ease-out;
  }

  .switch-ios.switch-toggle label {
    height: 2.4em;
    color: #888b92;
    line-height: 2.4em;
    vertical-align: middle;
  }

  .switch-ios input:checked + label {
    color: #3e4043;
  }

  /* Holo Theme
  */
  .switch-toggle.switch-holo,
.switch-light.switch-holo > span {
    background-color: #464747;
    border-radius: 1px;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 0;
    color: #fff;
    text-transform: uppercase;
  }

  .switch-holo label {
    color: #fff;
  }

  .switch-holo > span span {
    opacity: 0;
    transition: all 0.1s;
  }

  .switch-holo > span span:first-of-type {
    opacity: 1;
  }

  .switch-holo > span span,
.switch-holo label {
    font-size: 85%;
    line-height: 2.15625em;
  }

  .switch-holo a {
    background-color: #666;
    border-radius: 1px;
    box-shadow: inset rgba(255, 255, 255, 0.2) 0 1px 0, inset rgba(0, 0, 0, 0.3) 0 -1px 0;
  }

  /* Selected ON switch-light
  */
  .switch-holo.switch-light input:checked ~ span a {
    background-color: #0E88B1;
  }

  .switch-holo.switch-light input:checked ~ span span:first-of-type {
    opacity: 0;
  }

  .switch-holo.switch-light input:checked ~ span span:last-of-type {
    opacity: 1;
  }

  /* Material Theme
  */
  /* switch-light
  */
  .switch-light.switch-material a {
    top: -0.1875em;
    width: 1.75em;
    height: 1.75em;
    border-radius: 50%;
    background: #fafafa;
    box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.14), 0 0.1875em 0.125em -0.125em rgba(0, 0, 0, 0.2), 0 0.125em 0.25em 0 rgba(0, 0, 0, 0.12);
    transition: right 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .switch-material.switch-light {
    overflow: visible;
  }

  .switch-material.switch-light::after {
    clear: both;
    content: "";
    display: table;
  }

  .switch-material.switch-light > span {
    overflow: visible;
    position: relative;
    top: 0.1875em;
    width: 3.25em;
    height: 1.5em;
    min-height: auto;
    border-radius: 1em;
    background: rgba(0, 0, 0, 0.26);
  }

  .switch-material.switch-light span span {
    position: absolute;
    clip: rect(0 0 0 0);
  }

  .switch-material.switch-light input:checked ~ span a {
    right: 0;
    background: #3f51b5;
    box-shadow: 0 0.1875em 0.25em 0 rgba(0, 0, 0, 0.14), 0 0.1875em 0.1875em -0.125em rgba(0, 0, 0, 0.2), 0 0.0625em 0.375em 0 rgba(0, 0, 0, 0.12);
  }

  .switch-material.switch-light input:checked ~ span {
    background: rgba(63, 81, 181, 0.5);
  }

  /* switch-toggle
  */
  .switch-toggle.switch-material {
    overflow: visible;
  }

  .switch-toggle.switch-material::after {
    clear: both;
    content: "";
    display: table;
  }

  .switch-toggle.switch-material a {
    top: 48%;
    width: 0.375em !important;
    height: 0.375em;
    margin-left: 0.25em;
    background: #3f51b5;
    border-radius: 100%;
    transform: translateY(-50%);
    transition: transform 0.4s ease-in;
  }

  .switch-toggle.switch-material label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1em;
  }

  .switch-toggle.switch-material label:before {
    content: "";
    position: absolute;
    top: 48%;
    left: 0;
    display: block;
    width: 0.875em;
    height: 0.875em;
    border-radius: 100%;
    border: 0.125em solid rgba(0, 0, 0, 0.54);
    transform: translateY(-50%);
  }

  .switch-toggle.switch-material input:checked + label:before {
    border-color: #3f51b5;
  }

  /* ripple
  */
  .switch-light.switch-material > span:before,
.switch-light.switch-material > span:after,
.switch-toggle.switch-material label:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: block;
    width: 4em;
    height: 4em;
    border-radius: 100%;
    background: #3f51b5;
    opacity: 0.4;
    margin-left: -1.25em;
    margin-top: -1.25em;
    transform: scale(0);
    transition: opacity 0.4s ease-in;
  }

  .switch-light.switch-material > span:after {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: -1.25em;
  }

  .switch-toggle.switch-material label:after {
    width: 3.25em;
    height: 3.25em;
    margin-top: -0.75em;
  }

  @keyframes materialRipple {
    0% {
      transform: scale(0);
    }
    20% {
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }
  .switch-material.switch-light input:not(:checked) ~ span:after,
.switch-material.switch-light input:checked ~ span:before,
.switch-toggle.switch-material input:checked + label:after {
    animation: materialRipple 0.4s ease-in;
  }

  /* trick to prevent the default checked ripple animation from showing
  * when the page loads.
  * the ripples are hidden by default, and shown only when the input is focused.
  */
  .switch-light.switch-material.switch-light input ~ span:before,
.switch-light.switch-material.switch-light input ~ span:after,
.switch-material.switch-toggle input + label:after {
    visibility: hidden;
  }

  .switch-light.switch-material.switch-light input:focus:checked ~ span:before,
.switch-light.switch-material.switch-light input:focus:not(:checked) ~ span:after,
.switch-material.switch-toggle input:focus:checked + label:after {
    visibility: visible;
  }
}
/* Bugfix for older Webkit, including mobile Webkit. Adapted from
 * http://css-tricks.com/webkit-sibling-bug/
 */
@media only screen and (-webkit-max-device-pixel-ratio: 2) and (max-device-width: 80em) {
  .switch-light,
.switch-toggle {
    -webkit-animation: webkitSiblingBugfix infinite 1s;
  }
}
@-webkit-keyframes webkitSiblingBugfix {
  from {
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
  }
}
.review-petition-tab-view.p-tabview {
  border: 0;
}
.review-petition-tab-view.p-tabview .p-tabview-panels {
  padding: 0px !important;
}
.review-petition-tab-view.p-tabview .p-tabview-nav {
  background-color: #F5F7F7 !important;
}
.review-petition-tab-view.p-tabview .p-tabview-nav li {
  width: auto;
}
.review-petition-tab-view.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  border-color: #6c757d !important;
}
.review-petition-tab-view.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: #dee2e6 !important;
  color: #6c757d !important;
  border-width: 0 0 2px 0 !important;
  padding: 0.2rem 0 !important;
  font-size: 16px;
}
.review-petition-tab-view.p-tabview .p-tabview-nav li .p-tabview-nav-link .p-tabview-title {
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-right: 1px solid #CECECE;
}
.review-petition-tab-view.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background-color: rgba(0, 0, 0, 0) !important;
  border-color: #2196F3 !important;
}

.ngx-extended-pdf-viewer input,
.ngx-extended-pdf-viewer select {
  font-family: Arial !important;
  border: none !important;
  border-radius: 0 !important;
  height: calc(100% - 8px);
  color: #f2f2f2 !important;
  font-size: 12px !important;
}