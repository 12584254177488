@import "color";
html,
body {
    height: 100vh !important;
    // overflow-y: auto;
    background-color: $primar-blueBayoux !important;
}

.app-root-container {
    height: calc(100% - 30px);
}

.app-container {
    // background-color: $neutral-white !important;
    background-color: #f8f8f8 !important;
    // padding-left: 5px;
    // padding-right: 5px;
    min-height: calc(100vh - 30px);
    height: max-content;
}
