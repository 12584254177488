.fb-default-column-table {
  .col {
    .p-inplace {
      text-align: center;
      height: 55px;
      .p-inplace-display {
        //padding: 0.3rem;
        font-size: 12px;
        display: block;
        padding: 0rem 0.3rem;
        word-break: break-all;
      }

      .p-inputtext {
        margin: 0px 0 0px 0px;
        padding: 1px;
        height: auto;
        font-size: 11px;
        vertical-align: top;
      }

      .p-button {
        width: 20px;
        height: 20px;
        color: #4cba20;
        background: transparent;
        padding: 0;
        border: 0;
        margin-bottom: 5px;
        &.p-button-danger {
          color: black !important;
          float: right;
          margin-right: 10px;
        }
        .p-button-icon {
          font-size: 11px;
        }
      }
    }
  }
}
