.p-button-edit {
  .p-button-icon-left {
    margin-right: 0px !important;
    font-size: 22px;
  }
}

.gc-re-other-recruitment-icon-btn {
  .p-button-icon.pi {
    height: 20px;
    width: 20px;
    font-size: 20px;
  }
}

.cancel-btn {
  color: #656565;
  background: #efefef;
  border: 1px solid #efefef;
}

.fb-filter-btn.p-button {
  .p-button-icon-right {
    color: #56addd !important;
  }
}

.blue-nav-btn-above-lists.p-button {
  background-color: #1aa0da;
  text-transform: uppercase;
  color: #ffffff;

  &:enabled:hover {
    background-color: #1aa0da;
    color: #ffffff;
  }
}

.selected-file-display {
  button {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      background-color: #dc3545 !important;
    }

    .p-button-label {
      display: none;
    }
  }
}

.notification-banner {
  .btn-close-black {
    height: 1rem !important;
    width: 1.1rem !important;
    background: #000000 !important;
    border: 1px solid #000000 !important;

    &:hover {
      background: #000000 !important;
      border: 1px solid #000000 !important;
    }

    .pi-times {
      font-size: 0.5rem !important;
    }
  }
}

.confirm-resend-request-data {
  button.btn-cancel-resend {
    background-color: #dddddd !important;
    border-color: #dddddd !important;
    color: #495057 !important;

    &:hover {
      background-color: #dddddd !important;
      border-color: #dddddd !important;
      color: #495057 !important;
    }
  }
}

.p-button {

  &.view-btn-form-review,
  &.edit-btn-form-review {
    color: #134563 !important;
    float: left;
    margin-right: 10px;
    padding: 0 !important;
    border: 0;
    width: 18px !important;
    background-color: #ffffff !important;

    .pi {
      font-size: 18px;
    }

    // &:hover {
    //     color: #01579b;
    // }
  }
}

.document-upload-section {
  .p-button-link {
    padding: 0 !important;
    font-size: small !important;
  }
}

.parsing-section {

  .p-button-success,
  .p-button-danger {
    height: 25px !important;
    cursor: default !important;
  }
}
.step-navigation{
  button.blue-btn {
    background-color: #1ca0da;
    border: none;
    color: #fff;
    padding: 8px 22px;
    border-radius: 6px;
    &:hover{
      background-color: #1ca0da;
    }
  }
}