.date-filter {
  .p-dropdown {
    width: 100%;
    height: 42px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0;

    &.p-highlight {
      background-color: #ffffff !important;

      &:hover {
        background: #e9ecef !important;
      }
    }
  }

  .p-dropdown-label-empty {
    visibility: visible;
  }
}

.filetype-dropdown {
  .p-dropdown {
    width: 100%;
    height: 42px;
  }
}
.filtertype-dropdown {
  .p-dropdown {
    height: 34px;
  }
  .p-dropdown-label {
    align-items: center;
    padding: 5px 10px;
  }
}

.filter-wage-select {
  width: calc(100% / 3);

  .p-dropdown {
    height: 34px;
    width: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;

    &.p-focus,
    &:hover {
      box-shadow: none !important;
      border-color: #ced4da !important;
    }

    .p-dropdown-label.p-placeholder {
      font-size: 12px;
    }

    .p-dropdown-label {
      font-size: 15px;
      text-transform: lowercase;
      line-height: 16px;

      &::first-line {
        text-transform: capitalize;
      }
    }

    .p-dropdown-item {
      text-transform: lowercase;

      &::first-line {
        text-transform: capitalize;
      }
    }
  }
}

.filter-dropdown {
  .p-dropdown-label {
    font-size: 14px !important;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 14px;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #495057;
    background: white;
    font-size: 14px;
  }

  .duties-list {
    .mat-form-field-flex {
      .mat-form-field-infix {
        border-top: 0.4375em solid transparent !important;
      }
    }
  }
}

.processing-dropdown {
  label {
    color: #4d4848;
    font-size: 14px;
  }

  .p-dropdown {
    width: 100%;

    .p-dropdown-items-wrapper {
      .p-dropdown-items {
        max-height: 100px;
      }
    }
  }

  .p-dropdown .p-dropdown-label {
    font-size: 14px;

    .p-placeholder {
      color: #d0c9d6;
      font-size: 14px;
    }
  }
}

.bene-we-dropdown {
  &.p-dropdown {
    border: 1px solid #7d8fa3;
    height: 40px;
    width: 100%;
  }
}

.pdropdown-readonly {
  &.p-dropdown {
    background-color: rgba(0, 0, 0, 0.12);

    * {
      cursor: not-allowed;
    }
  }
}

.h1b-err-dropdown {
  &.p-dropdown {
    height: 40px;
  }
}

.registrations-dropdown {
  &.p-dropdown {
    .p-dropdown-panel {
      width: 250px;
    }
  }
}

.create-beneficiary .p-fluid .p-dropdown .p-dropdown-label {
  font-family: "Source Sans Pro" !important;
  font-size: 17px;
  color: #000;
}

.address-component-country-dropdown.readonly-country {
  .p-dropdown {

    .p-dropdown-label,
    .p-dropdown-trigger {
      cursor: not-allowed;
    }
  }
}

.firm-info-dropdown {
  .p-dropdown.p-disabled {
    background-color: #dedede;
    opacity: 1;
  }
}

.move-to-step-dropdown.disabled {
  cursor: not-allowed !important;

  .p-dropdown.p-disabled {
    cursor: not-allowed !important;
  }
}

.json-viewer-popup {
  .json-viewer-content {
    .json-viewer-content-dropdown {
      &.p-dropdown {
        width: 100%;
      }
    }
  }
}

.edit-beneficiary-profile {
  .p-dropdown {
    &.p-component {
      width: 100% !important;
    }
  }
}

.filter-manage-case-status {
  .p-component {

    &.p-multiselect,
    &.p-dropdown {
      width: 100% !important;
    }
  }
}

.bene-we-dropdown {
  &.currency-dropdown {
    width: 85px !important;
  }

  &.country-code-dropdown {
    width: 120px !important;
  }
}

.has-passport-outer {
  .p-checkbox-label {
    margin-bottom: 0.2rem !important;
  }
}

.dynamic-menu-dropdown {
  &.p-dropdown {
    width: 100%;
    height: 40px;
  }
}

.fb-menu-dropdown {
  &.p-dropdown {
    width: 100%;
    height: 40px;
  }
}

.manage-user-country-dropdown {
  .p-dropdown {
    width: 120px !important;
  }
}

.p-dropdown-w-100 {
  &.p-dropdown {
    width: 100% !important;
  }
}

.info-graph-widget .mat-card-header-text {
  margin: 0 0 5px 0 !important;
}

.info-graph-widget .filter-dropdown .p-dropdown-label,
.visit-task-widget .p-dropdown-label {
  font-size: 10px !important;
  padding: 3px 3px 3px 10px;
  width: auto !important;
}

.info-graph-widget .pi-chevron-down,
.visit-task-widget .pi-chevron-down {
  font-size: 10px;
}

.info-graph-widget .mat-select-value-text,
.visit-task-widget .mat-select-value-text {
  font-size: 10px !important;
}

.info-graph-widget .mat-select-value-text,
.visit-task-widget .mat-select-value-text {
  font-size: 10px !important;
}

.petitioner-add-user {
  .phone-field {
    .p-dropdown {
      width: 100% !important;
    }
  }
}

.fb-dashboard-card-filter-dropdown {
  &.p-dropdown {
    margin: 15px 0 5px;

    &.reference-library-filter {
      width: 130px;
    }

    &.month-filter {
      width: 125px;
    }

    &.year-filter {
      width: 86px;
    }

    &.outcome-filter {
      width: 80px;
    }
  }

  &.p-multiselect {
    margin: 15px 0 5px;

    &.reference-library-filter {
      width: 130px;
    }

    .p-multiselect-label-empty {
      visibility: visible;
    }
  }
}