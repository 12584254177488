.pa-accordian {
  text-align: center;
  .p-accordion-header-link:focus {
    box-shadow: none !important;
  }
  .p-accordion-header-link {
    border: none !important;
    background-color: transparent !important;
    text-align: center;
    margin-bottom: 20px;
    display: block !important;
    .pi-chevron-down:before {
      border: 1px solid;
      border-radius: 19px;
      padding: 5px;
      font-size: 12px;
      font-weight: 700;
    }
    .pi-chevron-right:before {
      border: 1px solid;
      border-radius: 19px;
      padding: 5px;
      font-size: 12px;
      font-weight: 700;
    }
    .p-accordion-header-text {
      text-align: left !important;
      font-size: 16px;
      font-weight: 400;
    }
  }
  .p-accordion-content {
    padding: 0px !important;
    text-align: left;
    border: none !important;
    margin-bottom: 20px;
    .p-tree {
      padding: 1px !important;
      background: #f5f7f7;
      border: none !important;
      .pi-chevron-right:before {
        content: "\e90e";
      }
      .pi-chevron-down:before {
        content: "\e910";
      }
      .p-tree-container {
        .p-treenode {
          .p-treenode-content {
            margin: 0px 5px;
            border-bottom: 1px solid lightgray;
          }
          .p-treenode-children {
            .p-treenode-label {
              font-size: 12px;
            }
            .p-treenode-content {
              border-bottom: none;
              padding: 0px;
              .pi-chevron-right:before {
                content: "\e90e";
              }
            }
          }
        }
      }
    }
  }
}

.faqTopicAccordion {
  .p-accordion {
    .p-accordion-header {
      .p-accordion-header-link {
        background-color: #f8f8f8 !important;
        font-size: 24px;
        color: #707070 !important;
        .p-accordion-toggle-icon {
          display: none;
        }
      }
    }
    .p-accordion-content {
      background-color: #f8f8f8 !important;
    }
  }
}

.subProfileContent{
  .p-accordion .subTabProfile p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
    background: #F2F2F2!important;
  }
  .subTabProfile .p-accordion-header{
    background: #F2F2F2!important;
  }
  .p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link{
    padding: .8em !important;

  }

  .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon{
    display: block!important;
  }

  .subTabProfile .p-accordion-header .p-accordion-header-link .p-accordion-header-text {
    padding-left: 1rem !important;
}

  .p-accordion-header-text{
    text-align: left!important;
    width: 80%
  }
}

.mainProfileContent{
  .personal-details-header .p-accordion-header{
    background: #F2F2F2!important;
  }
  .p-accordion .personal-details-heade:not(.p-disabled) .p-accordion-header-link{
    background: #FFFFFF!important;
  }

  .p-accordion .personal-details-header .p-accordion-header:not(.p-disabled) .p-accordion-header-link{
     border-bottom: 1px solid #FFFFFF!important;
  }
}


.questionAnswerAccordion {
  .p-accordion {
    .p-accordion-header {
      padding-bottom: 10px;

      .p-accordion-header-link {
        background-color: #fff !important;
        font-size: 20px;
        color: #707070 !important;

        .p-accordion-toggle-icon {
          display: none;
        }
      }
    }
  }
}
.fdnsDocAccordion {
  .p-accordion {
    border: 1px solid #dee2e6 !important;
    .p-accordion-tab {
      margin-bottom: 5px !important;
    }
    .p-accordion-header {
      .p-accordion-header-link {
        background: #edf4fb !important;
        .p-accordion-toggle-icon {
          display: none;
        }
      }
    }
    .p-accordion-content {
      border: none !important;
      // background-color: #F8F8F8 !important;
    }
  }
}
.fdnsDocAccordion {
  .p-accordion {
    border: 1px solid #dee2e6 !important;
    .p-accordion-tab {
      margin-bottom: 5px !important;
    }
    .p-accordion-header {
      .p-accordion-header-link {
        background: #edf4fb !important;
        .p-accordion-toggle-icon {
          display: none;
        }
      }
    }
    .p-accordion-content {
      border: none !important;
      // background-color: #F8F8F8 !important;
    }
  }
}

.review-accordions {
  .p-accordion .p-accordion-header .p-accordion-header-link {
    background: none !important;
    color: #495057 !important;
    padding: 10px !important;
  }
  .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    padding-bottom: 15px !important;
    background: none !important;
    color: #495057 !important;
    padding: 10px !important;
  }
  .p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
    border: 1px solid #aaaaaa !important;
    background: none !important;
    color: #495057 !important;
    padding: 10px !important;
  }
  .p-accordion p-accordiontab:last-child .p-accordion-content {
    h6 {
      padding: 10px 10px 0px;
      font-size: 14px;
      font-weight: 400;
    }
    button {
      margin: 15px 0px 6px;
    }
  }
}

.notification-acc {
  .p-accordion-tab {
    //border: 2px solid #EDEDED;
    border: 0;
    padding: 10px 10px 0px;
    margin-bottom: 20px !important;
    .p-accordion-header {
      .p-accordion-header-link {
        align-items: baseline;
      }
      .p-disabled .p-toggleable-content {
        visibility: hidden;
        height: 0;
        overflow: hidden;
      }
    }
    .p-toggleable-content {
      margin-top: 10px;
      .p-accordion-content {
        border: none !important;
        border-top: 1px solid #dee2e6 !important;
        padding-top: 20px !important;
      }
    }
    .p-accordion-toggle-icon {
      color: #76889d;
      font-size: 13px;
    }
    .p-accordion-header-text {
      color: #6f6f6f;
    }
  }
}

.notification-acc {
  padding: 10px 15px;
  .p-accordion .p-accordion-content .p-datatable .p-datatable-tbody > tr > td:last-child {
    padding-left: 10px !important;
  }
}

.role-accordion {
  .p-accordion-tab {
    //border: 2px solid #EDEDED;
    border: 0;
    // padding: 10px 10px 0px;
    margin-bottom: 20px !important;
    .p-accordion-header {
      .p-accordion-header-link {
        align-items: baseline;
      }
      .p-disabled .p-toggleable-content {
        visibility: hidden;
        height: 0;
        overflow: hidden;
      }
    }
    .p-toggleable-content {
      // margin-top: 10px;
      .p-accordion-content {
        border: none !important;
        border-top: 1px solid #dee2e6 !important;
        padding-top: 20px !important;
      }
    }
    .p-accordion-toggle-icon {
      color: #76889d;
      font-size: 13px;
    }
    .p-accordion-header-text {
      color: #6f6f6f;
    }
  }
}

.role-accordion {
  padding: 10px 15px;
  .p-accordion .p-accordion-content .p-datatable .p-datatable-tbody > tr > td:last-child {
    padding-left: 10px !important;
  }
}

.role-accordion {
  .p-accordion p-accordiontab .p-accordion-tab {
    margin-bottom: 0px !important;
  }
  .p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
    background-color: #f7faff !important;
    border: 1px solid #dfe6ef !important;
  }
}
.leave_summary {
  .p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
    background-color: #edf4fb !important;
    border: none !important;
    border-top: 1px solid #ccc;
  }
  .role-accordion .p-accordion-tab .p-toggleable-content .p-accordion-content {
    border-top: 1px solid#ccc !important;
    background-color: #edf4fb !important;
    margin: 0;
    padding: 20px 35px !important;
  }
  .p-accordion-header-text {
    font-size: 16px !important;
  }
  .role-accordion {
    padding-bottom: 0 !important;
  }
}
.cloud-storage-main {
  .set-accordion {
    .p-accordion-header {
      background-color: transparent !important;
    }
    .p-accordion {
      .p-accordion-header-link {
        white-space: nowrap !important;
        flex-direction: row-reverse;
      }
    }
    .btn-link {
      text-transform: capitalize;
      color: #01a0da;
      &:disabled {
        color: #6c757d !important;
      }
    }
  }
}

.multiFeeAccordion {
  .p-accordion {
   // border: 1px solid #dee2e6 !important;
    .p-accordion-content {
      border: none !important;
      margin: 0 !important;
      // background-color: #F8F8F8 !important;
    }
  }
}

.individual-details{
  h6.heading{
    font-size: 1rem !important;
  }
  .add-title-sheet{
    .linkButton {
      .p-button-label{
        font-size: 1rem !important;
      }
    }
  }
  .action-block{
    .p-button-outlined{
      padding: 5px !important;
      font: normal normal bold 12px / 18px "Source Sans Pro" !important;
      &.p-button-icon-only{
        height: 43px !important;
      }
    }
  }
}