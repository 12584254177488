.green-card-pwd-subtask-accordian {
  &.p-panel > .p-panel-header {
    border: 0;
    border-bottom: 1px solid #dee2e6;
    padding: 1rem;
    background: #ffffff;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  &.p-panel > .p-toggleable-content > .p-panel-content {
    border: 0;
  }
}

.subtask-form-panel {
  &.p-panel .p-panel-header {
    border-color: #c3d9e2;
    background-color: #ebfaff;
  }
  &.p-panel .p-panel-content {
    border-color: #c3d9e2;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

.gc-pwd-duty-panel {
  &.p-panel .p-panel-header {
    border-color: #c3d9e2;
    background-color: #ebfaff;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  &.p-panel .p-panel-content {
    border-color: #c3d9e2;
  }
  .p-panel-header-icon {
    height: 1rem !important;
  }
}

.lcaPanel {
  .p-panel {
    .p-panel-header {
      background-color: #e4efff;
      padding: 5px;
      .p-panel-title {
        font-size: 15px;
      }
    }
    .p-panel-content {
      font-size: 14px;
      text-align: justify;
      padding: 10px;
      h6 {
        font-size: 14px;
      }
      p {
        font-size: 13px;
      }
    }
  }
}
