.p-form,
.common-form-styles {
  .p-label {
    font-size: 14px;
    line-height: 20px;
    font-family: "Source Sans Pro";
    color: #4a4a4a;
    &.p-label-small {
      font-size: 12px;
      line-height: 14px;
    }
    .p-sub-label {
      font-size: 12px;
      line-height: 14px;
    }
    &.p-label-blue {
      color: #1798db;
    }
    &.p-label-bold {
      font-weight: 600;
    }
  }

  .p-inputtextarea {
    min-height: 88px !important;
  }

  .p-form-control {
    display: block;
    width: 100%;
    height: 38px;
    margin: 0;
    &.p-input:disabled {
      background-color: #cccccc;
      color: #666666;
    }
    & > .p-component {
      width: 100%;
      height: 38px;
    }
    &.p-dropdown-readonly {
      & > .p-component {
        background-color: #f1f1f1 !important;
        border: 1px solid #c3d0de !important;
        box-shadow: none !important;
      }
    }
    &[readonly] {
      background-color: #f1f1f1 !important;
      border: 1px solid #c3d0de !important;
      box-shadow: none !important;
    }

    &.p-dropdown-contact {
      width: 70px;
      & > .p-component {
        border-radius: 3px 0 0 3px;
        border-right: 0;
        .p-inputtext {
          padding: 0.5rem 0 0.5rem 0.5rem;
        }
      }
    }

    &.p-input-contact {
      width: calc(100% - 70px);
      .p-inputtext {
        height: 38px;
        padding: 0.5rem 0.5rem 0.5rem 0;
        border-radius: 0 3px 3px 0;
        border-left: 0;
      }
    }
  }

  .p-btn {
    &.btn {
      border-radius: 4px !important;
      height: 43px !important;
      font-size: 14px !important;
      font-weight: bold !important;
      cursor: pointer;
      &.btn-primary {
        background-color: #19a0da;
        color: #ffffff;
      }
      &.btn-light {
        background-color: #dedede !important;
        color: #47607d !important;
      }
      &.btn-mark-complete {
        background-color: #ffffff;
        border: 1px solid #19a3dd;
        &:disabled {
          cursor: default;
          border: 1px solid #a2a2a2;
          .btn-text {
            color: #a2a2a2;
            border-right: 1px solid #707070;
          }
          .btn-info {
            background-color: #a2a2a2;
          }
        }
        .btn-text {
          color: #19a3dd;
          border-right: 1px solid #19a3dd;
          line-height: 20px;
          padding: 0 10px;
          margin-right: 10px;
          float: left;
        }
        .btn-info {
          background-color: #19a3dd;
          color: #ffffff;
          float: left;
          width: 14px;
          height: 14px;
          font-size: 14px;
          line-height: 14px;
          text-align: center;
          border-radius: 50%;
          margin: 3px 0;
        }
      }
    }
    &:disabled {
      cursor: not-allowed !important;
    }
  }

  .p-checkbox {
    width: max-content;
    .p-checkbox-checked + .p-checkbox-label {
      font-weight: bold;
    }
    .p-checkbox-label {
      font-size: 14px;
      line-height: 20px;
      font-family: "Source Sans Pro";
      color: #4a4a4a;
      margin-bottom: 0;
    }
  }

  .p-form-datepicker {
    &.p-calendar {
      width: 100%;
      .p-inputtext {
        height: 38px;
      }
      .p-datepicker {
        left: auto !important;
        right: 0px !important;
        .p-datepicker-header .p-datepicker-title select {
          border-radius: 3px;
          padding: 2px;
        }
      }
    }
  }
}

.p-btn-outside-form {
  border-radius: 4px !important;
  height: 43px;
  font-size: 14px !important;
  font-weight: bold !important;
  cursor: pointer;
}
