.termsButton {
  color: #19a0da !important;
  border: 0 !important;
  background: transparent;
  outline: 0;
  font: normal normal 600 12px/18px Source Sans Pro;
  text-align: left;
  width: fit-content;
  &:hover,
  &:focus {
    .mat-button-focus-overlay {
      opacity: 0;
    }
  }
}
.update-subtask-details-form{
  mat-slide-toggle.mat-checked{
    .mat-slide-toggle-bar{
      &::after{
        content: none !important;
      }
    }
  }
  .mat-slide-toggle{
    &:not(.mat-checked){
      .mat-slide-toggle-bar{
        &::after{
          content: none !important;
        }
      }
    }
  }
}
