.filter-wage-text {
  .p-inputnumber-input {
    height: 34px;
    width: 100%;
    float: left;
    &:focus,
    &:hover {
      box-shadow: none !important;
      border-color: #ced4da !important;
    }
  }
  &.wage-from {
    .p-inputnumber-input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
  }
  &.wage-to {
    .p-inputnumber-input {
      border-radius: 0;
      border-right: 0;
      border-left: 0;
    }
  }
}
