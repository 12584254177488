.main-timeline {
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
  position: relative;
}

.main-timeline:before {
  content: "";
  width: 6px;
  height: 100%;
  background: #4F6783;
  border-radius: 25px;
  /*border: 1px solid #505050;*/
  position: absolute;
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.main-timeline .timeline {
  padding: 0 0 0 110px;
  float: left;
  margin-left: 50%;
  max-width: 50%;
  position: relative;
  min-width: 40%;
}

.main-timeline .timeline:before,
.main-timeline .timeline:after {
  content: '';
  display: block;
  position: absolute;
}

.main-timeline .timeline:before {
  background-color: #FFF;
  height: 25px;
  width: 25px;
  border: 2px solid #fff;
  box-shadow: 0 0 1px 4px #189CD5;
  border-radius: 50%;
  transform: translateY(-50%);
  left: -13px;
  top: 50%;
  content: '\f00c';
  font-family: FontAwesome;
  text-align: center;
}

.main-timeline.app-project-timeline .timeline:before,
app-education-timeline .main-timeline .timeline:before,
app-training-timeline .main-timeline .timeline:before,
app-beneficiary-license .main-timeline .timeline:before,
app-immigration-timeline .main-timeline .timeline:before {
  left: -8px;
}

.main-timeline.app-project-timeline .timeline-content:before {
  left: -99px;
}

.main-timeline .timeline-icon {
  width: 16px;
  height: 95%;
  background: #029bbd;
  margin: auto;
  position: absolute;
  top: 5%;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.main-timeline .timeline-icon:before,
.main-timeline .timeline-icon:after {
  content: "";
  border-bottom: 11px solid #029bbd;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  top: -11px;
  left: 0;
}

.main-timeline .timeline-icon:after {
  border-bottom-color: #fff;
  top: auto;
  bottom: 0;
}

// .main-timeline .timeline:after{
//     background-color: #77A9DA;
//     height: 100%;
//     width: 22px;
//     border: 3px solid #4F7BC8;
//     border-radius: 0 5px 5px 0;
//     right: 0;
//     top: 0;
// }
.main-timeline .timeline-content {
  color: #909090;
  background-color: #fff;
  min-height: auto;
  padding: 11px 10px 7px 10px;
  border-radius: 50px;
  display: block;
  position: relative;
  border: 2px solid #189CD5;
  bottom: 13px;
  margin-top: 27px
}

.main-timeline .timeline-content:hover {
  text-decoration: none;
}

.main-timeline .timeline-content:before {
  content: '';
  transform: translateY(-50%);
  // border: 11px solid transparent;
  border-top: 3px dotted #189CD5;
  //border-right: 20px solid #4F6783;
  //border-left:0px;
  position: absolute;
  width: 95px;
  left: -99px;
  //left: -30px;
  top: 50%;
}

.main-timeline .timeline-duration {
  color: #4F6783;
  font-size: 13px;
  font-weight: 600;
  /* text-align: center; */
  line-height: 20px;
  padding-bottom: 8px;
  width: fit-content;
}

.main-timeline .timeline-year span {
  background-color: #77A9DA;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  border: 2px solid #fff;
  border-radius: 50%;
  display: block;
}

.main-timeline .title {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1;
  // text-transform: uppercase;
  margin: 0 0 10px 0;
}

.main-timeline .title i.fa {
  font-size: 30px;
}

.main-timeline .description {
  font-size: 12px;
  letter-spacing: 1px;
  margin: 0;
  overflow-wrap: break-word;
}

.main-timeline .title {
  min-height: 15px;
}

.main-timeline .description {
  min-height: 22px;
}

.main-timeline .timeline:nth-child(even) {
  padding: 0 110px 0 0;
  margin: 0 5px 0 0;
  float: right;
  margin-right: 50%;
}

.main-timeline .timeline:nth-child(even):before {
  left: auto;
  right: -13px;
}

.main-timeline .timeline:nth-child(even):after {
  border-radius: 5px 0 0 5px;
  right: auto;
  left: 0;
}

.main-timeline .timeline:nth-child(even) .timeline-content {
  border-radius: 58px;
  padding: 11px 10px 7px 10px;
  margin-top: 27px;
  ;
}

.main-timeline .timeline:nth-child(even) .timeline-content:before {
  left: auto;
  //right: -30px;
  right: -95px;
  border-top: 3px dotted #189CD5;
  // border: 11px solid transparent;
  // border-left: 20px solid #4F6783;
  // border-right:0px;
}

.main-timeline .timeline:nth-child(even) .timeline-year {
  left: auto;
  right: 0;
}

.main-timeline .timeline:first-child .timeline-icon {
  background: #9e489f;
}

.main-timeline .timeline:first-child .timeline-icon:before {
  border-bottom-color: #9e489f;
}

.main-timeline .timeline:nth-child(5n+2):before {
  background-color: #FFF;
}

.main-timeline .timeline:nth-child(5n+2):after {
  background-color: #77A9DA;
  border-color: #4F7BC8;
}

.main-timeline .timeline:nth-child(5n+2) .timeline-year span {
  background-color: #77A9DA;
}

.main-timeline .timeline:nth-child(5n+2) .timeline-year {
  background-color: #77A9DA;
}

.main-timeline .timeline:nth-child(5n+2) .timeline-content:before {
  border-left-color: #4F6783;
}

.main-timeline .timeline:nth-child(5n+3):before {
  background-color: #FFF;
}

.main-timeline .timeline:nth-child(5n+3):after {
  background-color: #77A9DA;
  border-color: #4F7BC8;
}

.main-timeline .timeline:nth-child(5n+3) .timeline-year span {
  background-color: #77A9DA;
}

.main-timeline .timeline:nth-child(5n+3) .timeline-year {
  background-color: #77A9DA;
}

.main-timeline .timeline:nth-child(5n+3) .timeline-content:before {
  border-right-color: #4F6783;
}

.main-timeline .timeline:nth-child(5n+4):before {
  background-color: #FFF;
}

.main-timeline .timeline:nth-child(5n+4):after {
  background-color: #77A9DA;
  border-color: #4F7BC8;
}

.main-timeline .timeline:nth-child(5n+4) .timeline-year span {
  background-color: #77A9DA;
}

.main-timeline .timeline:nth-child(5n+4) .timeline-year {
  background-color: #77A9DA;
}

.main-timeline .timeline:nth-child(5n+4) .timeline-content:before {
  border-left-color: #4F6783;
}

.main-timeline .timeline:nth-child(5n+5):before {
  background-color: #FFF;
}

.main-timeline .timeline:nth-child(5n+5):after {
  background-color: #77A9DA;
  border-color: #4F7BC8;
}

.main-timeline .timeline:nth-child(5n+5) .timeline-year span {
  background-color: #77A9DA;
}

.main-timeline .timeline:nth-child(5n+5) .timeline-year {
  background-color: #77A9DA;
}

.main-timeline .timeline:nth-child(5n+5) .timeline-content:before {
  border-right-color: #4F6783;
}

@media only screen and (max-width:990px) {
  .main-timeline .title {
    font-size: 20px;
  }

  .main-timeline .title i.fa {
    font-size: 25px;
    margin-top: 5px;
  }

  .main-timeline .timeline-year {
    font-size: 35px;
    width: 120px;
    height: 120px;
  }

  .main-timeline .timeline-year span {
    line-height: 100px;
  }

  .main-timeline .timeline-content {
    padding: 25px 20px 25px 130px;
  }

  .main-timeline .timeline:nth-child(even) .timeline-content {
    padding: 25px 130px 25px 20px;
  }
}

@media only screen and (max-width:767px) {
  .main-timeline:before {
    left: 13px;
  }

  .main-timeline .timeline,
  .main-timeline .timeline:nth-child(even) {
    width: 100%;
    padding: 0 20px 0 60px;
    margin: 0 0 30px 0;
    float: right;
  }

  .main-timeline .timeline:before,
  .main-timeline .timeline:nth-child(even):before {
    left: 0;
  }

  .main-timeline .timeline:nth-child(even):after {
    border-radius: 0 5px 5px 0;
    right: 0;
    left: auto;
  }

  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content {
    padding: 80px 25px 25px 25px;
    border-radius: 15px 0 0 15px;
  }

  .main-timeline .timeline-year,
  .main-timeline .timeline:nth-child(even) .timeline-year {
    height: 70px;
    width: 150px;
    border-radius: 20px;
    transform: translateY(0);
    top: 0;
    left: 0;
    right: auto;
  }

  .main-timeline .timeline-year span {
    line-height: 50px;
    border-radius: 20px;
  }

  .main-timeline .timeline:nth-child(even) .timeline-content:before {
    border: 11px solid transparent;
    border-right: 20px solid #4F6783;
    left: -40px;
    right: auto;
  }
}

@media only screen and (max-width:479px) {
  .main-timeline .title {
    font-size: 16px;
  }

  .main-timeline .timeline-content {
    padding: 80px 20px 25px 25px;
  }

  .main-timeline .description {
    text-align: justify;
  }

  .main-timeline .timeline:nth-child(even) .timeline-content {
    padding: 80px 20px 25px 25px;
  }
}

.timeline-custom-icon {
  position: relative;
  left: -20px;
  top: 10px;
}

.ed-duration {
  position: relative;
  left: 40%;
  bottom: 4px;
  font-size: 14px;
}


/*sachin css for timeline*/
.triangle-box {
  z-index: 999;
  width: 25px;
  margin: 0 auto;
}

.rounded-area {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3px solid #4F6783;
  background: #fff;
  margin: 0 auto;
  position: relative;
  top: -1px;
}

.rounded-area-inner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #4F6783;
  margin: 2px auto 0;
}

.arrow-icon {
  background-image: url(../images/arrow-icon.svg);
  width: 25px;
  height: 22px;
}

/*.main-timeline .timeline-content{min-height: auto;}*/
.main-timeline .title {
  margin-bottom: 3px;
}

/*.main-timeline .timeline-content{padding:11px 10px 7px 10px; margin-top: 27px;}
.main-timeline:before{top: 1em;}*/
.main-timeline .timeline:nth-child(1) {
  margin-top: 30px;
}



.main-timeline .timeline:before {
  content: '';
  height: 15px;
  width: 15px;
}

.main-timeline .timeline:nth-child(even):before {
  right: -7px;
}

.main-timeline .timeline:nth-child(even) .timeline-content:before {
  right: -99px;
}

.main-timeline.app-project-timeline .title,
.main-timeline.app-project-timeline .description,
app-education-timeline .main-timeline .title,
app-education-timeline .main-timeline .description,
app-training-timeline .main-timeline .title,
app-training-timeline .main-timeline .description,
app-beneficiary-license .main-timeline .title,
app-beneficiary-license .main-timeline .description,
app-immigration-timeline .main-timeline .title,
app-immigration-timeline .main-timeline .description {
  font-size: 15px;
  color: #000;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.app-project-timeline .timeline:nth-child(even) .timeline-duration.ed-duration,
app-education-timeline .timeline:nth-child(even) .timeline-duration.ed-duration,
app-training-timeline .timeline:nth-child(even) .timeline-duration.ed-duration,
app-beneficiary-license .timeline:nth-child(even) .timeline-duration.ed-duration,
app-immigration-timeline .timeline:nth-child(even) .timeline-duration.ed-duration {
  left: 220px;
  top: 8px;
}

.app-project-timeline .timeline-duration.ed-duration,
app-education-timeline .timeline-duration.ed-duration,
app-training-timeline .timeline-duration.ed-duration,
app-beneficiary-license .timeline-duration.ed-duration,
app-immigration-timeline .timeline-duration.ed-duration {
  left: -92px;
  top: 7px;
}

app-profile-landing .btn-group .childBtn {
  min-width: 116px;
}

.timeline-duration {
  span.d-block{
    width: max-content;
  }
}