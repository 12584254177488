.updt-emp-rb {
  .p-radiobutton-label {
    margin-bottom: 0;
    font-weight: 600;
    cursor: pointer;
  }
}

.letter-radio {
  font-size: 15px;
  padding-right: 20px;
  font-weight: 500;
  color: #4d4f5c;

  .p-radiobutton .p-radiobutton-box {
    border-color: #158fc4;
  }
}

.re-isnewspaper-radio-holder {
  margin-top: 3px;
  margin-right: 5px;
  float: right;

  .p-radiobutton {
    width: 14px;
    height: 14px;

    .p-radiobutton-box {
      width: 14px;
      height: 14px;
    }
  }

  .p-radiobutton-label {
    line-height: 14px;
    font-size: 14px;
    margin-left: 2px;
  }
}

.create-task-radio {
  align-items: start;

  .p-radiobutton {
    margin-top: 1px;
  }

  .p-radiobutton-label {
    font-size: 14px;
    line-height: 18px;
  }
}

.profileRadio {
  .p-radiobutton-label {
    font-size: 13px !important;
    color: grey !important;
  }
}

.disabled {
  .p-radiobutton {
    cursor: not-allowed !important;
  }
}

.p-radiobutton.p-radiobutton-disabled {
  cursor: not-allowed !important;
}

.p-radiobutton-label.p-disabled {
  cursor: not-allowed !important;
}

.form-value-radiobutton {
  &+.p-radiobutton-label {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #4a4a4a;
  }
}

.i130-radio-button {
  align-items: start;
}

.fd-radio-custom-style {
  .p-radiobutton-box {
    border: 2px solid #10A0DA !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -4px;

  }

}

.fd-vert-radio-custom-style {
  .p-radiobutton-box {
    border: 2px solid #10A0DA !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

}

.img-radio-button-label-container {
  float: left !important;

  p-radiobutton,
  p-checkbox {
    float: left !important;
  }

  .common-bene-class-for-translation.img-radio-button-label {
    margin-left: 2px !important;
    margin-right: 5px !important;
    line-height: 20px !important;
    float: left !important;
  }
}