.content{
    padding:35px;
}

.content-container{
    padding:30px;
    background: $white;
}

.task-header{
    
}

.divCenter{
    margin-left: auto;
    margin-right: auto;
}