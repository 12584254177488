$spacer:1rem;

.pl-0{padding-left:0px!important;}
.pr-0{padding-right: 0px!important;}

.pb-0{padding-bottom:0px!important;}

.mt-0{margin-top:0px!important;}
.mb-0{margin-bottom:0px!important;}
.mr-0{margin-right:0px!important;}
.ml-0{margin-left:0px!important;}

.mr-13{
    margin-right:13px;
}

.my-0{
    @extend .mt-0;
    @extend .mb-0;
}

.ml-n4r{
  margin-left: -4rem !important;
}

.border-bottom-0{border-bottom:0px!important};

.border-0{border:0px!important}

.border-radius-0{border-radius:0px !important}

.w-max-content{width:max-content !important;}
