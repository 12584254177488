.task-page-tab-state {
  .mat-tab-header {
    padding: 10px 10px 16px 10px;
    @extend .border-bottom-0;
    background: $white;
  }

  a {
    &.mat-tab-link {
      background: $tab-inactive 0% 0% no-repeat padding-box;
      @extend .border-radius-0;
      @extend .mr-0;
      text-align: left;
      @include im-font(22px, 32px, bold);
      letter-spacing: 0px;
      color: rgba(51, 50, 70, 0.5);
      border-bottom: 1px solid $border-light-gray;
      @extend .border-0;
      height: 64px;
      margin: 0 5px 0 5px;
      justify-content: left;

      &:hover {
        text-decoration: none;
      }

      &.mat-tab-label-active {
        background: $tab-active 0% 0% no-repeat padding-box;
        border-bottom: 5px solid $active-tab-border !important;
        color: $gray-darker;
        box-shadow: 1px 0px 5px $active-tab-border;
      }
    }
  }
}

.mat-tab-list {
  @extend .my-0;
}

.mat-tab-nav-bar {
  @extend .border-bottom-0;
}

.mat-tab-link-container {
  display: inline-block !important;
  flex-grow: revert !important;
}

.builder-tab {
  @extend .border-0;

  .mat-tab-link-container {
    padding: 0 30px;
    background: $white;

    .mat-tab-links {
      height: 80px;
      border: 1px solid $border-light-gray;
      background: $tab-gray-default;
    }

    .mat-tab-link {
      opacity: 1;
      background: $tab-gray-default;
      border-right: 1px solid $border-light-gray;
      @include im-font(16px, 22px, 600);
      letter-spacing: 0px;
      color: $gray-dark;
      min-width: 130px;
      padding-bottom: 10px;
      padding-top: 10px;

      &.active-tab {
        border-bottom: 5px solid $active-tab-border;
      }
    }
  }

  &.gray-tab {
    .mat-tab-link-container {
      padding: 0;

      .mat-tab-links {
        height: auto;
        padding: 0 10px;
        border: 0;
      }

      .mat-tab-link {
        border-right: 0;

        &.active-tab {
          font-weight: 700;
          text-decoration: none;
        }
      }
    }
  }
}

.summaryTask-tab {
  .p-tabview {
    text-align: center;
    border: 0;

    hr {
      border-width: 4px !important;
    }

    .p-tabview-nav {
      display: inline-flex;
      border: 0;

      li {
        width: auto;
      }

      li.p-highlight .p-tabview-nav-link {
        label {
          color: #2196f3;
          border-bottom: 3px solid #2196f3;
          padding: 10px;
        }
      }

      .p-tabview-nav-link {
        background: none !important;
        width: 170px;
        display: block;
        border: 0 !important;
        margin: 0;
        padding: 20px 0 !important;
        padding-right: 0;

        &:focus {
          box-shadow: none !important;
        }

        .header-icon {
          margin-left: auto;
          margin-right: auto;
          height: 90px;
          border-width: 4px !important;
          width: 90px;
          background: none !important;
          img {
            max-height: 50px;
            height: 100%;
            vertical-align: middle;
            transform: translate(-50%, -50%);
            position: relative;
            top: 50%;
            left: 50%;
            display: block;
          }
        }

        label {
          padding: 10px;
          border-width: 3px;
          margin: 0;
          color: #687c93;

          span.pi {
            pointer-events: auto !important;
            position: relative;
            top: 2px;
            margin-right: 5px;
          }
        }
      }
    }

    .p-tabview-panels {
      border-top: 1px solid rgb(112 112 112 / 46%) !important;
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 1px;

      .p-tabview-panel {
        text-align: left;

        .p-button-outlined {
          padding: 0 29px !important;
          font-size: 16px !important;
          min-height: auto !important;
          height: 43px;
          font-weight: normal !important;
          line-height: 20px !important;
          text-transform: none;
        }
      }
    }
  }
}

.summaryTimeline {
  .p-timeline {
    margin-left: 38px;
    margin-top: -3px;
  }

  .p-timeline-left .p-timeline-event-opposite {
    display: none;
  }

  .p-timeline-event {
    min-height: 0;
  }

  .p-timeline-event-marker {
    border: 1px solid #e1e1e1 !important;
    border-radius: 0 !important;
    width: 1px !important;
    height: 1rem !important;
  }
}

.vd-summaryTask-tab {
  .p-tabview {
    text-align: center;
    border: 0;

    .p-tabview-nav {
      display: flex;
      flex-wrap: nowrap;
      border: 0;
      margin: 0 30px;
      li {
        flex-grow: 1;
        flex-basis: 0;
      }
      li.p-highlight .p-tabview-nav-link {
        background-color: #2196f3;
        label {
          color: #2196f3;
          border: 0;
        }
      }
      .p-tabview-nav-link {
        background: none !important;
        width: 100%;
        display: block;
        border: 0 !important;
        margin: 0;
        padding: 10px 0 !important;
        &:focus {
          box-shadow: none !important;
        }
        .icon-holder {
          position: relative;
          margin-right: -10px;
          margin-left: -10px;
          .zero-index-line {
            width: 50%;
            position: absolute;
            top: 50%;
            right: 0;
            margin: 0;
            z-index: 0;
          }
          .non-zero-index-line {
            width: 100%;
            position: absolute;
            top: 50%;
            right: 0;
            margin: 0;
            z-index: 0;
          }
          .final-index-line {
            width: 50%;
            position: absolute;
            top: 50%;
            left: 0;
            margin: 0;
            z-index: 0;
          }
          .header-icon {
            margin-left: auto;
            margin-right: auto;
            border-width: 2px !important;
            padding: 0 !important;
            height: 43px;
            width: 43px;
            display: block;
            line-height: 43px;
            background-color: #fff;
            z-index: 2;
            position: relative;
            img {
              margin: 6px auto 7px;
              height: 25px;
              position: relative;
              display: block;
            }
          }
          label {
            padding: 5px;
            border-width: 3px;
            margin: 0;
            color: #687c93;
            span.pi {
              pointer-events: auto !important;
              position: relative;
              top: 2px;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .p-tabview-panels {
      border-top: 1px solid rgb(112 112 112 / 46%) !important;
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 1px;
      .p-tabview-panel {
        text-align: left;
        .p-button-outlined {
          padding: 0 29px !important;
          font-size: 16px !important;
          min-height: auto !important;
          height: 43px;
          font-weight: normal !important;
          line-height: 20px !important;
          text-transform: none;
        }
      }
    }
  }
}
#profileContainer .mainTabStyle {
  border: 0px;

  //   &.p-tabview{
  //     .mainTabFlexContainer{
  //       display: flex;
  //       flex-direction: row;
  //       justify-content: flex-start;
  //       .addDependentBtn{
  //         align-self: flex-end;
  //       }
  //   }
  // }
  &.p-tabview .p-tabview-nav {
    padding: 0px !important;
    border: 0px;

    li {
      width: calc(20% - 10px) !important;
      margin: 0 5px;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }

      a.p-tabview-nav-link {
        border: 0px !important;
      }

      &.p-highlight {
        a.p-tabview-nav-link {
          border-bottom: 3px solid #19a0da !important;
        }
      }
    }
  }
}

#profileContainer .mainTabStyle.p-tabview > .p-tabview-nav li.p-highlight:after,
#profileContainer .subTabStyle.p-tabview > .p-tabview-nav li.p-highlight:after {
  position: absolute;
  margin-left: 9%;
  border-top: 7px solid #19a0da;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  color: transparent;
}

#profileContainer .family .mainTabStyle.p-tabview > .p-tabview-nav li.p-highlight:after {
  // margin-left: 23% !important;
  top: 61px;
}

#profileContainer .subTabStyle.p-tabview > .p-tabview-nav li.p-highlight:after {
  margin-left: 50%;
  left: -7px;
  top: 100%;
}

#profileContainer .subTabStyle.p-tabview .p-tabview-nav {
  border: 0px solid gray !important;
  display: block;
  background-color: white !important;

  li {
    float: left;
    width: auto !important;
  }
}

#profileContainer .subTabStyle.p-tabview > .p-tabview-nav li {
  margin-left: 0% !important;
  margin-right: 0% !important;

  &.p-highlight {
    a.p-tabview-nav-link {
      border-bottom: 0 !important;
      background-color: #19a0da !important;
      color: #ffffff !important;
    }
  }
}

#profileContainer .subTabStyle.p-tabview .p-tabview-panels {
  // display: none !important;
}

#profileContainer .subTabStyle.p-tabview {
  // width: fit-content;
  margin-top: 0px !important;
  margin-bottom: 2rem !important;
  border: 0;
}

#profileContainer .subTabStyle.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: #6c757d !important;
  font-size: 14px;
  border: 1px solid #e3dfdf !important;
  padding: 18px 25px !important;
  border-right: none !important;
}

#profileContainer .subTabStyle.p-tabview > ul > li:first-child > a {
  border-radius: 25px 0 0 25px !important;
}

#profileContainer .subTabStyle.p-tabview .p-tabview-nav li.p-highlight {
  border-bottom: 0px !important;
  width: auto !important;
  position: relative;
}

.task-access-tab {
  // padding: 20px;

  .mat-tab-group {
    flex-direction: row;
  }

  .mat-tab-header {
    border-bottom: none;
  }

  .mat-tab-header-pagination {
    display: none !important;
  }

  .mat-tab-labels {
    flex-direction: column;

    .mat-tab-label {
      border: 1px solid #e1e1e1;
      background-color: #f5f7f7 !important;
      color: #000000 !important;
      font-weight: 600 !important;
    }

    .mat-tab-label-content {
      padding: 0px 40px;
    }

    .mat-tab-label-active {
      color: #2196f3 !important;
      cursor: pointer !important;
      font-weight: 600 !important;
    }
  }

  .mat-ink-bar {
    background-color: unset !important;
  }

  .mat-ink-bar {
    height: 100%;
    left: 98% !important;
  }

  .mat-tab-body-wrapper {
    flex: 1 1 auto;
  }
}

.emailNotification-tab {
  .p-tabview {
    text-align: left;
    border: 0;

    hr {
      border-width: 4px !important;
    }

    .p-tabview-nav {
      // display: inline-flex;
      background: #f5f7f7 !important;
      border: 1px solid #f5f7f7 !important;
      border: 0;

      li {
        width: auto;
        cursor: pointer;
        &:hover{
          background-color: #f6f9fd;
        }
      }

      li.p-highlight {
        background-color: #f6f9fd;
        .p-tabview-nav-link {
          label {
            color: #2196f3;
            border-bottom: 3px solid #2196f3;
            padding: 10px;
          }
        }
      }

      .p-tabview-nav-link {
        background: none !important;
        display: block;
        border: 0 !important;
        margin: 0;
        padding: 0 !important;
        text-align: center;
        border-right: 1px solid #bebebe !important;

        &:focus {
          box-shadow: none !important;
        }

        label {
          padding: 18px 40px !important;
          border-width: 3px;
          margin: 0;
          color: #687c93;

          span.pi {
            pointer-events: auto !important;
            position: relative;
            top: 2px;
            margin-right: 5px;
          }
        }
      }
    }

    .p-tabview-panels {
      // border-top: 1px solid rgb(112 112 112 / 46%) !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-top: 30px !important;
      margin-top: 1px;

      .p-tabview-panel {
        text-align: left;

        .p-button-outlined {
          padding: 0 29px !important;
          font-size: 16px !important;
          min-height: auto !important;
          height: 43px;
          font-weight: normal !important;
          line-height: 20px !important;
          text-transform: none;
        }
      }
    }
  }
}

.taskAllocation-tab {
  .p-tabview {
    text-align: center;
    border: 0;
    hr {
      border-width: 4px !important;
    }
    .p-tabview-nav {
      display: inline-flex;
      border: 0;
      li {
        width: auto;
      }
      li.p-highlight .p-tabview-nav-link {
        label {
          color: #2196f3;
          border-bottom: 3px solid #2196f3;
          padding: 10px;
        }
      }
      li.p-highlight .p-tabview-nav-link .row {
        span {
          background-color: #2196f3;
          color: #ffffff;
        }
      }
      .p-tabview-nav-link {
        background: none !important;
        width: 170px;
        display: block;
        border: 0 !important;
        margin: 0;
        padding: 20px 0 !important;
        padding-right: 0;
        &:focus {
          box-shadow: none !important;
        }
        .header-icon {
          margin-left: auto;
          margin-right: auto;
          height: 60px;
          border-width: 4px !important;
          width: 60px;
          img {
            max-height: 50px;
            height: 100%;
            vertical-align: middle;
            transform: translate(-50%, -50%);
            position: relative;
            top: 50%;
            left: 50%;
            display: block;
          }
        }
        label {
          padding: 10px;
          border-width: 3px;
          margin: 0;
          color: #687c93;
          span.pi {
            pointer-events: auto !important;
            position: relative;
            top: 2px;
            margin-right: 5px;
          }
        }
      }
    }
    .p-tabview-panels {
      border-top: 1px solid rgb(112 112 112 / 46%) !important;
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 1px;
      .p-tabview-panel {
        text-align: left;
        .p-button-outlined {
          padding: 0 29px !important;
          font-size: 16px !important;
          min-height: auto !important;
          height: 43px;
          font-weight: normal !important;
          line-height: 20px !important;
          text-transform: none;
        }
      }
    }
  }
}

.ques-tabs {
  .p-tabview .p-tabview-nav li{
    width:335px!important;
  }
}

.attorneyInterfaceTab {
  .p-tabview {
    text-align: center;
    border: 0;

    hr {
      border-width: 4px !important;
    }

    .p-tabview-nav {
      display: inline-flex;
      border: 0;

      li {
        width: auto;
      }

      li.p-highlight .p-tabview-nav-link {
        label {
          color: #2196f3;
          border-bottom: 3px solid #2196f3;
          padding: 5px;
        }
      }

      .p-tabview-nav-link {
        background: none !important;
        width: 170px;
        display: block;
        border: 0 !important;
        margin: 0;
        padding: 20px 0 !important;
        padding-right: 0;

        &:focus {
          box-shadow: none !important;
        }

        .header-icon {
          margin-left: auto;
          margin-right: auto;
          height: 70px;
          border-width: 4px !important;
          width: 70px;
          background: none !important;
          img {
            max-height: 50px;
            height: 100%;
            vertical-align: middle;
            transform: translate(-50%, -50%);
            position: relative;
            top: 50%;
            left: 50%;
            display: block;
          }
        }

        label {
          padding: 10px;
          border-width: 3px;
          margin: 0;
          color: #687c93;

          span.pi {
            pointer-events: auto !important;
            position: relative;
            top: 2px;
            margin-right: 5px;
          }
        }
      }
    }

    .p-tabview-panels {
      border-top: 1px solid rgb(112 112 112 / 46%) !important;
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 1px;

      .p-tabview-panel {
        text-align: left;

        .p-button-outlined {
          padding: 0 29px !important;
          font-size: 16px !important;
          min-height: auto !important;
          height: 43px;
          font-weight: normal !important;
          line-height: 20px !important;
          text-transform: none;
        }
      }
    }
  }
}
.attorneyInterfaceTab {
  .p-tabview{
      .p-tabview-nav {
          li.p-highlight{
                  .header-icon{
                      &.active {
                          // background-color: #01A0DA;
                          border-color: #01A0DA!important;
                      }
                  
              }
          } 
      }
      .p-tabview-panels{
          border-top:1px dashed rgba(112, 112, 112, 0.46) !important;
      }
  } 
}
.visit-task-widget{
  .mat-tab-label {
      background-color: #d2d2d2;
      min-width: 49% !important;
      margin-right: 8px;
  }
  .mat-tab-label:last-child {
      margin-right: 0;
  }
  .mat-ink-bar {
      height: 4px !important;
  }
  .mat-table .mat-header-row {
      height: 45px;
  }
}