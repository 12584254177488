.timeline {
  .p-timeline-event-content {
    padding-left: 0px !important;
  }
  .p-timeline-event-opposite {
    padding-right: 0px !important;
  }
}
.green-card-pwd-timeline {
  .p-timeline.p-timeline-vertical .p-timeline-event-opposite,
  .p-timeline.p-timeline-vertical .p-timeline-event-content {
    padding: 0;
  }
}
