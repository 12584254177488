$autocompleteTxtFontSize: 0.8rem;
.autocomplete-heading-text{
    font-size: $autocompleteTxtFontSize;
    font-weight: 600;
}
.autocomplete-value-text{
    font-size: $autocompleteTxtFontSize;
    max-width: 95%;
    display: inline-block;
    overflow-x: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}
.p-autocomplete-item{
    padding: 0.5rem 0 0.5rem 0 !important;
}
.p-autocomplete-dd{
    width: 100% !important;
}
.petition-type-autocomplete{
    .p-autocomplete-panel{
        min-width: 800px !important;
    }
}
// .client-company-autocomplete{
//     .p-autocomplete-panel{
//         min-width: 500px !important;
//     }
// }
.p-autocomplete-dd {
    .p-autocomplete-input, .p-autocomplete-multiple-container{
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;   
    }
}
.attorney-autocomplete{
    .p-autocomplete{
        width: 100% !important;
        .p-autocomplete-item{
            padding-right: 0.5rem !important;
            padding-left: 0.5rem !important;
        }
    }
    &.platform-admin-ac{
        .p-autocomplete-loader{
            display: none;
        }
    }
}