table.reports-table {
  border-collapse: separate !important;
  border-spacing: 0;
  font-size: 14px;

  .p-datatable-thead {
    background-color: #f8fbff;
  }
}

.profile-table {
  .p-datatable-wrapper {
    border-bottom: 1px solid #e9ecef;
  }

  th {
    font-size: 12px;
    text-transform: initial;
    color: #a2a2a2;
  }
}

.pa-table {
  .p-datatable-wrapper {
    border-bottom: 1px solid #dee2e6;
  }
}

.i9-table {
  .p-datatable .p-datatable-tbody>tr>td {
    padding: 0px 10px !important;
  }

.i9-table .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 48%;
    height: 1.357rem;
    padding: 4px!important;
}
.p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.357rem;
}
.p-button.p-button-icon-only {
    width:1.357rem;
    padding: 0.5rem 0;
}

  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 2.357rem;
  }
}

.greencard-pwd-table {
  &>.p-datatable-thead>tr>th {
    background-color: #f2f2f2 !important;
    border: 0 !important;
    color: #4a4a4a !important;
    font-weight: 300 !important;
    font-size: 14px;
    line-height: 20px;
    height: 46px;
    padding: 13px 1rem !important;
  }

  &>.p-datatable-tbody>tr {
    &>td {
      padding: 13px 1rem !important;
      border-bottom: 1px solid #afafaf !important;
      font-size: 16px;
      color: #4a4a4a;
      line-height: 22px;
    }

    &:last-child>td {
      border-bottom: 0 !important;
    }
  }
}

.visa-table {
  &>.p-datatable-thead>tr>th {
    background-color: #f2f2f2 !important;
    border: 0 !important;
    color: #4a4a4a !important;
    font-weight: 300 !important;
    font-size: 14px;
    line-height: 20px;
    height: 46px;
    padding: 13px 1rem !important;
    white-space: nowrap;
  }

  &>.p-datatable-tbody>tr {
    &>td {
      padding: 13px 1rem !important;
      border-bottom: 1px solid #afafaf !important;
      font-size: 16px;
      color: #4a4a4a;
      line-height: 22px;
    }

    &:last-child>td {
      border-bottom: 0 !important;
    }
  }
}

.visa-row-nodata {
  text-align: center;
  padding: 13px 1rem;
  font-size: 16px;
  color: #ff9417;
  line-height: 22px;
}

.beneficiary-details-perm {
  tr.main-header>th {
    background-color: #f5f5f5 !important;
    border: 1px solid #707070 !important;
    border-width: 1px 0 1px 0 !important;
    color: #4a4a4a !important;
    font-weight: 300 !important;
    font-size: 14px;
    line-height: 18px;
    padding: 13px 1rem !important;

    &:first-child {
      border-width: 1px 0 1px 1px !important;
    }

    &:last-child {
      border-width: 1px 1px 1px 0 !important;
    }

    &.row-toggle-width {
      width: 70px;
    }

    &.col-actions-width {
      width: 100px;
    }
  }

  tr {
    &>td {
      padding: 6px 1rem !important;
      border: 1px solid #707070 !important;
      font-size: 16px;
      color: #4a4a4a;
      line-height: 20px;

      &:first-child {
        border-width: 0 0 0 1px !important;
      }

      &:last-child {
        border-width: 0 1px 0 0 !important;
      }

      .expanded-row-title {
        display: block;
        font-size: 14px;
        font-weight: 300;
        line-height: 18px;
        color: #9a9a9a;
      }

      .expanded-row-value {
        display: block;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        color: #4a4a4a;
      }
    }

    &.main-row>td {
      border-width: 1px 0 0 0 !important;

      &:first-child {
        border-width: 1px 0 0 1px !important;
      }

      &:last-child {
        border-width: 1px 1px 0 0 !important;
      }
    }

    &.expanded-row>td {
      padding: 0 0 !important;
    }

    &:last-child {
      &.expanded-row>td {
        border-width: 0 0 1px 0 !important;

        &:first-child {
          border-width: 0 0 1px 1px !important;
        }

        &:last-child {
          border-width: 0 1px 1px 0 !important;
        }
      }

      &.main-row>td {
        border-width: 1px 0 1px 0 !important;

        &:first-child {
          border-width: 1px 0 1px 1px !important;
        }

        &:last-child {
          border-width: 1px 1px 1px 0 !important;
        }
      }
    }
  }
}

.main-row-nodata {
  text-align: center;
  padding: 6px 1rem;
  border: 1px solid #707070;
  border-width: 0 1px 1px 1px;
  font-size: 16px;
  color: #ff9417;
  line-height: 20px;
}

.fdnsBeneListingTable {
  border: 1px solid #e9ecef !important;

  .p-datatable-tbody>tr>td {
    text-align: left !important;
    border: 1px solid #e9ecef;
    border-width: 1px !important;
    padding: 10px !important;
    padding-left: 20px !important;
  }

  .p-datatable-thead>tr>th {
    text-align: left !important;
    padding: 10px !important;
    padding-left: 20px !important;
    border: 1px solid #e9ecef;
    border-width: 1px !important;
    font-weight: 600;
    color: #495057;
    background: #f8fbff;
    transition: box-shadow 0.2s;
  }
}

.fdnsQAListingTable {
  border: 1px solid #e9ecef !important;

  .p-datatable-tbody>tr>td {
    text-align: left !important;
    border: 1px solid #e9ecef;
    border-width: 1px !important;
    padding: 10px !important;
  }

  .p-datatable-thead>tr>th {
    text-align: left !important;
    padding: 10px !important;
    border: 1px solid #e9ecef;
    border-width: 1px !important;
    font-weight: 600;
    color: #495057;
    background: #d0dce8 !important;
    transition: box-shadow 0.2s;
  }
}

.fdnsVisitListingTable {
  border-bottom: 1px solid #e9ecef !important;

  .p-datatable-thead>tr>th {
    padding: 10px !important;
  }
}

.fileUploadedDocumentsTable {
  border: 1px solid #e9ecef !important;

  .p-datatable-thead>tr>th {
    padding: 10px !important;
  }
}

.myFormListingTable {
  border-bottom: 1px solid #e9ecef !important;

  .p-datatable-thead>tr>th {
    background: #edf4fb !important;
    padding: 10px !important;
  }

  .p-datatable-tbody>tr>td {
    padding: 10px !important;
  }

  padding: 10px !important;
}

.fileUploadedDocumentsTable {
  border: 1px solid #e9ecef !important;

  .p-datatable-thead>tr>th {
    padding: 10px !important;
  }
}

.vdFeeTable {
  border: 1px solid #e9ecef !important;

  .p-datatable-thead>tr>th {
    padding: 10px !important;
    background-color: #edf4fb !important;
  }

  .p-datatable-thead>tr>th>span {
    margin-left: 20px !important;
  }

  .p-datatable-tbody>tr>td {
    padding: 10px !important;
  }

  .attorney-search-beneficiary {
    font-size: 13px;

    tr {
      &>th {
        font-weight: bolder;
      }

      &>td {
        padding: 0.5rem 1rem !important;
      }
    }
  }

  .i130-address-table {
    border-collapse: separate !important;
    border-spacing: 0;

    .p-datatable-thead {
      >tr>th {
        border-top: 1px solid #e1e1e1 !important;
        font-size: 16px;
        line-height: 22px;
        color: #333333 !important;
        padding: 6px 1rem !important;
        font-weight: normal !important;

        &:first-child {
          border-top-left-radius: 5px;
          border-left: 1px solid #e1e1e1 !important;
        }

        &:last-child {
          border-top-right-radius: 5px;
          border-right: 1px solid #e1e1e1 !important;
        }
      }
    }

    .p-datatable-tbody {
      >tr>td {
        border-color: #b5b5b5 !important;
        font-size: 16px;
        line-height: 22px;
        color: #626262 !important;
        padding: 7px 1rem !important;

        &:first-child {
          border-width: 0 0 1px 1px;
        }

        &:last-child {
          border-width: 0 1px 1px 0;
        }
      }
    }
  }

  .p-datepicker .p-datepicker-buttonbar {
    padding: 0 !important;
  }

  .task-table {
    .table {
      border: 0 !important;
      margin-top: 0px !important;

      .p-datatable.p-component {
        border: 1px solid #eef4fa !important;
      }
    }
  }
}

.vdFileUploadTable {
  border: 1px solid #e9ecef !important;

  .p-datatable-thead>tr>th {
    padding: 10px !important;
    background-color: #edf4fb !important;
  }

  .p-datatable-thead>tr>th>span {
    margin-left: 20px !important;
  }

  .p-datatable-tbody>tr>td {
    padding: 10px !important;
  }
}

.container-json-viewer {
  .p-datatable-scrollable-body {
    border-bottom: 1px solid #eef4fa;
  }
}

.p-datatable {
  .pdf-list-json-viewer {
    .p-datatable-thead {
      >tr {
        >th {
          background-color: #e0f0f7 !important;
          padding: 10px 1rem;
          cursor: default;

          .col-title {
            font-size: 16px !important;
          }

          .col-json-viewer-title {
            font-size: 20px;
            font-weight: 600;
            color: #4d4f5c;
            line-height: 42px;
          }

          &.action-width {
            width: 200px;
          }

          &.checkbox-col-width {
            width: 68px;
            padding: 1rem 1rem 1rem 2rem;

            &.in-search-popup {
              padding: 1rem;
              width: 53px;
            }
          }
        }
      }
    }

    .p-datatable-tbody {
      >tr {
        &.group-row {
          background-color: #dfefe1;
        }

        >td {
          position: relative;

          &.checkbox-col-width {
            width: 68px;
            padding: 1rem 1rem 1rem 2rem;
            overflow: hidden;

            .checkbox-holder {
              position: absolute;
              top: 1rem;
              left: 2rem;
              z-index: 1;
            }

            &.in-search-popup {
              padding: 1rem;
              width: 53px;

              .checkbox-holder {
                left: 1rem;
              }
            }

            .group {
              position: absolute;
              height: 100%;
              left: 1rem;
              width: 15px;
              z-index: 1;
              border-style: solid;
              border-color: #2680eb;

              &.group-first {
                border-width: 0 0 0 2px;
                top: calc(1rem + 10px);
              }

              &.group-middle {
                border-width: 0 0 0 2px;
                top: 0;
              }

              &.group-last {
                border-width: 0 0 0 2px;
                bottom: calc(100% - (1rem + 10px));
              }
            }

            .minimise-group {
              position: absolute;
              top: 1rem;
              left: 0.5rem;
              border: 2px solid #2680eb;
              color: #2680eb;
              font-size: 10px;
              background-color: #ffffff;
              display: block;
              z-index: 2;
              width: 20px;
              height: 20px;
              padding: 3px;
              font-weight: 700;
            }
          }

          .content-json-viewer {
            font-size: 14px;
            font-weight: 600;
          }

          .group-title {
            margin-left: 1rem;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
          }

          .pi {
            font-size: 20px;
            color: #465f7c;
            cursor: pointer;

            &.pi-arrows-alt {
              float: left;
            }

            &.p-checkbox-icon.pi-check {
              font-size: 14px;
              color: #ffffff;
            }

            &.maximize-group {
              position: absolute;
              top: 1rem;
              left: 0.5rem;
              border: 2px solid #87bc8e;
              color: #87bc8e;
              font-size: 10px;
              background-color: #dfefe1;
              display: block;
              z-index: 2;
              width: 20px;
              height: 20px;
              padding: 3px;
              font-weight: 700;
            }
          }

          .container-edit-json-object {
            float: left;
            width: calc(100% - 241px);
            min-height: 1px;
            margin-right: 15px;

            .edit-element-search-popup-textarea {
              width: calc(100% - 80px);
              padding: 5px;
              line-height: 20px !important;
              font-size: 16px !important;
              min-height: 34px;
              margin-right: 15px;
              float: left;
            }

            .pi {
              margin-top: 7px;
            }
          }

          .container-json-object {
            float: left;
            width: calc(100% - 241px);
            min-height: 1px;
            margin-right: 15px;

            .part-title {
              font-size: 20px;
              font-weight: 700;
              display: block;
            }

            .form-group {
              .highlight-search-text-json-viewer {
                padding: 0.2em;
                background-color: #f8ec8f !important;
              }

              .json-viewer-input {
                width: 350px;
                max-width: 100%;
              }

              .pdf-checkbox {
                width: max-content;
                margin-right: 15px;
                float: left;
                max-width: 100%;
              }

              .pdf-dropdown {
                &.p-dropdown {
                  width: 350px;
                  max-width: 100%;
                }
              }

              .pdf-radiobutton {
                width: max-content;
                margin-right: 15px;
                float: left;
                max-width: 100%;
              }
            }

            .sub-head-title {
              font-size: 19px;
              font-weight: 600;
              display: block;
              margin-bottom: 10px;
            }

            .element-head-title {
              font-size: 18px;
              margin-bottom: 5px;
              display: block;
            }

            .questionnaire-lbl {
              float: left;
              margin-right: 15px;
              margin-top: 3px;
              margin-bottom: 0;
              max-width: calc(100% - 70px);
            }

            .p-inputswitch.questionnaire-switch {
              background-color: rgba(0, 0, 0, 0);
            }

            .json-viewer-textarea {
              width: 100%;
            }
          }

          .container-edit-object {
            float: left;
            width: calc(100% - 107px);
            min-height: 1px;
            margin-right: 15px;

            .edit-element-search-popup-textarea {
              width: 100%;
              padding: 5px;
              line-height: 20px !important;
              font-size: 16px !important;
              min-height: 34px;
            }
          }

          .container-search-object {
            float: left;
            width: calc(100% - 73px);
            min-height: 1px;
            margin-right: 15px;

            &.container-contry-state-object {
              width: calc(100% - 190px);
            }

            .highlight-search-text-json-viewer {
              padding: 0.2em;
              background-color: #f8ec8f !important;
            }

            .part-title {
              font-size: 20px;
              font-weight: 700;
              display: block;
            }

            .form-group {
              .json-viewer-input {
                width: 350px;
                max-width: 100%;
              }

              .pdf-checkbox {
                width: max-content;
                margin-right: 15px;
                float: left;
                max-width: 100%;
              }

              .pdf-dropdown {
                &.p-dropdown {
                  width: 350px;
                  max-width: 100%;
                }
              }

              .pdf-radiobutton {
                width: max-content;
                margin-right: 15px;
                float: left;
                max-width: 100%;
              }

              .sub-head-title {
                font-size: 19px;
                font-weight: 600;
                display: block;
                margin-bottom: 10px;
              }

              .element-head-title {
                font-size: 18px;
                margin-bottom: 5px;
                display: block;
              }

              .questionnaire-lbl {
                float: left;
                margin-right: 15px;
                margin-top: 3px;
                margin-bottom: 0;
                max-width: calc(100% - 70px);
              }

              .p-inputswitch.questionnaire-switch {
                background-color: rgba(0, 0, 0, 0);
              }

              .json-viewer-textarea {
                width: 100%;
              }
            }
          }

          .element-type-div {
            float: left;
            margin-right: 15px;
            margin-top: -5px;
            width: 150px;

            .p-dropdown.p-component {
              height: 30px;
              width: 100%;

              .p-dropdown-label {
                padding: 0;
                line-height: 28px;
                margin-left: 5px;
              }

              .p-dropdown-trigger {
                .p-dropdown-trigger-icon {
                  font-size: 14px;
                }
              }
            }
          }

          .speed-dial {
            float: left;
            position: relative;
            height: 20px;
            width: 26px;
            margin-right: 15px;

            .fa {
              font-size: 23px;
              color: #465f7c;
              cursor: pointer;
              width: 20px;
              height: 23px;
              margin-top: -2px;

              &.speed-dial-active {
                padding: 8px 10px 10px;
                border-radius: 50%;
                background-color: #01a0da;
                width: 40px;
                height: 40px;
                margin-left: -8px;
                margin-top: -12px;
              }
            }

            .speed-dial-content {
              position: absolute;
              top: calc(100% + 10px);
              left: 50%;
              z-index: 1;
              width: 56px;
              margin-left: -28px;
              height: 0;
              overflow: hidden;
              transition:
                height 0.2s,
                padding 0.2s;
              padding: 0;

              &.speed-dial-content-active {
                height: 171px;
                padding: 3px;
              }

              >.pi {
                display: block;
                padding: 15px;
                border-radius: 50%;
                box-shadow: 0px 0px 3px 1px rgb(0, 0, 0, 0.3);
                margin-bottom: 5px;
                background-color: #fff;
                width: 50px;
                height: 50px;
              }
            }
          }

          .element-id-txt {
            color: #999999;
            font-size: 14px;
            font-weight: 600;
          }
        }

        &:nth-child(even) {
          background-color: #f5f7f7;

          &.group-row {
            background-color: #dfefe1;
          }

          >td {
            &.checkbox-col-width {
              .minimise-group {
                background-color: #f5f7f7;
              }
            }
          }
        }
      }
    }
  }
}

.fd-table-widget {
  .p-datatable-tbody>tr {
    background-color: #ffffff;
  }

  .p-datatable-tbody>tr:nth-child(even) {
    background-color: #f8f9fa;
  }
}

.action-col-width {
  width: 90px;
}

.p-datatable-fb-forms {
  &.p-datatable {
    &.alternate-colour {
      .p-datatable-tbody {
        &>tr:nth-child(even) {
          background-color: #f8f9fa;
        }
      }
    }

    .p-datatable-tbody {
      &>tr {
        &>td {
          word-break: break-all;

          &>span:not(.not-text) {
            word-break: break-all;
          }
        }
      }
    }
  }
}

.p-datatable {
  .fb-past-holiday {
    border: 0 !important;

    .p-datatable-tbody>tr>td {
      padding: 2px 0 2px 5px;
      color: #475f7b;
      font-weight: 600 !important;
      font-size: 14px;
      opacity: 0.6;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:first-child {
        width: 35px;
        text-overflow: clip;
      }

      &:last-child {
        width: 170px;
        font-weight: normal;
      }
    }
  }
}

.p-datatable {
  .fb-upcoming-holiday {
    border: 0 !important;

    .p-datatable-tbody>tr {
      &.next-holiday {
        background-color: #edf4fb;
      }

      &>td {
        padding: 2px 0 2px 5px;
        color: #475f7b;
        font-weight: 600 !important;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:first-child {
          width: 35px;
          text-overflow: clip;
        }

        &:last-child {
          width: 170px;
          font-weight: normal;
        }
      }
    }
  }
}

.p-datatable {
  &.p-fbtable-striped {
    .fb-table {
      .p-datatable-tbody>tr:nth-child(even) {
        background: #f8f9fa;
      }
    }
  }

  &.p-fbtable-gridlines {
    .fb-table {

      .p-datatable-tbody,
      .p-datatable-thead {
        &>tr {
          border: 1px solid #e9ecef !important;
        }
      }
    }
  }

  &.p-fbtable-text-wrap {
    .fb-table {
      .p-datatable-tbody>tr>td {
        white-space: unset;
      }
    }
  }

  .fb-table {
    border: 0 !important;

    .p-datatable-thead>tr>th {
      padding: 10px 5px;
      background-color: #edf4fb;
      font-weight: 600;
      font-size: 16px;
      color: #475f7b;
      white-space: nowrap;
      line-height: 16px;

      &.w-180px {
        width: 180px;
      }

      &.w-110px {
        width: 110px;
      }

      &.w-100px {
        width: 100px;
      }

      &.w-80px {
        width: 80px;
      }

      &.w-75px {
        width: 75px;
      }

      &.w-65px {
        width: 65px;
      }

      &.w-60px {
        width: 60px;
      }

      &.w-40px {
        width: 40px;
      }

      &.header-column-wrap {
        white-space: normal;
        line-height: 16px;
      }
    }

    .p-datatable-tbody>tr {
      &.next-holiday {
        background-color: #edf4fb;
      }

      &>td {
        padding: 10px 5px;
        color: #475f7b;
        font-weight: normal !important;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 14px;

        &.text-wrap {
          white-space: unset;
        }

        &.w-100px {
          width: 100px;
        }

        &.w-110px {
          width: 110px;
        }

        &.w-180px {
          width: 180px;
        }

        &.w-60px {
          width: 60px;
        }

        &.w-80px {
          width: 80px;
        }

        &.td-data-blue {
          color: #23a7dc;
        }

        &.td-data-green {
          color: #1e9502;
        }

        .col-wrapper {
          display: flex;
          align-items: center;

          .col-content {
            flex: 1;
            white-space: normal;
            word-wrap: break-word;
          }

          .col-info-text {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 5px;
            width: 20px;

            .pi.pi-ellipsis-v {
              font-size: 7px;
              font-weight: 900;
              display: block;
              border: 1px solid #475f7b;
              border-radius: 50%;
              padding: 2px 3px 2px 2px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.timesheet-summary .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
  background: #f5f5f5 !important;
}