.ngx-extended-pdf-viewer {

    input,
    select {
        font-family: Arial !important;
        border: none !important;
        border-radius: 0 !important;
        height: calc(100% - 8px);
        color: rgba(242, 242, 242, 1) !important;
        font-size: 12px !important;
    }
}