/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap";
@import "../node_modules/ngx-toastr/toastr.css";
@import "./assets/global/pie.scss";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import "primeicons/primeicons.css";
@import "bootstrap-icons/font/bootstrap-icons.css";

.k-button-solid-primary {
  border-color: #1ea7db;
  color: white;
  background-color: #1ea7db;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}

form input,
form iframe {
  width: 100%;
  margin: 5px;
}

form iframe {
  height: 45px;
}

.k-button-solid-primary:hover,
.k-button-solid-primary.k-hover {
  border-color: #1ea7db;
  background-color: #0b93c6;
}

.k-dialog-titlebar {
  border-color: inherit;
  color: white;
  background-color: #1ea7db;
}

.row {
  margin: 0px;
}

mat-card {
  margin: 0px;
}

.mat-tab-body-wrapper {
  min-height: 500px !important;
}

// .select-comp {
//   height: 38px;
//   width: 100%;
// }
.icn {
  cursor: pointer !important;
  color: #475f7b !important;
}

.mat-stepper-vertical {
  margin-top: 8px;
}

// .mat-form-field {
//   margin-top: 16px;
// }

//pinto adding over ride  class to mat-table for width adjust :begin

//pinto adding over ride  class to mat-table for width adjust :end
.bordered-panel {
  border-left: 1px solid lightgrey;
  min-height: 500px;
  border-bottom: 1px solid lightgrey;
}

.no-border {
  border: none;
}

/* Dashboard widgets*/
.card-title {
  font-size: 16px;
  color: grey;
}

.card-nav-link {
  float: right;
  font-size: 16px;
}

.card-action-button {
  position: absolute;
  right: 10px;
}

.ad1 {
  top: 230px;
  left: 29px;
  width: 1143px;
  height: 107px;
  background: transparent url("/assets/ad1.png") 0% 0% no-repeat padding-box;
  opacity: 1;
}

// .select-comp,
// .txt-comp {
//   font-size: 16px;
//   width: 100%;
//   border: 1.5px solid #a3a6b4;
//   border-radius: 6px;
//   min-height: 43px;
// }
.widget-border {
  border: 1px solid #cdcdcd;
}

// .label-cls {
//   color: lightgrey;
// }
.btn-stepper {
  background: black;

  &.span {
    color: white;
  }
}

.dashed-line {
  border-bottom: 1px dashed lightgrey;
}

.invalid-message {
  font-size: 13px;
  color: #dc3545;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #158fc4;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: white;
}

.mat-radio-inner-circle {
  background-color: #158fc4 !important;
}

.mat-radio-outer-circle {
  border-color: #158fc4 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #158fc4 !important;
}

select.selector option {
  color: red;
}

.mat-select-panel {
  border: 1.5px solid #a3a6b4;
}

mat-option {
  padding: 0 47px !important;
}

.mat-tab-list {
  background-color: #fff;
  //opacity: 0.5 !important;
}

.mat-tab-label-active {
  border-color: #cdcdcd !important;
  background-color: #ffff;
}

.dashboard-regular-card {
  min-height: 580px;
  max-height: 580px !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  overflow-y: auto;
}

.dashboard-mini-card {
  min-height: 200px;
  max-height: 292px !important;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0), 0 1px 1px 0 rgba(0, 0, 0, 0),
    0 1px 3px 0 rgba(0, 0, 0, 0) !important;
  overflow-y: auto;
  width: 100%;
}

.dashboard-mini-tile-card {
  min-width: 160px;
  max-width: 245px;
  min-height: 90px;
  max-height: 90px;
}

.dashboard-medium-card {
  min-height: 365px;
  max-height: 365px !important;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0), 0 1px 1px 0 rgba(0, 0, 0, 0),
    0 1px 3px 0 rgba(0, 0, 0, 0) !important;
  overflow-y: auto;
  width: 100%;
}

.tab-container {
  height: 60px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background: transparent;
}

.tab-label {
  background: #f5f7f6;
  cursor: pointer;
  text-align: center;
}

.tab-action {
  font-size: 18px !important;
  font-weight: bold !important;
  color: #60626e;
}

.noMargin {
  margin: 0 0 0 0px !important;
  padding: 0 0 0 0px !important;
}

.manage-pet-action {
  .mat-form-field-infix {
    width: 49px !important;
  }
}

.activeTimeLine {
  color: rgb(77, 79, 92);
  border-bottom: 4px solid #19a0da;
}

.activeI9Records {
  color: rgb(77, 79, 92);
  border-bottom: 4px solid #19a0da;
}

.activeTab {
  color: #19a0da !important;
  border-bottom: 4px solid #19a0da;
}

.btn-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px auto;

  .childBtn {
    height: 35px;
    min-width: 125px;
    padding: 0px 10px 0px 10px;
    border: none;
    outline: none;
    color: black;
    background-color: rgb(223, 223, 223);
    height: auto;
    min-height: 40px;
  }

  .firstBtn {
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
  }

  .lastBtn {
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
  }

  .childBtn:not(.firstBtn),
  .childBtn:not(.lastBtn) {
    border-right: 0.75px solid grey !important;
    border-left: 0.75px solid grey !important;
  }

  .activebtn {
    background-color: rgb(0, 46, 131);
    color: white;
    border-right: 0.75px solid white !important;
    border-left: 0.75px solid white !important;
  }
}

// .beneficiary-workexp-clients-box {
//   .mat-form-field {
//     margin-top: -5px !important;
//   }
//   // .mat-form-field-appearance-outline {
//   //   height: 40px !important;
//   // }
//   .mat-form-field-flex {
//     height: 40px !important;
//   }
//   .mat-form-field-infix {
//     height: 40px !important;
//   }
//   .mat-form-field-appearance-outline .mat-form-field-suffix {
//     top: -0.6em !important;
//   }
// }
//beneficiary login dashboard
.notification-mat-card-content {
  .mat-select-value-text {
    font-weight: 700 !important;
    color: rgb(98, 118, 142) !important;
  }
}

.imageBox {
  & img {
    width: 200px;
    height: 200px;
    cursor: pointer;
    border: 4px solid #19a0da;
  }

  & .mat-icon {
    color: #19a0da;
    position: relative;
    top: 75px;
    right: 28px;
  }
}

.toggle-col {
  width: 10px;
  margin-right: 4px;
}

.mat-icon {
  cursor: pointer;
}

.hint-text {
  color: rgba(0, 0, 0, 0.6);
  font-size: 11px;
}

.no-data {
  padding-left: 50%;
  padding-top: 20px;
}

.add-placeholder {
  min-height: 200px;
  position: relative;
  // left: 45%; // removing for extra spaccing
  text-align: center;
  top: 45px;

  .btn {
    background-color: #19a0da;
    padding: 0 16px !important; // aligning plus symbol in center
  }
}

//  form-generate
.form-generate {
  dd {
    font-size: 14px !important;
  }
}

.form-rounded {
  border-radius: 1rem;
}

.dasheedTopBorder {
  border-top: 1px dashed gray;
}

.circleIndex {
  width: 20px;
  height: 20px;
  background-color: #8ecdd6;
  text-align: center;
}

.w100 {
  width: 100% !important;
}

.lca-list-date-picker {
  .mat-form-field-infix {
    padding: 0.3em 0 1em 0 !important;
  }

  .mat-form-field-flex {
    height: 40px !important;
  }
}

// .valueTxt {
//   color: #333 !important;
//   font-weight: 600 !important;
// }
.labelTxt {
  color: #666666 !important;
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 500;
}

//task page
.task-page-tab-state {
  .mat-tab-header {
    padding-left: 0px;
  }

  // a.mat-tab-link {
  //   border-top-left-radius: 5px;
  //   border-top-right-radius: 5px;
  //   background-color: #ffff;
  //   margin-right: 16px;
  //   opacity: unset;
  //   border: 1px solid rgb(201, 201, 201);
  //   height: 42px;
  //   border-bottom: 0px;
  // }

  .mat-tab-list {
    background-color: inherit !important;
  }

  .mat-tab-link-container {
    flex-grow: unset !important;
  }

  .mat-tab-link {
    font-size: 18px;
    font-weight: 500;
  }
}

.btn-content-align {
  display: flex;
  flex-direction: row;
  align-items: center;

  .mat-icon {
    margin: 0px 5px !important;
  }
}

mat-drawer.settings-landing {
  border: 0px !important;
}

.setting-profileview-control {
  .mat-radio-label-content {
    padding-left: 2px;
  }
}

.settings.ad1 {
  background-size: 100% !important;
  width: 100vw !important;
}

button.notification.mat-menu-item:not(:last-child) {
  width: 300px;
  border-bottom: 0.25px solid rgb(244, 244, 244) !important;
}

button.notification.activeFilter {
  font-weight: bold;
  color: rgb(60, 151, 186);
}

.mat-menu-content {
  // display:flex; // IM-3524
  font-size: 12px;

  .flex-col {
    .flex-row {
      display: flex;
      flex-direction: row;
      border-top: 1px solid rgb(247, 243, 243) !important;
      color: rgb(71, 95, 123) !important;
    }
  }

  .mat-menu-item {
    line-height: 16px !important;
    color: rgb(71, 95, 123) !important;
  }
}

.titleFontColor {
  color: #4e6580 !important;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0px;
}

.noButtonStyle {
  background: transparent !important;
  border: none !important;
  padding: 0;
}

// >>>>> landing.component.html -> Testimonials corousel
.owl-carousel.owl-drag .owl-item.active.center>div.item>div>div {
  box-shadow: 0px 8px 16px #00000029 !important;
  background: transparent linear-gradient(1deg, #bf96f9 0%, #7c6bf0 100%) 0% 0% no-repeat padding-box !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;

  & div>p {
    color: #fff;
  }

  .testimonials-name {
    color: #fff !important;
  }

  .testimonials-role {
    color: #fff;
  }
}

.owl-theme .owl-dots .owl-dot span {
  width: 15px !important;
  height: 15px !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #19a0da 0% 0% no-repeat padding-box !important;
}

// landing.component.html -> Testimonials corousel <<<<<

.signup-form {

  .im-input,
  .im-input-error,
  .im-input-error-outline,
  .im-input-success,
  .im-textbox,
  .select2-container--default .select2-selection--multiple {
    font-family: "Source Sans Pro", sans-serif;
    border: 1.5px solid #a3a6b4;
    border-radius: 0.4rem;
    height: 38px;
  }

  .col-md-6 {
    padding-right: 5px;
    padding-left: 2px;
  }

  label {
    margin-bottom: 0.15rem;
  }
}

.pwd-same-persl {
  .mat-checkbox-inner-container {
    margin-right: 2px !important;
  }
}

// .im-drop-icon {
//   padding: 5px 7px !important;
// }

// .im-btn-success {
//   color: #fff;
//   background-color: #28a745;
//   border-color: #28a745;
// }

.taskModalTxt {
  font-size: 14px;
  font-weight: 600;
  color: #847e7e;
}

.createNewPlaceholderDiv {
  padding: 0.5rem 0.5rem 1.5rem 0.5rem;
}

.page-heading {
  padding-left: 0px !important;

  &>h4 {
    text-align: left;
    font: normal normal 600 22px/30px Source Sans Pro Bold;
    letter-spacing: 0px;
    color: #333246;
    opacity: 1;
  }

  &>p {
    text-align: left;
    font: normal normal 600 18px/14px Source Sans Pro;
    // font: normal normal 600 15px/10px Source Sans Pro bold;
    letter-spacing: 0px;
    color: #2271cc;
    opacity: 1;
  }
}

.mr-search {
  position: absolute;
  // margin-left: 960px;
  right: 40px;
  padding: 10px;
}

// .control-lbl {
//   font-size: 14px;
//   font-weight: 600;
//   color: #847e7e;
// }
.required:after {
  content: "*";
  color: red;
}

#fr-logo {
  display: none;
}

.fr-element.fr-view>p {
  margin-bottom: 0px;
}

.template-editor-maxwidth div:nth-child(2) {
  max-width: 868px !important;
}

.headerEditor .fr-box.fr-basic .fr-wrapper,
.footerEditor .fr-box.fr-basic .fr-wrapper {
  max-height: 150px !important;
}

.contentEditor .fr-box.fr-basic .fr-wrapper {
  min-height: 500px !important;
}

.cdk-overlay-container {
  z-index: 2000 !important;
}

.work-experience-component {
  .mat-horizontal-stepper-header-container {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

#fr-logo {
  display: none;
}

.fileUploadSection {
  border: 1px dashed #e9ecef !important;
}

.header-title {
  color: #3a3a3a;
  font-weight: 700;
  font-size: 23px;
}

.lcaWorkNotifySwitch.p-inputswitch {
  width: 2.7rem;
  height: 1.3rem;
  /* margin-top: 10px; */
  /* padding-top: 10px; */
  top: 6px;
}

.vd-container {
  background-color: white;
  margin: 10px;
  width: 100%;
  padding: 20px;
}

.customInputContainer {
  display: flex;
}

.side-nav-btn {
  font-family: "Source Sans Pro";
  font-size: 18px;
  color: #4d4f5c;
  font-weight: 600;
}

.im-card-table tbody tr .mat-cell,
.im-card-no-border-table tbody tr .mat-cell {
  font-size: 14px;
}

.im-card-table thead th,
.im-card-no-border-table thead th {
  font-size: 16px;
}

.input-group input.rounded-pill {
  font-size: 14px;
  font-weight: 600;
}

.float-right {
  float: right;
}

.p-datatable .p-datatable-thead>tr>th {
  font-size: 16px;
  font-weight: bold !important;
}

.p-datatable .p-datatable-tbody>tr>td {
  font-size: 14px;
  font-weight: normal !important;
  overflow-wrap: break-word;
}

// common style for standerd
.search-btn .search-text {
  width: 90%;
  float: right;
}

.heading {
  color: #3498db;
  font-weight: bold;
  font-size: 1.25rem;
}

h5.labelText {
  font-weight: bold !important;
  font-size: 1.25rem !important;
  line-height: 1.5rem !important;
}

.lbl-nbr {
  font-size: 12px;
  color: #60626e;
  font-weight: normal;
  font-style: italic;
}

.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.input-group .input-group-append button.rounded-pill {
  padding: 0px 20px !important;
}

textarea {
  border: 1px solid #ced4da;
}

.text-primary {
  color: #3498db !important;
}

//  .table,.p-datatable{
.table,
p-table table {
  border-color: #eef4fa;
  border: 1px solid #eef4fa !important;
}

table thead tr,
table thead.p-datatable-thead,
.p-datatable .p-datatable-thead>tr>th,
.table-striped .p-datatable-thead tr th,
.table thead th,
.im-card-table thead th,
.im-card-no-border-table thead th,
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background-color: #edf4fb !important;
  font-family: "Source Sans Pro";
  font-size: 13px !important;
  color: #2c2c2c !important;
  border: 0px !important;
}

.table-striped-odd>tbody>tr:nth-child(4n+3),
.table-striped>tbody>tr:nth-child(even),
.table-striped tbody tr:nth-of-type(even),
.table-striped tbody tr:nth-child(4n),
.table-striped tbody tr:nth-child(4n-1),
.table-striped .p-datatable-tbody tr:nth-of-type(even),
.table-striped-odd .p-datatable-tbody tr:nth-child(4n),
//  .table-striped-odd .p-datatable-tbody tr:nth-child(4n-1),
.table-striped-simple tbody tr:nth-of-type(even) {
  background-color: #f8f9fa;
  border: 1px solid #eef4fa;
  border-left: 0px;
  border-right: 0px;
  font-family: "Source Sans Pro";
  font-size: 13px !important;
  border: 0px !important;
}

.table-striped-odd>tbody>tr:nth-child(4n+1),
.table-striped>tbody>tr:nth-child(odd),
.table-striped tbody tr:nth-of-type(odd),
.table-striped-simple tr:nth-of-type(odd),
.table-striped tbody tr:nth-child(4n-2),
.table-striped tbody tr:nth-child(4n-3),
.table-striped .p-datatable-tbody tr:nth-of-type(odd) //  .table-striped-odd .p-datatable-tbody tr:nth-child(4n-2)

//  .table-striped-odd .p-datatable-tbody tr:nth-child(4n-3)
  {
  background-color: #fff;
  font-family: "Source Sans Pro";
  font-size: 13px !important;
  color: #505050;
  border: 0px !important;
}

.table-striped-odd>tbody>tr:nth-child(4n + 1):hover {
  background-color: #fff;
  font-family: "Source Sans Pro";
  font-size: 13px !important;
  color: #505050;
  border: 0px !important;
}

th.mat-header-cell,
.example-element-row td:first-child,
.im-card-table-noborder-spacing tr .mat-cell {
  // border:1px solid #EEF4FA ;
  border: 0px !important;
}

.pa-table .p-datatable-wrapper {
  border-bottom: unset !important;
}

.p-datatable .p-datatable-tbody>tr>td {
  border: unset !important;
}

.btn,
.btn-primary,
.button_bg-gray,
.back-button {
  border-radius: 4px !important;
}

.side-nav {
  button {
    padding: 15px 10px !important;
    margin: 0px !important;
  }
}

.page-header {
  h2 {
    font-size: 22px;
    color: #333246;
    font-weight: 700;
    margin-bottom: 0px;
    // padding-top : 25px;
    font-family: "Source Sans Pro";
  }

  p {
    font-size: 14px;
    color: #333246;
    font-weight: 500;
    font-family: "Source Sans Pro";
  }
}

.search-input-group {
  width: 85% !important;
  float: right !important;

  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2;
    right: 15px;
    top: 2px;
  }
}

.btn-add {
  background-color: #ffffff !important;
  border: 1px solid #19a0da !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  text-transform: uppercase;
  color: #10a0da !important;

  i.fa-plus-circle {
    // background: #10A0DA !important;
    font-size: 18px !important;
    color: #10a0da;
  }
}

.btn-add:hover {
  background-color: #ffffff !important;
  border: 1px solid #19a0da !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  text-transform: uppercase;
  color: #10a0da !important;

  i.fa-plus-circle {
    // background: #10A0DA !important;
    font-size: 18px !important;
    color: #10a0da;
  }
}

.bg-white,
.tab-container {
  background-color: #fff;
  height: auto;
}

.im-btn-primary {
  border-radius: 4px !important;
}

.p-radiobutton-label {
  font-size: 15px;
  color: #4d4f5c;
  font-family: "Source Sans Pro";
}

.p-paginator {
  justify-content: end !important;
}

.back-btn-white-bg {
  background: transparent;
  text-transform: capitalize;
}

.back-btn-white-bg:hover {
  background: transparent;
  box-shadow: unset;
  border: unset;
  color: #a3a6b4 !important;
  text-transform: capitalize;
  font-weight: 600;
}

.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-label-selected {
  color: #3498db !important;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 22px;
}

.btn-xs {
  height: 30px !important;
  font-size: 12px !important;
  background-color: #dae9fd !important;
  color: #465f7c !important;
  border-radius: 18px !important;
  margin-right: 10px !important;
}

label {
  font-size: 15px !important;
}

// input styleing

input,
.p-dropdown,
textarea,
input:focus,
input:focus-visible,
.p-dropdown:focus,
textarea:focus,
select {
  border: 1px solid #c3d0de !important;
  border-radius: 4px !important;
  font-family: "Source Sans Pro" !important;
  color: #4d4f5c !important;
  font-size: 15px !important;
}

.p-calendar {
  .p-calendar-w-btn .p-datepicker-trigger {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-right: 1px solid #c3d0de !important;
    border-top: 1px solid #c3d0de !important;
    border-bottom: 1px solid #c3d0de !important;
    border-radius: 4px !important;
    font-family: "Source Sans Pro" !important;
    color: #4d4f5c !important;
    font-size: 15px !important;
  }

  input {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .p-button:enabled:hover {
    border-color: #2196f3 !important;
  }
}

.p-calendar .p-datepicker-trigger {
  border-right: 1px solid #c3d0de !important;
  border-top: 1px solid #c3d0de !important;
  border-bottom: 1px solid #c3d0de !important;
}

.date-field input {
  border: unset !important;
}

.p-inputtext,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-family: "Source Sans Pro" !important;
  color: #4d4f5c !important;
  font-size: 15px !important;
}

.p-component {
  font-family: "Source Sans Pro" !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #c3d0de;
}

//  button Style
.btn-cancel {
  background-color: #e1e1e1 !important;
  color: #465f7c !important;
  font-size: 14px !important;
  text-transform: uppercase !important;
}

.btn-save {
  border: 1px solid #19a0da !important;
  letter-spacing: 0px !important;
  color: #19a0da !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
}

.btn-generic {
  color: #fff !important;
  height: 25px;
  border: #fff;
}

//mat step

.mat-step-header .mat-step-icon-state-edit {
  background-color: #2da7d5 !important;
}

.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-label-active {
  color: #3498db !important;
}

.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header {
  .mat-step-label {
    color: #728499 !important;
  }

  .mat-step-icon {
    background-color: #728499 !important;
  }
}

.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header .mat-step-label-selected {
  color: #2da7d5 !important;
}

.mat-stepper-horizontal .mat-horizontal-stepper-header-container .mat-step-header {
  .mat-step-icon-selected {
    background-color: #2da7d5 !important;
  }
}

.btn-primary.disabled,
.btn-primary:disabled,
.im-btn.im-btn-primary:disabled {
  background-color: #f0f0f0 !important;
  color: #a3a6b4 !important;
  font-size: 14px !important;
  font-family: "Source Sans Pro";
  border: 1px solid transparent !important;
}

.editBtn {
  .btn-primary:hover {
    box-shadow: none !important;
  }
}

.addDutyBtn {
  :hover {
    color: #ffffff !important;
  }
}

.btn-primary.disabled :hover,
.btn-primary:disabled :hover {
  background-color: #f0f0f0 !important;
  color: #a3a6b4 !important;
  font-size: 14px !important;
  font-family: "Source Sans Pro";
  border: 1px solid transparent !important;
  box-shadow: none !important;
}

.im-btn.im-btn-primary:disabled:hover {
  background-color: #f0f0f0 !important;
  color: #a3a6b4 !important;
  font-size: 14px !important;
  font-family: "Source Sans Pro";
  border: 1px solid transparent !important;
  box-shadow: none !important;
}

.mat-raised-button:not([class*="mat-elevation-z"]) {
  box-shadow: unset !important;
}

.customPanel .p-overlaypanel .p-overlaypanel-content {
  padding: 20px 0px !important;
}

.ap-overlaypanel {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  cursor: pointer;
}

.h-min-100 {
  min-height: 100%;
}

.word-wrap-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.min-height-unset {
  min-height: unset !important;
}

.table-striped-simple th {
  text-transform: uppercase !important;
}

.edit {
  color: #465f7c !important;
  font-weight: bold !important;
  font-family: "Lato", Regular !important;
}

.editBtn {
  .button_whitebg:disabled {
    border: none !important;
    background-color: #ededed !important;
  }
}

.span-pi-exclamation-circle {
  border-left-style: solid !important;
  border-left-color: inherit !important;
  border-left-width: 2px !important;
  padding-left: 5px !important;
  margin-left: 6px !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.p-tabview .p-tabview-nav {
  background: none !important;

  // New Time Line Step css changes

  .btn-success {
    font: normal normal bold 14px Source Sans Pro !important;
    color: #ffffff !important;
    text-transform: uppercase;
    background: #19a0da !important;
    border-radius: 10px !important;
    padding: 0px 29px !important;
    width: 131px;
  }

  .step-title {
    h5 {
      color: #3d3d63;
      font: normal normal bold 22px Source Sans Pro;
    }

    p {
      color: #626262;
      font: normal normal normal 16px Source Sans Pro;
    }
  }

  .cancel-btn {
    font: normal normal bold 14px Source Sans Pro !important;
    color: #47607d !important;
    text-transform: uppercase;
    background: #dedede !important;
    border-radius: 10px !important;
    padding: 0px 29px !important;
    width: 131px;
    height: 43px;
  }

  .p-fileupload {
    :hover {
      background-color: #ffffff !important;
    }
  }

  .p-button.p-fileupload-choose {
    background-color: white;
    border: 1px solid #19a0da;
    border-radius: 6px;
    width: 128px;
    height: 45px;

    .p-button-label {
      text-align: center;
      letter-spacing: 0px;
      color: #19a0da;
      font-size: 18px;
      font-family: "Lato", Regular;
    }
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    background: #ffffff !important;
    border: 1px solid #2da7d5 !important;
    border-radius: 6px !important;
  }

  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #2da7d5 !important;
    font-size: 10px !important;
    font-weight: bold;
    margin-top: 2px;
  }

  .pi-plus::before {
    content: url("./assets/images/newUploadImage.png") !important;
  }

  .p-checkbox .p-checkbox-box {
    border: 1px solid #2da7d5 !important;
    border-radius: 6px !important;
  }

  .completeStep {
    .button_whitebg:hover {
      color: #19a0da !important;
      border: 1px solid #19a0da !important;
      background-color: #ffffff !important;
      box-shadow: none !important;
    }

    .button_whitebg:disabled {
      color: #a2a2a2 !important;
      border: 1px solid #a2a2a2 !important;
      background-color: #ffffff !important;
      box-shadow: none !important;
    }
  }

  .stepCompleted {

    button:not(:disabled),
    [type="button"]:not(:disabled) {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #19a0da !important;
      border-radius: 6px;
      text-transform: uppercase;
      color: #19a0da;
      font: normal normal bold 14px Source Sans Pro;
      letter-spacing: 0px;
      display: inline-block;
      padding: 10px;

      .pi-exclamation-circle {
        font-size: 1rem;
        background-color: #19a0da;
        border-radius: 10px;
        color: white;
      }
    }

    .im-btn-disabled-outline:not(:disabled):not(.disabled):not(.im-disabled):not(.im-btn-disabled):not(.im-btn-disabled-light):not(.im-btn-disabled-outline) {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #a2a2a2 !important;
      border-radius: 6px;
      text-transform: uppercase;
      color: #a2a2a2;
      font: normal normal bold 14px Source Sans Pro;
      letter-spacing: 0px;
      display: inline-block;
      padding: 10px;

      // &:hover span{
      //   color: #A2A2A2;
      // }
      .pi-exclamation-circle {
        font-size: 1rem;
        background-color: #a2a2a2;
        border-radius: 10px;
        color: white;
      }

      .vertical-line {
        display: inline-block;
        border-left: 1px solid #a2a2a2;
        margin: 0 10px;
        height: 20px;
      }
    }
  }

  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    .vertical-line {
      display: inline-block;
      border-left: 1px solid #19a0da;
      margin: 0 10px;
      height: 20px;
    }
  }

  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    .vertical-line {
      display: inline-block;
      border-left: 1px solid #a2a2a2;
      margin: 0 10px;
      height: 20px;
    }
  }

  .edit {
    color: #465f7c !important;
    font-weight: bold !important;
    border: none !important;
    font-family: "Lato", Regular !important;
    border-radius: 10px !important;
    background-color: #ededed !important;
    text-transform: capitalize !important;

    :hover {
      color: #465f7c !important;
      background-color: #ededed !important;
      box-shadow: none !important;
    }
  }

  .editBtn {
    .edit {
      background-color: #ededed !important;
      border: none !important;

      :hover {
        background-color: #ededed !important;
      }
    }
  }

  .editBtn {
    .button_whitebg:disabled {
      border: none !important;
      text-transform: capitalize !important;
    }
  }

  .editBtn {
    .button_whitebg:hover {
      border: 0px solid #ededed !important;
      text-transform: capitalize !important;
      background-color: #ededed !important;
      color: #465f7c !important;
      box-shadow: none !important;
    }
  }

  .btn-primary:disabled {
    :hover {
      background-color: #ededed !important;
    }
  }

  .p-chips .p-chips-multiple-container .p-chips-token {
    color: #465f7c !important;
    border-radius: 30px !important;
    background: #dae9fd 0% 0% no-repeat padding-box !important;
  }

  .pi-times-circle {
    background-color: #465f7c !important;
    color: white !important;
    border-radius: 10px !important;
  }

  .uploadLetter {
    font: normal normal 600 16px/20px Source Sans Pro !important;
    color: #4d4f5c !important;
  }

  .label-cls {
    span {
      color: #9a9999 !important;
      font: normal normal normal 12px/17px Source Sans Pro !important;
    }
  }

  .p-accordion .p-accordion-content {
    background-color: #eff5fd !important;
    border: none !important;
    padding: 1rem !important;
    text-align: justify !important;
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    background-color: #eff5fd !important;
    font-size: 24px !important;
    font-family: "Lato" !important;
    font-weight: bold !important;
    color: #616161 !important;
  }

  .leftSidebar {
    border: none !important;
  }

  .activeItem {
    font: normal normal bold 14px/24px Source Sans Pro !important;
    color: #0089cf !important;
    cursor: pointer !important;
  }

  .inActiveItem {
    color: #4d4848 !important;
    cursor: pointer !important;
  }

  .leftSidebar>a.im-list-group-item {
    background-color: #fff !important;
    font-size: 16px !important;
    padding: 15px 10px 15px 10px !important;
  }

  .im-list-group-item {
    padding: 15px 10px 15px 0px !important;
    border-bottom-width: 0.5px !important;
    border-bottom-style: solid !important;
    border-bottom-color: rgba(112, 112, 112, 0.3) !important;
    font-weight: 600 !important;

    span {
      border: none !important;
      padding: 0px !important;
      margin: 0px !important;
    }
  }

  .leftSidebar>a.im-list-group-item {
    span {
      border: none !important;
      padding: 0px !important;
      margin: 0px !important;
    }
  }

  .card {
    border-right: solid 0.5px rgba(112, 112, 112, 0.3) !important;
    padding-right: 15px;
  }

  .itemListActive {
    color: #01a0da !important;
  }

  .pi-download {
    color: #475f7b !important;
    font-weight: bold !important;
  }

  .pi-eye {
    color: #475f7b !important;
    font-weight: bold !important;
  }

  .btnTrash,
  .btnPencil,
  .refreshIcon {

    .pi-trash,
    .pi-pencil,
    .pi-refresh {
      color: #475f7b !important;
      font-weight: bold !important;
    }
  }

  .exclCircle {
    margin-top: 5px !important;
  }
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.notepanel {
  .p-overlaypanel {
    width: 600px;
    background: #fff3df !important;
    top: auto !important;
    right: -20px;
    z-index: 100;

    &::before {
      margin-left: 6px !important;
      border-bottom-color: #fff3df !important;
    }

    &::after {
      border: 0 !important;
      border-color: transparent !important;
    }

    .p-overlaypanel-close {
      background: #febc54 !important;
      color: #777777 !important;
    }
  }
}

.lockpanel {
  .p-overlaypanel {
    width: 450px;
    // background: #fff3df !important;
    top: auto !important;
    left: -20px;
    z-index: 100;

    &::before {
      margin-left: 6px !important;
      border-bottom-color: #fff3df !important;
    }

    &::after {
      border: 0 !important;
      border-color: transparent !important;
    }

    .p-overlaypanel-close {
      // background: #febc54 !important;
      color: #777777 !important;
      background: transparent !important;
      top: 20px !important;
      right: 10px;
    }
  }
}

.lockpanel-pet {
  .p-overlaypanel {
    width: 450px;
    // background: #fff3df !important;
    top: auto !important;
    // right: -20px;
    z-index: 100;

    &::before {
      margin-left: 6px !important;
      border-bottom-color: #fff3df !important;
    }

    &::after {
      border: 0 !important;
      border-color: transparent !important;
    }

    .p-overlaypanel-close {
      // background: #febc54 !important;
      color: #777777 !important;
      background: transparent !important;
      top: 20px !important;
      right: 10px;
    }
  }
}

.pet-lock-unlock {
  position: absolute;
  top: 0px;
  right: 52%;
}

.icon-background {
  &.notes {
    height: 34px !important;
    margin: 0 4px !important;
    cursor: pointer;
    border-radius: 0 !important;

    .icn-note {
      //  color: #2F3440;
      background-image: url("./assets/images/Notes.svg") !important;
      background-repeat: no-repeat;
      position: absolute;
      height: 22px;
      width: 18px;
    }

    &.yellow {
      background: #febc54;
    }
  }
}

#request-data-document {
  div.visa-type-main {
    div.visa-type {
      display: grid;
      width: 20%;

      p-dropdown {
        div.p-dropdown {
          width: 100% !important;
        }
      }
    }
  }

  mat-checkbox {
    label.mat-checkbox-layout {
      span.mat-checkbox-label {
        b {
          font-size: 14px !important;
          color: #534e4e !important;
        }
      }
    }
  }

  mat-checkbox.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #0b72f2;
  }

  .access-rights {
    p-calendar {
      span {
        width: 100% !important;
      }
    }
  }

  .doc-portion {
    button {
      span.p-button-icon.pi.pi-info {
        font-size: 12px;
      }
    }
  }

  .p-dropdown-panel .p-dropdown-items p-dropdownitem:not(last-child) .p-dropdown-item {
    border-bottom: none !important;
  }
}

// p-confirmPopup styles starts
.p-confirm-popup {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.p-confirm-popup:before {
  border-bottom-color: #ccc !important;
}

.p-confirm-popup:after,
.p-confirm-popup:before {
  left: calc(var(--overlayArrowLeft, 0) + 0rem) !important;
}

.pi-exclamation-triangle:before {
  color: #f4a005 !important;
}

// p-confirmPopup styles end

.dc-tab-container {
  column-gap: 18px;

  span.pi-sitemap {
    color: #a3a6b4;
    font-size: 19px;
    transform: rotate(90deg);
    margin-right: 5px;
  }

  .tab-height {
    height: 50px;
  }

  .tab-label {
    color: #a3a6b4;
    flex: 1%;

    h3 {
      font-weight: bold;
      position: relative;
      top: 17%;
      font-size: 1.4rem;
      width: 93%;
      padding-left: 4rem;
    }
  }

  .activePetTab {
    color: #3d3d63;
    border-bottom: 4px solid #19a0da;
  }

  .help-video-section {
    position: relative;
    margin-top: -37px;
    display: inherit;
  }

  .pi-server {
    &::before {
      content: '' !important;
    }
  }
}

.dc-icon {
  background-repeat: no-repeat;
  width: 25px;
  height: 32px;
  margin: -1px 10px 0 -38px;
  position: absolute;
  transform: none !important;
}

.pi-sitemap-big {
  background-image: url("./assets/images/sitemap-big.svg") !important;
  @extend .dc-icon;
}

.pi-server {
  background-image: url("./assets/images/server.svg") !important;
  @extend .dc-icon;
}

.icon-lock-background {
  &.locks {
    height: 34px !important;
    margin: 0px 4px !important;
    cursor: pointer;
    border-radius: 0 !important;
    padding: 5px 25px 0px 0px !important;
    // border: 1px solid #E0E0E0;

    .icn-unlock {
      //  color: #2F3440;
      background-image: url("./assets/images/UnLock_1.svg") !important;
      background-repeat: no-repeat;
      position: absolute;
      height: 22px;
      width: 25px;
    }

    .icn-lock {
      //  color: #2F3440;
      background-image: url("./assets/images/Lock_1.svg") !important;
      background-repeat: no-repeat;
      position: absolute;
      height: 22px;
      width: 25px;
    }

    &.yellow {
      background: #febc54;
    }
  }
}

.slider-toggle {
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    left: 0px !important;
  }
}

.doc-checklist-modal {
  .p-dialog-footer {
    padding-top: 0.5rem !important;
  }
}

.documents-container {
  .p-tree-empty-message {
    text-align: center;
    padding: 20px 0;
  }
}

.dc-dropdown-panel {
  .p-dropdown-item {
    font-size: 15px !important;
    cursor: pointer;
    font-weight: 400;
  }
}

mat-accordion#e-file-form {
  .mat-expansion-panel {
    border: none;
    margin-bottom: 15px;
  }

  .mat-expansion-panel .mat-expansion-panel-header {
    padding: 0px 20px;
    font-family: "Source Sans Pro";
    font-size: 16px;
    background: #f5f7f7;
    border-radius: 4px;
    border: 1px solid #e1e7ee;
    opacity: 1;
    background-color: #e1e7ee;
    color: #4a4a4a;
    opacity: 1;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .mat-accordion .mat-expansion-panel:last-of-type {
    margin-bottom: 15px;
  }

  .mat-expansion-panel-content {
    border: 1px solid #e1e7ee;
  }

  h5.labelText {
    font-weight: 600;
    font-size: 18px !important;
    font-family: "Source Sans Pro";
    letter-spacing: 0px;
    color: #465f7b;
    opacity: 1;
  }

  .label-cls {
    font-family: "Source Sans Pro";
    letter-spacing: 0px;
    color: #24262f;
    opacity: 1;
    font-size: 14px !important;
  }

  div.slider-toggle {
    h6 {
      color: #4d4f5c !important;
      opacity: 1;
      font-family: "Source Sans Pro" !important;
      font-size: 14px !important;
      font-weight: 600;
    }
  }

  input,
  .im-input {
    font-size: 14px !important;
  }

  div {
    hr {
      border-top: 2px dashed rgba(0, 0, 0, 0.1);
    }
  }

  mat-radio-button {
    .mat-radio-label-content {
      font-size: 14px;
    }
  }

  .mat-expansion-panel-header.mat-expansion-toggle-indicator-before .mat-expansion-indicator {
    background-color: #e4eaea;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

  .mat-expansion-indicator::after {
    position: relative;
    left: 6px;
    top: -5px;
    color: #7c76bb;
  }

  mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          border-top: none;

          mat-select {
            border: 1px solid #c3d0de;
          }
        }
      }
    }
  }
}

form#previewEfileDialog {
  height: 450px;
  overflow-y: auto;

  .mat-expansion-panel {
    margin-bottom: 15px !important;

    div.mat-expansion-panel-body {
      pointer-events: none !important;
    }
  }
}

#previewModal {
  mat-expansion-panel {
    div.mat-expansion-panel-content {
      visibility: visible !important;
      display: block !important;
      height: auto !important;

      span.invalid-message {
        display: none !important;
      }
    }
  }

  div.efile-seperator {
    display: none !important;
  }

  #e-file-button {
    display: none !important;
  }

  mat-form-field {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          border-top: none;

          mat-select {
            border: 1px solid #c3d0de;
          }
        }
      }
    }
  }
}

.app-container {
  &.bene-quest-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-height: 100vh !important;
    background-color: #666666 !important;
  }
}

// Start: merge conflict fix
.upper-case {
  text-transform: uppercase;
}

body {
  // font-family: 'source sans pro';
  font-family: "Source Sans Pro", sans-serif;
}

.lead-notes {
  max-width: 60vw !important;
  min-width: 60vw !important;

  .mat-dialog-container {
    background-color: #fffbf5 !important;
    padding: 0px;
    overflow-x: hidden;

    .loading-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: auto;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;

      span {
        position: absolute;
        width: 65px;
        height: 25px;
        margin: auto;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
      }

      &::before {
        position: absolute;
        content: "";
        background-color: rgba(0, 0, 0, 0.2);
        width: 100%;
        height: 100%;
      }
    }

    .mat-dialog-title {
      padding: 10px 24px;
      border-bottom: 1px solid #e5d5a1;
      background-color: #fff3df;
      margin-bottom: 0px;
    }

    mat-dialog-content {
      padding: 0px 24px;
      margin: 0px;
      min-height: 35vh;
      max-height: 35vh;
      position: relative;

      h4 {
        font-weight: 600;
      }

      textarea {
        width: 100%;

        &.notesInput {
          background-color: transparent !important;
          border: 0px !important;
        }
      }
    }

    .mat-dialog-actions {
      margin-bottom: unset !important;
      border-top: 1px solid #e5d5a1;
      min-height: unset;
      padding: 0px 24px;
    }
  }
}

.shake-any {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

// End: merge conflict fix

.updateProfile {
  position: absolute;
  display: flex;
  padding-left: 338px;
  padding-bottom: 21px;
  z-index: 1;
}

.updateEducation {
  position: absolute;
  display: flex;
  padding-left: 200px;
  padding-bottom: 20px;
  z-index: 1;
}

.updateExperience {
  position: absolute;
  display: flex;
  padding-left: 180px;
  padding-bottom: 20px;
  z-index: 1;
}

.updateKExperience {
  position: absolute;
  display: flex;
  padding-left: 408px;
  padding-top: 29px;
  z-index: 1;
}

.updateKImmigration {
  padding-left: 200px;
  position: absolute;
  display: flex;
  top: 2px;
  z-index: 1;
}

.updateI-140-Immigration {
  padding-left: 350px;
  position: absolute;
  display: flex;
  padding-bottom: 22px;
  z-index: 1;
}

.dc-tab-container {
  column-gap: 18px;

  span.pi-sitemap {
    color: #a3a6b4;
    font-size: 19px;
    transform: rotate(90deg);
    margin-right: 5px;
  }

  .tab-height {
    height: 50px;
  }

  .tab-label {
    color: #a3a6b4;
    flex: 1%;

    h3 {
      font-weight: bold;
      position: relative;
      top: 17%;
      font-size: 1.4rem;
      width: 93%;
      padding-left: 4rem;
    }
  }

  .activePetTab {
    color: #3d3d63;
    border-bottom: 4px solid #19a0da;
  }

  .help-video-section {
    position: relative;
    margin-top: -37px;
    display: inherit;
  }
}

.dc-icon {
  background-repeat: no-repeat;
  width: 25px;
  height: 32px;
  margin: -1px 10px 0 -38px;
  position: absolute;
  transform: none !important;
}

.pi-sitemap-big {
  background-image: url("./assets/images/sitemap-big.svg") !important;
  @extend .dc-icon;
}

.pi-server {
  background-image: url("./assets/images/server.svg") !important;
  @extend .dc-icon;
}

.slider-toggle {
  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    left: 0px !important;
  }
}

.doc-checklist-modal {
  .p-dialog-footer {
    padding-top: 0.5rem !important;
  }
}

.documents-container {
  .p-tree-empty-message {
    text-align: center;
    padding: 20px 0;
  }
}

.dc-dropdown-panel {
  .p-dropdown-item {
    font-size: 15px !important;
    cursor: pointer;
    font-weight: 400;
  }
}

.personal-details-header {
  .p-accordion-header {
    background: #465f7d !important;

    .p-accordion-header-link {
      background: none !important;
      margin-right: 100px !important;
      width: auto !important;

      .p-accordion-header-text {
        padding-left: 4rem !important;
      }
    }
  }
}

.list-inner {
  .cdk-column-docName {
    span.p-checkbox-icon {
      margin-bottom: 0 !important;
    }
  }
}

.lcaProgress {
  .p-progressbar-label {
    font-size: 12px !important;
  }
}

.manage-case-status {
  .filter-manage-case-status {
    .p-overlaypanel {
      top: 55px !important;
      left: 1rem !important;
    }

    .p-multiselect {
      .p-multiselect-label {
        padding: 0.4rem 0.5rem !important;
      }
    }
  }

  .p-datatable-gridlines {
    margin-top: -1.5rem !important;
  }

  .p-datatable-thead {
    tr {
      height: 50px;
    }
  }

  p-table {
    p-divider {
      float: left;

      .p-divider-vertical {
        width: 5px;
        height: 30px;
        float: left;
        padding: 0;
        margin: 0;

        &.status-divider {
          height: 40px;
        }
      }
    }
  }
}

.company-estd-date-year {
  .estd-date-field {
    width: 48%;
  }

  .estd-or-field {
    width: 4%;

    span {
      width: 100%;
      text-align: center;
      margin-top: 1rem;
    }
  }

  .estd-year-field {
    width: 48%;
  }
}

.naicsCodeMain {
  mat-form-field.custom-multidropdown {
    div.mat-form-field-wrapper {
      div.mat-form-field-flex {
        padding: 0px;

        .mat-form-field-infix {
          border-top: 0px;
        }
      }
    }
  }
}

p-multiSelect {
  .p-multiselect {
    width: 100%;
  }
}

.align-tabs {
  padding: 10px;
}

.lbl-text {
  // max-width: 77.2222%;
  text-align: center;
  font: normal normal 600 22px/14px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
}

.activeTimeLine {
  color: #01a0da;
}

.activeI9Records {
  color: #01a0da;
}

.tab-label {
  &.externel-links {
    background: none !important;
  }
}

.activeTimeLine:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid #19a0da;
  left: 0;
  right: 0;
  bottom: -5px;
  margin: auto;
  z-index: 1;
}

.p-dialog {

  .close-icon,
  .p-dialog-header-close-icon {
    background-image: url(./assets/images/modal-close.svg) !important;
    width: 26px !important;
    height: 26px !important;
    display: inline-block !important;
    cursor: pointer !important;

    &.pi-times:before {
      content: "" !important;
    }
  }
}

#beneficiaryProfileList {
  p-table {
    .p-datatable table {
      table-layout: auto;
    }

    .p-datatable-tbody {
      tr:hover {
        background-color: #f8f8f8;
      }
    }
  }

  .deleteleaddialog .p-dialog {
    .p-dialog-content {
      overflow: auto;
      min-height: 250px;
      max-height: 300px;
    }

    .p-dialog-header {
      border-bottom: 0px solid #e1e1e1;
      padding: 1em;
    }

    .p-dialog-title {
      margin-left: 10px;
    }
  }
}

#manageCustomQuestionnaire {
  p-tabView {
    .p-tabview .p-tabview-nav {
      border: none !important;
      background-color: #fff !important;
      padding: 0px 25px !important;
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background-color: #fff !important;
      color: #2196f3 !important;
      border-bottom: 4px solid #2196f3 !important;
      font-weight: 900 !important;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      background-color: #fff !important;
      color: #333 !important;
      border: none !important;
      font-weight: 900 !important;
    }
  }
}

#visaBulletingDetails {
  p-tabView {
    .p-tabview .p-tabview-nav {
      border: none !important;
      background-color: #fff !important;
      padding: 25px !important;
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background-color: #fff !important;
      color: #19191a !important;
      /* border-bottom: 4px solid #2196f3 !important; */
      font-weight: 900 !important;
      border-radius: 20px !important;
      border: 1px solid #898585 !important;
      box-shadow: 1px 1px 1px -1px;
      padding: 15px 8px !important;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      background-color: #fff !important;
      color: #333 !important;
      border: none !important;
      font-weight: 900 !important;
      padding: 15px 8px !important;
    }

    ul {
      display: flex;
      justify-content: center;
    }
  }

  p-dropdown {
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      padding: 0.5rem 3rem 0.5rem 0.5rem;
    }
  }
}

#visaBulletingDetailsSub {
  p-tabView {
    .p-tabview {
      margin-top: 0px;
      border: none !important;
    }

    .p-tabview .p-tabview-nav {
      border: none !important;
      background-color: #fff !important;
      padding: 15px !important;
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background-color: #4a64ad !important;
      color: #eaeaf0 !important;
      font-weight: 900 !important;
      border: 1px solid #898585 !important;
      border-radius: 0px !important;
      display: flex;
      justify-content: center;
      text-align: center;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      background-color: #fff !important;
      color: #4a64ad !important;
      border: 1px solid #4a64ad !important;
      font-weight: 900 !important;
      display: flex;
      justify-content: center;
      text-align: center;
    }

    ul {
      display: flex;
      justify-content: center;
    }
  }
}

#visaBulletinginnerTab {
  p-tabView {
    .p-tabview {
      margin-top: 0px;
      border: none !important;
    }

    .p-tabview .p-tabview-nav {
      border: none !important;
      background-color: #fff !important;
      padding: 15px 0px !important;

      li {
        margin-right: 15px;
      }
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      background-color: #e8ebf4 !important;
      color: #2c4faf !important;
      font-weight: 900 !important;
      border: 1px solid #e8ebf4 !important;
      border-radius: 23px !important;
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 5px !important;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      background-color: #dadada !important;
      color: #5a5858 !important;
      font-weight: 900 !important;
      border: 1px solid #dadada !important;
      border-radius: 23px !important;
      display: flex;
      justify-content: center;
      text-align: center;
      padding: 5px !important;
    }

    ul {
      display: flex;
      justify-content: flex-start;
    }
  }
}

#createSection {
  p-multiselect {
    div.p-multiselect.p-multiselect-open {
      div.p-multiselect-panel.p-component {
        z-index: 2 !important;
      }
    }

    .p-multiselect-filter-container {
      input.p-inputtext {
        width: 80% !important;
      }
    }

    .p-multiselect-filter-container::before {
      content: "All" !important;
      margin-right: 6px !important;
    }
  }
}

.custom-questionnaire-dialog-container {
  mat-dialog-container {
    padding: 0 !important;
  }
}

.draft-save-confirm {
  .p-confirm-dialog-message {
    margin-left: 0.5rem !important;
  }
}

app-visa-timeline {
  p-timeline {
    div.p-timeline-event {
      .p-timeline-event-content {
        #customCaseView {
          button {
            height: auto;
            padding: 0px !important;
            padding-top: 8px !important;
          }

          button.dropdown-toggle::after {
            display: none;
          }
        }
      }
    }
  }
}

#notificationSettings {
  p-tabView {
    .p-tabview {
      border: none !important;
    }

    .p-tabview .p-tabview-nav {
      li {
        background-color: #ececec !important;
      }
    }

    .p-tabview .p-tabview-nav {
      border: none !important;
      background-color: #fff !important;
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      // background-color: #fff !important;
      color: #2196f3 !important;
      border-bottom: 4px solid #2196f3 !important;
      font-weight: 900 !important;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link {
      // background-color: #fff !important;
      color: #333 !important;
      border: none !important;
      font-weight: 900 !important;
    }
  }

  .p-tabview-panels {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

#templateBuilder {
  p-chips {
    .p-chips {
      display: block !important;
    }
  }
}

lib-custom-date-time-picker {
  p-calendar {
    .p-component.p-button {
      background: #fff !important;
      color: #8f8e8e !important;
    }
  }
}

.create-task-new-dialog,
.add-additional-details-dialog {
  .p-dialog-header {
    padding: 0.5rem 0.5rem 0.5rem 1rem !important;
  }

  .p-dialog-content {
    overflow-y: hidden !important;
  }

  .sub-task-type-text {
    b {
      text-transform: capitalize;
    }
  }

  vd-switch-group {
    textarea.form-control {
      max-width: 100% !important;
    }
  }
}

.task-additional-details {
  .p-card.p-component {
    background-color: #f0f9fe !important;

    .p-card-body {
      padding: 0.5rem 1rem !important;

      .p-card-content {
        display: flex;
        padding: 0.25rem 0 !important;
      }
    }

    .flex-direction-col {
      flex-direction: column;
      align-items: end;
    }

    button {
      margin-top: auto;
      border-radius: 4px !important;
      height: 28px;
      width: 108px;
      padding: 0.5rem !important;
    }
  }
}

.label-large-bold {
  font-weight: 600 !important;
  font-size: 0.95rem !important;
}

.policyAddQuestions {
  .p-dialog-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .p-dialog-header {
    padding-bottom: 0px !important;
  }
}

.expanded {
  background-color: #f2f2f2 !important;
}

tr.expanded+tr {
  background-color: #f2f2f2 !important;
}

.attorney-notifications {
  .dashboard-regular-card {
    max-height: 1254px !important;
    overflow-y: unset;

    .mat-card-header-text {
      margin-left: 0 !important;
    }

    .mat-card-actions {
      padding: 0 !important;
    }
  }
}

.tasklist-card {
  .p-card-body {
    padding-top: 0 !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  .tab-label {
    .p-dropdown {
      border: none !important;
      background: none !important;

      &.p-focus {
        box-shadow: none !important;
      }

      .p-dropdown-trigger {
        width: 1.3rem !important;
      }
    }
  }
}

.w-600 {
  font-weight: 600;
}

.w-800 {
  font-weight: 800;
}

.view_project_timesheet {
  .date_range {
    background: #f8f9fa;
    padding: 20px;
  }

  .parent_table {
    table thead.p-datatable-thead {
      background: #e0f0f7;
    }

    .child_table {
      table thead.p-datatable-thead {
        background: #edf4fb !important;
      }
    }
  }

  .p-datatable .p-datatable-thead>tr>th {
    padding: 20px 25px 10px 10px !important;
  }

  .approved {
    color: #01a0da;
    font-size: 12px !important;
  }

  .rejected {
    color: #980412;
    font-size: 12px !important;
  }
}

.timesheet_popup {
  .p-dialog-header {
    border-bottom: 2px dashed #ccc;
    padding-bottom: 10px !important;
  }

  .p-dialog-content {
    padding-top: 10px !important;
  }
}

.create_project_timesheet_dialog {
  .p-dialog-footer button {
    width: 80px !important;
    height: 40px !important;
    border-radius: 10px !important;
    padding: 10px !important;
  }

  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 0;
  }
}

.create_project_timesheet {
  .p-radiobutton .p-radiobutton-box.p-highlight {
    //border-color: #ccc !important;
    //background: #fff !important;
  }

  p-table {

    th,
    td {
      padding: 5px;
      text-align: center;

      input {
        padding: 5px 10px;
        text-align: center;
      }
    }
  }

  .p-datatable .p-datatable-tfoot>tr>td table thead.p-datatable-thead {
    background: #e0f0f7;
    height: 50px;
  }

  #caseRequestMainDiv {
    mat-horizontal-stepper#caseRequestSubDiv {
      div.mat-horizontal-stepper-header-container {
        pointer-events: none !important;
      }
    }
  }


  .p-datatable .p-datatable-thead>tr>th {
    padding: 10px 6px 10px 0px;
    width: 135px;
  }

  .p-datatable .p-datatable-thead>tr>th:nth-child(2) {
    width: 200px;
  }

  .p-datatable .p-datatable-thead>tr>th:first-child {
    text-align: left !important;
    padding: 0 25px;
  }

  .p-datatable .p-datatable-thead>tr>th {
    text-align: center !important;
  }

  .p-datatable .p-datatable-tbody>tr>td,
  .p-datatable .p-datatable-tfoot>tr>td {
    width: 135px;
    text-align: center;
  }

  .p-datatable .p-datatable-tbody>tr>td:nth-child(2) {
    width: 200px;
  }

  .p-datatable .p-datatable-tfoot>tr>td:first-child {
    width: 390px;
    padding: 5px 0;
  }

  .p-datatable-scrollable-body>table>.p-datatable-tbody>tr>td:first-child {
    text-align: left !important;
  }

  input,
  .p-dropdown {
    height: 40px;
  }

  .p-component:disabled {
    opacity: 0.3;
    background: transparent;
  }

  .p-dropdown .p-dropdown-label {
    padding: 0 5px;
    font-size: 14px !important;
    width: 140px;
    align-content: center;
  }

  .p-dropdown-items-wrapper {
    min-width: 200px !important;
  }

}

.timesheet-summary {

  .p-datatable .p-datatable-thead>tr>th,
  .p-datatable .p-datatable-tbody>tr>td {
    padding: 0.5rem !important;
  }
}

.timesheet-summary,
.correction-summary,
.attendance-summary {

  .summary_details_heading,
  .summary_details_content {
    color: #000;
    font-size: 14px;
  }

  .summary_details_heading {
    font-weight: 600;
  }

  .absent {
    color: #fd747c;
  }

  .present {
    color: #4d823c;
  }

  .attendance_details_heading {
    color: #4d4f5c;
    font-size: 14px;
    font-weight: 600;
  }

  .session {
    display: inline-block;
  }

  .displayDate {
    padding-right: 0 !important;
    padding-left: 8px !important;
  }
}

.timesheet-summary {
  .present {
    color: #10a0da;
  }
}

.create_project_timesheet .p-datatable.p-datatable-striped .p-datatable-tbody>tr:nth-child(even) {
  background: #f5f7f7 !important;
}

.hideView {
  visibility: hidden;
}

.showView {
  visibility: visible;
}

.roles-responsibilities .p-accordion-content {
  border: none !important;
  background: transparent !important;
}

.roles-responsibilities .p-accordion .p-accordion-header .p-accordion-header-link {
  background: transparent !important;
}

.roles-responsibilities {
  .p-accordion-tab:not(.p-accordion-tab-active) {
    .p-toggleable-content {
      height: 0 !important;
      overflow: hidden !important;
    }
  }

  .p-accordion-tab.p-accordion-tab-active {
    .p-toggleable-content {
      height: 0 !important;
      overflow: auto !important;
    }
  }
}

.roles-responsibilities .p-accordion-tab-active .p-accordion-header .p-accordion-header-text.headercol {
  overflow: visible !important;
  display: block !important;
}

.roles-responsibilities .p-accordion-tab .p-accordion-header .p-accordion-header-text.headercol {
  overflow: hidden;
  text-overflow: ellipsis !important;
  display: -webkit-box;
  -webkit-line-clamp: 3 !important;
  /* number of lines to show */
  line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  text-align: justify;
}

.roles-responsibilities .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  max-width: 5%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.remove-billing-item {
  border: none !important;
}

.remove-billing-item .mat-button-focus-overlay {
  background: transparent !important;
}

.viewinvoice .p-datatable .p-datatable-tfoot>tr>td {
  text-align: right !important;
}

.viewinvoice .table thead.p-datatable-thead>tr,
.viewinvoice .p-datatable-tbody {
  border: 1px solid #ccc !important;
}

.create-invoice .p-error {
  font-size: 11px !important;
}

.custom-period-selection {
  .p-inputnumber-input {
    border-right: none !important;
    padding-top: 7px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .p-inputnumber-input:focus-visible {
    outline: none !important;
  }

  .currency {
    display: inline-block !important;
    width: 33%;
    position: relative;

    .p-dropdown.p-component:focus-visible {
      outline: none !important;
    }

    .p-dropdown.p-component {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-left: 0 !important;
      width: 120px !important;
    }
  }

  .currency:focus-visible {
    outline: none !important;
  }

  .currency::before {
    position: absolute;
    content: "";
    background: #ccc;
    width: 1px;
    height: 25px;
    left: 0;
    top: 20%;
    z-index: 9;
  }
}

#invoiceDate,
#invoiceDueDate {
  .p-calendar.p-calendar-w-btn {
    width: 50%;
  }
}

.link {
  font-size: 16px;
  color: #10a0da !important;
  cursor: pointer;
  text-decoration: none !important;
}

.sendBulkMessage {
  .p-dialog-header {
    padding-bottom: 0px !important;
  }

  .p-dialog-content {
    padding-bottom: 0px !important;
  }
}

.payslip_dialog_container {
  .mat-dialog-content {
    overflow: visible !important;
  }
}

.payslip_table {
  .p-datatable .p-datatable-thead>tr>th {
    border: 1px solid #000 !important;
  }

  .p-datatable .p-datatable-thead tr th {
    background: #fff !important;
  }

  .p-datatable .p-datatable-thead tr:nth-child(1) th {
    background: #EDEBDF !important;
    color: #EF080D !important;
  }

  .p-datatable .p-datatable-tbody>tr>td,
  .p-datatable .p-datatable-tfoot>tr:first-child>td {
    border-right: 1px solid #000 !important;
  }

  .p-datatable .p-datatable-tbody>tr>td {
    text-align: left;
  }

  .p-datatable .p-datatable-tbody>tr>td:nth-child(2) {
    text-align: right;
  }

  .p-datatable .p-datatable-tfoot>tr>td {
    border-top: 1px solid #000 !important;
    background: #fff;
    border: none !important;
    text-transform: capitalize;
  }

  .p-datatable .p-datatable-tfoot>tr:first-child td {
    border-bottom: 1px solid #333 !important;
    border-top: 1px solid #333 !important;

    h6 {
      color: #333 !important;
      font-weight: 800;
      font-size: 12px;
    }
  }

  .p-datatable .p-datatable-tfoot>tr>td {
    color: #EF080D;
    font-size: 12px;
  }

  .p-datatable .p-datatable-tfoot>tr:first-child td:nth-child(2),
  .p-datatable .p-datatable-tfoot>tr:first-child td:nth-child(4),
  .p-datatable .p-datatable-tfoot>tr:nth-child(2) td:nth-child(2) {
    text-align: right;
  }
}

/* .mat-dialog-container {
  overflow: unset !important;
  width: 70% !important;
  margin: 0 auto !important;
  min-height: 550px !important;
} */

.font-weight-600 {
  font-weight: 600;
}

.bulk_upload_container,
.create_project_timesheet {
  .upload_section {
    border-bottom: 1px dashed #7C7C7C;
  }

  .p-fileupload.p-fileupload-basic.p-component {
    width: 60%;
    border: 1px solid #1AA0DA;
    border-radius: 5px;
    text-align: right;
  }

}

.bulk_upload {
  display: inline-block !important;
  width: 60%;
}

.edit-talent-details {
  .p-chips .p-chips-multiple-container {
    padding: 0;
    margin: 0;
    border: none;
  }

  .p-chips .p-chips-multiple-container .p-chips-input-token input {
    padding: 10px;
  }

  .p-chips {
    display: block;
  }
}

.individual_upload_container {
  .im-input {
    margin: 0;
  }

  .p-fileupload.p-fileupload-basic.p-component {
    width: 50%;
    margin: 0 auto;
    border: 2px dashed #00A0DA;
    padding: 30px;
    border-radius: 5px;
    background: #E9F3FE;
    opacity: 0.6;
  }

  .p-button.p-fileupload-choose,
  .p-button.p-fileupload-choose:hover,
  .p-button.p-fileupload-choose:active {
    background: transparent;
    color: #000;
    border: none;
    font-weight: bold;
  }
}

.role-radio-style {
  width: 20px !important;
  position: relative;
  top: 16px;

}

.edit-talent-details {

  textarea:focus-visible {
    border: none !important;
    outline: 1px solid #ccc;
  }


  .btn_width {
    width: 80px;
  }
}

.talent_edit {
  color: #01a0da;
  background: transparent;
  border: 1px solid #01a0da;
}

.evaluation_details {
  .applicant_details {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    background: #F8F9FA;
    padding: 10px;

    .header {
      font-weight: 600;
      font-size: 14px;
    }

    .context {
      font-size: 14px;
    }
  }

  .rounds_section,
  .result_updates {
    .header {
      font-size: 14px;
    }

    .context {
      font-weight: 600;
      font-size: 14px;
    }
  }
}

/* .p-chips-multiple-container:focus-visible,.skills_selection:focus-visible.p-chips.p-component:focus-visible,.p-chips-input-token:focus-visible {
  outline: 1px solid red !important;
  border: none !important;
}
p-chips.skills_selection:focus-visible {
  border: 0;
  outline: none !important;
}
.p-dropdown.p-component:focus-visible {
  outline: none !important;
  border: none !important;
} */
/* ::ng-deep .p-dropdown.p-component:focus-visible {
  border: none !important;
  outline: 1px solid #ccc !important;
}
::ng-deep .p-chips .p-chips-multiple-container:focus-visible {
  border: none !important;
  outline: 1px solid #ccc !important;
} */

#simplifySteps {
  p-accordion {
    div.p-accordion-header {
      a {
        border-bottom: 1px dashed #ccc !important;
      }
    }

    div.p-toggleable-content {
      padding: 15px 0px 0px;
    }

    div.p-accordion-header.p-highlight {
      a {
        background-color: #EBFAFF !important;
      }
    }

    .p-accordion-content {
      border: none !important;
      // margin-top: 5%;
    }
  }
}

#simplifySteps {
  p-accordion {
    .personalTab .p-accordion-content {
      margin-top: 5%;
    }
  }
}

.simplifyTimeLine {
  .customVideoPortion {
    position: relative !important;

    div.help-video-section {
      position: absolute !important;
      top: 34px !important;
      right: 0px !important;
    }
  }

}

.disableAccordion {
  pointer-events: none;
  opacity: .6;
}

.skipPortion {
  p-inputSwitch {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#cusomJobdate {
  p-calendar {
    span.p-calendar {
      button {
        height: auto !important;
        font-size: 20px !important;
        top: 0px !important;
      }
    }
  }
}

#oNetPortion {
  mat-selection-list {
    mat-list-option {
      div.mat-list-item-content {
        padding: 0px !important;

        .mat-list-text {
          font-size: 14px !important;
        }
      }
    }

    mat-list-option.mat-list-option {
      .mat-list-item-content {
        mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
          background: #5060e9 !important;
        }
      }
    }
  }

}

#displayList {
  div.p-dialog-header {
    padding-bottom: 0px !important;
  }

  .p-dialog .p-dialog-content {
    overflow: hidden !important;
  }

  p-dropdown {
    .p-dropdown .p-dropdown-panel {
      left: 0 !important;
    }
  }
}

.addDutyPortion {
  min-height: 215px !important;
}

#imgLibrary {
  .dutyHeadPortion {
    width: 100%;

    .selectPortion {
      width: 6% !important;
      padding: 1rem 1rem !important;
    }

    .dutyPortion {
      width: 47% !important;
      padding: 1rem 1rem !important;
    }
  }
}

#cartDutyPortion {
  table {
    tbody {
      tr {
        td {
          padding: 1rem 1rem !important;
        }
      }
    }
  }
}

.headerSubPortion {
  ul {
    list-style: none;
    padding: 0px;
    display: flex;
    justify-content: right;
    gap: 10px;
    margin: 0px;

    li {
      display: flex;
    }

    li.firstPortion {
      // border-right: 1px solid #8E8E8E;
      justify-content: right;

      lib-step-status-update-component-library {
        min-width: 145px !important;
      }
    }

    li.secondPortion {
      padding-top: 3px;
      justify-content: right;
    }
  }

  .notepanel .p-overlaypanel {
    right: 100px !important;
  }
}

.alignRight {
  text-align: right;
}

#chatGpt {
  mat-form-field {
    border: 1px solid #ccc;
    border-radius: 4px;

    div.mat-form-field-infix {
      margin: 0px;
      padding: 0px;
      border-top: 0px;

      input {
        border: none !important;
      }
    }

    div.mat-form-field-wrapper {
      div.mat-form-field-flex {
        padding: 5px !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.bulk-upload-checkbox .mat-checkbox span {
  width: 16px !important;
  margin-bottom: 0px !important;
}

.bulk-upload-checkbox .mat-checkbox .mat-checkbox-frame {
  border: 2px solid rgba(0, 0, 0, .54) !important;
}

.bulk-upload-checkbox .mat-checkbox .mat-checkbox-ripple,
.bulk-upload-checkbox .mat-checkbox .mat-checkbox-persistent-ripple {
  width: 40px !important;
}

.infoPortion {
  display: flex;
  justify-content: end;
  align-items: center;

  p {
    margin: 0px;
    font-size: 14px;
    font-weight: 600;
    color: #333;
  }

  mat-icon {
    height: 16px;
    width: 16px;
    font-size: 16px;
  }
}

#wizardJobDescription {
  mat-tab-group {
    div.mat-tab-body-wrapper {
      padding-top: 15px !important;
    }

    mat-tab-header {
      div.mat-tab-label-container {
        div.mat-tab-labels {
          div.mat-tab-label-content {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.company-details-form {
  input {
    margin: 0 !important;
  }
}

#tabCustom {
  p-checkbox {
    label {
      margin-bottom: 0px !important;
    }
  }
}

.disclaimer-wrap .p-overlaypanel {
  top: 100px !important;
}

div.wizardSettings {
  div.p-dialog-content {
    overflow-y: hidden !important;
  }
}

div.wizardConfimPetition {
  div.p-dialog-content {
    overflow-y: hidden !important;
    padding-bottom: 15px;
  }
}

.remove_border .p-multiselect {
  border: 1px solid #c3d0de !important;
}

.footer_btn .cancel_btn .p-button.p-component {
  background: #ccc !important;
  color: #888 !important;
  border-color: #ccc !important;
}

.dialog_container.bulk_upload {
  .p-dialog .p-dialog-header .p-dialog-title {
    font-size: 22px !important;
    font-weight: 800 !important;
  }

  .p-dialog-header {
    border-bottom: 1px solid #ccc;
  }
}

.each-step-content-section.progress-section {
  .p-progressbar .p-progressbar-label {
    font-size: 12px;
    top: -6px;
  }

  .p-progressbar.p-component.p-progressbar-determinate {
    border-radius: 10px;

  }
}

.view-edit-quest-tooltip {
  max-width: 22.5rem !important;

  .p-tooltip-text {
    width: auto;
    white-space: normal !important;

    p {
      margin-bottom: 0.5rem !important;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-bottom: 0.5rem;

      li {
        padding-bottom: 0.5rem;
      }
    }
  }
}

.main-body.footer-adjust {
  min-height: calc(100vh - 190px) !important;
  height: auto !important;
}


.main-body.footer-adjust-v2 {
  min-height: calc(100vh - 252px) !important;
  height: auto !important;
}

.main-body.footer-adjust-v1 {
  min-height: calc(100vh - 290px) !important;
  height: auto !important;
}

.main-body.no-footer {
  min-height: calc(100vh - 20px) !important;
  height: auto !important;
}

.main-body {
  overflow: hidden;
  width: 100%;
}

.no-scroll {
  overflow: hidden;
}

.custom-questionnaire-dialog-container {
  max-width: 100% !important;
}

.visatypes .p-dropdown-item-group {
  background-color: #ddd !important;
}

.opacity-0 {
  opacity: 0;
}

.sidebar-accordion .p-accordion .p-accordion-content,
.sidebar-accordion .p-accordion .p-accordion-header {
  background-color: #eff5fd !important;
  border: none !important;
  text-align: justify !important;
  margin: 0;
  cursor: pointer;
}

.sidebar-accordion .p-accordion .p-accordion-content {
  padding: 0 0 0 30px !important
}

.mat-card-header-text {
  width: 100%;
}

.toast-middle-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 9999;
}

.toast-success {
  background-image: none;
}

.toast-error {
  background-image: none;
  // pointer-events: none !important;
}

.toast-warning {
  background-image: none;
}

.toast-container .ngx-toastr {
  padding: 15px 15px 15px 45px;
}

#feeCalculation {
  .p-accordion .p-accordion-content .p-datatable .p-datatable-thead>tr>th:last-child {
    width: 100px !important;
  }
}

.includeAssociate {
  mat-checkbox {
    label.mat-checkbox-layout {
      margin-bottom: 0px !important;

      span.mat-checkbox-label {
        font-size: 14px !important;
        font-weight: 300 !important;
      }
    }
  }
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(2n) .p-timeline-event-opposite {
  position: relative;
  left: 85px !important;
}

.beneficiaryInitiate .p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(2n) .p-timeline-event-opposite {
  position: relative;
  left: 65px !important;

  .completed-border-ben {
    border-left: 6px solid #4cba20;
    border-right: none;
  }

  .new-border-ben {
    border-left: 6px solid #8f8d8d;
    border-right: none;
  }

  .inprogress-border-ben {
    border-left: 6px solid #01a0da;
    border-right: none;
  }

  .new-border-ben {
    border-left: 6px solid #8f8d8d;
    border-right: none;
  }

  .submit-border-ben {
    border-left: 6px solid #3d9b89;
    border-right: none;
  }

  button {
    position: relative;

    &:after {
      background-color: #ddd;
      content: "";
      position: absolute;
      height: 2px;
      width: 83px;
      top: 16px;
      left: -88px;
    }
  }
}

.beneficiaryInitiate .p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(2n - 1) .p-timeline-event-opposite {
  position: relative;
  right: 80px !important;

  .completed-border-ben {
    border-right: 6px solid #4cba20;
    border-left: none;
  }

  .new-border-ben {
    border-right: 6px solid #8f8d8d;
    border-left: none;
  }

  .inprogress-border-ben {
    border-right: 6px solid #01a0da;
    border-left: none;
  }

  .new-border-ben {
    border-right: 6px solid #8f8d8d;
    border-left: none;
  }

  .submit-border-ben {
    border-right: 6px solid #3d9b89;
    border-left: none;
  }

  button {
    position: relative;

    &:before {
      background-color: #ddd;
      content: "";
      position: absolute;
      height: 2px;
      width: 83px;
      top: 16px;
      right: -88px;
    }
  }
}

app-timeline-step .mat-tab-body-content {
  overflow-x: hidden !important;
}

.primaryContact {
  mat-checkbox {
    label {
      font-size: 14px !important;
      margin-bottom: 0px !important;
      font-weight: 600 !important;
    }
  }
}

.checkBoxPortion {
  display: inline;

  p-checkbox {
    label {
      margin-bottom: 0px !important;
    }
  }
}

#emailSendContent {
  p-chips {
    div.p-chips.p-component {
      width: 100% !important;

      ul {
        width: 100% !important;
      }
    }
  }
}

table#signatureUpload {
  tbody {
    tr {
      td {
        text-align: left !important;
      }
    }
  }
}

#signatureUpload {
  p-fileupload {
    div.p-fileupload {
      span.p-fileupload-choose {
        padding: 0px;
        background-color: #fff;
        border: none;
        color: #3284c2;
        font-size: 14px;
      }
    }
  }

  thead {
    position: relative;
    top: -38px;
  }

  tbody {
    tr {
      border-bottom: 1px solid #eae1e1;
    }
  }
}

// .generatePetition{
//   position: relative !important;
//   top: 45px !important;
//   z-index: 1 !important;
//   border-bottom: 1px solid #e6e3e3 !important;
// }
// .generateCustomPetition{
//   z-index: 1 !important;
//   position: relative !important;
//   top: 36px !important;
// }


table#signatureCustomUpload {
  tbody {
    tr {
      td {
        text-align: left !important;
      }
    }
  }
}

#signatureCustomUpload {
  p-fileupload {
    div.p-fileupload {
      span.p-fileupload-choose {
        padding: 0px;
        background-color: #fff;
        border: none;
        color: #3284c2;
        font-size: 14px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #eae1e1;
    }
  }
}

.iconPortion {
  p-button {
    button {
      background-color: #fff !important;
      border: none !important;
      color: #7d7878 !important;
    }
  }
}

#uploadSign {
  p-fileupload {
    line-height: 1;

    div.p-fileupload {
      span.p-fileupload-choose {
        padding: 0px;
        font-size: 14px;
        color: #595757;
        background-color: #fff;
        border: none;
        font-weight: 600;

        span.p-button-label {
          position: relative;
          left: -8px;
        }
      }
    }
  }
}

.vd-master-data-titlesheet {
  .p-orderlist-controls {
    display: none;
  }

  .p-orderlist .p-orderlist-list .p-orderlist-item {
    padding: 1px;
  }

  .p-orderlist .p-orderlist-list {
    padding: 0;
  }

  .p-accordion .p-accordion-content {
    margin: 0;
  }

  .six-header .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:last-child,
  .six-header .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:nth-last-child(2),
  .six-header .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th:last-child,
  .six-header .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th:nth-last-child(2) {
    width: 12%;
  }

  .six-header .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:nth-last-child(3),
  .six-header .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th:nth-last-child(3) {
    width: 13%;
  }

  .five-header .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:last-child,
  .five-header .p-datatable.p-datatable-gridlines .p-datatable-tbody>tr>td:nth-last-child(2),
  .five-header .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th:last-child,
  .five-header .p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th:nth-last-child(2) {
    width: 15%;
  }

  .five-header .p-datatable .p-sortable-column .p-sortable-column-icon,
  .six-header .p-datatable .p-sortable-column .p-sortable-column-icon {
    color: #000;
    font-size: 13px;
    font-weight: bold;
  }
}

#immigrationTab {
  background-color: #fff !important;

  div.mat-tab-body-wrapper {
    background-color: #fff !important;

    mat-tab-body {
      background-color: #fff !important;
    }
  }

  mat-tab-header {
    div.mat-tab-label-container {
      div.mat-tab-list {
        div.mat-tab-labels {
          background-color: #fff;

          div.mat-tab-label {
            max-width: 180px !important;
            font-size: 15px !important;
          }

          div.mat-tab-label.mat-tab-label-active {
            font-weight: 600 !important;
          }

          div.mat-tab-label.mat-tab-label-content {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

.visaDetails {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  mat-radio-group {
    mat-radio-button {
      label {
        margin-bottom: 0px;
      }
    }
  }
}

.caseStatusMain {
  p-button {
    button {
      padding: 0px !important;
      background-color: transparent !important;
      border: none !important;
      color: #2196F3 !important;
    }
  }
}

.disableFrequency {
  opacity: .5;
  pointer-events: none;

  .p-inputnumber-input {
    background-color: #efefef;
  }
}

.small-input {
  input {
    width: 50px;
  }
}

.addMembers {
  p-button.addMembersBtn {
    button {
      width: 150px;

      span {
        padding-left: 6px !important;
      }
    }
  }
}

.education-container .mat-horizontal-content-container {
  overflow: visible;
}

.custom-stepper .mat-step-icon,
.custom-stepper .mat-step-header-ripple,
.custom-stepper .mat-stepper-horizontal-line,
.custom-stepper .mat-horizontal-stepper-header::after,
.custom-stepper .mat-horizontal-stepper-header::before {
  display: none !important;
}

.custom-stepper .mat-horizontal-stepper-header-container {
  padding: 0 !important;
  border-bottom: 1px solid #ced4da
}

.custom-stepper .mat-step-text-label {
  text-transform: capitalize;
}

.custom-stepper .mat-step-header {
  padding: 0 !important;
}

.custom-stepper .mat-step-label-selected {
  border-bottom: 3px solid #2da7d5;
}

.custom-stepper .mat-step-label {
  padding: 15px !important;
  font-size: 16px;
}

.custom-stepper .mat-step-header {
  margin: 0 !important;
}

.custom-stepper .mat-step-header:hover,
.custom-stepper .mat-step-header:focus {
  background-color: white;
}

.custom-stepper .mat-chip-input {
  border: none !important;
  margin: 0 !important;
}

.custom-stepper .mat-horizontal-content-container {
  padding-bottom: 0 !important;
}

.custom-stepper .mat-expansion-panel {
  overflow: visible;
}

.list-view-table .im-card-no-border-table tbody tr .mat-cell.mat-column-expandedDetail {
  padding-top: 0 !important;
  padding-bottom: 15px !important;
}

.printPetition {
  p-tabview {
    div.review-petition-tab-view {
      ul.p-tabview-nav {
        background-color: #fff !important;

        li {
          a.p-tabview-nav-link {
            background-color: #ccc !important;
            color: #000 !important;
          }
        }

        li.p-highlight {
          a.p-tabview-nav-link {
            background-color: #040456 !important;
            color: #fff !important;
          }
        }
      }

      ul.p-tabview-nav li:first-child {
        margin-right: 10px !important;
      }
    }
  }
}

#addNewMemeber{
  p-button {
    button {
      width: 100%;
      padding: 10px;
    }
  }
}

.custom-drop .p-dropdown .p-dropdown-panel {
  width: 200px;
}

.custom-drop .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    white-space: break-spaces;
}

.companyMenu{
  position: relative !important;
  p-menu{
    position: absolute !important;
    right: 30px !important;
    z-index: 1 !important;
    top: 38px !important;
    ul.p-menu-list{
      padding: 3px;
      li.p-submenu-header{
        padding: 5px 5px;
        span{
          margin-bottom: 0px;
        }
      }
      li.p-menuitem{
        padding-left: 0px;
        span{
          margin-bottom: 0px;
        }
      }
    }
  }
}
#userListActions{
  p-menu{
    ul.p-menu-list{
      li.p-menuitem{
        a{
          span{
            margin-bottom: 0px !important;
          }
        }
      }
    }
  }
}