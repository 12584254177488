.filter-p-multiselect {
  .p-multiselect.p-component {
    font-size: 0.7rem;
    width: 100%;
    height: 34px;
  }
}

.filter-check {
  .p-multiselect {
    width: 100%;
    float: right;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    visibility: hidden;
  }
}

.multiselect-custom {
  .p-multiselect {
    width: 100%;
    float: right;
  }
  .p-multiselect .p-multiselect-label {
    font-size: 14px;
  }
  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    visibility: hidden;
  }
}
.add-additional-details-dialog {
  .p-multiselect {
    border-color: #c3d0de !important;
  }
  .p-multiselect-open {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
  }
}

.role-multiselect {
  width: 100%;
  .p-multiselect-panel {
    width: 100%;
  }
}
.associate-company-multiselect{
  .p-multiselect {
    .p-multiselect-label.p-placeholder{
      font-size: 0.9rem !important;
    }
  }
}
.filter-manage-case-status{
  .p-multiselect-filter-container{
    padding-left: 30px !important;
  }
}