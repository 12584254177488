button.disabled,
button:disabled {
  cursor: not-allowed !important;
}

.p-button:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn {
  border-radius: $border-radius-base * 3 + 1 !important;
  opacity: 1;
  font: normal normal bold 14px/20px $font-family-base !important;
  min-width: 43px !important;
  text-transform: uppercase;
  border: 1px solid transparent;
  height: 43px;
  padding: 0px 29px !important;
  vertical-align: top;

  &.btn-primary {
    background-color: $border-blue;
    border: $border-blue;
    &:focus,
    &:hover,
    &:active {
      background-color: $border-blue-active;
      box-shadow: 0px 0px 10px #00000029 !important;
    }
  }
}

.im-btn.btn-primary {
  &:focus,
  &:hover,
  &:active {
    background-color: $border-blue-active !important;
  }
}

.p-button-outlined,
.im-btn {
  @extend .btn;
  &:focus,
  &:hover,
  &:active {
    background: transparent !important;
  }
  &.im-btn-primary.im-btn-md {
    &:focus,
    &:hover,
    &:active {
      background-color: $border-blue-active !important;
    }
  }
}

.tab_button {
  text-align: center;
  background: no-repeat padding-box #dedede;
  color: #5f6c74 !important;
  height: 41px !important;
  font: normal normal normal 22px/24px $font-family-base !important;
  border-radius: 0 !important;
  text-transform: none;
}

.active-button_blue {
  color: $white !important;
  background-color: $button-color-blue !important;
}

.btn-success,
.im-btn-primary {
  @extend .btn;
  color: $white;
  background-color: $border-blue !important;
  border: 1px solid transparent !important;
  &:focus,
  &:active,
  &:hover {
    box-shadow: 0px 0px 10px #00000029 !important;
  }

  &.btn-success_disabled,
  &:disabled {
    background-color: $default-button-bg !important;
    color: $border-gray !important;
    cursor: not-allowed !important;
  }
}

.btn-success {
  border: 1px solid transparent;
  &:focus,
  &:active,
  &:hover {
    background-color: $border-blue !important;
  }
}

.im-drop-icon {
  background-color: $button-color-blue;
  border-radius: $spacer;
}

.im-btn-outline-primary {
  &:hover {
    background-color: $button-color-blue;
    color: $white;
  }
}

.im-btn.btn-outline-primary {
  &:hover {
    color: $button-color-blue !important;
  }
}

.navbar-rgt-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  // background: $button-blue-dark;
  background: #043085;
  padding: 6px 15px;
  color: $white;
  @include im-font(12px, 12px);
  text-transform: uppercase;

  img {
    width: 25px;
    margin-right: 10px;
  }
  &:hover {
    color: $white;
    text-decoration: none;
  }
}

@media (max-width: $media-lg) {
  .navbar-rgt-btn {
    position: absolute;
    right: 10px;
    top: 5px;
  }
}

.btn-stepper {
  background-color: $button-light-gray !important;
  height: 32px !important;
  width: 32px !important;
  span {
    @include im-font(17px, 12px, bold);
    color: $white;
    vertical-align: initial !important;
    line-height: 0px !important;
    @extend .pl-0;
  }
}

.default-button {
  background: $default-button-bg !important;
  height: 43px;
  font: normal normal bold 14px/20px $font-family-base !important;
  color: $input-border !important;
}

.save-button_whitebg {
  border: 1px solid $border-blue;
  background: $white;
  height: 43px;
  @include im-font(14px, 14px, bold);
  color: $border-blue;
  .im-drop-icon {
    color: $white;
    margin: 0px;
    height: 25px;
    width: 25px;
    vertical-align: text-bottom;
  }
  &:hover {
    .im-drop-icon {
      background: $white;
      color: $border-blue;
    }
  }
}

.button_whitebg {
  //@include im-font(14px,20px, bold);
  border: 1px solid $border-blue !important;
  background: $white;
  height: 43px;
  color: $border-blue;
  text-transform: uppercase;
  padding: 0 17px !important;

  .im-drop-icon {
    color: $white;
    margin: 0px;
    height: 22px;
    width: 23px;
    font-size: 12px;
    padding-top: 7px;
  }
  &:hover,
  &:focus,
  &:active {
    background: $white;
    color: $border-blue-active !important;
    box-shadow: 0px 0px 10px #00000029;
    border: 1px solid $border-blue-active !important;
    .im-drop-icon {
      background: $border-blue-active;
      color: white;
    }
  }
  &:disabled {
    cursor: not-allowed !important;
    box-shadow: none;
    border: 1px solid $border-gray !important;
    color: $border-gray !important;
  }
}

.p-button-icon-only .p-button-label {
  display: none;
}
.button_whitebgImp {
  @extend .button_whitebg;
  color: $border-blue !important;
}

.button-width-icon-left {
  padding-left: 12px !important;
  //padding-top: 3px!important;
  padding-right: 17px !important;
  .fa {
    font-size: 24px;
    vertical-align: middle;
    height: 22px;
    width: 22px;
    margin-top: -6px;
  }
  &:hover,
  &:focus {
    .fa {
      background-color: transparent;
    }
  }
}

.button_bg-gray,
.back-button {
  @extend .btn;
  background: $border-light-gray;
  color: #465f7c;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid #a3a6b4;
    color: #47607d;
    box-shadow: 0px 0px 10px #00000029;
  }
}

.viewType {
  text-align: right;

  button {
    width: 155px;
    line-height: 40px;

    &.activebtn {
      background-color: #19a0da !important;
    }

    .mat-icon {
      position: relative;
      top: 6px;
      margin-right: 3px;
    }
  }
}

.taskFilter-btn {
  .pi-filter:before {
    color: #56addd;
    padding-left: 10px;
    font-size: 20px;
  }
}

.hideLabel {
  .p-button-label {
    display: none;
  }
}

.ui-button:enabled:focus {
  box-shadow: none;
}

.buttonWithDropDownMenu {
  &.settingButton {
    .p-button {
      background: white;
      width: 60px !important;
      border-radius: 3px;
      padding: 3px;
      height: 30px;
      border: 1px solid #c1b8b8;
      &:hover {
        background-color: transparent;
      }
      .fa {
        color: #475f7b !important;
        height: 24px;
        padding: 0;
        margin: 0;
        font-size: 24px;
        top: 10%;
        left: 10%;
        width: 50%;
      }
    }
    .p-button-label {
      color: #475f7b;
      font-size: 24px;
      font: normal normal normal 14px/1 FontAwesome;
      margin-left: 5px;
      margin-bottom: 0;
      &::after {
        content: "\f078";
      }
    }
  }
}

.buttonWithDropDownMenu {
  &.settingButton {
    .p-button {
      background: white;
      width: 52px !important;
      border-radius: 3px;
      padding: 3px;
      height: 30px;
      border: 1px solid #c1b8b8;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.p-picklist {
  .p-picklist-header {
    width: 400px;
  }
  .p-picklist-buttons {
    width: 150px;
    padding: 0 !important;
    margin-right: 0.5rem;

    .p-button {
      width: 100%;
      font: normal normal bold 14px/20px "Source Sans Pro" !important;
      &:last-child,
      &:nth-last-child(2) {
        background-color: $gray-lighter;
        color: $black;
        border-color: $gray-lighter;
      }

      .pi-angle-right {
        &:before {
          content: "Add ";
        }
        &:after {
          content: "\e932";
          font-size: 13px;
        }
      }
      .pi-angle-double-right {
        &:before {
          content: "Add All ";
        }
        &:after {
          content: "\e92e";
          font-size: 13px;
        }
      }
      .pi-angle-left {
        &:after {
          content: " Remove";
        }
        &:before {
          content: "\e931";
          font-size: 13px;
        }
      }
      .pi-angle-double-left {
        &:after {
          content: " Remove All";
        }
        &:before {
          content: "\e92d";
          font-size: 13px;
        }
      }
    }
  }
}

.vd-picklist.p-picklist {
  .p-picklist-header {
    width: 545px !important;
  }
  .p-picklist-buttons {
    width: 150px;
    padding: 0 !important;
    margin-right: 0.5rem;
    margin-left: 0.5rem;

    .p-button {
      width: 100%;
      font: normal normal bold 14px/20px "Source Sans Pro" !important;
      &:last-child,
      &:nth-last-child(2) {
        background-color: $gray-lighter;
        color: $black;
        border-color: $gray-lighter;
      }

      .pi-angle-right {
        &:before {
          content: "ADD ";
          font-size: 11px;
        }
        &:after {
          content: "\e932";
          font-size: 13px;
        }
      }
      .pi-angle-double-right {
        &:before {
          content: "ADD ALL ";
          font-size: 11px;
        }
        &:after {
          content: "\e92e";
          font-size: 13px;
        }
      }
      .pi-angle-left {
        &:after {
          content: " REMOVE";
          font-size: 11px;
        }
        &:before {
          content: "\e931";
          font-size: 13px;
        }
      }
      .pi-angle-double-left {
        &:after {
          content: " REMOVE ALL";
          font-size: 11px;
        }
        &:before {
          content: "\e92d";
          font-size: 13px;
        }
      }
    }
  }
}

.p-button.linkButton {
  background: transparent;
  color: $button-color-blue;
  border: 0;
  padding: 0;
  .p-button-icon {
    background: $button-color-blue;
    color: white;
    padding: 8px 10px;
    border-radius: 50%;
  }
  &:hover {
    background: transparent !important;
    color: $button-color-blue !important;
    .p-button-icon {
      background: $button-color-blue;
      color: white;
    }
  }
}

.icon-button {
  border: 0;
  background: transparent;
  &:hover {
    color: #19a0da;
  }
}

.link-button {
  border: 0;
  background: transparent;
  color: #19a0da !important;
  text-decoration: underline;
  text-transform: none;
  &:hover {
    border: 0;
    background: transparent;
    text-decoration: underline !important;
  }
  &:focus {
    border: 0;
    background: transparent;
    box-shadow: none !important;
  }
}

.copyButton {
  &.p-button {
    padding: 0px !important;
    &.p-button-outlined {
      height: 27px !important;
      border-radius: 3px !important;
      padding: 10px !important;
    }
    &.p-button-success {
      padding: 5px !important;
      height: 27px !important;
      background-color: #4cba20 !important;
    }
    &.copiedButton {
      padding: 5px !important;
      height: 27px !important;
      background-color: #4cba20 !important;
      color: white !important;
    }
    .p-button-label {
      font-weight: normal;
      font-size: 14px;
    }
  }
}

.lcaBrowseButton {
  &.p-button {
    background-color: #ffffff !important;
    color: #158fc4 !important;
    padding: 5px !important;
  }
}

.copyButton {
  &.p-button {
    padding: 0px !important;
    &.p-button-outlined {
      height: 27px !important;
      border-radius: 3px !important;
      padding: 10px !important;
    }
    &.p-button-success {
      padding: 5px !important;
      height: 27px !important;
      background-color: #4cba20 !important;
    }
    .p-button-label {
      font-weight: normal;
      font-size: 14px;
    }
  }
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  cursor: not-allowed !important;
}

.btn.vdCreateBtn {
  color: white;
  background-color: #1aa0da;
  border-radius: 3px !important;
  font-weight: 100 !important;
}
.btn.vdCancelBtn {
  color: #596f88;
  background-color: #e1e1e1;
  border-radius: 3px !important;
  font-weight: 100 !important;
}
.btn.vdResetBtn {
  color: #1aa0da;
  background-color: white;
  border-radius: 3px !important;
  border: 1px solid #1aa0da !important;
  font-weight: 100 !important;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
.p-button.menu-button {
  background: transparent;
  border: 1px solid $input-border-gray;
  padding: 0 !important;
  color: $input-border-gray;
  height: 25px;
  width: 3rem !important;
  padding-right: 2rem !important;
  .p-button-icon {
    padding-left: 1.8rem;
    margin-right: 1rem;
    margin-bottom: 0;
    padding-right: 0;
    position: absolute;
    font-size: 1rem;
  }
  .downArrowIcon {
    position: absolute;
    right: 5px;
  }
  &:focus,
  &:hover,
  &:active {
    background: transparent !important;
    border: 1px solid $input-border-gray !important;
    color: $input-border-gray !important;
  }
}

.create-task-btn.btn-add {
  line-height: 30px;
  i.fa-plus-circle {
    border: none;
    padding: 0;
    font-size: 18px !important;
    top: 2px;
    position: relative;
  }
}

.sync-btn span {
  font-weight: 700;
  font-size: 17px;
  line-height: 18px;
}

.external-links-container {
  .btn-create {
    .p-button-label {
      color: #3686b1;
      font-size: 13px;
      font-weight: 600;
    }
  }
}

.efile-btn {
  .p-button-rounded {
    display: contents;
  }
  .pi {
    padding: 10px 15px;
  }
}

.inline-edit-mode {
  .edit-controls {
    button {
      .p-button-icon {
        font-size: 12px;
      }
      &.save-btn {
        .p-button-icon {
          color: #28a745;
        }
      }
      &.clear-btn {
        .p-button-icon {
          color: #dc3545;
        }
      }
      &.cancel-btn {
        .p-button-icon {
          color: #7f7f7f;
        }
      }
    }
  }
}

.p-button {
  &.fd-taskFilter-btn,
  &.hover {
    color: #8c9195;
    background: #f5f7f7;
    border: 1px solid #dddddd;
    padding: 0.3rem 0.5rem;
  }
}

.fb-btn-icon {
  .pi {
    font-size: 25px !important;
  }
}

.syncBtn {
  .pi {
    padding-left: 10px !important;
  }
}
