.lcaProgress{
    .p-progressbar{
        height: 1.5rem;
        top:5px;
        .p-progressbar-determinate .p-progressbar-label{
        font-weight: 600;
        }
    }
    .p-progressbar .p-progressbar-value{
        background: lightskyblue!important;
    }
    .p-progressbar-label{
        line-height: 1.5rem!important;
    font-weight: 500!important;
    } 

}