.mat-stepper-horizontal{
    font-family: $font-family-base;
    width:100%;
    .mat-horizontal-content-container{
        @extend .pl-0;
        @extend .pr-0;

    }
    .mat-horizontal-stepper-header-container{
         width: 60%;
         margin-left: 20%;
         .mat-step-header{
             padding:10px 0;
             text-transform: uppercase;    
            .mat-step-label-active{        
                color: #01a0da !important;
            }
            .mat-step-icon-selected{
                background-color:  #01a0da !important;
            }
            .mat-step-label,.mat-step-text-label{
                font-weight: bold !important;
            }
         }
    }
}

