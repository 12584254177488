.beneficiary-experience-froala-editor {
  .fr-box.fr-basic.fr-top {
    .fr-toolbar.fr-top {
      border-radius: 0;
      .fr-btn-grp {
        margin: 0 5px 0 5px;
      }
    }
    .fr-wrapper {
      max-height: 115px !important;
      height: 115px !important;
      display: flex;
      flex-flow: column;
      .fr-element {
        flex-grow: 1;
      }
    }
    .second-toolbar {
      border-radius: 0;
    }
    .fr-sticky-on {
      position: absolute;
    }
  }
}
