.leftSidebar{
    border: 0.5px solid $border-gray;
    margin-left:15px;
    display: flex;
    flex-direction: column;
    a{
        &.im-list-group-item{
            padding: 20px 0 0 0;
            border:0; 
            width:250px;
            &.active {
                text-align: left;
                @include im-font(16px,22px, 600);
                letter-spacing: 0px;
                color: $active-tab-border!important;
                border-right:5px solid $active-tab-border;


              }
              span {
                border-bottom: 0.5px solid$border-gray;
                margin-left: 14px;
                width: 88%;
                padding-bottom: 20px;
                padding-left:14px;
                display: inline-block;
                
            }
            &:last-child{ span{
                border-bottom:none;
            }
            }
            &:first-child{ span{
                border-top:none;
            }
        }
    }
}
}

.im-list-group-item {
    width: 250px;
    white-space: nowrap;
    padding: 20px;
    color:$gray-dark;
    font-weight: bold;
    background-color: $white !important;
    border-bottom: 0.5px solid $border-gray;
    border-top: .5px solid $border-gray;
    &:last-child{
        border-bottom:none;
    }
    &:first-child{
        border-top:none;
    }
  }

  .mat-chip-list-wrapper{
    .mat-standard-chip,input.mat-input-element{
        @include im-font(14px,20px);
        color: $label-dark;
        margin-right: 15px;
        margin-top: 9px!important;
        margin-bottom: 18px!important;
    }
  }  
    
    .mat-chip.mat-standard-chip {
        background-color: #DAE9FD;
        color: #465F7C;
        @include im-font(14px,20px);
    } 

