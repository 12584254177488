.p-divider.p-divider-horizontal:before {
  border-top: 1px #c3c3c3;
}

.header-divider {
  .p-divider-horizontal.p-divider-left {
    margin-bottom: 1px;
  }
}

.tab-header-divider {
  .p-divider.p-divider-horizontal {
    margin: 0.3rem 0 1rem;
  }
}

.vd-container {
  .vd-build-steps {
    .p-divider.p-divider-horizontal.p-component {
      margin: 0;
      &:before {
        border-width: 2px;
      }
    }
  }
}

.vertical-dashed-blue {
  .p-divider-vertical {
    &:before {
      border-left: 2px dashed #94d3ef !important;
      margin-top: 0.2rem;
    }
  }
}

.divider-m-5 {
  &.p-divider.p-divider-horizontal {
    margin: 5px 0;
  }
}
