.im-navbar{
    // background: $active-tab-border ;
    background: rgb(8,130,188);
    background: linear-gradient(90deg, rgba(8,130,188,1) 0%, rgba(2,87,165,1) 100%);
    box-shadow: 0px 0px 13px #0000000F;
    height:50px;

    .im-nav-item{
        height:50px;
        .im-nav-link{
            display:block;
            @include im-font(14px,17px, 600);
            letter-spacing: -1px;
            color: #FFFFFF;
            text-shadow: 0px 1px 0px #17040429;
            text-transform: uppercase;
            border-right: 1px solid #1EA7DB;
            border-left: 1px solid #0B93C6;
            padding: 7px 10px;
            height:35px;
            margin-top:7px;
            &.dropdown-toggle:after{
                content: "\f078";
                font-family: 'FontAwesome';
                font-weight: 900;
                display: inline-block;
                margin-left: 8px;
                vertical-align: 0;
                border: 0;
                font-size: 12px;
            }
        }
        &:hover,&.active{
            background: #005f93;
            color:$white;
            .im-nav-link{
            border-left: 1px solid #2f8dc9;
            border-right: 1px solid #2f8dc9;
            }
        }
        &:first-child{
            border-left:0
        }
        &:last-child{
            border-right:0;
        }


}
}

.navbar-light {
    .dropdown-menu, .im-dropdown-menu{
        min-width: 100%;
        margin-top: 8px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0;
        padding:0px;
        a{
            @include im-font(14px,17px, 600);
            letter-spacing: -0.09px;
            color: #4D4F5C;
            text-transform: uppercase;
            padding: 12px 19px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            &:last-child{
                border:0;
            }
            &:hover{
                background:#F5F6F9;

            }

        }

    }

}
