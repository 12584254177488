.mat-expansion-panel {
  border: 1px solid $border-light-gray;
  box-shadow: none !important;
  .mat-expansion-panel-header {
    height: 60px !important;
    padding: 0 20px;
    font-family: $font-family-base;
    font-size: 18px;
    background: $tab-gray-default !important;
    &:focus,
    &:hover {
      background: $tab-gray-default !important;
    }
    .mat-expansion-panel-header-title {
      color: $panel-header;
    }
  }
}

.mat-expansion-panel-content {
  .mat-expansion-panel-body {
    padding: 0px;
    .timeline-row {
      text-align: center;
      background: no-repeat padding-box #dedede;
      color: #5f6c74;
      height: 41px;
      @include im-font(22px, 24px);
    }
  }
}

.col-styled {
  padding-top: 7px;
}

.petition-timeline {
  padding-top: 44px;
  .timeline-content {
    padding: 5px;
    border-radius: 0;
    display: block;
    position: relative;
    min-height: 32px;
    bottom: 0;
    max-height: 60px;
    margin: 5px;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #ededed;
    color: #657071;
    border: 0 !important;
    border-left: 3px solid #ededed;
    white-space: break-spaces;
    line-height: inherit;
    @include im-font(15px, 21px);

    &:hover {
      text-decoration: none;
    }
    &:before {
      content: "";
      transform: translateY(-50%);
      border: 2px solid #e1e1e1;
      position: absolute;
      width: 75px;
      left: -80px;
      top: 50%;
      height: 1px;
    }
  }
  .timeline {
    .inprogress {
      .inprogress {
        background-color: #c4e5ef;
        border: 0 !important;
        border-left: 3px solid #01a0da !important;
      }
    }
  }
}

.mat-stepper-horizontal.pb-mat-stepper-custom-horizontal {
  .mat-horizontal-stepper-header-container {
    width: 100%;
    margin-left: 0;
    .mat-horizontal-stepper-header {
      .mat-step-label {
        padding: 5px 10px;
        top: 5px;
      }
    }
  }
}

.background_white {
  background: white;
}

.blue_panel {
  background-color: $light-blue;
  .mat-form-field-appearance-outline .mat-form-field-flex .mat-form-field-infix {
    margin-top: -3px;
  }
}

.white_panel {
  background-color: $white;
  height: 60px;
}

.customPanel .p-overlaypanel {
  border: 0;
  bottom: 40px !important;
  left: 0% !important;
  top: auto !important;
  &::before,
  &:after {
    border: 0 !important;
    border-color: transparent !important;
  }
  .p-overlaypanel-content {
    padding: 0 !important;
    padding-bottom: 20px !important;
    border: 1px solid $border-color;
    .p-dropdown .p-dropdown-panel .p-dropdown-items-wrapper {
      max-height: 100px !important;
    }
    p-button {
      display: block;
    }
    > .p-button {
      background: transparent;
      border: 0;
      width: 100%;
      font-size: 14px;
      color: $input-color-base;
      text-align: left;
      border-bottom: 1px solid $border-color;
      height: 30px;
      border-radius: 0;
      &:hover {
        background-color: $default-button-bg;
        border-color: $border-color;
      }
      .p-button-label {
        margin-bottom: 0;
        color: $gray-darker !important;
      }
    }
  }
}

.rfeTimelineLeft {
  margin-left: -100%;
  .p-timeline.p-timeline-vertical .p-timeline-event-content .separator {
    width: 5% !important;
  }
}

.rfeTimeline {
  margin-top: 30px;
  .p-timeline-event-connector {
    width: 5px;
  }
  .p-button {
    padding: 5px;
    border-radius: 0;
    min-height: 32px;
    max-height: 60px;
    width: fit-content;
    background-color: $gray-lighter;
    color: #657071;
    border: 0 !important;
    border-left: 3px solid $gray-lighter;
    white-space: break-spaces;
    font-family: "Source Sans Pro";
    @include im-font(15px, 22px);
    margin-top: -4px;
    //z-index: 1;
    border-left: 4px solid #4d4f5c !important;
    &:hover,
    &:focus {
      background-color: #e4e4e4 !important;
      color: $gray-darker !important;
    }
  }
  .inprogress {
    .p-button {
      background-color: #c4e5ef;
      border-left: 4px solid #01a0da !important;
      &:hover,
      &:focus {
        background-color: #bcdce6 !important;
      }
    }
  }
  .complete {
    .p-button {
      background-color: #dff4dd;
      border-left: 4px solid #4cba20 !important;
      &:hover,
      &:focus {
        background-color: #d6ead4 !important;
      }
    }
  }
  .submitted {
    .p-button {
      background-color: #84e0ce;
      border-left: 4px solid #54a796 !important;
      &:hover,
      &:focus {
        background-color: #84e0ce !important;
      }
    }
  }

  .custom-marker {
    height: 28px;
    width: 28px;
    // border: 2px solid #fff;
    // box-shadow: 0 0 1px 4px #189cd5;
    // border-radius: 50%;
    // transform: translateY(24%);
    // background:white;

    .new {
      background-image: url(http://64.227.6.98/assets/images/StepNew.svg);
      height: 28px;
      width: 28px;
      display: inline-block;
    }

    .inprogress {
      background-image: url(http://64.227.6.98/assets/images/StepInprogress.svg);
      height: 28px;
      width: 28px;
      display: inline-block;
    }
    .complete {
      background-image: url(http://64.227.6.98/assets/images/StepComplete.svg);
      height: 28px;
      width: 28px;
      display: inline-block;
    }
    .fa {
      left: -3px;
      top: 1px;
      position: absolute;
    }
    .submitted {
      background-image: url(http://64.227.6.98/assets/images/StepSubmitted.svg);
      height: 28px;
      width: 28px;
      display: inline-block;
    }
  }

  .p-timeline-event-separator {
     z-index: 1;
  }

  .p-timeline.p-timeline-vertical {
    .p-timeline-event-opposite {
      padding: 0 5rem !important;
       z-index: 0;
      .separator {
        margin: 0;
        top: 15px;
        width: 18%;
        position: absolute;
        right: 50%;
        z-index: -1;
        border: 2px solid #e1e1e1;
        background: #e1e1e1;
      }
      .p-button {
        top: 2px;
      }
    }
    .p-timeline-event-content {
      padding: 0 5rem !important;
       //z-index: 0;
      .separator {
        position: absolute;
        margin: 0;
        transform: translate(0, 12px);
        width: 14%;
        left: 50%;
        // z-index: -1;
        border: 2px solid #e1e1e1;
        background: #e1e1e1;
      }
    }
  }
}

.p-card {
  box-shadow: none;
  border: 1px solid #e1e1e1;
  .p-card-title {
    color: #007bff;
    font-weight: 600;
    margin: 0;
    font-size: 19px;
  }
}

.bg-gray {
  .p-card {
    background: #f8f8f8;
    box-shadow: none;
    .radio-panel {
      display: inline-block;
      border-radius: 3px;
      background: #f2ffed;
      border: 1px solid #e1e1e1;
      padding: 1rem;
    }
  }
}

.p-accordion {
  .p-accordion-header .p-accordion-header-link {
    background: white !important;
    border: 0 !important;
    padding: 10px !important;
  }
  .p-accordion-content {
    padding: 0 !important;
    border: 1px solid #e1e1e1 !important;
    margin: 0 15px 15px 15px;
    .p-datatable .p-datatable-tbody > tr > td {
      padding: 10px !important;
      vertical-align: top;
      border-bottom: 1px solid #e9ecef;
      &:last-child {
        width: 30%;
        padding: 10px 0px !important;
      }
    }
    .p-datatable .p-datatable-tbody > tr > td > .content {
      padding: 10px !important;
      vertical-align: top;
      &:last-child {
        padding-right: 0px !important;
        padding-left: 0px !important;
      }
    }
    .p-datatable {
      .p-datatable-thead > tr > th:last-child {
        width: 30%;
      }
    }
    .p-button.p-button-icon-only {
      padding: 0px;
      height: 23px;
      font-size: 20px;
      top: -3px;
      // &.notificationButton{
      //   height: 23px;
      //   font-size: 14px!important;
      //   width: 23px;
      //   color: white!important;
      //   margin-left: 8px;
      // }
    }
  }
}

.rfeDialog {
  // .p-dialog{
  //   position: absolute;
  //   right: 10%;
  //   left: auto;
  // }
  .p-dialog-content {
    overflow-y: scroll !important;
    max-height: 90%;
  }
  .p-dialog-mask.p-component-overlay {
    z-index: 1000 !important;
  }
}

.p-overlaypanel {
  z-index: 1;
}

.p-tabview {
  border: 1px solid #e1e1e1;
  margin-top: 10px;
  .p-tabview-panels {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .p-tabview-panel {
    .p-button-outlined {
      padding: 5px 7px !important;
      font-size: 14px !important;
      min-height: auto !important;
      height: auto;
      font-weight: normal !important;
      line-height: 12px !important;
      border-radius: 7px !important;
    }
  }
  .p-tabview-nav {
    li {
      width: 50%;

      .p-tabview-nav-link {
        padding: 10px !important;
        color: #01a0da !important;
        background-color: #f5f5f5 !important;
        font-size: 14px;
        border-right: 1px solid #e1e1e1 !important;
        border-radius: 0 !important;
        .p-tabview-title {
          margin-left: auto;
          margin-right: auto;
        }
      }
      &.p-highlight .p-tabview-nav-link {
        border-color: #e1e1e1 !important;
        color: #6c757d !important;
        // background-color: #a6d5fa !important;
      }
    }
  }
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus,
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

.p-tree {
  border: 0 !important;
  padding: 0 !important;

  .p-treenode-children {
    padding: 0 1rem 1rem !important;
    .p-treenode-droppoint {
      height: 0.5rem !important;
    }
    // .p-treenode-leaf{
    //   border-top:0!important;
    // }
    .p-treenode {
      .p-treenode-content {
        .p-tree-toggler {
          display: none;
        }
      }
    }
  }
  .p-treenode-droppoint {
    height: 1rem !important;
  }
  .p-tree-container {
    .p-treenode {
      padding: 0 !important;
      border: 1px solid #e1e1e1;
      .p-treenode-content {
        border-radius: 0 !important;
        padding: 0 !important;
        &[aria-expanded="false"] {
          .p-datatable-header {
            display: none;
          }
          .p-tree-toggler {
            top: 0px;
          }
        }
        &:focus {
          box-shadow: none !important;
        }
        .p-treenode-label {
          width: 100%;
          .p-button-text {
            padding: 0px;
          }
        }
        .p-tree-toggler {
          top: -2rem;
        }
      }
    }
  }
}

// .p-datatable .p-datatable-tbody > tr:last-child > td {
//   border-bottom: 0;
// }

.summary-accordion {
  .p-accordion {
    .p-accordion-header {
      .p-accordion-header-link {
        padding: 0px !important;
        background-color: #ffffff !important;
        color: #495057 !important;
        .p-accordion-header-text {
          width: auto;
        }
      }
      &:not(.p-disabled) {
        .p-accordion-header-link {
          padding: 0px !important;
          background-color: #ffffff !important;
          color: #495057 !important;
        }
      }
    }
  }
  .p-accordion-content {
    border: 0 !important;
  }
}

#fdnsqaContainer {
  .fdnsqa {
    &.p-tabview {
      border: none !important;
      margin-top: 10px;
      .p-tabview-panels {
        padding: 0px !important;
        margin-top: 20px !important;
        margin-bottom: 20px !important;
      }
      .p-tabview-panel {
        padding: 0px !important;
        .p-button-outlined {
          padding: 5px 7px !important;
          font-size: 14px !important;
          min-height: auto !important;
          height: auto;
          font-weight: normal !important;
          line-height: 12px !important;
          border-radius: 7px !important;
        }
      }
      .p-tabview-nav {
        padding: 0 20px !important;
        //  padding-bottom: 0px !important;
        background-color: #f5f7f7 !important;
        border: 1px solid #dee2e6 !important;
        border-width: 1px !important;
        li {
          width: 25% !important;
          padding: 20px 0;
          // margin-left: 5% !important;
          // margin-right: 5% !important;
          .p-tabview-nav-link {
            padding: 10px !important;
            color: #6c757d !important;
            background-color: inherit !important;
            font-size: 14px;
            border-right: 1px solid #dee2e6 !important;
            border-radius: 0 !important;
            border-width: 0 0 0 0px;
            .p-tabview-title {
              margin-left: auto;
              margin-right: auto;
            }
          }
          &.p-highlight {
            border-bottom: 3px solid #08a3db !important;
            .p-tabview-nav-link {
              color: #08a3db !important;
              background-color: #a6d5fa !important;
            }
          }
        }
      }
    }
  }
}

.overlayContainer {
  height: 500px;
  width: 500px;
  .footer {
    position: absolute;
    bottom: 0;
    width: 94%;
    text-align: center;
  }
}

.overlayContainer {
  .mat-tab-label {
    font-size: 16px;
    font-family: "Source Sans Pro";
  }

  .mat-tab-label-container {
    padding-bottom: 2px;
  }

  .mat-tab-label-active {
    border-bottom: 1px solid #01a0da !important;
    color: #01a0da;
  }

  .mat-tab-body-wrapper {
    min-height: auto !important;
  }

  .mat-ink-bar {
    display: none;
  }
  .mat-tab-header {
    border-bottom: 0;
  }
}

.p-dialog {
  box-shadow: none;
  max-height: 100% !important;
}

.p-dialog-content {
  overflow-y: scroll !important;
  max-height: 100%;
}

.p-fileupload {
  .p-message.p-message-error {
    margin: 0px 0px 10px;
  }
  .p-message .p-message-wrapper {
    padding: 5px;
    font: normal normal normal 14px/20px Source Sans Pro;
  }
}

.joyride-step__container {
  width: 700px;
  padding: 1rem !important;
  border-radius: 5px;
  .joyride-step__header {
    display: none;
  }
  .joyride-step__title {
    font-weight: 500;
    font: normal normal bold 14px/20px Source Sans Pro;
    font-size: 1.5rem;
    color: #257cb0 !important;
  }

  .joyride-step__footer {
    display: none;
  }
  .joyride-step__button {
    display: inline;
    .joyride-button {
      margin: 0;
      background-color: white !important;
      color: #ccd4de !important;
      font-size: 34px;
      padding: 0;
      line-height: 18px;
      font-weight: normal;
      vertical-align: middle;

      &:hover {
        border: 2px solid transparent !important;
        color: #687c93 !important;
      }
    }
  }

  .joyride-step__close {
    display: none;
  }

  .btn-primary {
    text-transform: none;
    font-weight: normal !important;
    font-size: 18px !important;
  }

  .navigationButtons .btn {
    padding: 0 !important;
    min-width: 0 !important;
    &:focus {
      box-shadow: none;
    }
    i {
      font-size: 40px;
      margin-top: -6px;
      width: 30px;
      color: #687c93;
    }
  }
}

.lca-acknowledgement-container {
  .lcaAcknowledge {
    &.p-tabview {
      border: none !important;

      .p-tabview-panels {
        padding: 0px !important;
      }
      .p-tabview-panel {
        padding: 0px !important;

        .p-button-outlined {
          padding: 7px 7px !important;
          font-size: 14px !important;
          min-height: auto !important;
          height: auto;
          font-weight: normal !important;
          line-height: 14px !important;
          border-radius: 0px !important;
        }
        .p-button-secondary,
        .p-button-primary {
          padding: 7px 7px !important;
          line-height: 14px !important;
          border-radius: 0px !important;
          text-transform: uppercase;
          font-size: 14px;
        }
      }
      .p-tabview-nav {
        border: none !important;

        li {
          width: auto !important;

          margin-left: 1% !important;
          margin-right: 1% !important;
          .p-tabview-nav-link {
            //  padding: 10px !important;
            color: #6c757d !important;
            background-color: inherit !important;
            font-size: 16px;
            border-right: 0px !important;
            border-radius: 0 !important;
            border-width: 0 0 0 0px;
            .p-tabview-title {
              margin-left: auto;
              margin-right: auto;
            }
          }
          &.p-highlight {
            border-bottom: 3px solid #08a3db !important;
            .p-tabview-nav-link {
              color: #08a3db !important;
              background-color: #a6d5fa !important;
            }
          }
        }
      }
    }
  }
}

.overlayPanel {
  top: 38px !important;
  left: 15px !important;
}

.fd_hr_tab_style {
  &.p-tabview {
    border-top: none !important;
    border-right: none !important;
    // border: none !important;
    // margin-top: 10px;
    .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      border-bottom: 4px solid #6eb4ff !important;
      border-right: 1px solid #e1e1e1 !important;
      background-color: #ffffff !important;
    }

    .p-tabview-nav li.custom-add-tab {
      flex-grow: 1;
      border-bottom: 1px solid #dee2e6 !important;
      max-width: 100%;
    }

    .p-tabview-nav li.custom-add-tab .p-tabview-nav-link {
      background-color: transparent !important;
      border-right: none !important;
      border-top: none !important;
      border-bottom: none !important;
      pointer-events: none;
    }
    .p-tabview-nav li.custom-add-tab .p-tabview-nav-link * {
      pointer-events: auto;
    }
    .p-tabview-nav li {
      flex: 1;
      max-width: calc(100% / var(--tab-count, 1)) !important;
      //max-width: 200px;
    }
    .p-tabview-nav li .p-tabview-nav-link {
      height: 50px;
      width: 100%;
      min-width: 150px;
      line-height: 40px;
      border-bottom: 1px solid #dee2e6 !important;
      border-top: 1px solid #dee2e6 !important;
    }

    .p-tabview-nav {
      border-left: none !important;
      border-right: none !important;

      li.p-highlight .p-tabview-nav-link .p-link-active {
        border-bottom: none !important;
      }

    }
   .p-tabview-panels {
    border-right:  1px solid #dee2e6 !important;
      // padding: 0px !important;
      // margin-top: 20px !important;
      // margin-bottom: 20px !important;
   }
    .p-tabview-panel {
      padding: 0px !important;
      //border-right:  1px solid #dee2e6 !important;
    }
    .p-tabview-nav {
     // padding: 0 20px !important;
      //  padding-bottom: 0px !important;
     // background-color: #f5f7f7 !important;
     // border: 1px solid #dee2e6 !important;
     // border-width: 1px !important;
      border-bottom: none !important;
      li {
        width: auto !important;
       // padding: 20px 0;
        // margin-left: 5% !important;
        // margin-right: 5% !important;
        .p-tabview-nav-link {
          padding: 10px !important;
          color: #6c757d !important;
          background-color: inherit !important;
          font-size: 14px;
          border-right: 1px solid #dee2e6 !important;
          border-radius: 0 !important;
          border-width: 0 0 0 0px;
          .p-tabview-title {
            margin-left: auto;
            margin-right: auto;
          }
        }
       // &.p-highlight {
         // border-bottom: 3px solid #08a3db !important;
         // .p-tabview-nav-link {
            // color: #08a3db !important;
            // background-color: #a6d5fa !important;
          //}
        //}
      }
    }
  }
}

.fd_vr_tab_style {
  &.p-tabview {
   // border-top: none !important;
   // border-right: none !important;
    // border: none !important;
    // margin-top: 10px;
    border:  1px solid #dee2e6 !important;
    display: flex;
    border-width: 2px !important;
    // .fd_vr_tab_style {
    //   & .p-tabview .p-tabview-nav {
    //     display: flex;
    //     flex-direction: column;
    //   }
    // }
    .p-tabview-nav:not(.fd_hr_tab_style > .p-tabview-nav) {
      display: flex;
      flex-direction: column;
      padding-top: 12px !important;
      // padding: 0 20px !important;
       padding-bottom: 24px !important;
      background-color: #fff !important;
      //border: 1px solid #dee2e6 !important;
      border-width: 1px !important;
      border-bottom: none !important;
      li.custom-add-tab {
        flex-grow: 1;
        margin-top: -10px;
        border-right: 1px solid #dee2e6 !important;
       //border-bottom: 1px solid #dee2e6 !important;
       .p-tabview-nav-link {
        background-color: transparent !important;
        border: none !important;
        // border-right: none !important;
        // border-top: none !important;
        // border-bottom: none !important;
        pointer-events: none;
      }

      }
      // li.p-highlight .p-tabview-nav-link {
      //   border-right: none !important;
      //   //border-right: 1px solid #e1e1e1;
      //   background-color: #ffffff !important;;
      // }
      li {
        width: auto !important;
       // padding: 20px 0;
        // margin-left: 5% !important;
        // margin-right: 5% !important;
        .p-tabview-nav-link {
          padding: 10px !important;
          color: #6c757d !important;
          //background-color: inherit !important;
          font-size: 14px;
          border-right: 1px solid #dee2e6 !important;
          border-left: 1px solid #dee2e6 !important;
          margin-left: 28px;
          margin-top: 12px;
          border-radius: 0 !important;
          border-width: 0 0 0 0px;
          line-height: 16px;
          .p-tabview-title {
            margin-left: auto;
            margin-right: auto;
          }
        }
       // &.p-highlight {
         // border-bottom: 3px solid #08a3db !important;
         // .p-tabview-nav-link {
            // color: #08a3db !important;
            // background-color: #a6d5fa !important;
          //}
       // }
      }
    }
    .p-tabview-nav li.p-highlight .p-tabview-nav-link {
      border-right: none !important;
      //border-right: 1px solid #e1e1e1;
      background-color: #ffffff !important;;
    }

    // .p-tabview-nav li.custom-add-tab {
    //   flex-grow: 1;
    //   margin-top: -10px;
    //   border-right: 1px solid #dee2e6 !important;
    //  //border-bottom: 1px solid #dee2e6 !important;
    // }

    // .p-tabview-nav li.custom-add-tab .p-tabview-nav-link {
    //   background-color: transparent !important;
    //   border: none !important;
    //   // border-right: none !important;
    //   // border-top: none !important;
    //   // border-bottom: none !important;
    //   pointer-events: none;
    // }

    .p-tabview-nav li.custom-add-tab .p-tabview-nav-link * {
      pointer-events: auto;
      position: relative;
      left: 12px;
    }

    .p-tabview-nav li.custom-add-tab .p-tabview-nav-link i {
      pointer-events: auto;
      color: #3b922b !important;
      font-size: 20px;
    }
   .p-tabview-nav li.custom-add-tab .p-tabview-nav-link span {
      position: relative;
      bottom: 2px;
      left: 8px;
    }

    .p-tabview-nav li .p-tabview-nav-link {
      height: 50px;
      width: 180px;
      line-height: 40px;
      border-bottom: 1px solid #dee2e6 !important;
      border-top: 1px solid #dee2e6 !important;
    }

    .p-tabview-nav {
      border-left: none !important;
      border-right: none !important;

      li.p-highlight .p-tabview-nav-link .p-link-active {
        border-bottom: none !important;
      }

    }
   .p-tabview-panels {
    flex-grow: 1;
    // border:  1px solid #dee2e6 !important;
      // padding: 0px !important;
      // margin-top: 20px !important;
      // margin-bottom: 20px !important;
   }
    .p-tabview-panel {
      padding: 0px !important;
      //border:  1px solid #dee2e6 !important;
    }
    // .p-tabview-nav {
    //   padding-top: 12px !important;
    //  // padding: 0 20px !important;
    //   //  padding-bottom: 0px !important;
    //  background-color: #f5f7f7 !important;
    //  //border: 1px solid #dee2e6 !important;
    //  border-width: 1px !important;
    //  border-bottom: none !important;

    // }
  }
}
