.gc-file-upload {
  width: 121px;
  display: block;
  &.disabled {
    .p-fileupload {
      cursor: not-allowed;
    }
  }
  .p-fileupload {
    width: 121px;
    .p-button {
      border: 1px solid #027cff !important;
      border-radius: 6px !important;
      background-color: #ffffff !important;
      color: #027cff !important;
      font-size: 18px !important;
      line-height: 22px;
      height: 40px;
      width: 121px;
      margin-top: 10px;
      &.p-disabled {
        cursor: not-allowed !important;
      }
    }
    .p-message.p-message-error {
      background: rgba(0, 0, 0, 0);
      border: 0;
      color: #f44336;
      white-space: nowrap;
      margin: 0 0;
      height: 0;
      font-size: 12px;
      position: relative;
      top: 55px;
      .p-message-wrapper {
        padding: 0;
        .p-message-icon {
          display: none;
        }
      }
    }
  }
}

.upload-container {
  border: 1px dashed #dee2e6;
  border-radius: 4px;
  margin: 30px 100px 50px;
  position: relative;
  &.files-exit {
    .p-component.p-fileupload.p-fileupload-advanced {
      .p-fileupload-buttonbar {
        .p-button {
          display: none;
          &.p-fileupload-choose {
            display: none;
          }
        }
        p-button {
          display: none;
          // &:nth-child(2) {
          //   display: block;
          //   .p-button {
          //     display: block;
          //     padding: 0;
          //     border: 0;
          //     width: 186px;
          //     height: 36px;
          //     background-color: rgba(0, 0, 0, 0);
          //     margin: 0;
          //     color: rgba(0, 0, 0, 0);
          //   }
          // }
        }
      }
    }
  }
  .p-component.p-fileupload.p-fileupload-advanced {
    background: rgba(0, 0, 0, 0);
    .p-fileupload-content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0);
      z-index: 1;
      * {
        display: none !important;
      }
    }
    .p-fileupload-buttonbar {
      position: absolute;
      width: 186px;
      height: 36px;
      border: 0;
      padding: 0;
      top: 270px;
      left: calc(50% - 93px);
      z-index: 2;
      background-color: rgba(0, 0, 0, 0);
      .p-button {
        display: none;
        &.p-fileupload-choose {
          width: 186px;
          padding: 0;
          height: 36px;
          border: 0;
          background-color: rgba(0, 0, 0, 0);
          display: block;
          margin: 0;
          color: rgba(0, 0, 0, 0);
        }
      }
      p-button {
        display: none;
      }
    }
  }
  .upload-pdf-img {
    width: 70px;
    height: 87px;
    position: absolute;
    top: 50px;
    left: calc(50% - 35px);
  }
  .upload-bold-txt {
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    display: block;
    margin-top: 160px;
    margin-bottom: 10px;
  }
  .upload-normal-txt {
    display: block;
    font-size: 19px;
    text-align: center;
    margin-bottom: 5px;
  }
  .upload-choose-btn {
    background-color: #1aa0da;
    color: #fff;
    width: 186px;
    height: 36px;
    padding: 8px 40px;
    font-size: 13px;
    border-radius: 4px;
    margin: 5px auto 50px;
  }
  .selected-file-holder {
    margin: 30px;
    width: calc(100% - 60px);
    background-color: #edf4fb;
    padding-top: 8px;
    padding-bottom: 8px;
    .uploaded-file-txt {
      outline-width: 0px;
    }
    .selected-file-delete {
      .p-button {
        height: 40px;
        color: #465f7c;
        background-color: rgba(0, 0, 0, 0) !important;
        border: 0;
        margin-right: 15px;
      }
    }
    .selected-file-upload {
      .p-button {
        height: 40px;
      }
    }
  }
  .upload-success {
    border: 1px solid #707070;
    padding: 10px 15px;
    background-color: #eaf8e7;
    height: 55px;
    margin: 0 auto 30px;
    width: max-content;
    position: relative;
    z-index: 1;
    i.pi.pi-check {
      color: #eaf8e7;
      background-color: #41ad49;
      border-radius: 50%;
      padding: 7px;
      font-size: 21px;
      font-weight: 700;
      margin-right: 10px;
      float: left;
    }
    span {
      color: #000000;
      line-height: 35px;
      float: left;
      font-size: 18px;
      margin-right: 50px;
    }
    a {
      color: #0f92e5;
      font-size: 18px;
      float: left;
      line-height: 35px;
      font-weight: 600;
      text-decoration: none;
    }
  }
  .uploaded-file-list {
    position: relative;
    z-index: 1;
    padding: 15px;
    .p-datatable table {
      border: 0 !important;
      .p-datatable-thead tr {
        height: 0px;
        th {
          padding: 0;
        }
      }
      .p-datatable-tbody tr {
        td {
          padding: 0 0 15px 0 !important;
          position: relative;
          .p-datatable-reorderablerow-handle {
            position: absolute;
            z-index: 2;
            top: 15px;
            right: 15px;
          }
          .uploaded-file-delete {
            position: absolute;
            z-index: 2;
            top: 6px;
            right: 46px;
            .p-button {
              color: #465f7c;
              background-color: rgba(0, 0, 0, 0);
              border: 0;
            }
          }
          .p-accordion.p-component {
            background-color: #edf4fb;
            padding-bottom: 15px;
            p-accordiontab {
              .p-accordion-tab {
                .p-accordion-header {
                  .p-accordion-header-link {
                    background-color: #edf4fb !important;
                    padding: 15px 15px 0 !important;
                    transition: padding 1s;
                  }
                }
                &.p-accordion-tab-active {
                  .p-accordion-header {
                    .p-accordion-header-link {
                      padding: 15px 15px 15px !important;
                    }
                  }
                }
                &:last-child .p-accordion-content {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.build-steps-content-div {
  border: 1px solid #bcc5d3;
  border-width: 0 1px 1px 1px;
  background-color: #f7faff;
  padding: 15px 0;
  .build-steps-scrollable {
    max-height: calc(100vh - 140px);
    overflow: auto;
    .uploaded-file-list {
      .p-datatable table {
        border: 0 !important;
        .p-datatable-thead tr {
          height: 0px;
          th {
            padding: 0;
          }
        }
        .p-datatable-tbody {
          tr {
            &:last-child {
              td {
                padding: 0 0 0 0 !important;
              }
            }
            td {
              padding: 0 0 15px 0 !important;
              position: relative;
              background-color: #f7faff;
              .p-datatable-reorderablerow-handle {
                position: absolute;
                z-index: 2;
                top: 15px;
                right: 15px;
              }
              .uploaded-file-delete {
                position: absolute;
                z-index: 2;
                top: 6px;
                right: 46px;
                .p-button {
                  color: #465f7c;
                  background-color: rgba(0, 0, 0, 0);
                  border: 0;
                }
              }
              .p-accordion.p-component {
                background-color: #edf4fb;
                padding-bottom: 15px;
                p-accordiontab {
                  .p-accordion-tab {
                    .p-accordion-header {
                      .p-accordion-header-link {
                        background-color: #edf4fb !important;
                        padding: 15px 15px 0 !important;
                        transition: padding 1s;
                      }
                    }
                    &.p-accordion-tab-active {
                      .p-accordion-header {
                        .p-accordion-header-link {
                          padding: 15px 15px 15px !important;
                        }
                      }
                    }
                    .p-accordion-content {
                      margin-bottom: 0;
                      background-color: #edf4fb;
                      border-width: 1px 0 0 0 !important;
                      border-style: dashed !important;
                      border-color: #aaaaaa !important;
                      .disabled-element {
                        position: absolute;
                        width: 100%;
                        height: calc(100% - 1rem);
                        top: -0.5rem;
                        z-index: 1;
                        left: 0;
                        background-color: rgba(0, 0, 0, 0.05);
                        cursor: not-allowed;
                      }
                      .part-title {
                        font-size: 20px;
                        font-weight: 700;
                        margin-bottom: 15px;
                        display: block;
                      }
                      .sub-head-title {
                        font-size: 19px;
                        font-weight: 600;
                        display: block;
                        margin-bottom: 10px;
                      }
                      .element-head-title {
                        font-size: 18px;
                        margin-bottom: 5px;
                        display: block;
                      }
                      .questionnaire-lbl {
                        float: left;
                        margin-right: 15px;
                        margin-top: 3px;
                        margin-bottom: 0;
                        max-width: calc(100% - 70px);
                      }
                      .p-inputswitch.questionnaire-switch {
                        background-color: rgba(0, 0, 0, 0);
                      }
                      .json-viewer-textarea {
                        width: 100%;
                      }
                      .form-group {
                        .json-viewer-input {
                          width: 350px;
                          max-width: 100%;
                        }
                        .pdf-checkbox {
                          width: max-content;
                          margin-right: 15px;
                          float: left;
                          max-width: 100%;
                        }
                        .pdf-radiobutton {
                          width: max-content;
                          margin-right: 15px;
                          float: left;
                          max-width: 100%;
                        }
                        .pdf-dropdown {
                          &.p-dropdown {
                            width: 350px;
                            max-width: 100%;
                          }
                        }
                        .vd-pdf-datepicker {
                          &.p-calendar {
                            width: 350px;
                            .p-inputtext {
                              height: 38px;
                            }
                            .p-datepicker {
                              left: auto !important;
                              right: 0px !important;
                              .p-datepicker-header .p-datepicker-title select {
                                border-radius: 3px;
                                padding: 2px;
                              }
                            }
                            .p-datepicker-trigger.p-component.p-button.p-button-icon-only {
                              height: 38px;
                              top: 0;
                              color: #ffffff;
                              background-color: #2196f3;
                              border-color: #2196f3 !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.pdf-preview-step-popup {
  .part-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
    display: block;
  }
  .sub-head-title {
    font-size: 19px;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
  }
  .element-head-title {
    font-size: 18px;
    margin-bottom: 5px;
    display: block;
  }
  .questionnaire-lbl {
    float: left;
    margin-right: 15px;
    margin-top: 3px;
    margin-bottom: 0;
    max-width: calc(100% - 70px);
  }
  .p-inputswitch.questionnaire-switch {
    background-color: rgba(0, 0, 0, 0);
  }
  .json-viewer-textarea {
    width: 100%;
  }
  .form-group {
    .json-viewer-input {
      width: 350px;
      max-width: 100%;
    }
    .pdf-checkbox {
      width: max-content;
      margin-right: 15px;
      float: left;
      max-width: 100%;
    }
    .pdf-radiobutton {
      width: max-content;
      margin-right: 15px;
      float: left;
      max-width: 100%;
    }
    .pdf-dropdown {
      &.p-dropdown {
        width: 100%;
        max-width: 100%;
      }
    }
    .vd-pdf-datepicker {
      &.p-calendar {
        width: 100%;
        .p-inputtext {
          height: 38px;
        }
        .p-datepicker {
          left: auto !important;
          right: 0px !important;
          .p-datepicker-header .p-datepicker-title select {
            border-radius: 3px;
            padding: 2px;
          }
        }
        .p-datepicker-trigger.p-component.p-button.p-button-icon-only {
          height: 38px;
          top: 0;
          color: #ffffff;
          background-color: #2196f3;
          border-color: #2196f3 !important;
        }
      }
    }
  }
}

.dynamic-menu-img-upload-container {
  background-color: #f8f8f8;
  height: 75px;
  border-radius: 3px;
  padding: 17px 10px 17px 10px;
  .dynamic-menu-image-upload {
    width: 111px;
    float: left;
    margin-right: 10px;
    &.disabled {
      .p-fileupload {
        cursor: not-allowed;
      }
    }
    .p-fileupload {
      width: 111px;
      .p-button {
        border: 0px;
        border-radius: 3px !important;
        background-color: #efefef !important;
        color: #a3a6b4 !important;
        font-size: 18px !important;
        line-height: 22px;
        height: 40px;
        width: 111px;
        &.p-disabled {
          cursor: not-allowed !important;
        }
      }
      .p-message.p-message-error {
        background: rgba(0, 0, 0, 0);
        border: 0;
        color: #f44336;
        white-space: nowrap;
        margin: 0 0;
        height: 0;
        font-size: 12px;
        position: relative;
        top: 55px;
        .p-message-wrapper {
          padding: 0;
          .p-message-icon {
            display: none;
          }
        }
      }
    }
  }
  .dynamic-menu-img-upload-name-container {
    float: left;
    width: calc(100% - 130px);
    .fileuploaded-name-anchor {
      cursor: pointer;
      float: left;
      margin-right: 10px;
      max-width: calc(100% - 40px);
      .fileuploaded-name {
        line-height: 40px;
        color: #027cff;
        overflow: hidden;
        white-space: nowrap;
        display: block;
        text-overflow: ellipsis;
        .pi.pi-image {
          color: #e04443;
          font-weight: 600;
          margin-right: 5px;
          float: left;
          line-height: 40px;
        }
      }
      &:hover {
        .fileuploaded-name {
          text-decoration: underline;
          .pi.pi-image {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.create-application-hidden {
  .p-fileupload {
    display: none;
  }
}
